import "./EmptyResult.css";
import emptyResponseAvatar from "../../assets/empty-response.svg";
import { translate } from "../../infrastructure/i18n/InternationalizationService";

export function EmptyResult() {
  return (
    <div className="avatar-empty-response">
      <img src={emptyResponseAvatar} alt="Empty results" title={translate("EMPTYRESULT.Description")} />
    </div>
  );
}
