import React from "react";
import "./AllocationHistoryPanel.css";
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, TooltipDelay, TooltipHost } from "@fluentui/react";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { useMemo } from "react";
import { useClosePanel } from "../../../../infrastructure/ui/UIServices";
import { HistoryDTO } from "../../../../services/AllocationsService";
import dayjs from "dayjs";

import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

interface IAllocationHistoryPanelProps {
  allocationHistory: HistoryDTO[];
}

export function AllocationHistoryPanel(props: IAllocationHistoryPanelProps) {
  const closePanel = useClosePanel();

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "column1",
        name: translate("ALLOCATIONS.COMMON.AllocatedBy"),
        isRowHeader: true,
        isResizable: true,
        fieldName: "employeeApprover",
        minWidth: 50,
        maxWidth: 135,
        data: "string",
        onRender: (item: any) => <>{item.employeeApprover}</>,
      },
      {
        key: "column2",
        name: translate("COMMON.Date"),
        isRowHeader: true,
        isResizable: true,
        fieldName: "date",
        minWidth: 70,
        maxWidth: 70,
        data: "string",
        onRender: (item) => (
          <div>
            {dayjs(item.date).format("DD/MM/YYYY ")}
            <br />
            {dayjs(item.date).format("HH:mm")}
          </div>
        ),
      },
      {
        key: "column3",
        name: translate("COMMON.Status"),
        fieldName: "state",
        minWidth: 35,
        maxWidth: 40,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        onRender: (allocationHistory: any) =>
          allocationHistory.state ? (
            <TooltipHost content={translate("COMMON.Status") + " " + allocationHistory.state.toLowerCase()} delay={TooltipDelay.zero}>
              <div className={`state-dot ${allocationHistory.state.toLowerCase()}`}></div>
            </TooltipHost>
          ) : null,
      },
    ],
    [props.allocationHistory]
  );

  return (
    <div className="allocation-history-panel">
      <CardHeader headerTitle={translate("ALLOCATIONS.AllocationHistoryTitle")} icon={true} setDisplayState={(state: boolean) => closePanel()} />
      <DetailsList
        className="allocation-history-list"
        items={props.allocationHistory}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </div>
  );
}
