import "./../../App.css";

import { Route, Routes } from "react-router-dom";

import { EmployeeHoursPage } from "./hours/EmployeeHoursPage";
import { EmployeeInfoPage } from "./employee-info/EmployeeInfoPage";
import { FeatureFlags } from "../../infrastructure/features/Flags";
import { FontIcon } from "@fluentui/react";
import { Header } from "../../components/header/Header";
import { ReactComponent as IconNavDocs } from "../../assets/docs-icon.svg";
import { ReactComponent as IconNavEquipments } from "../../assets/equipment-icon.svg";
import { ReactComponent as IconNavHours } from "../../assets/hours-icon.svg";
import { ReactComponent as IconNavProfile } from "../../assets/user-icon.svg";
import { ReactComponent as IconNavWorkingplaces } from "../../assets/working-place.svg";
import { MobileNav } from "../../components/navbar/MobileNav";
import { ModuleDefinition } from "../../infrastructure/modules/ModuleDefinition";
import { ModuleHelper } from "../../infrastructure/modules/ModuleHelper";
import { MyDocs } from "./my-docs/MyDocs";
import { MyEquipments } from "./my-equipments/MyEquipments";
import { NavBar } from "../../components/navbar/NavBar";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../../infrastructure/navigation/hooks/useBreadcrumb";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

export const ROUTES = {
  ROOT: "/me",
  Profile: {
    PATH: "/",
  },
  Equipments: {
    PATH: "/equipments",
  },
  Documents: {
    PATH: "/documents",
  },
  Hours: {
    PATH: "/hours",
  },
  Workplaces: {
    PATH: "/workplaces",
  },

  Sharepoint: {
    PATH: "/sharepoint",
  },
};

const moduleDefinition: ModuleDefinition = {
  basePath: ROUTES.ROOT,
  routes: [
    {
      name: () => translate("MENU.Profile"),
      pathPattern: ROUTES.Profile.PATH,
      menuIcon: <IconNavProfile />,
      element: <EmployeeInfoPage />,
      features: [FeatureFlags.EMPLOYEE_PROFILE],
    },
    {
      name: () => translate("MENU.Equipments"),
      pathPattern: ROUTES.Equipments.PATH,
      menuIcon: <IconNavEquipments />,
      element: <MyEquipments /* deviceList={deviceList} */ />,
      features: [FeatureFlags.EMPLOYEE_PROFILE],
    },
    {
      name: () => translate("MENU.Documents"),
      pathPattern: ROUTES.Documents.PATH,
      menuIcon: <IconNavDocs />,
      element: <MyDocs />,
      features: [FeatureFlags.EMPLOYEE_DOCUMENTS],
    },
    {
      name: () => translate("MENU.Hours"),
      pathPattern: ROUTES.Hours.PATH,
      menuIcon: <IconNavHours />,
      element: <EmployeeHoursPage />,
      features: [FeatureFlags.EMPLOYEE_HOURS],
    },
    {
      name: () => translate("MENU.Sharepoint"),
      pathPattern: "https://n4it.sharepoint.com/",
      newTab: true,
      isExternalLink: true,
      menuIcon: <FontIcon aria-label="SharepointLogo" iconName="SharepointLogo" className="sharepoint-logo" />,
      element: null,
      features: [FeatureFlags.EMPLOYEE_HOURS],
    },
  ],
};

const menus = () => [
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 0),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 1),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 2),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 3),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 4),
];

export function EmployeeModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();
  const windowResize = useWindowResize();

  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  return (
    <>
      <div className="App">
        {windowResize > 768 ? <NavBar buttons={menus()} /> : null}
        <div className="content-wrapper">
          <Header btnActiveText={translate("AREA.Employee")} />
          <div className="content">
            <Routes>
              {moduleDefinition.routes
                .filter((a) => !a.isExternalLink)
                .map((r) => (
                  <Route key={r.pathPattern} path={r.pathPattern} element={r.element} />
                ))}
            </Routes>
          </div>
        </div>
      </div>
      {windowResize <= 768 ? <MobileNav buttons={menus()} btnActiveText={translate("AREA.SHORT.Employee")} /> : null}
    </>
  );
}
