import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
  AREA: {
    Manager: "Manager",
    Employee: "Employee",

    SHORT: {
      Manager: "M",
      Employee: "E",
    },
  },

  MENU: {
    Profile: "Profile",
    Hours: "Hours",
    Employees: "Employees",
    Projects: "Projects",
    Equipments: "Equipments",
    Documents: "Documents",
    Sharepoint: "Sharepoint",
  },

  PROFILE: {
    MyProfile: "My Profile",
    PersonalInfo: "Personal Information",
    Phone: "Phone",
    Address: "Address",
    Nationality: "Nationality",
    Email: "Email",
  },

  LOADING: {
    LoadingDataPleaseWait: "Loading data, please wait ...",
    PleaseWait: "Please, wait ...",
  },

  CALENDAR: {
    Weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    Months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  },

  EMPTYRESULT: {
    Description: "No results were found ...",
  },

  ASSOCIATIONS: {
    EmptyListTitle: "No employees yet",
    EmptyAssociationsProjectListMessage: "This project has no associated employees.",
    EmptyAssociationsActivitiesListMessage: "This activity has no associated employees.",
    AddEmployee: "Add employee",
    GrantedBy: "Granted by",
    SuccessfullAssociation: "You have successfully made an employee association.",

    REMOVE: {
      Message: "Are you sure you want to remove this association?",
      Title: "Remove association?",
      SuccessfullResponse: "Association removed with success.",
    },

    WARNINGS: {
      ThisActivityIsPublic: "This activity is public!",
      ThisProjectIsPublic: "This project is public!",
    },

    ERRORS: {
      UserNotSelected: "Employee not selected",
    },
  },

  MANAGERHOURS: {
    SelectAll: "Select all",
    DeselectAll: "Deselect all",
    TotalHoursView: "Total hours view",

    APPROVE: {
      Title: "Approve hours?",
      Message: "You are about to approve the hours from the selected days.",
      SuccessResponse: "The hours have been approved.",
    },

    DECLINE: {
      Title: "Decline hours?",
      Message: "You are about to decline the hours from the selected days.",
      Reason: "Reason",
      SuccessResponse: "The hours have been declined.",
    },

    EXPORT: {
      Export: "Export",
      CurrentMonthAllEmployees: "Current month - All employees",
      EmployeeCurrentMonth: "Current month",
      EmployeeCurrentMonthPdf: "Current month PDF",
      EmployeeCurrentMonthTimesheet: "Current month TimeSheet PDF",
    },
  },

  PROJECTS: {
    AddProject: "Add project",
    AddProjectSuccessfully: "You have inserted a project successfully",

    EDIT: {
      Title: "Edit project info",
      EditedWithSuccess: "Edited with success.",
      VisibilityChangedWithSuccess: "Project visibility changed.",
      AreYouSureYouWantToChangeVisibility: "Are you sure you want to change the project visibility?",
    },

    DISABLE: {
      DisableProject: "Disable project",
      ProjectDisabled: "Project disabled",
      AreYouSureYouWantDisable: "Are you sure you want to deactivate the project?",
    },

    ERRORS: {
      LoadingProject: "Error while trying to load project details due to an invalid project ID",
      InvalidProjectId: "Invalid project ID",
    },

    COMMANDBAR: {
      PHSearchProject: "Search project",
      RefreshCache: "Refresh cache",
    },
  },

  ACTIVITIES: {
    Activity: "Activity",
    AddActivity: "Add activity",
    ProjectActivitiesName: "Project activities",
    ActivityDisabled: "Activity disabled",
    DisableActivity: "Disable activity",

    EMPTYLIST: {
      Message: "This project has no activities! Try add one!",
      Title: "No activities yet",
    },

    ADD: {
      SuccessMessage: "You have inserted a new activity.",
    },

    EDIT: {
      SuccessMessage: "You have edited an activity successfully.",
      Title: "Edit activity info",
      VisibilityChangedWithSuccess: "Activity visibility changed.",
      AreYouSureYouWantToChangeVisibility: "Are you sure you want to change the project visibility?",
    },

    DISABLE: {
      DisableWithSuccess: "Activitiy disabled with success.",
      AreYouSureYouWantDisable: "Are you sure you want to deactivate the activity?",
    },

    ERRORS: {
      ActivityIdNotValidLongMessage: "Error while trying to load activity details due to an invalid activity ID.",
      ActivityIdNotValid: "Activity ID not valid",
    },
  },

  ALLOCATIONS: {
    CreateAllocation: "Create allocation",
    AllocationHistoryTitle: "Allocation history",
    SelectOneEmployee: "Select one employee",
    AllocationCreatedWithSuccess: "Allocation created with success.",
    AllocationChangedTo: "You have changed the allocation status to",

    COMMON: {
      AllocatedBy: "Allocated by",
      AllocateTo: "Allocate to",
      Approved: "Approved",
    },

    WARNINGS: {
      WantToChangeAllocationStatus: "Change the allocation status?",
      AreYouSureToChangeAllocationStatus: "Are you sure you want to change the allocation status?",
    },
  },

  MYEQUIPMENTS: {
    NoEquipmentsAllocated: "No equipments allocated to you.",
  },

  EMPLOYEES: {
    Photo: "Photo",
    Position: "Position",
    Contact: "Contact",
  },

  ERRORPAGES: {
    Unavailable: "Something Went Wrong",

    NOTFOUND: {
      Title: "Page Not Found",
      Message: "We can't seem to find the page you are looking for.",
    },

    EXPIRED: {
      Title: "Session Expired",
      Message: "Your session has expired due to your inactivity.",
    },
  },

  ACTIONS: {
    Allocate: "Allocate",
    Edit: "Edit",
    Save: "Save",
    Ok: "OK",
    Confirm: "Confirm",
    SeeDetails: "See Details",
    Clone: "Clone",
    Remove: "Remove",
    Cancel: "Cancel",
    ChangeVisibility: "Change visibility?",
    Override: "Override",
    TryAgain: "Try Again",
    Done: "Done",
    Continue: "Continue",
    AreYouSure: "Are you sure?",
    Error: "Error!",
    Success: "Success!",
    Approve: "Approve",
    Decline: "Decline",
    Close: "Close",
    Create: "Create",
    Deactivate: "Deactivate",
    Change: "Change",
    Add: "Add",
    Delete: "Delete",
    View: "View",
    Info: "Info",
    Back: "Back ",
  },

  HOURS: {
    ClickToAllocateTime: "Click to Allocate Time",
    EditTimeAllocation: "Edit Time Allocation",
    CloneFromX: "Clone from {0}",
    SelectTheTargetDaysFromTheCalendar: "Select the target days from the calendar.",

    YouHaveClonedADailyRecord: "You have cloned a daily record.",
    YouHaveCreatedADailyRecord: "You have created a daily record.",
    YouHaveEditedADailyRecord: "You have edited a daily record.",
    YouHaveDeletedADailyRecord: "You have deleted a daily record.",

    AllocateTime: "Allocate Time",
    MorningTime: "Morning Time",
    AfternoonTime: "Afternoon Time",
    WorkingTime: "Working Time",
    ProjectHours: " Project Hours",
    Hours: "Hours",
    Hrs: "Hrs",
    TotalWorkingHours: "Total Working Hours: {0}",
    TotalProjectHours: "Total Project Hours: {0}h",
    Notes: "Notes",

    ACTIONS: {
      Allocate: "Allocate",
      AddHours: "Add Hours",
      SelectActivity: "Select Activity",
      SelectProject: "Select Project",
      DeleteTimeRecord: "Delete time record?",
    },

    ERRORS: {
      TargetDaysAlreadyContainsInformation: "Target days already contain information!",
      XAlreadyContainsInformation: "{0}, already contain information.",
      DoYouWantToOverride: "Do you want to override?",
    },

    WARNINGS: {
      YouAreAboutToPermanentlyRemoveATimeRecord: "You are about to permanently delete this time record.",
    },
  },

  COMMON: {
    RequiredField: "Input field required",
    History: "History",
    ApprovedBy: "Approved By",
    PermanentAction: "This is a permanent action.",
    PlaceholderSearchEmployee: "Search an employee",
    OtherOptions: "Other options",
    Private: "Private",
    Public: "Public",
    Visibility: "Visibility",
    Employee: "Employee",
    Employees: "Employees",
    Name: "Name",
    AllocatedTo: "Allocated to",
    Date: "Date",
    Status: "Status",
    Type: "Type",
    Client: "Client",
    Code: "Code",
    SelectEmployee: "Select an employee",
  },

  AREATITLES: {
    Allocations: "Allocations",
    MyEquipments: "My equipments",
    ActivityInfo: "Activity info",
    ProjectInfo: "Project info",
  },

  EQUIPMENTS: {
    AddEquipment: "Add equipment",
    ClickToFilterEquipments: "Filters",
    PlaceholderSearchFor: "brand, model, serial, ...",
    EquipmentStatus: "Equipment status: ",
    DeactivatedWithSucess: "You have deactived the equipment successfully",
    NoPhotos: "No photos available",

    FILTERS: {
      MainTitle: "Filters",
      TemplatesTitle: "Quick search",
      TemplateInRepair: "In repair",
      TemplateNeedsRepair: "Needs repair",
      TemplateAvailablePC: "Available PC's",
      TemplateMonitor: "Available monitors",
      AllocateTo: "Allocated to",
      PhAllocateTo: "Select and employee",
      EquipmentType: "Equipment type",
      PhEquipmentType: "Select one or more types",
      AllocationStatus: "Allocation status",
      PhAllocationStatus: "Select one or more allocation statuses",
      EquipmentStatus: "Equipment status",
      PhEquipmentStatus: "Select one or more types",
      ClickApplyFilters: "Apply filters",
    },

    COMMON: {
      Images: "Images",
      NoImagesUploaded: "No images uploaded",
      UploadImages: "Upload images",
      Ref: "Ref",
      Brand: "Brand",
      Model: "Model",
      Description: "Description",
      SerialNumber: "Serial number",
    },

    EDITPANEL: {
      Title: "Edit equipment",
      EditWithSuccess: "You have edited an equipment successfully.",
    },

    ADDEQUIPPANEL: {
      PhDate: "Select date",
      PhType: "Select equipment type",
    },

    WARNINGS: {
      ThisEquipmentWillBeDeactivated: "This equipment will be deactivated!",
      YouAreAboutToDeleteImage: "Are you sure you want to delete this image?",
    },

    ERRORS: {
      InvalidId: "A problem ocurred due to invalid equipment ID",
    },
  },
};
