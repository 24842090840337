import React from "react";
import { CardHeader } from "../../../components/cards/CardHeader";
import { Spacer } from "../../../components/layouts/spacer/Spacer";
import "./MyDocs.css";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { useEffect } from "react";
import { useShowSpinner, useWindowResize } from "../../../infrastructure/ui/UIServices";
import { DocsList } from "./DocsList";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { useNavigate } from "react-router-dom";

export function MyDocs() {
  const updatePageTitle = usePageTitle();
  const { setShowSpinner } = useShowSpinner();
  const windowSize = useWindowResize();
  const isPageActive = useActiveFeatures([FeatureFlags.EMPLOYEE_DOCUMENTS]);
  const navigate = useNavigate();
  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    updatePageTitle("My Documents");
  }, []);

  return (
    <div className="mydocs-wrapper">
      <DocsList></DocsList>
      <Spacer mode="horizontal"></Spacer>
      {showUploadCard(windowSize, setShowSpinner)}
    </div>
  );
}

function showUploadCard(windowSize: number, setShowSpinner: any) {
  if (windowSize > 1024) {
    return (
      <CardLayout classNames="upload-wrapper">
        <CardHeader headerTitle="Upload Document" setDisplayState={() => {}}></CardHeader>
        <div className="upload">
          <div className="upload-area">
            <SecondaryButton text="Choose File" onClick={() => setShowSpinner(true)} />
            <div className="upload-text">or drop it here</div>
          </div>
        </div>
      </CardLayout>
    );
  }
}
