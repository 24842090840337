import "./EquipmentCard.css";
import React from "react";
import { ReactComponent as IconLaptop } from "../../../assets/laptop-icon.svg";
import { ReactComponent as IconScreen } from "../../../assets/screen-icon.svg";
import { ReactComponent as IconMouse } from "../../../assets/mouse-icon.svg";
import { ReactComponent as IconKeyboard } from "../../../assets/keyboard-icon.svg";
import { ReactComponent as IconOtherDevices } from "../../../assets/other-devices-icon.svg";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { InfoBlock } from "../../../components/cards/InfoBlock";
import { ReactComponent as IconDevice } from "../../../assets/laptop-icon.svg";
import { IEquipmentType, IEquipmentAllocatedDTO } from "../../../services/EquipmentsService";
import dayjs from "dayjs";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

export interface IEquipmentCardProps {
  date: Date | null;
  brand: string;
  description: string;
  model: string;
  type: IEquipmentType;
  reference: string;
  lastStatus: string;
}

export function EquipmentCard(props: IEquipmentCardProps) {
  //Mapper equipment type to a icon
  function equipmentTypeIconMapper() {
    switch (props.type.code) {
      case "PC":
        return <IconLaptop />;
      case "SCREEN":
        return <IconScreen />;
      case "MOUSE":
        return <IconMouse />;
      case "KEYBOARD":
        return <IconKeyboard />;
      case "OTHERS":
        return <IconOtherDevices />;
    }
  }

  return (
    <CardLayout classNames="device-container">
      <div className="device-icon-wrapper">
        <div className="device-icon">{equipmentTypeIconMapper()}</div>
      </div>
      <div className="device-info">
        <InfoBlock label={translate("EQUIPMENTS.COMMON.Ref")} text={props.reference}></InfoBlock>
        <InfoBlock label={translate("EQUIPMENTS.COMMON.Brand")} text={props.brand}></InfoBlock>
        <InfoBlock label={translate("EQUIPMENTS.COMMON.Model")} text={props.model}></InfoBlock>
        <InfoBlock label={translate("EQUIPMENTS.COMMON.Description")} text={props.description}></InfoBlock>
        <InfoBlock label={translate("COMMON.Date")} text={dayjs(props.date).format("DD/MM/YYYY") ?? null}></InfoBlock>
        <InfoBlock label={translate("COMMON.Status")} text={props.lastStatus}></InfoBlock>
      </div>
    </CardLayout>
  );
}
