import "./AddEmployeeActivityPopup.css";

import { ComboBox, IComboBoxOption, IObjectWithKey, Persona, PersonaSize, Selection, SelectionMode, Spinner, SpinnerSize, getInitials } from "@fluentui/react";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { User, UsersService } from "../../../../services/UsersService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup } from "../../../../infrastructure/ui/UIServices";

import { CardHeader } from "../../../../components/cards/CardHeader";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { ProjectsAssociationsService } from "../../../../services/ProjectsAssociationsService";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { useNavigate } from "react-router-dom";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

var usersService = new UsersService();
var associationsService = new ProjectsAssociationsService();

interface IAddEmployeePopupProps {
  projectId: string;
  activityId: string;
  onCompletedOperations: () => void;
}

const getErrorMessage = (value: string): string => {
  var trimmed = value.trim();
  return trimmed.length === 0 ? translate("COMMON.RequiredField") : "";
};

export function AddEmployeeActivityPopup(props: IAddEmployeePopupProps) {
  const navigate = useNavigate();
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [submitButtonSpinner, setSubmitButtonSpinner] = useState<boolean>(false);
  const [isAddEmployeeDisabled, setIsAddEmployeeDisabled] = useState<boolean>(true);
  const [selectedEmployee, setSelectedEmployee] = useState<IComboBoxOption>();
  const [employees, setEmployees] = useState<IComboBoxOption[]>([]);
  const [employeesSpinner, setEmployeesSpinner] = useState<boolean>(true);

  useEffect(() => {
    setEmployeesSpinner(true);
    usersService
      .getAll()
      .then((res) => {
        mapUsersListToIComboBoxOption(res);
        setEmployeesSpinner(false);
      })
      .catch(() => {
        setEmployeesSpinner(false);
        navigate("/error");
      });
  }, []);

  useEffect(() => {
    if (selectedEmployee?.key) setIsAddEmployeeDisabled(false);
  }, [selectedEmployee]);

  const mapUsersListToIComboBoxOption = useCallback(
    (users: User[]) => {
      var employeesList: IComboBoxOption[] = users.map((r) => {
        var employee: IComboBoxOption = {
          text: r.displayName,
          key: r.userPrincipalName,
          data: r.url,
        };
        return employee;
      });
      setEmployees(employeesList);
      setEmployeesSpinner(false);
    },
    [setEmployees, setEmployeesSpinner]
  );

  const selectionModel = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.single,
        getKey: (item: any) => "" + item.key,
        items: employees as IObjectWithKey[],
        onSelectionChanged: () => {
          if (selectionModel.getSelection() && selectionModel.getSelection()[0]) {
            setSelectedEmployee(selectionModel.getSelection()[0] as IComboBoxOption);
            selectionModel.toggleAllSelected();
          }
        },
      }),
    [employees]
  );

  const handleSelectEmployee = useCallback(
    (item?: IComboBoxOption) => {
      if (item) {
        const idx = selectionModel.getItems().findIndex((i) => i.key === item.key);
        selectionModel.setIndexSelected(idx, true, false);
      }
    },
    [selectionModel]
  );

  const onClickAddEmployee = useCallback(() => {
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
    setIsAddEmployeeDisabled(true);
    if (selectedEmployee?.key) {
      associationsService
        .createProjectActivityAssociation(props.projectId, props.activityId, selectedEmployee?.key as string, false)
        .then((_) => {
          closeOverlaySpinner();
          props.onCompletedOperations();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("ASSOCIATIONS.SuccessfullAssociation")}</p>
            </FeedbackPopup>
          );
        })
        .catch((err) => {
          try {
            var parsedResponse: DomainExceptionResponse = JSON.parse(err);
            if (parsedResponse.AlertType === ErrorTypeEnum.ISPUBLIC) {
              closeOverlaySpinner();
              openPopup(
                <FeedbackPopup
                  type="warning"
                  title={translate("ASSOCIATIONS.WARNINGS.ThisActivityIsPublic")}
                  primaryActionLabel={translate("ACTIONS.Change")}
                  primaryActionFunc={() => {
                    changeActivityStatusToPrivate(props.projectId, selectedEmployee?.key as string);
                  }}
                >
                  <p>{parsedResponse.Response}</p>
                </FeedbackPopup>
              );
            }
            if (parsedResponse.AlertType === ErrorTypeEnum.ERROR) {
              closeOverlaySpinner();
              openPopup(
                <FeedbackPopup type="error">
                  <p>{parsedResponse.Response}</p>
                </FeedbackPopup>
              );
            }
          } catch {
            closeOverlaySpinner();
            openPopup(
              <FeedbackPopup type="error">
                <p>{err}</p>
              </FeedbackPopup>
            );
          }
          closeOverlaySpinner();
          setIsAddEmployeeDisabled(true);
        });
    } else {
      closeOverlaySpinner();
      openPopup(
        <FeedbackPopup type="error">
          <p>{translate("ASSOCIATIONS.ERRORS.UserNotSelected")}</p>
        </FeedbackPopup>
      );
    }
  }, [selectedEmployee, setSelectedEmployee, openPopup, props.projectId, selectedEmployee, openOverlaySpinner, closeOverlaySpinner]);

  const changeActivityStatusToPrivate = useCallback(
    (projId: string, userPrincipalName: string) => {
      associationsService
        .createProjectActivityAssociation(projId, props.activityId, userPrincipalName as string, true)
        .then((_) => {
          props.onCompletedOperations();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("ASSOCIATIONS.SuccessfullAssociation")}</p>
            </FeedbackPopup>
          );
        })
        .catch((err) => {
          try {
            var parsedResponse: DomainExceptionResponse = JSON.parse(err);
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
          } catch {
            openPopup(
              <FeedbackPopup type="error">
                <p>{err}</p>
              </FeedbackPopup>
            );
          }
        });
    },

    [props.onCompletedOperations, selectedEmployee, props.activityId, openPopup]
  );

  return (
    <div className="add-employee-popup">
      <CardHeader headerTitle={translate("ASSOCIATIONS.AddEmployee")} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <form>
          <ComboBox
            label={translate("COMMON.Employee")}
            placeholder={translate("COMMON.SelectEmployee")}
            allowFreeform={true}
            autoComplete={"on"}
            options={employees}
            selectedKey={selectedEmployee?.key}
            onItemClick={(ev, item, index) => {
              handleSelectEmployee(item);
            }}
            onChange={(event, item) => {
              if (item) handleSelectEmployee(item);
            }}
            onRenderOption={(item) => {
              if (item) {
                return (
                  <>
                    <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                  </>
                );
              }
              return null;
            }}
            required={true}
          />
        </form>
        <div className="action-btns">
          <TertiaryButton isDisabled={submitButtonSpinner} text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
          <PrimaryButton
            dataTest="add-act-association"
            text={translate("ACTIONS.Add")}
            isDisabled={isAddEmployeeDisabled}
            onClick={() => {
              onClickAddEmployee();
            }}
          >
            {" "}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
