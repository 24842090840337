import { AxiosError } from "axios";
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { GraphUser, IEquipmentAllocatedDTO } from "./EquipmentsService";
import { HttpClient } from "../infrastructure/http/HttpClient";
import { AllocationsGetResponseDTO } from "../models/api/allocations/AllocationsGetResponseDTO";
import { StatusRecordCreateRequestDTO } from "../models/api/allocations/StatusRecordCreateRequestDTO";
import { AllocationCreateRequestDTO } from "../models/api/allocations/AllocationCreateRequestDTO";
import { EquipmentAllocatedGetResponseDTO } from "../models/api/allocations/EquipmentAllocatedGetResponseDTO";
import dayjs from "dayjs";
import { AllocationStatusesGetResponseDTO } from "../models/api/allocations/AllocationStatusesGetResponseDTO";
import { AllocationsGetRequestDTO } from "../models/api/allocations/AllocationsGetRequestDTO";
import * as qs from "qs";
import { RequestFilterDTO } from "../areas/people-management/allocations/AllocationsPage";

export interface HistoryDTO {
  allocationId: string;
  employeeApprover: string;
  date: string;
  state: string;
}
export interface States {
  id: number;
  name: string;
  statusCode: string;
  priority: number;
}
export interface GetAllocationsDTO {
  allocationId: number;
  employeeTarget: GraphUser;
  employeeApprover: GraphUser;
  date: string;
  state: States;
  nextStates: States[];
  history: HistoryDTO[];
}

export interface GetAllocationsStatus {
  id: number;
  name: string;
  code?: string; 
}

export interface GetAllocationsRequestDTO {
  equipmentId: number;
  employeePrincipalName: string;
  statusIds: number[];
}

export interface CreateAllocationRequestDTO {
  equipmentId: number;
  employeePrincipalName: string;
  throwException: boolean;
}



interface CreateResponseDTO {
  response: boolean;
  message: string;
}

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AllocationsService {
  getEmployeeEquipmentsAllocated(): Promise<IEquipmentAllocatedDTO[]> {
    return HttpClient.sessionRequest<EquipmentAllocatedGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/me/allocations`),
    })
      .then((response) => {
        var mappedResult = response.data.equipmentsAllocated.map(
          (equip): IEquipmentAllocatedDTO => ({
            equipmentId: equip.equipmentID,
            brand: equip.brand,
            model: equip.model,
            description: equip.description,
            reference: equip.reference,
            allocationStartDate: dayjs(equip.beginAllocation).toDate(),
            type: {
              id: equip.type.id,
              code: equip.type.code,
              description: equip.type.description,
            },
            lastAllocationStatus: equip.lastAllocationStatus
          })
        );
        return mappedResult;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getAllocations(equipmentId: string): Promise<GetAllocationsDTO[]> {


    return HttpClient.sessionRequest<AllocationsGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/allocations/${equipmentId}`),
    })
      .then((res) => {
        var mapped = res.data.allocations.map(
          (alloc): GetAllocationsDTO => ({
            allocationId: alloc.allocationId,
            date: alloc.date?.toString() || "",
            state: {
              id: alloc.status.id,
              name: alloc.status.description,
              statusCode: alloc.status.code,
              priority: alloc.status.priority,
            },
            employeeApprover: {
              displayName: alloc.approverEmployee.displayName || "", //FIX THIS
              url: alloc.approverEmployee.photoURL || "",
            },
            employeeTarget: {
              displayName: alloc.targetEmployee.displayName || "",
              url: alloc.targetEmployee.photoURL || "",
            },
            history: alloc.history.map(
              (hist): HistoryDTO => ({
                allocationId: "" + hist.allocationId,
                date: hist.date,
                employeeApprover: hist.approverEmployeeName,
                state: hist.status.description,
              })
            ),
            nextStates: alloc.nextStatuses.map(
              (nx): States => ({
                id: nx.id,
                name: nx.description,
                statusCode: nx.code,
                priority: nx.priority,
              })
            ),
          })
        );
        return mapped;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createAllocationStatusRecord(allocationID: number, statusID: number): Promise<void> {
    var requestDTO: StatusRecordCreateRequestDTO = {
      statusID: statusID,
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/allocations/${allocationID}/status-records`),
      data: requestDTO,
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createAllocation(employeePrincipalName: string, equipmentId: number): Promise<CreateResponseDTO> {
    var requestDTO: AllocationCreateRequestDTO = {
      employeePrincipalName: employeePrincipalName,
      equipmentID: equipmentId,
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/allocations`),
      data: requestDTO,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getAllocationsStatus(): Promise<GetAllocationsStatus[]> {
    return HttpClient.sessionRequest<AllocationStatusesGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/allocations/statuses`),
    })
      .then((response) => {
        var mappedResult = response.data.statuses.map(
          (status): GetAllocationsStatus => ({
            id: status.id,
            name: status.description,
            code: status.code
          })
        );
        return mappedResult;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }
}
