import "./EditProjectInfoPopup.css";

import { MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useShowSpinner, useWindowResize } from "../../../infrastructure/ui/UIServices";


import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { ProjectPathDTO } from "../../../models/api/projects/ProjectPathDTO";
import { ProjectsService } from "../../../services/ProjectsService";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { TertiaryButton } from "../../../components/buttons/TertiaryButton";

import { useNavigate } from "react-router-dom";
import { CardHeader } from "../../../components/cards/CardHeader";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

var projectsService = new ProjectsService();

export interface EditProjectInfoProps {
  projectId: number;
  onOperationCompleted: () => void;
}

export function EditProjectInfoPopup(props: EditProjectInfoProps) {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [projectName, setProjectName] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const [projectCode, setProjectCode] = useState<string>("");

  const [projectSpinner, setProjectSpinner] = useState<boolean>(true);
  const [errorMessage, setMessageError] = useState<string>("");

  const [showPrimaryMessageBar, setShowMessageBar] = useState<boolean>(false);
  const [isEditProjectDisabled, setIsEditProjectDisabled] = useState<boolean>(true);

  useEffect(() => {
    getProjectDetails();
  }, []);

  useEffect(() => {
    if (projectName && clientName) setIsEditProjectDisabled(false);
    else setIsEditProjectDisabled(true);
  }, [projectName, clientName]);

  const getProjectDetails = useCallback(() => {
    if (props.projectId > 0) {
      projectsService
        .getProjectById(props.projectId)
        .then((res) => {
          setProjectName(res.name);
          setClientName(res.client);
          setProjectCode(res.code);
          setProjectSpinner(false);
        })
        .catch((error) => {
          closeOverlaySpinner();
          setProjectSpinner(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    } else {
      openPopup(
        <FeedbackPopup type="error">
          <p>{translate("PROJECTS.ERRORS.InvalidProjectId")}</p>
        </FeedbackPopup>
      );
    }
  }, [setProjectName, setClientName, setProjectCode, setProjectSpinner, closeOverlaySpinner, setProjectSpinner, openPopup, props.projectId]);

  const onClickEditProjectInfo = useCallback(() => {
    if (projectCode) {
      var request: ProjectPathDTO = {
        clientName: clientName,
        projectName: projectName,
      };
      openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
      projectsService
        .updateProjectPatch(projectCode, request)
        .then((_) => {
          props.onOperationCompleted();
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("PROJECTS.EDIT.EditedWithSuccess")}</p>
            </FeedbackPopup>
          );
        })
        .catch((error) => {

          if (error.code === "ERR_BAD_REQUEST") {
            if (error.response?.data) {
              closeOverlaySpinner();
              openPopup(
                <FeedbackPopup type="error">
                  <p>{error.response?.data}</p>
                </FeedbackPopup>
              );
            }
          } else {
            closeOverlaySpinner();
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.response?.data}</p>
              </FeedbackPopup>
            );
          }
        });
    }
  }, [
    projectName,
    projectCode,
    clientName,
    props.projectId,
    setMessageError,
    props.onOperationCompleted,
    setProjectName,
    setClientName,
    closeOverlaySpinner,
    openOverlaySpinner,
    openPopup,
  ]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setShowMessageBar(false);
        }}
        dismissButtonAriaLabel="Close"
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setShowMessageBar]);

  return (
    <div className="edit-project-popup">
      <div className="project">
        {projectSpinner ? (
          <Spinner size={SpinnerSize.large} label={translate("Loading.PleaseWait")} />
        ) : (
          <div>
            <CardHeader headerTitle={translate("PROJECTS.EDIT.Title")} icon={true} setDisplayState={(state: boolean) => closePopup()} />
            <div className="project-info">
              <TextField disabled={true} label={translate("COMMON.Code")} defaultValue={projectCode ? projectCode : "-"} />
              <TextField
                onChange={(e) => {
                  setProjectName(e.currentTarget.value);
                }}
                label={translate("COMMON.Name")}
                placeholder="Ex: N4IT.ECSTeam"
                value={projectName}
                validateOnLoad={false}
                required={true}
              />
              <TextField
                onChange={(e) => {
                  setClientName(e.currentTarget.value);
                }}
                label={translate("COMMON.Client")}
                placeholder="Ex: N4IT"
                required={true}
                value={clientName}
                validateOnLoad={false}
              />
            </div>
            {showPrimaryMessageBar ? errorMessageBar() : null}
            <div className="act-btns">
              <TertiaryButton text={translate("ACTIONS.Close")} onClick={closePopup} />
              <PrimaryButton text={translate("ACTIONS.Edit")} isDisabled={isEditProjectDisabled} onClick={onClickEditProjectInfo}></PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
