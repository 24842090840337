import { ActivitiesGetAssociationsResponseDTO } from "../models/api/project-associations/ActivitiesGetAssociationsResponseDTO";
import { ActivityAssociationCreateRequestDTO } from "../models/api/project-associations/ActivityAssociationCreateRequestDTO";
import { Association } from "../models/projects/Association";
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../infrastructure/http/HttpClient";
import { ProjectAssociationCreateRequestDTO } from "../models/api/project-associations/ProjectAssociationCreateRequestDTO";
import { ProjectGetAssocationsResponseDTO } from "../models/api/project-associations/ProjectGetAssocationsResponseDTO";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class ProjectsAssociationsService {
  getProjectAssociations(proj: string): Promise<Association[]> {
    return HttpClient.sessionRequest<ProjectGetAssocationsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects/${proj}/Associations`),
    })
      .then((response) => {
        return response.data.associations.map(
          (p): Association => ({
            id: p.id,
            targetUserId: p.targetUserId,
            grantedByUserID: p.grantedByUserID,
            grantedByUserName: p.grantedByUserName,
            targetUserName: p.targetUserName,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createProjectAssociation(proj: string, targetUserPrincipalName: string, turnToPrivate: boolean): Promise<void> {
    let request: ProjectAssociationCreateRequestDTO = {
      targetUserPrincipalName: targetUserPrincipalName,
      turnToPrivate: turnToPrivate,
    };
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Projects/${proj}/Associations`), 
      data: request,
    })
      .then((response) => {
        return;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  deleteProjectAssociation(id: string, assocId: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/Projects/${id}/Associations/${assocId}`),
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }


  getProjectActivityAssociations(proj: string, activity: string): Promise<Association[]> {
    return HttpClient.sessionRequest<ActivitiesGetAssociationsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects/${proj}/Activities/${activity}/Associations`),
    })
      .then((response) => {
        return response.data.associations.map(
          (p): Association => ({
            id: p.id,
            targetUserId: p.targetUserId,
            grantedByUserID: p.grantedByUserID,
            grantedByUserName: p.grantedByUserName,
            targetUserName: p.targetUserName,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  
  createProjectActivityAssociation(proj: string, activity: string, targetUserPrincipalName: string, turnToPrivate: boolean): Promise<void> {
    let request: ActivityAssociationCreateRequestDTO = {
      targetUserPrincipalName: targetUserPrincipalName,
      turnToPrivate: turnToPrivate,
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Projects/${proj}/Activities/${activity}/Associations`),
      data: request,
    })
      .then((response) => {
        return;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  deleteProjectActivityAssociation(id: string, actId: string, assocId: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/Projects/${id}/Activities/${actId}/Associations/${assocId}`),
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }
}
