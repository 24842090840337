import "./AddGroupMemberPopup.css";

import { ComboBox, IComboBoxOption, IObjectWithKey, Persona, PersonaSize, Selection, SelectionMode, Spinner, SpinnerSize, getInitials } from "@fluentui/react";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { User, UsersService } from "../../../../services/UsersService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup } from "../../../../infrastructure/ui/UIServices";

import { CardHeader } from "../../../../components/cards/CardHeader";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { ProjectsAssociationsService } from "../../../../services/ProjectsAssociationsService";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { useNavigate } from "react-router-dom";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { ApprovalGroupMember } from "../../../../models/approval-groups/ApprovalGroupMember";
import { Employee } from "../../../../models/common/Employee";
import { ApprovalGroupsService } from "../../../../services/ApprovalGroupsService";

var usersService = new UsersService();
var approvalGroupsService = new ApprovalGroupsService();

interface IAddEmployeePopupProps {
  groupCode: string;
  alreadySelectedEmployees: ApprovalGroupMember[];
  isApprover: boolean;
  typeOfEmployee: string;
  onCompletedOperations: () => void;
}

export function AddGroupMemberPopup(props: IAddEmployeePopupProps) {
  const navigate = useNavigate();
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [submitButtonSpinner, setSubmitButtonSpinner] = useState<boolean>(false);
  const [isAddEmployeeDisabled, setIsAddEmployeeDisabled] = useState<boolean>(true);
  const [selectedEmployee, setSelectedEmployee] = useState<IComboBoxOption>();
  const [employees, setEmployees] = useState<IComboBoxOption[]>([]);
  const [employeesSpinner, setEmployeesSpinner] = useState<boolean>(true);

  useEffect(() => {
    setEmployeesSpinner(true);
    var distinctGroupEmployees: Employee[] = [];
    if (props.alreadySelectedEmployees) {
      const allGroupEmails = props.alreadySelectedEmployees.map((emp) => emp.employee.userPrincipalName);
      distinctGroupEmployees = props.alreadySelectedEmployees
        .map((u) => u.employee)
        .filter(({ userPrincipalName }, index) => !allGroupEmails.includes(userPrincipalName, index + 1));
    }

    usersService
      .getAll()
      .then((res) => {
        const results = res.filter((u1) => !distinctGroupEmployees.some((u2) => u1.userPrincipalName.toLowerCase() === u2.userPrincipalName.toLowerCase()));
        mapUsersListToIComboBoxOption(results);
        setEmployeesSpinner(false);
      })
      .catch(() => {
        setEmployeesSpinner(false);
        navigate("/error");
      });
  }, []);

  useEffect(() => {
    if (selectedEmployee?.key) setIsAddEmployeeDisabled(false);
  }, [selectedEmployee]);

  const mapUsersListToIComboBoxOption = useCallback(
    (users: User[]) => {
      var employeesList: IComboBoxOption[] = users.map((r) => {
        var employee: IComboBoxOption = {
          text: r.displayName,
          key: r.userPrincipalName,
          data: r.url,
        };
        return employee;
      });
      setEmployees(employeesList);
      setEmployeesSpinner(false);
    },
    [setEmployees, setEmployeesSpinner]
  );

  const onClickAddEmployee = useCallback(() => {
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
    setIsAddEmployeeDisabled(true);
    
    if (selectedEmployee?.key && props.groupCode) {
      approvalGroupsService
        .addGroupMember(selectedEmployee?.key + "", props.groupCode, props.isApprover)
        .then((_) => {
          closeOverlaySpinner();
          props.onCompletedOperations();
          openPopup(
            <FeedbackPopup type="success">
              <p>{"A new " + props.typeOfEmployee + " was added with success."}</p>
            </FeedbackPopup>
          );
        })
        .catch((err) => {
          try {
            closeOverlaySpinner();
            openPopup(
              <FeedbackPopup type="error">
                <p>{err.response.data}</p>
              </FeedbackPopup>
            );
          } catch {
            navigate("/error");
          }
        });
    } else {
      closeOverlaySpinner();
      //TODO: Ver resposta melhor para a mensagem abaixo.
      openPopup(
        <FeedbackPopup type="error">
          <p>{"The selected employee and group code are invalid."}</p>
        </FeedbackPopup>
      );
    }
  }, [
    selectedEmployee,
    props.groupCode,
    props.isApprover,
    props.onCompletedOperations,
    setIsAddEmployeeDisabled,
    navigate,
    setSelectedEmployee,
    openPopup,
    openOverlaySpinner,
    closeOverlaySpinner,
  ]);

  return (
    <div className="add-member-popup">
      <CardHeader headerTitle={props.typeOfEmployee ? "Add " + props.typeOfEmployee : "Add employee"} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <form>
          <ComboBox
            disabled={employeesSpinner}
            label={"Employee"}
            placeholder={"Select an option"}
            allowFreeform={true}
            autoComplete={"on"}
            options={employees}
            selectedKey={selectedEmployee?.key}
            onChange={(event, item) => {
              if (item) setSelectedEmployee(item);
            }}
            onRenderOption={(item) => {
              if (item) {
                return (
                  <>
                    <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                  </>
                );
              }
              return null;
            }}
            required={true}
          />
        </form>
        <div className="action-btns">
          <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
          <PrimaryButton
            text={"Add"}
            isDisabled={isAddEmployeeDisabled}
            onClick={() => {
              onClickAddEmployee();
            }}
          >
            {" "}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
