import { IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { ConfigurationProvider } from '../configuration/ConfigurationProvider';
import { IdentityService } from './IdentityService';
import { UrlUtility } from '../../utils/UrlUtility';

export class IdentityProvider {


    static msalInstance: IPublicClientApplication;

    static async init() {
        let msalInstance = new PublicClientApplication(this.getConfiguration());
        IdentityService.initInstance(msalInstance);
        IdentityProvider.msalInstance = msalInstance;
    }

    static getMsalClient(): IPublicClientApplication {
        return IdentityProvider.msalInstance;
    }

    static getConfiguration() {
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;
        return  ({
            auth: {
                clientId: identityConfig.ClientId,
                authority: UrlUtility.join(identityConfig.Authority, identityConfig.TenantId),
                redirectUri:ConfigurationProvider.getConfiguration().App.BaseUrl,
                navigateToLoginRequestUrl: false,
            },
            cache: {
                cacheLocation: "localStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {

                loggerOptions: {
                    logLevel: LogLevel.Verbose,
                    loggerCallback: (level:any, message:any, containsPii:any) => {


                        // if (containsPii) {
                        //     return;
                        // }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    }
                }
            }
        });
    }


    static getBaseLoginRequest(){
        const identityConfig = ConfigurationProvider.getConfiguration().Identity;

        return ({
            scopes:identityConfig.Scopes
        });
    }


}
