import React, { useEffect, useState } from "react";
import "./MyEquipments.css";
import { EquipmentCard } from "./EquipmentCard";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { usePopup, useShowSpinner } from "../../../infrastructure/ui/UIServices";
import { EquipmentsService, IEquipmentAllocatedDTO } from "../../../services/EquipmentsService";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { AllocationsService } from "../../../services/AllocationsService";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import emptyResponseAvatar from "../../../assets/empty-response.svg";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { EmptyResult } from "../../../components/images/EmptyResult";

var allocationsService = new AllocationsService();

export function MyEquipments() {
  const updatePageTitle = usePageTitle();
  const openPopup = usePopup();
  const isPageActive = useActiveFeatures([FeatureFlags.EMPLOYEE_PROFILE]);
  const navigate = useNavigate();
  if (!isPageActive) {
    navigate("/error");
  }

  const { showSpinner, setShowSpinner } = useShowSpinner();
  const [deviceList, setDeviceList] = useState<IEquipmentAllocatedDTO[]>([]);

  useEffect(() => {
    updatePageTitle(translate("AREATITLES.MyEquipments"));
    setShowSpinner(true);
    allocationsService
      .getEmployeeEquipmentsAllocated()
      .then((res) => {
        setDeviceList(res);
        if (res) setShowSpinner(false);
      })
      .catch((error) => {
        openPopup(
          <FeedbackPopup type="error">
            <p>{error}</p>
          </FeedbackPopup>
        );
      });
  }, []);

  return (
    <div className="my-equipments">
      {showSpinner ? (
        <Spinner size={SpinnerSize.large} label={translate("LOADING.LoadingDataPleaseWait")} />
      ) : (
        <>
          {deviceList.length > 0 ? (
            <div className="device-wrapper">
              {deviceList.map((device, idx) => (
                <EquipmentCard
                  key={idx}
                  lastStatus={device.lastAllocationStatus}
                  reference={device.reference}
                  date={device.allocationStartDate || null}
                  brand={device.brand}
                  model={device.model}
                  description={device.description}
                  type={device.type}
                ></EquipmentCard>
              ))}
            </div>
          ) : (
            <EmptyResult/>

          )}
        </>
      )}
    </div>
  );
}
