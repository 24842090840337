import "./AddPermission.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup } from "../../../../infrastructure/ui/UIServices";
import { Checkbox, ComboBox, getInitials, IComboBoxOption, Persona, PersonaSize, Stack } from "@fluentui/react";
import { User, UsersService } from "../../../../services/UsersService";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";

var usersService = new UsersService();
const stackTokens = { childrenGap: 10 };

export function AddPermission() {
  const navigate = useNavigate();
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [submitButtonSpinner, setSubmitButtonSpinner] = useState<boolean>(false);
  const [isAddEmployeeDisabled, setIsAddEmployeeDisabled] = useState<boolean>(true);
  const [selectedEmployee, setSelectedEmployee] = useState<IComboBoxOption>();
  const [employees, setEmployees] = useState<IComboBoxOption[]>([]);
  const [employeesSpinner, setEmployeesSpinner] = useState<boolean>(true);

  useEffect(() => {
    setEmployeesSpinner(true);
    usersService
      .getAll()
      .then((res) => {
        mapUsersListToIComboBoxOption(res);
        setEmployeesSpinner(false);
      })
      .catch(() => {
        setEmployeesSpinner(false);
        navigate("/error");
      });
  }, []);

  useEffect(() => {
    if (selectedEmployee?.key) setIsAddEmployeeDisabled(false);
  }, [selectedEmployee]);

  const mapUsersListToIComboBoxOption = useCallback(
    (users: User[]) => {
      var employeesList: IComboBoxOption[] = users.map((r) => {
        var employee: IComboBoxOption = {
          text: r.displayName,
          key: r.userPrincipalName,
          data: r.url,
        };
        return employee;
      });
      setEmployees(employeesList);
      setEmployeesSpinner(false);
    },
    [setEmployees, setEmployeesSpinner]
  );



  return (
    <div className="add-permission-popup">
      <CardHeader headerTitle={"Add permission"} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <h2>Employee</h2>
        <ComboBox
          className="employee-selection"
          placeholder="Select an option"
          allowFreeform={true}
          autoComplete={"on"}
          options={employees}
          selectedKey={selectedEmployee?.key}
          onChange={(event, item) => {
            if (item) setSelectedEmployee(item);
          }}
          onRenderOption={(item) => {
            if (item) {
              return (
                <>
                  <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                </>
              );
            }
            return null;
          }}
          required={true}
        />
        <h2>Allow features</h2>
        <Stack tokens={stackTokens}>
          <Checkbox label="ALL" />
          <Checkbox label="Hours" />
          <Checkbox label="Equipments" />
        </Stack>
        <div className="action-btns">
          <TertiaryButton isDisabled={submitButtonSpinner} text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
          <PrimaryButton text={translate("ACTIONS.Add")} onClick={() => {}}>
            {" "}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
