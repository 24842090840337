import "./ActivityInfoPage.css";

import { Activity, ProjectsService } from "../../../services/ProjectsService";
import {
  CommandBar,
  DetailsListLayoutMode,
  DetailsRow,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  IDetailsRowStyles,
  IFacepilePersona,
  IIconProps,
  IconButton,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  Toggle,
  getInitials,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useShowSpinner, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { ActivityPathDTO } from "../../../models/api/projects/ActivityPathDTO";
import { AddEmployeeActivityPopup } from "./popups/AddEmployeeActivityPopup";
import { Association } from "../../../models/projects/Association";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { InfoBlock } from "../../../components/cards/InfoBlock";
import { ProjectsAssociationsService } from "../../../services/ProjectsAssociationsService";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { Spacer } from "../../../components/layouts/spacer/Spacer";
import { transcode } from "buffer";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { useParams } from "react-router-dom";
import { useUpdatePageData } from "../../../infrastructure/navigation/hooks/useUpdatePageData";

const deleteIcon: IIconProps = { iconName: "Delete" };
const add: IIconProps = { iconName: "Add" };

var associationsService = new ProjectsAssociationsService();
var projectsService = new ProjectsService();

const imageOnErrorHandler = () => {
  //Could use a method of this kind to choose what to do if the photo is empty
  //Replacing for some default or not.
};

export function ActivityInfoPage() {
  const addToRouteData = useUpdatePageData();
  const updatePageTitle = usePageTitle();
  const { showSpinner, setShowSpinner } = useShowSpinner();
  const isPageActive = useActiveFeatures([FeatureFlags.EMPLOYEE_PROFILE]);
  const windowResize = useWindowResize();
  const navigate = useNavigate();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [associationsSpinner, setAssociationsSpinner] = useState<boolean>(true);
  const [activityDetailsSpinner, setActivityDetailsSpinner] = useState<boolean>(true);
  const [employeesAssociations, setEmployeesAssociations] = useState<Association[]>();
  const [activityDetails, setActivityDetails] = useState<Activity>();
  const [isPublic, setIsPublic] = useState(activityDetails?.isPublic);

  const { id: projectId, actId: activityId } = useParams();

  let disabledClass = useMemo(() => {
    if (activityDetails?.isDisable) return "disabled";
    else return "";
  }, [activityDetails?.isDisable]);

  useEffect(() => {
    updatePageTitle(translate("AREATITLES.ActivityInfo"));
    addToRouteData(["##ACTIVITY##", "..."], ["##PROJECT##", "..."]);
    getActivityDetails();
  }, []);

  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    if (activityDetails) {
      getActivityAssociations();
    }
  }, [setActivityDetails, activityDetails]);

  const getActivityDetails = useCallback(() => {
    if (activityId) {
      projectsService
        .getActivityById(activityId)
        .then((res) => {
          addToRouteData(["##ACTIVITY##", res.activityId], ["##PROJECT##", res.projectId]);
          setActivityDetails(res);
          setIsPublic(res.isPublic);
          setActivityDetailsSpinner(false);
        })
        .catch((error) => {
          setActivityDetailsSpinner(false);
          setAssociationsSpinner(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
  }, [activityId, addToRouteData, setActivityDetails, setAssociationsSpinner, openPopup]);

  const getActivityAssociations = useCallback(() => {
    setAssociationsSpinner(true);
    if (activityDetails?.projectId && activityDetails.activityId) {
      associationsService
        .getProjectActivityAssociations(activityDetails.projectId, activityDetails.activityId)
        .then((res) => {
          setAssociationsSpinner(false);
          setEmployeesAssociations(res);
        })
        .catch((error) => {
          closeOverlaySpinner();
          setAssociationsSpinner(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
    // else
  }, [openPopup, closeOverlaySpinner, setEmployeesAssociations, setAssociationsSpinner, activityDetails]);

  const _itemsEmployees: ICommandBarItemProps[] = [
    {
      className: "add-employee",
      key: "addEmployee",
      text: windowResize > 768 ? translate("ACTIONS.Add") : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () => {
        if (activityDetails?.activityId && activityDetails.projectId) {
          openPopup(
            <AddEmployeeActivityPopup
              projectId={activityDetails?.projectId}
              activityId={activityDetails?.activityId}
              onCompletedOperations={() => {
                getActivityAssociations();
              }}
            />
          );
        }
      },
      disabled: activityDetails?.isDisable,
    },
  ];

  const onClickRemoveAssociation = useCallback(
    (associationId: number) => {
      if (activityDetails) {
        associationsService
          .deleteProjectActivityAssociation(activityDetails?.projectId, activityDetails.activityId, associationId)
          .then(() => {
            openPopup(
              <FeedbackPopup type="success">
                <p>{translate("ASSOCIATIONS.REMOVE.SuccessfullResponse")}</p>
              </FeedbackPopup>
            );
            getActivityAssociations();
          })
          .catch((error) => {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          });
      }
    },
    [openPopup, getActivityAssociations, activityDetails]
  );

  const projectEmployeesColumns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: translate("COMMON.Employee"),
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        fieldName: "employee",
        minWidth: 140,
        maxWidth: 350,
        data: "string",
        onRender: (item) => (
          <div className="truncate">
            <div className="allocation-employee">
              <Persona
                text={item.targetUserName}
                imageInitials={getInitials(item.targetUserName, false, true)}
                /* imageUrl={item.employee.url} */
                size={PersonaSize.size24}
              />
            </div>
          </div>
        ),
      },
      {
        key: "column2",
        name: translate("ASSOCIATIONS.GrantedBy"),
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        fieldName: "grantedBy",
        minWidth: 140,
        data: "string",
        onRender: (item) => (
          <div className="truncate">
            <div className="allocation-employee">
              <Persona
                text={item.grantedByUserName}
                imageInitials={getInitials(item.grantedByUserName, false, true)}
                /*                   imageUrl={item.employee.url} */
                size={PersonaSize.size24}
              />
            </div>
          </div>
        ),
      },
      {
        key: "column3",
        name: "Remove",
        fieldName: "removeEmployee",
        minWidth: 32,
        maxWidth: 32,
        isRowHeader: true,
        isIconOnly: true,
        isCollapsible: false,
        data: "string",
        onRender: (item) => (
          <IconButton
            data-test={"remove-association-" + item.targetUserName}
            onClick={(e) => {
              openPopup(
                <FeedbackPopup
                  type="warning"
                  title={translate("ASSOCIATIONS.REMOVE.Title")}
                  primaryActionLabel={translate("ACTIONS.Remove")}
                  primaryActionFunc={() => {
                    onClickRemoveAssociation(item.id);
                  }}
                >
                  <p>{translate("ASSOCIATIONS.REMOVE.Message")}</p>
                </FeedbackPopup>
              );
            }}
            iconProps={deleteIcon}
          />
        ),
      },
    ];
  }, [onClickRemoveAssociation, openPopup]);

  const _renderRow = useCallback((props: any) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        customStyles.root = { backgroundColor: "#fafafa" };
      }
    }
    return <DetailsRow {...props} styles={customStyles} />;
  }, []);

  const handleChangeVisibility = useCallback(
    (newVisibility: boolean) => {
      if (activityDetails?.projectId && activityDetails.activityId) {
        var request: ActivityPathDTO = {
          isPublic: newVisibility,
        };
        projectsService
          .updateActivityPatch(activityDetails.projectId, activityDetails.activityId, request)
          .then((_) => {
            setActivityDetailsSpinner(true);
            getActivityDetails();
            openPopup(
              <FeedbackPopup type="success">
                <p>{translate("ACTIVITIES.EDIT.VisibilityChangedWithSuccess")}</p>
              </FeedbackPopup>
            );
          })
          .catch((_) => {
            setIsPublic(!newVisibility);
            closePopup();
          });
      }
    },
    [closePopup, setIsPublic, openPopup, getActivityDetails, setActivityDetailsSpinner, activityDetails?.projectId, activityDetails?.activityId, isPublic]
  );

  const handleCancelVisibility = useCallback(
    (newVisibility: boolean) => {
      setIsPublic(!newVisibility);
    },
    [setIsPublic]
  );

  return (
    <div className={"activity-wrapper " + disabledClass}>
      <CardLayout classNames="activity-info-card">
        {activityDetailsSpinner ? (
          <Spinner size={SpinnerSize.large} label={translate("LOADING.LoadingDataPleaseWait")} />
        ) : (
          <>
            <div className="header">
              <h1 className="truncate">
                {activityDetails?.isDisable ? <FontIcon className="disabled-icon" iconName="Hide" /> : null}
                {activityDetails?.name ? activityDetails.name : "-"}
              </h1>
              <Toggle
                checked={isPublic ? true : false}
                onText={translate("COMMON.Public")}
                offText={translate("COMMON.Private")}
                onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                  setIsPublic(checked || false);
                  openPopup(
                    <FeedbackPopup
                      type="warning"
                      title={translate("ACTIONS.ChangeVisibility")}
                      primaryActionLabel={translate("ACTIONS.Change")}
                      primaryActionFunc={() => handleChangeVisibility(checked || false)}
                      cancelActionFunc={() => handleCancelVisibility(checked || false)}
                    >
                      <p>{translate("ACTIVITIES.EDIT.AreYouSureYouWantToChangeVisibility")}</p>
                    </FeedbackPopup>
                  );
                }}
                disabled={activityDetails?.isDisable}
              />
            </div>
            <div className="activity-info">
              <InfoBlock label={translate("COMMON.Code")} text={activityDetails?.activityId ? activityDetails?.activityId : "-"} />
            </div>
          </>
        )}
      </CardLayout>
      <Spacer mode="vertical" />
      <CardLayout classNames="activity-employees-card">
        <div className="commandbar">
          <h1>{translate("COMMON.Employees")}</h1>
          <div className="action-btns">
            {employeesAssociations && employeesAssociations.length > 0 ? <CommandBar items={_itemsEmployees} ariaLabel="Employees action buttons" /> : null}
          </div>
        </div>
        {employeesAssociations?.length === 0 ? (
          <div className="empty">
            <div className="empty-title">{translate("ASSOCIATIONS.EmptyListTitle")}</div>
            <p>{translate("ASSOCIATIONS.EmptyAssociationsProjectListMessage")}</p>
            <SecondaryButton
              dataTest="add-association-to-activity"
              text={translate("ASSOCIATIONS.AddEmployee")}
              icon={add}
              onClick={() => {
                if (activityDetails?.activityId && activityDetails.projectId) {
                  openPopup(
                    <AddEmployeeActivityPopup
                      projectId={activityDetails?.projectId}
                      activityId={activityDetails?.activityId}
                      onCompletedOperations={() => {
                        getActivityAssociations();
                        getActivityDetails();
                      }}
                    />
                  );
                }
              }}
              isDisabled={activityDetails?.isDisable}
            />
          </div>
        ) : (
          <div className="activity-employees">
            <ShimmeredDetailsList
              enableShimmer={associationsSpinner}
              className="activity-employees-list"
              items={employeesAssociations || []}
              onShouldVirtualize={(_) => false}
              columns={projectEmployeesColumns}
              selectionMode={SelectionMode.none}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={_renderRow}
            />
          </div>
        )}
      </CardLayout>
    </div>
  );
}
