import "./DeleteImagePopup.css";
import { useClosePopup } from "../../../../infrastructure/ui/UIServices";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

export interface IProps {
  onClicked: () => void;
}

export function DeleteImagePopup(props: IProps) {
  const closePopup = useClosePopup();

  return (
    <div className="delete-image-popup">
      <h1>{translate("ACTIONS.AreYouSure")}</h1>
      <div className="popup-text">{translate("EQUIPMENTS.WARNINGS.YouAreAboutToDeleteImage")}</div>
      <div className="action-btns">
        <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
        <PrimaryButton
         text={translate("ACTIONS.Confirm")}
          onClick={() => {
            props.onClicked();
          }}
        />
      </div>
    </div>
  );
}
