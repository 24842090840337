import { AddAxiosInterceptors } from './http/AddAxiosInterceptor';
import { ConfigurationProvider } from './configuration/ConfigurationProvider';
import { IdentityProvider } from './identity/IdentityProvider';
import { InternationalizationProvider } from './i18n/InternationalizationProvider';

export class Startup {
    static async Run( app: () => void) {

        AddAxiosInterceptors();
        await ConfigurationProvider.init();
        await InternationalizationProvider.init();

        await Promise.all([
            IdentityProvider.init(),
        ]);

        app();
    }
}
