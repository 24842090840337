import "./CardHeader.css";
import React from "react";
import { IIconProps } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";

interface ICardHeaderProps {
  headerTitle: string;
  children?: React.ReactNode;
  createdDate?: React.ReactNode;
  icon?: boolean;
  setDisplayState: (state: boolean) => void;
}

const closeIcon: IIconProps = { iconName: "ChromeClose" };

export function CardHeader(props: ICardHeaderProps) {
  return (
    <div className="card-header">
      <div className="titles">
        <h1>{props.headerTitle}</h1>
        {props.children}
        {props.createdDate}
      </div>
      {props.icon ? (
        <div className="close-icon">
          <IconButton iconProps={closeIcon} onClick={() => props.setDisplayState(false)} />
        </div>
      ) : null}
    </div>
  );
}
