import "./ProjectInfoPage.css";

import {
  CommandBar,
  DetailsListLayoutMode,
  DetailsRow,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  IDetailsRowStyles,
  IIconProps,
  IOverflowSetItemProps,
  IconButton,
  Link,
  OverflowSet,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  Toggle,
  TooltipDelay,
  TooltipHost,
  getInitials,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, usePopup, useShowSpinner, useWindowResize } from "../../../infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AddActivityPopup } from "./popups/AddActivityPopup";
import { AddEmployeeProjectPopup } from "./popups/AddEmployeeProjectPopup";
import { Association } from "../../../models/projects/Association";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { EditActivityInfoPopup } from "./EditActivityInfoPopup";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { InfoBlock } from "../../../components/cards/InfoBlock";
import { ProjectDetails } from "../../../models/projects/ProjectDetails";
import { ProjectPathDTO } from "../../../models/api/projects/ProjectPathDTO";
import { ProjectsAssociationsService } from "../../../services/ProjectsAssociationsService";
import { ProjectsService } from "../../../services/ProjectsService";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { Spacer } from "../../../components/layouts/spacer/Spacer";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { useUpdatePageData } from "../../../infrastructure/navigation/hooks/useUpdatePageData";

const disableIcon: IIconProps = { iconName: "RedEye" };
const disabledIcon: IIconProps = { iconName: "Hide" };
const add: IIconProps = { iconName: "Add" };
const deleteIcon: IIconProps = { iconName: "Delete" };

var projectsService = new ProjectsService();
var associationsService = new ProjectsAssociationsService();

//On render overflow menu items
const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <Link role="menuitem" onClick={item.onClick}>
      {item.name}
    </Link>
  );
};

//On render overflow menu
const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return <IconButton role="menuitem" title={translate("COMMON.OtherOptions")} menuIconProps={{ iconName: "More" }} menuProps={{ items: overflowItems! }} />;
};

export function ProjectInfoPage() {
  const addToRouteData = useUpdatePageData();
  const updatePageTitle = usePageTitle();
  const { showSpinner, setShowSpinner } = useShowSpinner();

  const windowResize = useWindowResize();
  const navigate = useNavigate();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [activitiesSpinner, setActivitiesSpinner] = useState<boolean>(true);
  const [employeesAssociatedSpinner, setEmployeesAssociatedSpinner] = useState<boolean>(true);
  const [projectDetails, setProjectDetails] = useState<ProjectDetails>();
  const [projectAssociations, setProjectsAssociations] = useState<Association[]>();
  const [isPublic, setIsPublic] = useState(projectDetails?.isPublic);

  const { id: projectId } = useParams();

  const isPageActive = useActiveFeatures([FeatureFlags.EMPLOYEE_PROFILE]);
  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    updatePageTitle(translate("AREATITLES.ProjectInfo"));
    addToRouteData(["##PROJECT##", "..."]);
    setActivitiesSpinner(true);
    getProjectDetails();
  }, []);

  let disabledClass = useMemo(() => {
    if (projectDetails?.isDisabled) return "disabled";
    else return "";
  }, [projectDetails?.isDisabled]);

  //TODO: Validations
  const getProjectAssociations = useCallback(() => {
    setEmployeesAssociatedSpinner(true);
    if (projectDetails?.code) {
      associationsService
        .getProjectAssociations(projectDetails?.code)
        .then((response) => {
          setProjectsAssociations(response);
          setEmployeesAssociatedSpinner(false);
        })
        .catch((error) => {
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
          setEmployeesAssociatedSpinner(false);
        });
    }
  }, [setProjectsAssociations, projectDetails, openPopup]);

  useEffect(() => {
    if (projectDetails?.code) {
      getProjectAssociations();
    }
  }, [projectDetails]);

  const getProjectDetails = useCallback(() => {
    if (projectId) {
      projectsService
        .getProjectById(projectId)
        .then((res) => {
          setProjectDetails(res);
          addToRouteData(["##PROJECT##", res.code]);
          setActivitiesSpinner(false);
          setIsPublic(res.isPublic);
        })
        .catch((error) => {
          setActivitiesSpinner(false);
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
  }, [setProjectDetails, setActivitiesSpinner, openPopup, closeOverlaySpinner, addToRouteData, projectId]);

  const disableActivity = useCallback(
    (activityId: string) => {
      if (projectId) {
        projectsService
          .deleteActivity(projectId, activityId)
          .then((_) => {
            openPopup(
              <FeedbackPopup type="success">
                <p>{translate("ACTIVITIES.DISABLE.DisableWithSuccess")}</p>
              </FeedbackPopup>
            );
            getProjectDetails();
          })
          .catch((error) => {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          });
      }
    },
    [openPopup, getProjectDetails, projectId]
  );
  const _itemsActivities: ICommandBarItemProps[] = [
    {
      className: "add-activity",
      key: "addActivity",
      role: "addActivity",
      text: windowResize > 768 ? translate("ACTIONS.Add") : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () => {
        if (projectId && projectDetails?.code)
          openPopup(
            <AddActivityPopup
              onAddActivityCompleted={() => {
                getProjectDetails();
                setActivitiesSpinner(true);
              }}
              projectId={projectDetails?.code}
            />
          );
      },
      disabled: projectDetails?.isDisabled,
    },
  ];

  const activitiesColumns: IColumn[] = [
    {
      key: "column1",
      name: translate("COMMON.Code"),
      fieldName: "activityId",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      onRender: (item) => {
        if (item.activityId) return <div className={"truncate" + (item.isDisabled ? " disable" : "")}>{item.activityId}</div>;
        return <>{"-"}</>;
      },
      data: "string",
    },
    {
      key: "column2",
      name: translate("COMMON.Name"),
      fieldName: "acivityName",
      minWidth: 140,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: false,
      onRender: (item) => {
        if (item.name) return <div className={"truncate" + (item.isDisabled ? " disable" : "")}>{item.name}</div>;
        return <>{"-"}</>;
      },
      data: "string",
    },
    {
      key: "column3",
      name: "Vis.",
      fieldName: "isPublic",
      minWidth: 40,
      maxWidth: 40,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      onRender: (item) => {
        return <FontIcon className={item.isDisabled ? "disable" : ""} aria-label={item.isPublic ? "Public" : "Private"} iconName={item.isPublic ? "" : "LockSolid"} />;
      },
      data: "string",
    },
    {
      key: "column4",
      name: translate("COMMON.Status"),
      fieldName: "acivityStatus",
      minWidth: 40,
      maxWidth: 40,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: false,
      onRender: (item) => (
        <>
          <TooltipHost content={item.isDisabled ? translate("ACTIVITIES.ActivityDisabled") : translate("ACTIVITIES.DisableActivity")} delay={TooltipDelay.medium}>
            <IconButton
              data-test={item.isDisabled ? "hidden-disable-act-" + item.activityId : "disable-act-" + item.activityId}
              iconProps={!item.isDisabled ? disableIcon : disabledIcon}
              disabled={item.isDisabled ? true : false}
              title={translate("ACTIVITIES.DisableActivity")}
              ariaLabel="Disable Activity"
              onClick={(e) => {
                e.stopPropagation();
                openPopup(
                  <FeedbackPopup
                    type="warning"
                    title={translate("ACTIVITIES.DisableActivity") + "?"}
                    primaryActionLabel={translate("ACTIONS.Deactivate")}
                    primaryActionFunc={() => {
                      disableActivity(item.activityId);
                    }}
                  >
                    <p>{translate("ACTIVITIES.DISABLE.AreYouSureYouWantDisable")}</p>
                  </FeedbackPopup>
                );
              }}
            />
          </TooltipHost>
        </>
      ),
      data: "string",
    },
    {
      className: "overflow-menu",
      key: "column8",
      name: "Overflow Menu",
      fieldName: "overflow",
      minWidth: 30,
      maxWidth: 30,
      isIconOnly: true,
      isCollapsible: false,
      onRender: (item) => (
        <OverflowSet
          aria-label="Basic Menu Example"
          role="menubar"
          items={[]}
          overflowItems={[
            {
              key: "item1",
              name: translate("ACTIONS.Edit"),
              onClick: () =>
                openPopup(
                  item?.id ? (
                    <EditActivityInfoPopup
                      activityId={item?.id}
                      onOperationCompleted={() => {
                        getProjectDetails();
                      }}
                    />
                  ) : (
                    <FeedbackPopup type="error" title={translate("ACTIVITIES.ERRORS.ActivityIdNotValid")} primaryActionLabel="Close">
                      <p>{translate("ACTIVITIES.ERRORS.ActivityIdNotValidLongMessage")}</p>
                    </FeedbackPopup>
                  )
                ),
            },
            {
              key: "item2",
              name: translate("ACTIONS.View"),
              onClick: () => navigate("activities/" + item?.id),
            },
          ]}
          onRenderOverflowButton={onRenderOverflowButton}
          onRenderItem={onRenderItem}
        />
      ),
      data: "string",
    },
  ];

  const _renderRow = useCallback(
    (props: any) => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if (props.itemIndex % 2 === 0) {
          customStyles.root = { backgroundColor: "#fafafa" };
        }
      }
      return <DetailsRow {...props} styles={customStyles} onClick={() => navigate("activities/" + props.item.id)} />;
    },
    [navigate]
  );

  const _itemsEmployees: ICommandBarItemProps[] = [
    {
      className: "add-employee",

      key: "addEmployee",
      role: "addEmployee",
      text: windowResize > 768 ? translate("ACTIONS.Add") : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () => {
        openPopup(
          <AddEmployeeProjectPopup
            projectId={projectDetails?.code ? projectDetails.code : ""}
            onCompletedOperations={() => {
              getProjectAssociations();
              setEmployeesAssociatedSpinner(true);
            }}
          />
        );
      },
      disabled: projectDetails?.isDisabled,
    },
  ];

  const onClickRemoveProjectAssociation = useCallback(
    (associationId: number) => {
      if (projectId) {
        associationsService
          .deleteProjectAssociation(projectId, associationId)
          .then(() => {
            openPopup(
              <FeedbackPopup type="success">
                <p>{translate("ASSOCIATIONS.REMOVE.SuccessfullResponse")}</p>
              </FeedbackPopup>
            );
            getProjectAssociations();
          })
          .catch((error) => {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          });
      }
    },
    [openPopup, projectId, getProjectAssociations]
  );

  const projectAssociationsColumns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: translate("COMMON.Employee"),
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        fieldName: "employee",
        minWidth: 140,
        data: "string",
        onRender: (item) => (
          <div className="truncate">
            <div className="allocation-employee">
              <Persona
                text={item.targetUserName}
                imageInitials={getInitials(item.targetUserName, false, true)}
                /* imageUrl={item.employee.url} */
                size={PersonaSize.size24}
              />
            </div>
          </div>
        ),
      },
      {
        key: "column2",
        name: translate("ASSOCIATIONS.GrantedBy"),
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        fieldName: "grantedBy",
        minWidth: 140,
        data: "string",
        onRender: (item) => (
          <div className="truncate">
            <div className="allocation-employee">
              <Persona text={item.grantedByUserName} imageInitials={getInitials(item.grantedByUserName, false, true)} size={PersonaSize.size24} />
            </div>
          </div>
        ),
      },
      {
        key: "column3",
        name: "Remove",
        fieldName: "removeEmployee",
        minWidth: 32,
        maxWidth: 32,
        isRowHeader: true,
        isIconOnly: true,
        isCollapsible: false,

        data: "string",
        onRender: (item) => (
          <IconButton
            data-test={"remove-association-" + item.targetUserName}
            onClick={(e) => {
              openPopup(
                <FeedbackPopup
                  type="warning"
                  title={translate("ASSOCIATIONS.REMOVE.Title")}
                  primaryActionLabel={translate("ACTIONS.Remove")}
                  primaryActionFunc={() => {
                    onClickRemoveProjectAssociation(item.id);
                  }}
                >
                  <p>{translate("ASSOCIATIONS.REMOVE.Message")}</p>
                </FeedbackPopup>
              );
            }}
            iconProps={deleteIcon}
          />
        ),
      },
    ];
  }, [onClickRemoveProjectAssociation, openPopup]);

  const handleChangeVisibility = useCallback(
    (newVisibility: boolean) => {
      if (projectDetails?.code) {
        var request: ProjectPathDTO = {
          isPublic: newVisibility,
        };
        projectsService
          .updateProjectPatch(projectDetails.code, request)
          .then((_) => {
            getProjectDetails();
            openPopup(
              <FeedbackPopup type="success">
                <p>{translate("PROJECTS.EDIT.VisibilityChangedWithSuccess")}</p>
              </FeedbackPopup>
            );
          })
          .catch((_) => {
            setIsPublic(!newVisibility);
            closePopup();
          });
      }
    },
    [closePopup, setIsPublic, projectDetails?.code, openPopup, getProjectDetails]
  );

  const handleCancelVisibility = useCallback(
    (newVisibility: boolean) => {
      setIsPublic(!newVisibility);
    },
    [setIsPublic]
  );

  return (
    <div className={"project-wrapper " + disabledClass}>
      <div className="project">
        <CardLayout classNames={"project-info-card"}>
          {activitiesSpinner ? (
            <Spinner size={SpinnerSize.large} label={translate("LOADING.LoadingDataPleaseWait")} />
          ) : (
            <>
              <div className="header">
                <h1 className="truncate">
                  {projectDetails?.isDisabled ? <FontIcon className="disabled-icon" iconName="Hide" /> : null}
                  {projectDetails?.name ? projectDetails.name : "-"}
                </h1>
                <Toggle
                  data-test={"change-project-privacy"}
                  checked={isPublic ? true : false}
                  onText={translate("COMMON.Public")}
                  offText={translate("COMMON.Private")}
                  onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                    setIsPublic(checked || false);
                    openPopup(
                      <FeedbackPopup
                        type="warning"
                        title={translate("ACTIONS.ChangeVisibility")}
                        primaryActionLabel={translate("ACTIONS.Change")}
                        primaryActionFunc={() => handleChangeVisibility(checked || false)}
                        cancelActionFunc={() => handleCancelVisibility(checked || false)}
                      >
                        <p>{translate("PROJECTS.EDIT.AreYouSureYouWantToChangeVisibility")}</p>
                      </FeedbackPopup>
                    );
                  }}
                  disabled={projectDetails?.isDisabled}
                />
              </div>
              <div className="project-info">
                <InfoBlock label={translate("COMMON.Code")} text={projectDetails?.code ? projectDetails.code : "-"} />
                <InfoBlock label={translate("COMMON.Client")} text={projectDetails?.client ? projectDetails.client : "-"} />
              </div>
            </>
          )}
        </CardLayout>
        <Spacer mode="vertical" />
        <CardLayout classNames="project-employees-card">
          <div className="commandbar">
            <h1>{translate("COMMON.Employees")}</h1>
            <div className="action-btns">
              {projectAssociations && projectAssociations.length > 0 ? <CommandBar items={_itemsEmployees} ariaLabel="Employees action buttons" /> : null}
            </div>
          </div>
          {projectAssociations?.length === 0 ? (
            <div className="empty">
              <div className="empty-title">{translate("ASSOCIATIONS.EmptyListTitle")}</div>
              <p>{translate("ASSOCIATIONS.EmptyAssociationsProjectListMessage")}</p>
              <SecondaryButton
                text={translate("ASSOCIATIONS.AddEmployee")}
                dataTest="empty-associations-add-employee"
                icon={add}
                onClick={() =>
                  openPopup(
                    <AddEmployeeProjectPopup
                      projectId={projectDetails?.code ? projectDetails.code : ""}
                      onCompletedOperations={() => {
                        getProjectAssociations();
                        getProjectDetails();
                      }}
                    />
                  )
                }
                isDisabled={projectDetails?.isDisabled}
              />
            </div>
          ) : (
            <div className="project-employees">
              <ShimmeredDetailsList
                enableShimmer={employeesAssociatedSpinner}
                className="project-employees-list"
                items={projectAssociations || []}
                columns={projectAssociationsColumns}
                onShouldVirtualize={(_) => false}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
              />
            </div>
          )}
        </CardLayout>
      </div>
      <Spacer mode="horizontal" />
      <CardLayout classNames="activities-list-card">
        <div className="commandbar">
          <h1>{translate("ACTIVITIES.ProjectActivitiesName")}</h1>
          <div className="action-btns">
            {projectDetails?.activities && projectDetails?.activities.length > 0 ? <CommandBar items={_itemsActivities} ariaLabel="Action buttons" /> : null}
          </div>
        </div>
        <div className="wrapper">
          {projectDetails?.activities.length === 0 ? (
            <div className="empty">
              <div className="empty-title">{translate("ACTIVITIES.EMPTYLIST.Title")}</div>
              <p>{translate("ACTIVITIES.EMPTYLIST.Message")}</p>
              <SecondaryButton
                text={translate("ACTIVITIES.AddActivity")}
                dataTest="empty-activities-add-button"
                icon={add}
                onClick={() => {
                  if (projectId) {
                    openPopup(
                      <AddActivityPopup
                        onAddActivityCompleted={() => {
                          getProjectDetails();
                          setActivitiesSpinner(true);
                        }}
                        projectId={projectDetails?.code}
                      />
                    );
                  }
                }}
                isDisabled={projectDetails?.isDisabled}
              />
            </div>
          ) : (
            <ShimmeredDetailsList
              enableShimmer={activitiesSpinner}
              className="activities-list"
              items={projectDetails?.activities ? projectDetails.activities : []}
              columns={activitiesColumns}
              selectionMode={SelectionMode.none}
              onShouldVirtualize={(_) => false}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={_renderRow}
            />
          )}
        </div>
      </CardLayout>
    </div>
  );
}

// const overflowButtonProps = {
//   ariaLabel: "More employees",
//   onClick: (ev: React.MouseEvent<HTMLButtonElement>) => alert("overflow icon clicked"),
// };

/*   const employees: IFacepilePersona[] = [
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
    {
      imageInitials: getInitials("John Doe", false, true),
      imageUrl: avatar,
    },
  ]; */
