import { useCallback, useContext, useMemo } from "react";

import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { ModuleDefinition } from "../../modules/ModuleDefinition";
import { NavigationSvcContext } from "../NavigationServices";
import { useNavigate } from "react-router-dom";

export function useBreadcrumb() {
  const context = useContext(NavigationSvcContext);
  if (!context) {
    throw new Error("useBreadcrumb must be used within NavigationServices");
  }

  const navigate = useNavigate();

  const items = useMemo((): IBreadcrumbItem[] => {
    return context.breadcrumb
      .map((m) =>
        m.name
          ? {
              text: (Object.hasOwn(context.routeData, m.name || ".") ? context.routeData[m.name || "."] : m.name) || ".",
              key: m.path || ".",
              onClick: () => (m.path ? navigate(m.path) : null),
            }
          : null
      )
      .filter((m) => m !== null) as IBreadcrumbItem[];
  }, [context.breadcrumb, context.routeData, navigate]);

  return { breadcrumb: items };
}

export function useRegisterBreadcrumbRoutes() {
  const context = useContext(NavigationSvcContext);
  if (!context) {
    throw new Error("useRegisterBreadcrumbRoutes must be used within NavigationServices");
  }

  const registerFunction = useCallback(
    (definition: ModuleDefinition) => {
      let routes = definition.routes.map((r) => ({
        path: definition.basePath + r.pathPattern,
        name: r.name(),
      }));

      context.setRoutes(routes);
    },
    [context.setRoutes]
  );

  return registerFunction;
}
