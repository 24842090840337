import dayjs from 'dayjs';

export class DateTimeHelper {

    static getCurrentMonth(): number {
        return new Date().getMonth();
    }


    static getCurrentYear(): number {
        return new Date().getFullYear();
    }

    static getDayNumber(date: Date): number {
        return date.getDate()
    }


    static isToday(date: Date): boolean {
        return dayjs(date).isToday();
    }
}
