import "./DeleteHoursPopup.css";

import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { useCallback, useState } from "react";
import { useClosePanel, usePopup } from "../../../../infrastructure/ui/UIServices";

import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { HoursService } from "../../../../services/HoursService";
import { translate } from '../../../../infrastructure/i18n/InternationalizationService';

interface IDeletePopupProps {
  deleteOpCompleted: () => void;
  dailyRecordId?: number;
}

var hoursService = new HoursService();

export function DeleteHoursPopup(props: IDeletePopupProps) {
  const openPopup = usePopup();
  const closePanel = useClosePanel();

  const [spinner, setSpinner] = useState<boolean>(false);
  const [isStopSpinner, setIsStopSpinner] = useState<boolean>(false);

  const onClickDeleteButton = useCallback(() => {
    setSpinner(true);
    hoursService
      .deleteDailyRecord(props.dailyRecordId || 0)
      .then(() => {
        props.deleteOpCompleted();
        setIsStopSpinner(true);
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("HOURS.YouHaveDeletedADailyRecord")}</p>
          </FeedbackPopup>
        );
        closePanel();
        // setSpinner(false);
      })
      .catch((err) => {
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(err);
          if (parsedResponse.AlertType === ErrorTypeEnum.ERROR) {
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
          }
        } catch {
          setIsStopSpinner(true);
          openPopup(
            <FeedbackPopup type="error">
              <p>{err}</p>
            </FeedbackPopup>
          );
          // setSpinner(false);
        }
      });
  }, [props.dailyRecordId, props.deleteOpCompleted, spinner, openPopup]);

  return (
    <div className="delete-hours-popup">
      <FeedbackPopup
        type="warning"
        title={translate("HOURS.ACTIONS.DeleteTimeRecord")}
        primaryActionLabel={translate("ACTIONS.Remove")}
        primaryActionFunc={() => {
          onClickDeleteButton();
        }}
        isStopSpinner={isStopSpinner}
      >
        <p>{translate("HOURS.WARNINGS.YouAreAboutToPermanentlyRemoveATimeRecord")}</p>
      </FeedbackPopup>
    </div>
  );
}
