import "./AllocateHoursPopup.css";

import { Activity, Project, ProjectsService } from "../../../../services/ProjectsService";
import { DailyRecordDTO, EditDailyRecordRequestDTO, HoursService, ITimeRecord, ITimeRecordResponseDTO } from "../../../../services/HoursService";
import {
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  FontIcon,
  IColumn,
  IDropdownOption,
  IIconProps,
  IconButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  SpinnerSize,
  TextField,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TimeSpanHelper, TimeSpanHelperObj } from "../../../../helpers/TimeSpanHelper";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useWindowResize } from "../../../../infrastructure/ui/UIServices";

import { CardHeader } from "../../../../components/cards/CardHeader";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { NumberPicker } from "../../../../components/inputs/NumberPicker";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../components/buttons/SecondaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { TimePicker } from "../../../../components/inputs/TimePicker";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import { InternationalizationService, translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { useNavigate } from "react-router-dom";

const addIcon: IIconProps = { iconName: "Add" };
const deleteIcon: IIconProps = { iconName: "Delete" };

const minValue = 1;
const maxValue = 8;

var hoursService = new HoursService();
var projectsService = new ProjectsService();

interface IEditHoursProps {
  day: Date;
  dailyRecordId?: number;
  editCompleted?: () => void;
  holidayName?: string;
}

interface TimeRecordDTO {
  timeRecordId?: number;
  projectId: number | string;
  projectName: string;
  activityId: number | string;
  activityName: string;
  hours: string;
  notes?: string;
}

interface WorkingTimes {
  morningStart?: string;
  morningEnd?: string;
  afternoonStart?: string;
  afternoonEnd?: string;
}

//Mapper projects[] to IDropDownOptions
function mapProjectToIDropdownOptions(projects: Project[]) {
  var optionsMapped = projects.map((obj) => {
    var opMapped: IDropdownOption = {
      key: obj.projectId,
      text: obj.name,
    };
    return opMapped;
  });
  return optionsMapped;
}

//Mapper activity[] to IDropDownOptions
function mapActivityToIDropdownOptions(acitivities: Activity[]) {
  var optionsMapped = acitivities.map((obj) => {
    var opMapped: IDropdownOption = {
      key: obj.activityId,
      text: obj.name,
    };
    return opMapped;
  });
  return optionsMapped;
}

//Mapper from Service to TimeRecordDTO
function mapTimeRecordsFromServiceToTimeRecordDTO(timeRecords: ITimeRecordResponseDTO[]) {
  var timeRecordsMapped = timeRecords.map((tr) => {
    var recordMapped: TimeRecordDTO = {
      timeRecordId: tr.id,
      activityName: tr.activityName,
      projectName: tr.projectName,
      activityId: tr.activityCode,
      projectId: tr.projectCode,
      hours: tr.activityHours.toString(),
      notes: tr.notes || "",
    };
    return recordMapped;
  });

  return timeRecordsMapped;
}

const substringFromWorkingTime = (value: string | undefined, isMinutes: boolean) => {
  if (value) {
    if (!isMinutes) {
      return value.substring(0, 2);
    } else {
      return value.slice(-2);
    }
  }
  return "--";
};

export function EditHoursPopup(props: IEditHoursProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const navigate = useNavigate();

  const windowResize = useWindowResize();

  const [loadRecordSpinner, setLoadRecordSpinner] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(0);
  /* -------------------- Daily records -------------------- */
  const [initialMorningTime, setInitialMorningTime] = useState<string>(); //Default working time, to change later with several options.
  const [finalMorningTime, setFinalMorningTime] = useState<string>();
  const [initialAfternoonTime, setInitialAfternoonTime] = useState<string>();
  const [finalAfternoonTime, setFinalAfternoonTime] = useState<string>();
  const [workingTimeOriginal, setWorkingTimeOriginal] = useState<WorkingTimes>();

  /* -------------------- TimeRecords -------------------- */
  const [project, setProject] = useState<IDropdownOption>();
  const [mappedProjects, setMappedProjects] = useState<IDropdownOption[]>([]);
  const [activity, setActivity] = useState<IDropdownOption>();
  const [mappedActivities, setMappedActivities] = useState<IDropdownOption[]>([]);
  const [hours, setHours] = useState<string>(minValue.toString());
  const [allTimeRecords, setAllTimeRecords] = useState<TimeRecordDTO[]>([]);
  const [originalTimeRecords, setOriginalTimeRecords] = useState<TimeRecordDTO[]>([]);
  const [timeRecordsToDelete, setTimeRecordsToDelete] = useState<number[]>([]);
  const [activityNotes, setActivityNotes] = useState<string>("");

  /* -------------------- Booleans -------------------- */
  const [disableAddTimeRecordButton, setDisableAddTimeRecordButton] = useState<boolean>(true);
  const [activityNotSelected, setActivityNotSelected] = useState<boolean>(true);
  const [projectNotSelected, setProjectNotSelected] = useState<boolean>(true);
  const [upperValidationError, setUpperValidationError] = useState<boolean>(false);

  const [saveButton, setSaveButtonIsDisabled] = useState<boolean>(true);
  const [errorMessage, setMessageError] = useState<string>("");

  /* -------------------- TimeSpan -------------------- */
  const [workingTimespan, setWorkingTimespan] = useState<TimeSpanHelperObj>();
  const [totalProjectHours, setTotalProjectHours] = useState<number>(0);

  var advancedFormat = require("dayjs/plugin/advancedFormat");
  dayjs.extend(advancedFormat);

  // Header popup, extended date.
  const selectedDateAsDayJs = useMemo(
    () =>
      windowResize > 768
        ? dayjs(props.day).locale(InternationalizationService.getLocale()).format("dddd, D MMMM")
        : dayjs(props.day).locale(InternationalizationService.getLocale()).format("dddd, D MMM"),
    [props.day, windowResize]
  );

    

  useEffect(() => {
    if (props.dailyRecordId) {
      setLoadRecordSpinner(true);
      Promise.all([hoursService.getDailyRecordById(props.dailyRecordId), projectsService.getMyProjects()])
        .then((res) => {
          mapDataToEachState(res[0]);
          var projectsMapped = mapProjectToIDropdownOptions(res[1]);
          setMappedProjects(projectsMapped);
          setLoadRecordSpinner(false);
          setWorkingTimeOriginal({
            morningStart: res[0].morningStart,
            morningEnd: res[0].morningEnd,
            afternoonStart: res[0].afternoonStart,
            afternoonEnd: res[0].afternoonEnd,
          });
        })
        .catch((error) => {
          navigate("/error");
        });
    }
  }, []);

  useEffect(() => {
    var isMorningValid: boolean = true;
    var isAfternoonValid: boolean = true;

    //REFACTOR CODE BELLOW
    if (initialMorningTime !== undefined && finalMorningTime !== undefined && initialAfternoonTime !== undefined && finalAfternoonTime !== undefined) {
      initialMorningTime && finalMorningTime ? (isMorningValid = true) : (isMorningValid = false);
      initialAfternoonTime && finalAfternoonTime ? (isAfternoonValid = true) : (isAfternoonValid = false);
      var res = TimeSpanHelper.getTimeSpan(initialMorningTime, finalMorningTime, initialAfternoonTime, finalAfternoonTime, isMorningValid, isAfternoonValid);
      setWorkingTimespan(res);
    }
  }, [initialMorningTime, finalMorningTime, initialAfternoonTime, finalAfternoonTime]);

  //Set response from service, for each object propertie to state.
  function mapDataToEachState(originalData: DailyRecordDTO) {
    if (!originalData) {
      return null;
    }
    var sumHours: number = 0;
    setInitialMorningTime(originalData.morningStart);
    setFinalMorningTime(originalData.morningEnd);
    setInitialAfternoonTime(originalData.afternoonStart);
    setFinalAfternoonTime(originalData.afternoonEnd);
    var mappedTimeRecords = mapTimeRecordsFromServiceToTimeRecordDTO(originalData.timeRecord);
    mappedTimeRecords.map((pr) => {
      sumHours = sumHours + Number(pr.hours);
    });
    setTotalProjectHours(sumHours);
    setAllTimeRecords(mappedTimeRecords);
    setOriginalTimeRecords(mappedTimeRecords);
  }

  // THIS USEEFFECT NEEDS A REFACTOR
  //BAD CODING
  useEffect(() => {
    setCounter(counter + 1);
    if (counter > 5) {
      //number times this useEffect is used after loading the record. //NEEDS fix
      if (allTimeRecords.length > 0 && initialMorningTime && finalMorningTime) {
        setSaveButtonIsDisabled(false);
      }
      if (allTimeRecords.length > 0 && initialAfternoonTime && finalAfternoonTime) {
        setSaveButtonIsDisabled(false);
      }
      if (!initialAfternoonTime && !initialMorningTime && !finalAfternoonTime && !finalMorningTime) {
        setSaveButtonIsDisabled(true);
      }
      if ((initialAfternoonTime && !finalAfternoonTime) || (!initialAfternoonTime && finalAfternoonTime)) {
        setSaveButtonIsDisabled(true);
      }
      if ((initialMorningTime && !finalMorningTime) || (!initialMorningTime && finalMorningTime)) {
        setSaveButtonIsDisabled(true);
      }
    }
  }, [initialAfternoonTime, initialMorningTime, finalAfternoonTime, finalMorningTime, allTimeRecords]);

  useEffect(() => {
    var totalActivitiesHours: number = 0;

    if (allTimeRecords && workingTimespan) {
      allTimeRecords.map((timeRecord) => {
        return (totalActivitiesHours += Number(timeRecord.hours));
      });

      var remainingHours = Math.floor(workingTimespan?.totalHours - totalActivitiesHours);

      if (workingTimespan.totalHours < 0 || remainingHours < 0) {
        setHours("0");
      } else {
        setHours(remainingHours.toString());
      }

      if (activity?.key && remainingHours > 0) {
        setActivityNotSelected(false);
        setDisableAddTimeRecordButton(false);
      } else {
        setActivityNotSelected(true);
        setDisableAddTimeRecordButton(true);
      }
    }
  }, [allTimeRecords, workingTimespan, /* project, */ activity]);

  // SET project on the DropDownOptions
  const onChangeProject = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setProject(item);
      projectsService
        .getActivities(item.key)
        .then((response) => {
          var activitiesMapped = mapActivityToIDropdownOptions(response);
          setMappedActivities(activitiesMapped);
          setProjectNotSelected(false);
        })
        .catch((err) => {
          openPopup(
            <FeedbackPopup type="error">
              <p>{err}</p>
            </FeedbackPopup>
          );
        });
    },
    [setProject, setMappedActivities, setProjectNotSelected]
  );

  const onChangeActivity = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setActivity(item);
      setActivityNotSelected(false);
    },
    [setActivity, setActivityNotSelected]
  );

  const convertProjectIdToProjectName = useCallback(
    (item: IDropdownOption) => {
      var res = mappedProjects.find((p) => p.key === item.key);
      return res?.text;
    },
    [mappedProjects]
  );

  const convertActivityToActivityName = useCallback(
    (item: IDropdownOption) => {
      var res = mappedActivities.find((p) => p.key === item.key);
      return res?.text;
    },
    [mappedActivities]
  );

  const onClickAddTimeRecord = useCallback(() => {
    var allTimeRecordsCopy = [...allTimeRecords];
    var addTimeRecord: TimeRecordDTO = {
      projectId: project?.key || 0,
      activityId: activity?.key || 0,
      hours: hours || "",
      activityName: convertActivityToActivityName(activity!) || "",
      projectName: convertProjectIdToProjectName(project!) || "",
      notes: activityNotes.trim(),
    };
    setHours(minValue.toString());
    setActivityNotes("");
    setActivity({ key: "", text: "" });
    setActivityNotSelected(true); //not needed: test before remove
    setTotalProjectHours(totalProjectHours + Number(hours));

    allTimeRecordsCopy.push(addTimeRecord);
    setAllTimeRecords(allTimeRecordsCopy);
  }, [allTimeRecords, setAllTimeRecords, project, activity, hours, activityNotes, setActivityNotes]);

  const onClickRemoveTimeRecord = useCallback(
    (item: TimeRecordDTO) => {
      const index = allTimeRecords.findIndex((proj) => proj === item);
      const project = allTimeRecords.find((proj) => proj === item);
      var arrayCopy = [...allTimeRecords];
      var arrayCopyRecordsToDelete = [...timeRecordsToDelete];
      if (index > -1) {
        arrayCopy.splice(index, 1);
        setAllTimeRecords(arrayCopy);
        setTotalProjectHours(totalProjectHours - Number(project?.hours));
        if (item.timeRecordId) {
          arrayCopyRecordsToDelete.push(item.timeRecordId);
          setTimeRecordsToDelete(arrayCopyRecordsToDelete);
        }
      }
      if (arrayCopy.length === 0) {
        setSaveButtonIsDisabled(true);
      }
    },
    [allTimeRecords, setAllTimeRecords, setSaveButtonIsDisabled]
  );

  const columns: IColumn[] = useMemo(() => {
    if (windowResize > 768) {
      return [
        {
          key: "column1",
          name: translate("HOURS.Hrs"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "hours",
          minWidth: 40,
          maxWidth: 40,
          data: "string",
          onRender: (item) => <div className="truncate">{item.hours}</div>,
        },
        {
          key: "column2",
          name: translate("MENU.Projects"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "projectName",
          minWidth: 100,
          maxWidth: 250,
          data: "string",
          onRender: (item) => <div className="truncate">{item.projectName}</div>,
        },
        {
          key: "column3",
          name: translate("ACTIVITIES.Activity"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "activityName",
          minWidth: 100,
          data: "string",
          onRender: (item) => <div className="truncate">{item.activityName}</div>,
        },
        {
          key: "column4",
          name: translate("HOURS.Notes"),
          fieldName: "notes",
          minWidth: 20,
          maxWidth: 20,
          isRowHeader: false,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: disableAddTimeRecordButton,
          onRender: (item) =>
            item.notes ? (
              <TooltipHost content={item.notes} delay={TooltipDelay.medium}>
                <FontIcon aria-label="Note" iconName="QuickNote" className="notes-icon" />
              </TooltipHost>
            ) : null,
        },
        {
          key: "column5",
          name: translate("ACTIONS.Remove"),
          fieldName: "remove",
          minWidth: 32,
          maxWidth: 32,
          isRowHeader: true,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: disableAddTimeRecordButton,
          onRender: (item) => <IconButton onClick={() => onClickRemoveTimeRecord(item)} iconProps={deleteIcon} />,
        },
      ];
    } else {
      return [
        {
          key: "column1",
          name: translate("HOURS.Hrs"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "hours",
          minWidth: 40,
          maxWidth: 40,
          data: "string",
          onRender: (item) => <div className="truncate">{item.hours}</div>,
        },
        {
          key: "column2",
          name: translate("PROJECTS.Project"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "projectName",
          minWidth: 100,
          data: "string",
          onRender: (item) => (
            <div className="project-info truncate">
              <div className="project truncate">{item.projectName}</div>
              <div className="activity truncate">{item.activityName}</div>
            </div>
          ),
        },
        {
          key: "column3",
          name: translate("ACTIONS.Remove"),
          fieldName: "remove",
          minWidth: 32,
          maxWidth: 32,
          isRowHeader: true,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: disableAddTimeRecordButton,
          onRender: (item) => <IconButton onClick={() => onClickRemoveTimeRecord(item)} iconProps={deleteIcon} />,
        },
      ];
    }
  }, [onClickRemoveTimeRecord, convertActivityToActivityName, convertProjectIdToProjectName, windowResize]);

  //Mapper timeRecordDTO to ITimeRecord (component -> service)
  const mapTimeRecordDTOToTimeRecord = useCallback(
    (timeRecords: TimeRecordDTO[]) => {
      var timeRecordsMapped = timeRecords.map((tr) => {
        var replaceSpecialCharacters = tr.hours.replace(",", ".");
        var recordMapped: ITimeRecord = {
          activityId: tr.activityId,
          projectId: tr.projectId,
          hours: replaceSpecialCharacters,
          id: tr.timeRecordId,
          notes: tr.notes,
        };
        return recordMapped;
      });
      return timeRecordsMapped;
    },
    [allTimeRecords]
  );

  const onClickSaveInfo = useCallback(() => {
    setUpperValidationError(false);
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);

    var dailyRecord: EditDailyRecordRequestDTO = {
      id: props.dailyRecordId || 0,
      day: props.day,
      timeRecordsToDelete: timeRecordsToDelete,
      initialMorningTime: initialMorningTime || "",
      finalMorningTime: finalMorningTime || "",
      initialAfternoonTime: initialAfternoonTime || "",
      finalAfternoonTime: finalAfternoonTime || "",
      timeRecords: mapTimeRecordDTOToTimeRecord(allTimeRecords),
    };

    hoursService
      .editDailyRecord(dailyRecord.id, dailyRecord)
      .then(() => {
        closeOverlaySpinner();
        setSaveButtonIsDisabled(false);
        setDisableAddTimeRecordButton(true);
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("HOURS.YouHaveEditedADailyRecord")}</p>
          </FeedbackPopup>
        );
        props.editCompleted?.();
      })
      .catch((error) => {
        setSaveButtonIsDisabled(false);
        closeOverlaySpinner();
        setDisableAddTimeRecordButton(true);
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(error);
          if (parsedResponse.AlertType === ErrorTypeEnum.WARNING) {
            openPopup(
              <FeedbackPopup type="warning">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
          }
        } catch {
          if (error.code === "ERR_BAD_REQUEST") {
            setUpperValidationError(true);
            setDisableAddTimeRecordButton(true);
            setMessageError(error.response.data);
          } else {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.response?.data}</p>
              </FeedbackPopup>
            );
          }
        }
      });
  }, [
    props.editCompleted,
    initialMorningTime,
    finalMorningTime,
    initialAfternoonTime,
    finalAfternoonTime,
    allTimeRecords,
    originalTimeRecords,
    openPopup,
    setUpperValidationError,
  ]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setUpperValidationError(false);
          setSaveButtonIsDisabled(false);
        }}
        dismissButtonAriaLabel={translate("ACTIONS.Close")}
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setSaveButtonIsDisabled, setUpperValidationError]);

  return (
    <div className="popup-content allocate-hours-popup">
      <CardHeader
        headerTitle={translate("HOURS.EditTimeAllocation")}
        children={
          <div className={"subtitle" + (props.holidayName ? " holiday" : "")}>
            {selectedDateAsDayJs}
            {props.holidayName ? " - " + props.holidayName : null}
          </div>
        }
        icon={true}
        setDisplayState={(state: boolean) => closePopup()}
      />
      <div className="content">
        {loadRecordSpinner ? (
          <div className="editPopup-loading">
            {" "}
            <Spinner size={SpinnerSize.large} label={translate("LOADING.LoadingDataPleaseWait")} />
          </div>
        ) : (
          <>
            <div className="working-time">
              <div className="working-time-inputs">
                <TimePicker
                  labelText={translate("HOURS.MorningTime")}
                  func={(value) => setInitialMorningTime(value)}
                  defaultHour={substringFromWorkingTime(initialMorningTime, false)}
                  defaultMinutes={substringFromWorkingTime(initialMorningTime, true)}
                />
                <TimePicker
                  func={(value) => setFinalMorningTime(value)}
                  defaultHour={substringFromWorkingTime(finalMorningTime, false)}
                  defaultMinutes={substringFromWorkingTime(finalMorningTime, true)}
                />
                <TimePicker
                  labelText={translate("HOURS.AfternoonTime")}
                  func={(value) => setInitialAfternoonTime(value)}
                  defaultHour={substringFromWorkingTime(initialAfternoonTime, false)}
                  defaultMinutes={substringFromWorkingTime(initialAfternoonTime, true)}
                />
                <TimePicker
                  func={(value) => setFinalAfternoonTime(value)}
                  defaultHour={substringFromWorkingTime(finalAfternoonTime, false)}
                  defaultMinutes={substringFromWorkingTime(finalAfternoonTime, true)}
                />
              </div>
              {upperValidationError ? errorMessageBar() : null}
            </div>
            <div className="project-hours">
              <h2>{translate("HOURS.ProjectHours")}</h2>
              <div className="project-inputs">
                <Dropdown
                  selectedKey={project ? project.key : undefined}
                  placeholder={translate("HOURS.ACTIONS.SelectProject")}
                  label={translate("MENU.Projects")}
                  options={mappedProjects || []}
                  required={true}
                  onChanged={onChangeProject}
                />
                <Dropdown
                  className={projectNotSelected ? "project-disabled" : ""}
                  selectedKey={activity ? activity.key : undefined}
                  placeholder={translate("HOURS.ACTIONS.SelectActivity")}
                  label={translate("ACTIVITIES.Activity")}
                  options={mappedActivities || []}
                  required={true}
                  onChanged={onChangeActivity}
                  disabled={projectNotSelected}
                />
                <NumberPicker
                  value={hours}
                  isDisabled={activityNotSelected}
                  labelText={translate("HOURS.Hours")}
                  setValue={(value) => setHours(value)}
                  isRequired={true}
                  minValue={minValue}
                  maxValue={maxValue}
                />
                <TextField
                  className="notes"
                  onChange={(ev, input) => {
                    setActivityNotes(input || "");
                  }}
                  label={windowResize < 768 ? translate("HOURS.Notes") : ""}
                  placeholder={windowResize < 768 ? "Ex: Lorem Ipsum" : translate("HOURS.Notes")}
                  multiline
                  value={activityNotes}
                  rows={2}
                />
                <SecondaryButton isDisabled={disableAddTimeRecordButton} onClick={onClickAddTimeRecord} text={translate("HOURS.ACTIONS.AddHours")} icon={addIcon} />
              </div>
              {allTimeRecords.length > 0 ? (
                <DetailsList
                  className="project-hours-list"
                  items={allTimeRecords}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  setKey="none"
                  layoutMode={DetailsListLayoutMode.justified}
                />
              ) : null}
            </div>
          </>
        )}
      </div>
      {!loadRecordSpinner ? (
        <div>
          <div className="action-btns">
            <div className="total-hours">
              <div className="working-hours">{translate("HOURS.TotalWorkingHours", "" + workingTimespan?.phrase)}</div>
              {totalProjectHours > 0 ? <div className="project-time">{translate("HOURS.TotalProjectHours", "" + totalProjectHours)}</div> : null}
            </div>
            <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
            <PrimaryButton isDisabled={saveButton} onClick={() => onClickSaveInfo()} text={translate("ACTIONS.Allocate")}>
              {" "}
            </PrimaryButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}
