import { AxiosError } from "axios";
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../infrastructure/http/HttpClient";
import { IInfoPanelPhoto } from "../areas/people-management/equipment-list/panels/InfoPanel";
import { EquipmentCreateRequestDTO } from "../models/api/equipments/EquipmentCreateRequestDTO";
import { EquipmentsFieldsCreateDTO } from "../models/api/equipments/EquipmentsFieldsCreateDTO";
import { EquipmentAndRecentAllocationDTO } from "../models/api/equipments/EquipmentAndRecentAllocationDTO";
import { EmployeeCell } from "../components/calendar/cells/EmployeeCell";
import { EquipmentsGetResponseDTO } from "../models/api/equipments/EquipmentsGetResponseDTO";
import { EquipmentStatusesGetResponseDTO } from "../models/api/equipments/EquipmentStatusesGetResponseDTO";
import { EquipmentTypesGetResponseDTO } from "../models/api/equipments/EquipmentTypesGetResponseDTO";
import { EquipmentGetResponseDTO } from "../models/api/equipments/EquipmentGetResponseDTO";
import dayjs from "dayjs";
import { EquipmentPhotosGetResponseDTO } from "../models/api/equipments/EquipmentPhotosGetResponseDTO";
import { PatchOperationDTO } from "../models/api/projects/PatchOperationDTO";
import { PatchDTO } from "../models/api/projects/PatchDTO";

import * as qs from "qs";
import { EquipmentsGetRequestDTO } from "../models/api/equipments/EquipmentsGetRequestDTO";

export interface GraphUser {
  displayName: string;
  url: string;
}

export interface GetEquipmentsAndMostRecentAllocationResponseDTO {
  equipmentId: number;
  reference: string;
  description: string;
  type: IEquipmentType;
  brand: string;
  model: string;
  status: IEquipmentStatus;
  employee: GraphUser;
  date: string | null;
  state: string | null;
  photos: IInfoPanelPhoto[];
  serialNumber: string;
  purchaseDate: string | null;
}

export interface CreateEquipmentRequestDto {
  reference: string;
  equipmentTypeId: number | string;
  description: string;
  brand: string;
  model?: string;
  purchaseDate?: Date;
  serialNumber: string;
}

export interface IEquipmentType {
  id: number;
  description: string;
  code: string;
}

export interface IEquipmentStatus {
  id: number;
  description: string;
  code: string;
}

export interface GetSingleEquipmentInfoResponseDTO {
  equipmentId: number;
  brand: string;
  description: string;
  model: string | null;
  reference: string;
  serialNumber: string;
  purchaseDate: string | null;
  photos: IInfoPanelPhoto[];
  type: IEquipmentType;
  status: IEquipmentStatus;
}

export interface UpdateEquipmentInputsRequestDTO {
  brand?: string;
  description?: string;
  equipmentTypeId?: number;
  model?: string;
  purchaseDate?: string;
  serialNumber?: string;
  reference?: string;
  equipmentStatusId?: number;
}

export interface IEquipmentAllocatedDTO {
  equipmentId: number;
  brand: string;
  model: string;
  reference: string;
  description: string;
  type: IEquipmentType;
  allocationStartDate: Date | null;
  lastAllocationStatus: string;
}

export interface EquipmentsFiltersDTO {
  equipmentTypesId?: number[];
  allocatedTo?: string;
  allocationStatusesId?: number[];
  equipmentStatusesId?: number[];
  needsRepairId?: number;
  availableComputers?: boolean;
  availableMonitors?: boolean;
  inRepairId?: number;
  searchFor?: string;
  orderBy?: string;
  orderDirection?: string;
}

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export interface OrderInfoDTO {
  propertyName: string;
  direction: string;
}

export class EquipmentsService {
  getEquipmentsList(filters: EquipmentsFiltersDTO): Promise<GetEquipmentsAndMostRecentAllocationResponseDTO[]> {
    var requestDTO: EquipmentsGetRequestDTO = {   
      searchFor: filters.searchFor,
      allocatedTo: filters.allocatedTo,
      equipmentTypesId: filters.equipmentTypesId,
      equipmentStatusesId: filters.equipmentStatusesId,
      allocationStatusesId: filters.allocationStatusesId,
      orderBy: filters.orderBy,
      orderDirection: filters.orderDirection
    };

    return HttpClient.sessionRequest<EquipmentsGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/equipments`),
      params: requestDTO,
      
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
      .then((res) => {
        //In progress: Needs a refactor in the target interface
        var mapped = res.data.equipments.map(
          (equip): GetEquipmentsAndMostRecentAllocationResponseDTO => ({
            equipmentId: equip.equipment.equipmentID,
            brand: equip.equipment.brand,
            model: equip.equipment.model,
            reference: equip.equipment.reference,
            purchaseDate: equip.equipment.purchaseDate,
            description: equip.equipment.description,
            date: equip.recentAllocation.date,
            state: equip.recentAllocation.state,
            serialNumber: equip.equipment.serialNumber,
            type: {
              id: equip.equipment.type.id,
              code: equip.equipment.type.code,
              description: equip.equipment.type.description,
            },
            //Recent allocation
            status: equip.equipment.status ?? "",
            employee: {
              displayName: equip.recentAllocation.allocatedTo?.displayName ?? "",
              url: equip.recentAllocation.allocatedTo?.photoURL ?? "",
            },
            photos: equip.equipment.photos.map(
              (img): IInfoPanelPhoto => ({
                name: img.name ?? "",
                url: img.url ?? "",
              })
            ),
          })
        );
        return mapped;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createNewEquipment(equipmentRequestDTO: EquipmentsFieldsCreateDTO, photos: File[]): Promise<void> {
    const formData = new FormData();

    formData.append("json", JSON.stringify(equipmentRequestDTO));
    photos.map((image) => {
      formData.append("files", image, image.name);
    });

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/v1/equipments"),
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getEquipmentByID(equipmentId: number): Promise<GetSingleEquipmentInfoResponseDTO> {
    return HttpClient.sessionRequest<EquipmentGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/equipments/${equipmentId}`),
    })
      .then((response) => {
        var mappedValue: GetSingleEquipmentInfoResponseDTO = {
          equipmentId: response.data.equipmentId,
          brand: response.data.brand,
          description: response.data.description,
          model: response.data.model,
          reference: response.data.reference,
          serialNumber: response.data.serialNumber,
          purchaseDate: response.data.purchaseDate,
          status: {
            id: response.data.status.id,
            code: response.data.status.code,
            description: response.data.status.description,
          },
          type: {
            id: response.data.type.id,
            code: response.data.type.code,
            description: response.data.type.description,
          },
          photos: response.data.photos.map(
            (img): IInfoPanelPhoto => ({
              name: img.name ?? "",
              url: img.url ?? "",
            })
          ),
        };
        return mappedValue;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getEquipmentPhotosById(equipmentId: number): Promise<IInfoPanelPhoto[]> {
    return HttpClient.sessionRequest<EquipmentPhotosGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/equipments/${equipmentId}/photos`),
    })
      .then((response) => {
        return response.data.photos.map(
          (photo): IInfoPanelPhoto => ({
            name: photo.name,
            url: photo.url,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  addEquipmentPhoto(equipmentId: number, images: File[]): Promise<void> {
    const formData = new FormData();
    for (var i = 0; i < images.length; i++) {
      formData.append("images", images[i], images[i].name);
    }

    return HttpClient.request({
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      url: Route(`/api/v1/equipments/${equipmentId}/photos`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  deleteEquipmentPhoto(equipmentId: number, name: string): Promise<void> {
    return HttpClient.request({
      method: "DELETE",
      url: Route(`/api/v1/equipments/${equipmentId}/photos/${name}`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateEquipmentInputs(equipmentId: number, values: UpdateEquipmentInputsRequestDTO): Promise<void> {
    var patchOperations: PatchOperationDTO[] = [];

    if (values.purchaseDate !== undefined) patchOperations.push({ op: "edit", field: "purchaseDate", value: values.purchaseDate });
    if (values.brand !== undefined) patchOperations.push({ op: "edit", field: "brand", value: values.brand });
    if (values.model !== undefined) patchOperations.push({ op: "edit", field: "model", value: values.model });
    if (values.reference !== undefined) patchOperations.push({ op: "edit", field: "reference", value: values.reference });
    if (values.description !== undefined) patchOperations.push({ op: "edit", field: "description", value: values.description });
    if (values.serialNumber !== undefined) patchOperations.push({ op: "edit", field: "serialNumber", value: values.serialNumber });
    if (values.equipmentTypeId !== undefined) patchOperations.push({ op: "edit", field: "typeId", value: values.equipmentTypeId });
    if (values.equipmentStatusId !== undefined) patchOperations.push({ op: "edit", field: "statusId", value: values.equipmentStatusId });
    var allOps: PatchDTO = { ops: patchOperations };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/equipments/${equipmentId}`),
      data: allOps,
    })
      .then((response) => {
        return response.request.response;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getAllEquipmentStatus(): Promise<IEquipmentType[]> {
    return HttpClient.sessionRequest<EquipmentStatusesGetResponseDTO>({
      method: "GET",
      url: Route("/api/v1/equipments/statuses"),
    })
      .then((response) => {
        return response.data.equipmentStatuses.map(
          (status): IEquipmentType => ({
            id: status.id,
            code: status.code,
            description: status.description,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getAllEquipmentTypes(): Promise<IEquipmentType[]> {
    return HttpClient.sessionRequest<EquipmentTypesGetResponseDTO>({
      method: "GET",
      url: Route("/api/v1/equipments/types"),
    })
      .then((response) => {
        return response.data.equipmentTypes.map(
          (type): IEquipmentType => ({
            id: type.id,
            code: type.code,
            description: type.description,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }
}
