import "./Spacer.css";
import React, { useMemo } from "react";

interface ISpacerProps {
  classNames?: string;
  mode: "vertical" | "horizontal";
}

export function Spacer(props: ISpacerProps) {
  const css = useMemo(() => {
    let cssNames = "spacer";
    if (props.mode) {
      cssNames = cssNames + " " + props.mode;
    }
    if (props.classNames) {
      cssNames = cssNames + " " + props.classNames;
    }
    return cssNames;
  }, [props.classNames, props.mode]);

  return <div className={css}></div>;
}
