import dayjs from "dayjs";

export interface TimeSpanHelperObj {
  phrase: string;
  totalHours: number;
}

export class TimeSpanHelper {
  static getTotalHours(initialTime: string, finalTime: string) {
    var init = dayjs(`2022-01-01 ${initialTime}`); //Needs to be improved
    var end = dayjs(`2022-01-01 ${finalTime}`);
    var totalMinutes = end.diff(init, "minutes");
    return totalMinutes / 60;
  }

  static getMinutes(totalHours: number) {
    var minutes = totalHours % 1;
    return minutes * 60;
  }

  static getOneInterval(initialTime: string, finalTime: string) {
    var totalHours = this.getTotalHours(initialTime, finalTime);
    return this.objectBuilder(totalHours);
  }

  static getTwoIntervals(initialMornTime: string, finalMornTime: string, initialAfterTime: string, finalAfterTime: string) {
    var morningTime = this.getTotalHours(initialMornTime, finalMornTime);
    var afternoonTime = this.getTotalHours(initialAfterTime, finalAfterTime);
    var totalHours = morningTime + afternoonTime;
    return this.objectBuilder(totalHours);
  }

  static checkTimeSpanArray(timeSpanArray: string[]) {
    var emptyInputsCounter = 0;
    timeSpanArray.forEach((str) => {
      if (str === "") emptyInputsCounter++;
    });
    return emptyInputsCounter;
  }

  static checkOverlapOnInputs(initMornTime: string, finalMornTime: string, initAfterTime: string, finalAfterTime: string) {

    var initMornTimeNumber = Number(initMornTime.replace(/[:]/g, ""));
    var finalMornTimeNumber = Number(finalMornTime.replace(/[:]/g, ""));
    var initAfterTimeNumber = Number(initAfterTime.replace(/[:]/g, ""));
    var finalAfterTimeNumber = Number(finalAfterTime.replace(/[:]/g, ""));

    if (finalMornTimeNumber > initAfterTimeNumber && initAfterTimeNumber !== 0) return true;
    if (initMornTimeNumber > finalMornTimeNumber) return true;
    if (initAfterTimeNumber > finalAfterTimeNumber) return true;
    return false;
  }

  static getTimeSpan(initMornTime: string, finalMornTime: string, initAfterTime: string, finalAfterTime: string, isMorningValid: boolean, isAfternoonValid: boolean) {
    var totalOfEmptyInputs = this.checkTimeSpanArray([initMornTime, finalMornTime, initAfterTime, finalAfterTime]);
    if (totalOfEmptyInputs === 1 || totalOfEmptyInputs === 3) return this.objectBuilder(-1);
    if (totalOfEmptyInputs === 4) return this.objectBuilder(0);

    if (this.checkOverlapOnInputs(initMornTime, finalMornTime, initAfterTime, finalAfterTime)) return this.objectBuilder(-1);

    if (!isMorningValid && !isAfternoonValid) return this.objectBuilder(-1);
    if (isMorningValid && !isAfternoonValid) return this.getOneInterval(initMornTime, finalMornTime);
    if (!isMorningValid && isAfternoonValid) return this.getOneInterval(initAfterTime, finalAfterTime);
    if (isMorningValid && isAfternoonValid) return this.getTwoIntervals(initMornTime, finalMornTime, initAfterTime, finalAfterTime);
  }

  static phraseBuilder(totalHours: number) {
    var minutes = this.getMinutes(totalHours);

    if (totalHours === 0) return "0";
    if (totalHours < 0) return "invalid";
    if (totalHours < 1 && totalHours > 0) return minutes + "m";
    if (totalHours % 1 === 0) return totalHours + "h";

    return totalHours.toString().split(".")[0] + "h:" + minutes + "m";
  }

  static objectBuilder(totalHours: number) {
    var response: TimeSpanHelperObj = {
      phrase: this.phraseBuilder(totalHours),
      totalHours: totalHours,
    };
    return response;
  }
}
