import "./ManagerCell.css";

import { Persona, TooltipDelay, TooltipHost, getInitials } from "@fluentui/react";
import { usePanel, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { CalendarDayInfoPanel } from "../CalendarDayInfoPanel";
import { DailyRecordDTO } from "../../../services/HoursService";
import {FontIcon} from "@fluentui/react/lib/Icon"
import { ReactComponent as IconMore } from "../../../assets/more-icon.svg";
import dayjs from "dayjs";
/* import { mergeStyles } from '@fluentui/react/lib/Styling'; */
import { useMemo } from "react";

interface IManagerCellProps {
  day: Date;
  dailyRecord: DailyRecordDTO;
  isOutsideCurrentMonth: boolean;
  isWeekend: boolean;
  onClick: (dailyRecordId: number, day: Date) => void;
  isSelected: boolean;
  isTotalHoursView: boolean;
  opCompleted?: () => void;
  isHoliday: boolean;
  holidayName?: string;
}

export function ManagerCell(props: IManagerCellProps) {
  const windowResize = useWindowResize();
  const openPanel = usePanel();

  const todayDate = dayjs(new Date()).format("YYYYMMDD");

  const cellStatus = useMemo(() => {
    switch (props.dailyRecord?.status) {
      case "NOT_OK":
        return " declined";
      case "OK":
        return " approved";
      default:
        return "";
    }
  }, [props.dailyRecord?.status]);

  return (
    <>
      {windowResize > 768 ? (
        <div
          className={
            "item manager" +
            (dayjs(props.day).format("YYYYMMDD") === todayDate ? " border" : "") +
            cellStatus +
            (props.isWeekend ? " weekend" : "") +
            (props.isHoliday ? " holiday" : "") +
            (props.isSelected ? " selected" : "") +
            (cellStatus === " approved" ? " disable" : "")
          }
          onClick={() => props.onClick(props.dailyRecord?.dailyRecordId!, props.day)}
        >
          <div className="item-info">
            {!props.isTotalHoursView ? (
              <div className="projects">
                {props.dailyRecord?.timeRecord.map((project, idx) => {
                  if (idx < 2) {
                    return (
                      <div key={idx} className="project-info">
                        <div className="project-hour">{project.activityHours}</div>
                        <div className="project">
                          <div className="project-name" title={project.projectName}>
                            {project.projectName}
                          </div>
                          {windowResize > 1200 ? (
                            <div className="project-activity" title={project.activityName}>
                              {project.activityName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            ) : (
              <div className="projects total-hours">
                <div className="hours">{props.dailyRecord?.timeRecord.map((project) => project.activityHours).reduce((prev, curr) => prev + curr, 0)}</div>
              </div>
            )}
            <TooltipHost content={props.holidayName} delay={TooltipDelay.medium}>
              <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
            </TooltipHost>
          </div>
          <div className="item-action-bar">
            <TooltipHost content={"See details"} delay={TooltipDelay.medium}>
              <button type="button" className="more-info-btn">
                <IconMore
                  onClick={(e) => {
                    e.stopPropagation();
                    openPanel(
                      <CalendarDayInfoPanel
                        dailyRecord={props.dailyRecord}
                        day={props.day}
                        opCompleted={() => props.opCompleted?.()}
                        isManagerCell={true}
                        holidayName={props.holidayName}
                      />
                    );
                  }}
                />
              </button>
            </TooltipHost>


            {props.dailyRecord?.timeRecord?.filter(p => p.projectCode === "N4IT.2022.Ausencias").length > 0 ? <FontIcon iconName={"AirTickets"} className="more-info-btn"  /> : null}


            {props.dailyRecord?.approvedBy && props.dailyRecord.status === "OK" ? (
              <>
                <TooltipHost content={"Approved by: " + props.dailyRecord.approvedBy.displayName} delay={TooltipDelay.zero}>
                  <Persona
                    imageInitials={getInitials(props.dailyRecord.approvedBy.displayName, false, true)}
                    hidePersonaDetails={true}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </TooltipHost>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className={
            "item manager" +
            (dayjs(props.day).format("YYYYMMDD") === todayDate ? " border" : "") +
            cellStatus +
            (props.isWeekend ? " weekend" : "") +
            (props.isHoliday ? " holiday" : "")
          }
          onClick={() => {
            openPanel(
              <CalendarDayInfoPanel
                day={props.day}
                dailyRecord={props.dailyRecord}
                isActionBtns={true}
                opCompleted={() => props.opCompleted?.()}
                holidayName={props.holidayName}
              />
            );
          }}
        >
          <div className="item-info">
            <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
          </div>
        </div>
      )}
    </>
  );
}
