import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";

import { IdentityService } from "../identity/IdentityService";
import { InternationalizationProvider } from "../i18n/InternationalizationProvider";

let source: CancelTokenSource;

const getLang = () => {
  return InternationalizationProvider.getCurrentLocale();
};

export class HttpClient {
  static sessionRequest<T = any>(config: AxiosRequestConfig, cancellation?: boolean): Promise<AxiosResponse<T>> {
    if (cancellation) {
      if (source) source.cancel();
      source = axios.CancelToken.source();
      config.cancelToken = source.token;
    }

    return IdentityService.Global.getAccessToken().then((accessToken) => {
      var requestOptions = { ...config };

      if (!requestOptions.headers) {
        requestOptions.headers = {};
      }

      if (accessToken) {
        requestOptions.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      requestOptions.headers["n4u-lang"] = getLang();
      return axios.request<T>({ ...requestOptions });
    });
  }

  static request<T = any>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    var requestOptions = { ...config };

    if (!requestOptions.headers) {
      requestOptions.headers = {};
    }

    requestOptions.headers["n4u-lang"] = getLang();

    return axios.request<T>({ ...requestOptions });
  }
}
