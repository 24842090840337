import "./EditApprovalGroupInfoPopup.css";

import { MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ApprovalGroupsService } from "../../../../services/ApprovalGroupsService";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup } from "../../../../infrastructure/ui/UIServices";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { DomainExceptionResponse } from "../../../../models/errors/DomainExceptions";
import { useNavigate } from "react-router-dom";

var approvalGroupsService = new ApprovalGroupsService();

export interface EditApprovalGroupInfoProps {
  groupId: number;
  name: string;
  code: string;
  onOperationCompleted: () => void;
}

export function EditApprovalGroupInfoPopup(props: EditApprovalGroupInfoProps) {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [groupName, setGroupName] = useState<string>(props.name);

  const [errorMessage, setMessageError] = useState<string>("");

  const [showPrimaryMessageBar, setShowMessageBar] = useState<boolean>(false);
  const [isEditGroupDisabled, setIsEditGroupDisabled] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [messageBarErrorContent, setMessageBarErrorContent] = useState<string>("");

  useEffect(() => {}, []);

  useEffect(() => {
    if (groupName) setIsEditGroupDisabled(false);
    else setIsEditGroupDisabled(true);
  }, [groupName]);

  const onClickEditGroup = useCallback(() => {
    setShowValidationError(false);
    if (props.groupId) {
      openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
      approvalGroupsService
        .updateGroupDetails(props.groupId, groupName)
        .then((_) => {
          props.onOperationCompleted();
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="success">
              <p>{"Approval group details edited with success!"}</p>
            </FeedbackPopup>
          );
        })
        .catch((error) => {
          try {
            var parsedResponse: DomainExceptionResponse = JSON.parse(error.response?.data);
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
            closeOverlaySpinner();
          } catch {
            if (error.code === "ERR_BAD_REQUEST") {
              if (error.response.data) {
                setShowValidationError(true);
                closeOverlaySpinner();
                setMessageBarErrorContent(error.response.data);
              } else {
                navigate("/error");
              }
            } else {
              closeOverlaySpinner();
              openPopup(
                <FeedbackPopup type="error">
                  <p>{error.response?.data}</p>
                </FeedbackPopup>
              );
            }
          }
        });
    }
  }, [setShowValidationError, groupName, setGroupName, props.onOperationCompleted, closeOverlaySpinner, openOverlaySpinner, openPopup]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setShowValidationError(false);
        }}
        dismissButtonAriaLabel={translate("ACTIONS.Close")}
      >
        <>{messageBarErrorContent}</>
      </MessageBar>
    );
  }, [messageBarErrorContent, setShowValidationError, showValidationError]);

  return (
    <div className="edit-group-popup">
      <div className="group">
        {isLoading ? (
          <Spinner size={SpinnerSize.large} label={translate("Loading.PleaseWait")} />
        ) : (
          <div>
            <CardHeader headerTitle={"Edit Approval Group"} icon={true} setDisplayState={(state: boolean) => closePopup()} />
            <div className="group-info">
              <TextField disabled={true} label={"Code"} defaultValue={props.code ? props.code : "-"} />
              <TextField
                onChange={(e) => {
                  setGroupName(e.currentTarget.value);
                }}
                label={"Name"}
                placeholder="Example: Group 01"
                value={groupName}
                validateOnLoad={false}
                required={true}
              />
            </div>
            {showValidationError ? errorMessageBar() : null}
            {showPrimaryMessageBar ? errorMessageBar() : null}
            <div className="act-btns">
              <TertiaryButton text={"Close"} onClick={closePopup} />
              <PrimaryButton text={"Edit"} isDisabled={isEditGroupDisabled} onClick={onClickEditGroup}></PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
