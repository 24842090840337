import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../infrastructure/http/HttpClient";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};
export class TimeReportService {

  exportAllExcelUrl(userPrincipalName: string, currentYear: number, currentMonth: number): Promise<void> {
    let queryString = new URLSearchParams();
    queryString.append("userPrincipalName", userPrincipalName);
    queryString.append("year", "" + currentYear);
    queryString.append("month", "" + currentMonth);
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/v1/Hours/Report/SignUrl"),
      data: {
        url: Route("/api/v1/Hours/Report/SelectedMonthExcel?" + queryString.toString()),
      },
    })
      .then((res) => {
        let newUrl = res.data.url;
        window.open(newUrl, "_blank");
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }


  exportAllEmployeesExcelUrl(currentYear: number, currentMonth: number): Promise<void> {
    let queryString = new URLSearchParams();
    queryString.append("year", "" + currentYear);
    queryString.append("month", "" + currentMonth);
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/v1/Hours/Report/SignUrl"),
      data: {
        url: Route("/api/v1/Hours/Report/SelectedMonthAllByTemplate?" + queryString.toString()),
      },
    })
      .then((res) => {
        let newUrl = res.data.url;
        window.open(newUrl, "_blank");
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  exportAllPDF(userPrincipalName: string, currentYear: number, currentMonth: number): Promise<void> {
    let queryString = new URLSearchParams();
    queryString.append("userPrincipalName", userPrincipalName);
    queryString.append("year", "" + currentYear);
    queryString.append("month", "" + currentMonth);

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/v1/Hours/Report/SignUrl"),
      data: {
        url: Route("/api/v1/Hours/Report/SelectedMonthPDF?" + queryString.toString()),
      },
    })
      .then((res) => {
        let newUrl = res.data.url;
        window.open(newUrl, "_blank");
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  exportTimeSheetPDF(userPrincipalName: string, currentYear: number, currentMonth: number): Promise<void> {
    let queryString = new URLSearchParams();
    queryString.append("userPrincipalName", userPrincipalName);
    queryString.append("year", "" + currentYear);
    queryString.append("month", "" + currentMonth);
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/v1/Hours/Report/SignUrl"),
      data: {
        url: Route("/api/v1/Hours/Report/SelectedMonthTimeSheetPDF?" + queryString.toString()),
      },
    })
      .then((res) => {
        let newUrl = res.data.url;
        window.open(newUrl, "_blank");
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }
}
