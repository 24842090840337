import "./AllocationsPage.css";

import { AllocationsService, GetAllocationsDTO, GetAllocationsRequestDTO } from "../../../services/AllocationsService";
import {
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  IDropdownOption,
  IIconProps,
  IconButton,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  getInitials,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePanel, usePanel, usePopup } from "../../../infrastructure/ui/UIServices";
import { useNavigate, useParams } from "react-router-dom";

import { AllocationHistoryPanel } from "./panels/AllocationHistoryPanel";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { CreateAllocationPanel } from "./panels/CreateAllocationPanel";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import dayjs from "dayjs";

import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { useUpdatePageData } from "../../../infrastructure/navigation/hooks/useUpdatePageData";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { EquipmentsService } from "../../../services/EquipmentsService";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { EmptyResult } from "../../../components/images/EmptyResult";

const filtersIcon: IIconProps = { iconName: "Filter" };
const iconProps = { iconName: "Search" };
const addIcon: IIconProps = { iconName: "Add" };
const historyIcon: IIconProps = { iconName: "History" };

interface IOrderInfo {
  columnName: string;
  direction: "ASC" | "DESC";
}

export interface RequestFilterDTO {
  statuses: number[];
  employeePrincipalName: string;
}

const allocationsService = new AllocationsService();
const equipmentsService = new EquipmentsService();

export function AllocationsPage() {
  const openPanel = usePanel();
  const closePanel = useClosePanel();
  const openPopup = usePopup();
  const params = useParams();
  const updatePageTitle = usePageTitle();

  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_ALLOCATION_EQUIPMENT]);
  const navigate = useNavigate();
  if (!isPageActive) {
    navigate("/error");
  }

  const [isAllocationListLoading, setIsAllocationListLoading] = useState(true);
  const [allocationsList, setAllocationsList] = useState<GetAllocationsDTO[]>([]);

  const [isUpdateStatusRecordSpinnerDisabled, setIsUpdateStatusRecordSpinnerDisabled] = useState<boolean>(false);
  const [isCreateButtonDisabled, setIsCreatedButtonDisabled] = useState<boolean>(true);

  const addToRouteData = useUpdatePageData();
  useEffect(() => {
    addToRouteData(["##DEVICE##", "..."]);
    updatePageTitle(translate("AREATITLES.Allocations"));
    getAllocations();
    if (params.id) {
      equipmentsService
        .getEquipmentByID(Number(params.id))
        .then((res) => {
          addToRouteData(["##DEVICE##", res.reference]);
          if (res.status.code == "DEACTIVATED") {
            setIsCreatedButtonDisabled(true);
          } else {
            setIsCreatedButtonDisabled(false);
          }
        })
        .catch((error) => {
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
  }, []);

  const getAllocations = useCallback(() => {
    if (params.id) {
      setIsAllocationListLoading(true);
      allocationsService
        .getAllocations(params.id)
        .then((res) => {
          setIsAllocationListLoading(false);
          setAllocationsList(res);
          closePanel();
        })
        .catch((error) => {
          setIsAllocationListLoading(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
  }, [params, openPopup, closePanel, setAllocationsList, allocationsList, setIsAllocationListLoading, isAllocationListLoading]);

  const updateStateValue = useCallback(
    (option: IDropdownOption<any> | undefined, key: number) => {
      if (option?.key && key) {
        setIsUpdateStatusRecordSpinnerDisabled(true);
        allocationsService
          .createAllocationStatusRecord(key, option?.key as number)
          .then(() => {
            getAllocations();
            setIsUpdateStatusRecordSpinnerDisabled(false);
            openPopup(
              <FeedbackPopup type="success">
                <p>
                  {translate("ALLOCATIONS.AllocationChangedTo")} {option.text}.
                </p>
              </FeedbackPopup>
            );
          })
          .catch((error) => {
            setIsAllocationListLoading(false);
            setIsUpdateStatusRecordSpinnerDisabled(false);
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          });
      }
    },
    [openPopup, getAllocations, setIsAllocationListLoading, isAllocationListLoading, setIsUpdateStatusRecordSpinnerDisabled, isUpdateStatusRecordSpinnerDisabled]
  );

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "column1",
        name: translate("COMMON.AllocatedTo"),
        fieldName: "employeeTarget",
        minWidth: 185,
        maxWidth: 450,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        onRender: (item) => (
          <Persona
            text={item.employeeTarget.displayName}
            imageInitials={getInitials(item.employeeTarget.displayName, false, true)}
            imageUrl={item.employeeTarget.url}
            size={PersonaSize.size32}
          />
        ),
        data: "string",
      },
      {
        key: "column2",
        name: translate("ALLOCATIONS.COMMON.AllocatedBy"),
        fieldName: "employeeApprover",
        minWidth: 185,
        maxWidth: 450,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        onRender: (item) => (
          <div className="allocation-employee">
            <Persona
              text={item.employeeApprover.displayName}
              imageInitials={getInitials(item.employeeApprover.displayName, false, true)}
              imageUrl={item.employeeApprover.url}
              size={PersonaSize.size32}
            />
          </div>
        ),
        data: "string",
      },
      {
        key: "column3",
        name: translate("COMMON.Date"),
        fieldName: "date",
        minWidth: 185,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item) => <div>{item.date ? <span>{dayjs(item.date).format("DD/MM/YYYY HH:mm")}</span> : ""}</div>,
      },
      {
        key: "column4",
        name: translate("COMMON.Status"),
        fieldName: "state",
        minWidth: 100,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        onRender: (allocations: GetAllocationsDTO) => {
          var options: IDropdownOption[] = [];
          if (allocations.nextStates !== null) {
            options = allocations.nextStates.map((s) => {
              var option: IDropdownOption = {
                key: s.id,
                text: s.name,
              };
              return option;
            });
          }

          return (
            <Dropdown
              placeholder={allocations.state.name}
              selectedKey={allocations.state.id}
              options={options}
              className={allocations.state.name.toLowerCase()}
              onChange={(ev, option) => {
                openPopup(
                  <FeedbackPopup
                    type="warning"
                    title={translate("ALLOCATIONS.WARNINGS.WantToChangeAllocationStatus")}
                    primaryActionLabel={translate("ACTIONS.Confirm")}
                    primaryActionFunc={() => updateStateValue(option, allocations.allocationId)}
                    isStopSpinner={isUpdateStatusRecordSpinnerDisabled}
                  >
                    <p>{translate("ALLOCATIONS.WARNINGS.AreYouSureToChangeAllocationStatus")}</p>
                    <p>{translate("COMMON.PermanentAction")}</p>
                  </FeedbackPopup>
                );
              }}
            />
          );
        },
        data: "string",
      },
      {
        className: "overflow-menu",
        key: "column5",
        name: "Allocations History",
        isRowHeader: true,
        isResizable: true,
        isIconOnly: true,
        fieldName: "history",
        minWidth: 30,
        maxWidth: 30,
        onRender: (item: GetAllocationsDTO) => (
          <IconButton className="history-btn" iconProps={historyIcon} onClick={() => openPanel(<AllocationHistoryPanel allocationHistory={item.history} />)} />
        ),
        data: "string",
      },
    ],
    [openPanel, openPopup, updateStateValue, allocationsList, setIsCreatedButtonDisabled, isCreateButtonDisabled]
  );

  return (
    <>
      <CardLayout classNames="list-container">
        <div className="command-bar">
          <div className="filters"></div>
          <div className="action-btns">
            <PrimaryButton
              additionalClass={"margin-left remove-text"}
              text={translate("ALLOCATIONS.CreateAllocation")}
              icon={addIcon}
              isDisabled={isCreateButtonDisabled}
              onClick={() =>
                openPanel(
                  <CreateAllocationPanel
                    onChange={() => {
                      getAllocations();
                    }}
                    equipmentId={Number(params.id) ?? (params.id as unknown as number)}
                  />
                )
              }
            />
          </div>
        </div>
        {allocationsList?.length === 0 && !isAllocationListLoading ? (
          <EmptyResult />
        ) : (
          <ShimmeredDetailsList
            className="allocations-list"
            items={allocationsList}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            enableShimmer={isAllocationListLoading}
          />
        )}
      </CardLayout>
    </>
  );
}
