import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { GraphUser } from "./EquipmentsService";
import { HttpClient } from "../infrastructure/http/HttpClient";

export interface PutDailyRecordResponseDTO {
  DailyRecordId: number;
  TimeRecordIds: number[];
}

export interface DateDTO {
  day: number;
  month: number;
  year: number;
}

export interface CreateDailyRecordRequestDTO {
  day: string;
  initialMorningTime?: string;
  finalMorningTime?: string;
  initialAfternoonTime?: string;
  finalAfternoonTime?: string;
  timeRecords?: ITimeRecord[];
}

export interface ITimeRecord {
  projectId: number | string;
  activityId: number | string;
  hours: /* number | string; */ string;
  id?: number | string;
  notes?: string;
}

export interface IDailyRecordResponseDTO {
  day: Date;
  data: DailyRecordDTO;
}

export interface DailyRecordDTO {
  dailyRecordId: number;
  morningStart: string;
  morningEnd: string;
  afternoonStart: string;
  afternoonEnd: string;
  employee: GraphUser;
  approvedBy?: GraphUser;
  approvedDate?: string;
  status: string;
  timeRecord: ITimeRecordResponseDTO[];
  history: string;
  createdDate: string;
}

export interface ITimeRecordResponseDTO {
  id: number;
  dailyRecordId: number;
  projectName: string;
  projectCode: string;
  activityName: string;
  activityHours: number;
  activityCode: string;
  notes?: string;
}

export interface EditDailyRecordRequestDTO {
  id: number;
  day: Date;
  initialMorningTime: string;
  finalMorningTime: string;
  initialAfternoonTime: string;
  finalAfternoonTime: string;
  timeRecordsToDelete: number[];
  timeRecords: ITimeRecord[];
}

export interface CloneDailyRecordsRequestDTO {
  daysToClone: string[];
  reWrite: boolean;
}

export interface DeclineDailyRecordsRequestDTO {
  dailyRecordsIds: number[];
  reason: string;
}

export interface ApproveDailyRecordsRequestDTO {
  dailyRecordsIds: number[];
}

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class HoursService {
  getDailyRecordById(dailyRecordId: number): Promise<DailyRecordDTO> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/me/Hours/${dailyRecordId}`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createDailyRecord(equipmentInputs: CreateDailyRecordRequestDTO) {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route("/api/v1/me/Hours"),
      data: equipmentInputs,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  editDailyRecord(dailyRecordId: number, request: EditDailyRecordRequestDTO) /* : Promise<boolean> */ {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/me/Hours/${dailyRecordId}`),
      data: request,
    })
      .then((res) => {})
      .catch((error) => {
        throw error;
      });
  }

  cloneDailyRecords(dailyRecordId: number, request: CloneDailyRecordsRequestDTO) {
    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/me/Hours/${dailyRecordId}/clone`),
      data: request,
    })
      .then((res) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  deleteDailyRecord(dailyRecordId: number) {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/me/Hours/${dailyRecordId}`),
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getMonthlyRecords(currentYear: number, currentMonth: number): Promise<IDailyRecordResponseDTO[]> {
    let queryString = {
      year: currentYear,
      month: currentMonth,
    };

    return HttpClient.sessionRequest(
      {
        method: "GET",
        url: Route(`/api/v1/me/Hours`),
        params: queryString,
      },
      true
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error?.response?.data;
      });
  }

  getMonthlyRecordsByManager(userPrincipalName: string, currentYear: number, currentMonth: number): Promise<IDailyRecordResponseDTO[]> {
    let queryString = {
      userPrincipalName: userPrincipalName,
      year: currentYear,
      month: currentMonth,
    };
    return HttpClient.sessionRequest(
      {
        method: "GET",
        url: Route(`/api/v1/Hours`),
        params: queryString,
      },
      true
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  approveDailyRecords(request: ApproveDailyRecordsRequestDTO) {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Hours/approveRecords`),
      data: request,
    })
      .then(() => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  declineDailyRecords(request: DeclineDailyRecordsRequestDTO) {
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Hours/declineRecords`),
      data: request,
    })
      .then(() => {})
      .catch((error) => {
        throw error;
      });
  }

  resetCache() {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Hours/ResetCache`),
    });
  }
}
