import { MenuItem } from './MenuItem';
import { ModuleDefinition } from './ModuleDefinition';

export class ModuleHelper {

    static generateFromModuleRoute(moduleDefinition: ModuleDefinition, idx: number) : MenuItem {
        return ({
            to: moduleDefinition.routes[idx].isExternalLink
                ? "/redirect?url=" +  moduleDefinition.routes[idx].pathPattern
                : moduleDefinition.basePath + moduleDefinition.routes[idx].pathPattern,
            icon: moduleDefinition.routes[idx].menuIcon,
            name: moduleDefinition.routes[idx].name(),
            features: moduleDefinition.routes[idx].features,
            newTab: moduleDefinition.routes[idx].newTab
        })
    }
}
