import "./CloneHoursPopup.css";

import { Calendar, ICalendarCell } from "../../../../components/calendar/Calendar";
import { CloneDailyRecordsRequestDTO, DailyRecordDTO, HoursService, IDailyRecordResponseDTO } from "../../../../services/HoursService";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { IDailyRecordCloneData, MiniCell } from "../../../../components/calendar/cells/MiniCell";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup } from "../../../../infrastructure/ui/UIServices";

import { CardHeader } from "../../../../components/cards/CardHeader";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import dayjs from "dayjs";
import { InternationalizationService, translate } from "../../../../infrastructure/i18n/InternationalizationService";

//import { AxiosError } from "axios";

interface ICloneHoursProps {
  day: Date;
  isOutsideCurrentMonth: boolean;
  dailyRecord?: DailyRecordDTO;
  cloneCompleted: () => void;
}
function removeNullRecords(dailyRecords: IDailyRecordResponseDTO[]) {
  return dailyRecords.filter((obj) => {
    return obj.day !== null;
  });
}

function convertToCalendarCell(dto: IDailyRecordResponseDTO) {
  return {
    day: new Date(dto.day),
    data: {
      dailyRecordId: dto.data.dailyRecordId,
      dailyRecordStatus: dto.data.status,
    },
  };
}

var hoursService = new HoursService();

/*      useEffect(() => {
    setCurrentMonth(props.day.getMonth());
    setCurrentYear(props.day.getFullYear());
  }, [props.day]);  */

export function CloneHoursPopup(props: ICloneHoursProps) {
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [submitButtonSpinner, setSubmitButtonSpinner] = useState<boolean>(false);

  const [miniCalendarSpinner, setMiniCalendarSpinner] = useState<boolean>(false);
  const [isStopSpinner, setIsStopSpinner] = useState<boolean>(false);
  const [cloneCalendarData, setCloneCalendarData] = useState<ICalendarCell<IDailyRecordCloneData>[]>([]);
  const [selectedDays, setSelectedDays] = useState<{ idx: number; day: Date }[]>([]);
  let localeCode = InternationalizationService.getLocale().toString();
  let extendedDateFormat = "";

  if (localeCode === "en-EN") {
    extendedDateFormat = dayjs(props.day).format("MMM Do");
  } else {
    extendedDateFormat = dayjs(props.day).locale(InternationalizationService.getLocale()).format("D MMM");
  }

  const currentDate = new Date();

  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  useEffect(() => {

    if (currentYear > 0 && currentMonth > -1) {
      setMiniCalendarSpinner(true);
      hoursService
        .getMonthlyRecords(currentYear, currentMonth + 1)
        .then((res) => {
          if (res.length > 0) {
            var filteredRecords = removeNullRecords(res);
            setCloneCalendarData(filteredRecords.map((dto: IDailyRecordResponseDTO) => convertToCalendarCell(dto)));
          }
          setMiniCalendarSpinner(false);
        })
        .catch((error) => {
          if (error !== undefined) {
            setMiniCalendarSpinner(false);
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          }
        });
    }
  }, [currentMonth, currentYear]);

  const handleCurrentMonthChanged = useCallback(
    (month: number, year: number) => {
      setCurrentMonth(month);
      setCurrentYear(year);
    },
    [setCurrentMonth, setCurrentYear]
  );

  const submitSelectedDays = useCallback(() => {
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
    var onlyDates = selectedDays.map((obj) => {
      var op = dayjs(obj.day).format("YYYY/MM/DD");
      return op;
    });
    var request: CloneDailyRecordsRequestDTO = {
      daysToClone: onlyDates,
      reWrite: false,
    };
    hoursService
      .cloneDailyRecords(props.dailyRecord?.dailyRecordId!, request)
      .then(() => {
        closeOverlaySpinner();
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("HOURS.YouHaveClonedADailyRecord")}</p>
          </FeedbackPopup>
        );
        props.cloneCompleted();
      })
      .catch((err) => {
        closeOverlaySpinner();
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(err);
          if (parsedResponse.AlertType === ErrorTypeEnum.OVERRIDE) {
            openPopup(
              <FeedbackPopup
                type="warning"
                title={translate("HOURS.ERRORS.TargetDaysAlreadyContainsInformation")}
                primaryActionLabel={translate("ACTIONS.Override")}
                primaryActionFunc={() => {
                  overrideDailyRecords(request);
                }}
                isStopSpinner={isStopSpinner}
              >
                <p>
                  {translate("HOURS.ERRORS.XAlreadyContainsInformation", parsedResponse.Response)}

                  <br />
                  {translate("HOURS.ERRORS.DoYouWantToOverride")}
                </p>
              </FeedbackPopup>
            );
          }
          if (parsedResponse.AlertType === ErrorTypeEnum.ERROR) {
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
          }
        } catch {
          openPopup(
            <FeedbackPopup type="error">
              <p>{err}</p>
            </FeedbackPopup>
          );
        }
      });
  }, [props.cloneCompleted, openPopup, selectedDays, isStopSpinner, setIsStopSpinner, closeOverlaySpinner, openOverlaySpinner]);

  const overrideDailyRecords = useCallback(
    (request: CloneDailyRecordsRequestDTO) => {
      request.reWrite = true;
      hoursService
        .cloneDailyRecords(props.dailyRecord?.dailyRecordId!, request)
        .then(() => {
          props.cloneCompleted?.();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("HOURS.YouHaveClonedADailyRecord")}</p>
            </FeedbackPopup>
          );
          setIsStopSpinner(true);
        })
        .catch((err) => {
          try {
            var parsedResponseAfterReWrite: DomainExceptionResponse = JSON.parse(err);
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponseAfterReWrite.Response}</p>
              </FeedbackPopup>
            );
            setIsStopSpinner(true);
          } catch {
            openPopup(
              <FeedbackPopup type="error">
                <p>{err}</p>
              </FeedbackPopup>
            );
          }
        });
    },
    [props.cloneCompleted, openPopup, setIsStopSpinner, isStopSpinner]
  );

  return (
    <div className="clone-hours-popup">
      <CardHeader headerTitle={translate("HOURS.CloneFromX", "" + extendedDateFormat)} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <div className="text">{translate("HOURS.SelectTheTargetDaysFromTheCalendar")}</div>
        <Calendar
          cellDataSource={cloneCalendarData}
          showHeader={true}
          onCurrentMonthChanged={handleCurrentMonthChanged}
          renderCell={(cellData, idx) => (
            <MiniCell
              key={idx}
              isOutsideCurrentMonth={cellData.isOutsideActiveMonth}
              dailyRecordId={cellData.data?.dailyRecordId}
              dailyRecordStatus={cellData.data?.dailyRecordStatus}
              day={cellData.day}
              dayToCloneFrom={props.day}
              onClick={(day, idx) => {
                let clone = [...selectedDays];
                let selectedDay = clone.find((info) => dayjs(info.day).format("YYYYMMDD") === dayjs(day).format("YYYYMMDD"));
                if (selectedDay) {
                  clone = clone.filter((info) => dayjs(info.day).format("YYYYMMDD") !== dayjs(day).format("YYYYMMDD"));
                } else {
                  clone.push({ idx, day });
                }
                setSelectedDays(clone);
              }}
              isSelected={!!selectedDays.find((info) => dayjs(info.day).format("YYYYMMDD") === dayjs(cellData.day).format("YYYYMMDD"))}
              idx={idx}
              isWeekend={cellData.isWeekend}
              isHoliday={cellData.isHoliday}
            />
          )}
          showSpinner={miniCalendarSpinner}
          isMini={true}
        />
        <div className="action-btns">
          <TertiaryButton isDisabled={submitButtonSpinner} text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
          <PrimaryButton isDisabled={selectedDays.length === 0 || submitButtonSpinner} text={translate("ACTIONS.Clone")} onClick={() => submitSelectedDays()}>
            {submitButtonSpinner ? <Spinner size={SpinnerSize.xSmall} /> : null}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
