import "./EmployeeCell.css";

import { TooltipDelay, TooltipHost } from "@fluentui/react";
import { usePanel, usePopup, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { CalendarDayInfoPanel } from "../CalendarDayInfoPanel";
import { CloneHoursPopup } from "../../../areas/employees/hours/popup/CloneHoursPopup";
import { DailyRecordDTO } from "../../../services/HoursService";
import { DateTimeHelper } from "../../../helpers/DateTimeHelper";
import { EditHoursPopup } from "../../../areas/employees/hours/popup/EditHoursPopup";
import {FontIcon} from "@fluentui/react/lib/Icon"
import { ReactComponent as IconCopy } from "../../../assets/copy-icon.svg";
import { ReactComponent as IconMore } from "../../../assets/more-icon.svg";
import { translate } from '../../../infrastructure/i18n/InternationalizationService';
import { useMemo } from "react";

export interface IEmployeeCellProps {
  day: Date;
  dailyRecord: DailyRecordDTO;
  isOutsideCurrentMonth: boolean;
  reRender?: () => void;
  isWeekend: boolean;
  isHoliday: boolean;
  holidayName?: string;
}

export function EmployeeCell(props: IEmployeeCellProps) {
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const openPanel = usePanel();

  const cellStatus = useMemo(() => {
    switch (props.dailyRecord?.status) {
      case "NOT_OK":
        return " declined";
      case "OK":
        return " approved";
      default:
        return "";
    }
  }, [props.dailyRecord?.status]);

  return (
    <>
      {windowResize > 768 ? (
        <div
          className={
            "item employee" +
            (DateTimeHelper.isToday(props.day) ? " border" : "") +
            cellStatus +
            (props.isWeekend ? " weekend" : "") +
            (props.isHoliday ? " holiday" : "")
          }
          onClick={
            props.dailyRecord?.status !== "OK"
              ? () => {
                  openPopup(
                    <EditHoursPopup
                      editCompleted={() => {
                        props.reRender?.();
                      }}
                      day={props.day}
                      dailyRecordId={props.dailyRecord?.dailyRecordId}
                      holidayName={props.holidayName}
                    />
                  );
                }
              : () => {}
          }
        >
          <div className="item-info">
            <div className="projects">
              {props.dailyRecord?.timeRecord.map((project, idx) => {
                if (idx < 2) {
                  return (
                    <div className="project-info" key={idx}>
                      <div className="project-hour">{project.activityHours}</div>
                      <div className="project">
                        <div className="project-name">{project.projectName}</div>
                        {windowResize > 1200 ? <div className="project-activity">{project.activityName}</div> : null}
                      </div>
                    </div>
                  );
                } else return null;
              })}
            </div>
            <TooltipHost content={props.holidayName} delay={TooltipDelay.medium}>
              <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
            </TooltipHost>
          </div>
          <div className="item-action-bar">
            <TooltipHost content={translate("ACTIONS.SeeDetails")} delay={TooltipDelay.medium}>
              <button type="button" className="more-info-btn">
                <IconMore
                  onClick={(e) => {
                    e.stopPropagation();
                    openPanel(
                      <CalendarDayInfoPanel opCompleted={() => props.reRender?.()} day={props.day} dailyRecord={props.dailyRecord} holidayName={props.holidayName} />
                    );
                  }}
                />
              </button>
            </TooltipHost>

            {props.dailyRecord?.timeRecord?.filter(p => p.projectCode === "N4IT.2022.Ausencias" || p.projectCode === "N4IT.2022.Ferias").length > 0 ? <FontIcon iconName={"AirTickets"} className="more-info-btn"  /> : null}

            <TooltipHost content={translate("ACTIONS.Clone")} delay={TooltipDelay.medium}>
              <button type="button" className="clone-btn">
                <IconCopy
                  onClick={(e) => {
                    e.stopPropagation();
                    if (props.dailyRecord) {

                      openPopup(
                        <CloneHoursPopup
                          cloneCompleted={() => {
                            props.reRender?.();
                          }}
                          isOutsideCurrentMonth={props.isOutsideCurrentMonth}
                          day={props.day}
                          dailyRecord={props.dailyRecord}
                        />
                      );
                    }
                  }}
                />
              </button>
            </TooltipHost>
          </div>
        </div>
      ) : (
        <div
          className={
            "item employee" +
            (DateTimeHelper.isToday(props.day) ? " border" : "") +
            cellStatus +
            (props.isWeekend ? " weekend" : "") +
            (props.isHoliday ? " holiday" : "")
          }
          onClick={
            props.dailyRecord?.status !== "OK"
              ? () => {
                  openPopup(
                    <EditHoursPopup
                      editCompleted={() => {
                        props.reRender?.();
                      }}
                      day={props.day}
                      dailyRecordId={props.dailyRecord?.dailyRecordId}
                    />
                  );
                }
              : () => {
                  openPanel(
                    <CalendarDayInfoPanel opCompleted={() => props.reRender?.()} day={props.day} dailyRecord={props.dailyRecord} holidayName={props.holidayName} />
                  );
                }
          }
        >
          <div className="item-info">
            <div className={"day" + (props.isHoliday ? " holiday" : "")}>{DateTimeHelper.getDayNumber(props.day)}</div>
          </div>
        </div>
      )}
    </>
  );
}
