import { IdentityService } from "../IdentityService";
import { SplashScreen } from '../../../areas/common/splash-screen/SplashScreen';
import { useEffect } from "react";

interface IIdentityActionProps {

    exec: (svc : IdentityService) => void;
}

export function IdentityAction(props: IIdentityActionProps) {

    useEffect(() => {
        props.exec(IdentityService.Global);
    }, []);


    return (
        <SplashScreen/>
    );

}
