import "./EmployeeInfoPage.css";

import React, { useState } from "react";

import { BigCVButton } from "./BigCVButton";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { PersonalInfo } from "./PersonalInfo";
import { Spacer } from "../../../components/layouts/spacer/Spacer";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { useUpdatePageData } from "../../../infrastructure/navigation/hooks/useUpdatePageData";

const bigCVButton = [{ label: "Work Experience" }, { label: "Education and Training" }, { label: "Technical Skills" }, { label: "Languages" }];
interface InfoFromEmployeesListPage {
  displayName: string;
  email: string;
}

export function EmployeeInfoPage() {
  const addToRouteData = useUpdatePageData();
  const updatePageTitle = usePageTitle();

  const location = useLocation();
  const state = location.state as InfoFromEmployeesListPage;

  useEffect(() => {
    updatePageTitle(translate("PROFILE.MyProfile"));
    if (state) {
      addToRouteData(["##EMPLOYEE##", state.displayName]);
    }
  }, []);

  return (
    <>
      <PersonalInfo  email={state ? state.email : null}  />
      {/* <Spacer mode="vertical" />
      <div className="cv-info-wrapper">
        <div className="cv-btns">
          {bigCVButton.map((btn, i) => {
            return (
              <>
                <BigCVButton label={bigCVButton[i].label} />
                <Spacer mode="vertical" />
              </>
            );
          })}
        </div>
        <Spacer mode="horizontal" />
        <CardLayout classNames="cv-info">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            sagittis odio ac congue consequat. Proin tristique metus vitae urna
            tincidunt porttitor. Nunc egestas metus nec nunc interdum, vitae
            vulputate velit mattis. Quisque odio quam, vehicula pulvinar nisl
            ac, aliquet lobortis sem. Proin efficitur sagittis arcu ac tempor.
            In aliquam lacus vel purus semper, sed pharetra dolor sodales.{" "}
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            sagittis odio ac congue consequat. Proin tristique metus vitae urna
            tincidunt porttitor. Nunc egestas metus nec nunc interdum, vitae
            vulputate velit mattis. Quisque odio quam, vehicula pulvinar nisl
            ac, aliquet lobortis sem. Proin efficitur sagittis arcu ac tempor.
            In aliquam lacus vel purus semper, sed pharetra dolor sodales.{" "}
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            sagittis odio ac congue consequat. Proin tristique metus vitae urna
            tincidunt porttitor. Nunc egestas metus nec nunc interdum, vitae
            vulputate velit mattis. Quisque odio quam, vehicula pulvinar nisl
            ac, aliquet lobortis sem. Proin efficitur sagittis arcu ac tempor.
            In aliquam lacus vel purus semper, sed pharetra dolor sodales.{" "}
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            sagittis odio ac congue consequat. Proin tristique metus vitae urna
            tincidunt porttitor. Nunc egestas metus nec nunc interdum, vitae
            vulputate velit mattis. Quisque odio quam, vehicula pulvinar nisl
            ac, aliquet lobortis sem. Proin efficitur sagittis arcu ac tempor.
            In aliquam lacus vel purus semper, sed pharetra dolor sodales.{" "}
          </p>




        </CardLayout>
      </div> */}
    </>
  );
}
