import "./SplashScreen.css";

import { Spinner, SpinnerSize } from "@fluentui/react";

// import {ReactComponent as Logo} from "../../../assets/retail-consult.svg"

import { ReactComponent as Logo } from "../../../assets/logo-stroke.svg";



export function SplashScreen() {
  return (
    <div className="n4u-splash">
      <div className="n4u-splash-logo">
        <Logo />
        <Spinner size={SpinnerSize.medium} className="n4u-splash-spinner" />
      </div>
    </div>
  );
}
