import "./DocsList.css";
import React from "react";
import { DetailsList, DetailsListLayoutMode, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { ReactComponent as IconNavDocs } from "../../../assets/docs-icon.svg";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { IIconProps } from "@fluentui/react";
import { useWindowResize } from "../../../infrastructure/ui/UIServices";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";

const upload: IIconProps = { iconName: "Upload" };

const columns: IColumn[] = [
  {
    key: "column1",
    name: "File Type",
    fieldName: "name",
    minWidth: 40,
    maxWidth: 60,
    isResizable: true,
    isIconOnly: true,
    isCollapsible: true,
    onRender: () => <IconNavDocs></IconNavDocs>,
  },
  {
    key: "column2",
    name: "Name",
    fieldName: "name",
    minWidth: 170,
    maxWidth: 400,
    isRowHeader: true,
    isResizable: true,
    isCollapsible: false,
    isSorted: true,
    data: "string",
  },
  {
    key: "column3",
    name: "Submission Date",
    fieldName: "date",
    minWidth: 130,
    maxWidth: 170,
    isRowHeader: true,
    isResizable: true,
    isCollapsible: false,
    isSorted: true,
    data: "string",
  },
  {
    key: "column4",
    name: "State",
    fieldName: "state",
    minWidth: 100,
    maxWidth: 170,
    isRowHeader: true,
    isResizable: true,
    isCollapsible: false,
    isSorted: true,
    data: "string",
  },
];

export function DocsList() {
  const windowSize = useWindowResize();

  let items = [];
  for (let i = 0; i < 5; i++) {
    items.push({
      key: i,
      name: "Document-" + i + ".docx",
      date: "00-00-0000",
      state: "On Hold",
      value: i,
    });
  }

  return (
    <CardLayout classNames="docslist-container">
      {showUploadButton(windowSize)}
      <DetailsList
        className="docs-list"
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
      />
    </CardLayout>
  );
}

function showUploadButton(windowSize: number) {
  if (windowSize <= 1024) {
    return (
      <div className="command-bar">
        <SecondaryButton additionalClass={"btn-margin-right"} text="Choose File" icon={upload} />
      </div>
    );
  }
}
