import "./NavBar.css";

import { evaluateRequestedFeatures, useFeatures } from "../../infrastructure/features/hooks/useFeatures";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import { MenuItem } from "../../infrastructure/modules/MenuItem";
import { NavIcon } from "./NavIcon";
import { NavLink } from "react-router-dom";

export interface INavBarProps {
  buttons: MenuItem[];
}

export function NavBar(props: INavBarProps) {
  const { features } = useFeatures();
  return (
    <div className="navbar">
      <div className="logo">
        <NavLink to={"/"}>
          <Logo/>
        </NavLink>
      </div>
      <ul>
        {props.buttons
          .filter((m) => (m.features ? evaluateRequestedFeatures(m.features, features) : true))
          .map((m) => (
            <NavIcon key={m.name} icon={m.icon} to={m.to} name={m.name} newTab={m.newTab}></NavIcon>
          ))}
      </ul>
    </div>
  );
}
