import "./TimePicker.css";

import { useEffect, useRef, useState } from "react";

import { TextField } from "@fluentui/react";

interface ITimePickerProps {
  labelText?: string;
  func: (value: string) => void;
  defaultHour: string;
  defaultMinutes: string;
}

const hoursPanel = ["--", "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
const minutesPanel = ["--", "00", "15", "30", "45"];

export function TimePicker(props: ITimePickerProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [hour, setHour] = useState<string>(props.defaultHour);
  const [minutes, setMinutes] = useState<string>(props.defaultMinutes);
  const [showPanel, setShowPanel] = useState<boolean>();
  const [selectedHourId, setSelectedHourId] = useState<number>();
  const [selectedMinutesId, setSelectedMinutesId] = useState<number>();

  useEffect(() => {
    if (hour === "--" && minutes === "--") {
      props.func("");
    } else {
      var res = hour.concat(":", minutes);
      if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(res)) {
        props.func(res);
      }
    }
  }, [hour, minutes]);

  useEffect(() => {
    setSelectedHourId(hoursPanel.indexOf(hour));
  }, [hour]);

  useEffect(() => {
    setSelectedMinutesId(hoursPanel.indexOf(minutes));
  }, [minutes]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showPanel && ref.current && !ref.current.contains(e.target)) {
        setShowPanel(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showPanel]);

  return (
    <div className="time-input">
      <TextField placeholder="--:--" label={props.labelText} value={hour + ":" + minutes} onFocus={() => setShowPanel(true)} />
      <div ref={ref} className={"time-panel" + (showPanel ? " displayPanel" : "")}>
        <div className="hours">
          <div className="header-label">hrs</div>
          {hoursPanel.map((hour, idx) => {
            return (
              <div
                className={selectedHourId === idx ? "selected" : ""}
                key={idx}
                onClick={(e) => {
                  setHour(hour);
                  if (hour === "--") {
                    setMinutes("--");
                  } else {
                    if (minutes === "--") {
                      setMinutes("00");
                    }
                  }
                }}
              >
                {hour}
              </div>
            );
          })}
        </div>
        <div className="minutes">
          <div className="header-label">min</div>
          {minutesPanel.map((minute, idx) => {
            return (
              <div
                className={selectedMinutesId === idx ? "selected" : ""}
                key={idx}
                onClick={() => {
                  setMinutes(minute);
                  if (minute === "--") {
                    setHour("--");
                  } else {
                    if (hour === "--") {
                      setHour("00");
                    }
                  }
                }}
              >
                {minute}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
