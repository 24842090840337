import "./EmptyCell.css";
import dayjs from "dayjs";
import { useWindowResize } from "../../../infrastructure/ui/UIServices";

interface IBasicCellProps {
  day: Date;
  isOutsideActiveMonth: boolean;
  isWeekend: boolean;
  isHoliday: boolean;
  holidayName?: string;
}

export function EmptyCell(props: IBasicCellProps) {
  const windowResize = useWindowResize();

  const todayDate = dayjs(new Date()).format("YYYYMMDD");

  return (
    <>
      <div
        className={
          "item empty" +
          (props.isOutsideActiveMonth ? " notCurrent" : "") +
          (dayjs(props.day).format("YYYYMMDD") === todayDate ? " border" : "") +
          (props.isWeekend ? " weekend" : "") +
          (props.isHoliday ? " holiday" : "")
        }
      >
        <div className="item-info">
          <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
          {windowResize >= 1024 ? <div className="holiday-label">{props.holidayName}</div> : null}
        </div>
      </div>
    </>
  );
}
