import "./SecondaryButton.css";
import { DefaultButton, IIconProps } from "@fluentui/react";

interface ISecondaryButtonProps {
  children?: React.ReactNode;
  additionalClass?: string;
  text: string;
  icon?: IIconProps;
  onClick?: () => void;
  isDisabled?: boolean;
  dataTest?: string;
}

export function SecondaryButton(props: ISecondaryButtonProps) {
  return (
    <DefaultButton
      data-test={props.dataTest}
      className={"secondary-btn " + (props.additionalClass ? props.additionalClass : "")}
      text={props.text}
      iconProps={props.icon}
      onClick={() => props.onClick?.()}
      disabled={props.isDisabled}
    >
      {props.children}
    </DefaultButton>
  );
}
