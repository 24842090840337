import React, { useCallback, useMemo, useState } from "react";
import "./DeclineHoursPopup.css";
import { useClosePanel, useClosePopup, usePopup } from "../../../../infrastructure/ui/UIServices";
import { DeclineDailyRecordsRequestDTO, HoursService } from "../../../../services/HoursService";
import dayjs from "dayjs";
import { ReactComponent as IconWarning } from "../../../../assets/warning-icon.svg";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

interface IDeclineHoursPopupProps {
  declineOpCompleted: () => void;
  selectedDays: { dailyRecordId: number; day: Date }[];
}

var hoursService = new HoursService();

export function DeclineHoursPopup(props: IDeclineHoursPopupProps) {
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const closePanel = useClosePanel();

  const [reason, setReason] = useState<string>("");
  const [spinner, setSpinner] = useState<boolean>(false);
  const [errorMessage, setMessageError] = useState<string>("");
  const [validationError, setValidationError] = useState<boolean>(false);

  const onClickDeclineButton = useCallback(() => {
    setSpinner(true);
    var getRecordsIdsSelected = props.selectedDays.map((obj) => {
      var op = obj.dailyRecordId;
      return op;
    });

    var request: DeclineDailyRecordsRequestDTO = {
      dailyRecordsIds: getRecordsIdsSelected,
      reason: reason,
    };

    hoursService
      .declineDailyRecords(request)
      .then(() => {
        props.declineOpCompleted();
        setSpinner(false);
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("MANAGERHOURS.DECLINE.SuccessResponse")}</p>
          </FeedbackPopup>
        );
        closePanel();
      })
      .catch((err) => {
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(err.response?.data);
          if (parsedResponse.AlertType === ErrorTypeEnum.ERROR) {
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
          }
        } catch {
          if (err.code === "ERR_BAD_REQUEST") {
            if (err.response.data) {
              setMessageError(err.response.data);
              setValidationError(true);
              setSpinner(false);
            }
          } else {
            setSpinner(false);
            openPopup(
              <FeedbackPopup type="error">
                <p>{err.response?.data}</p>
              </FeedbackPopup>
            );
          }
        }
      });
  }, [props.selectedDays, props.declineOpCompleted, reason, spinner, openPopup, closePanel]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setValidationError(false);
        }}
        dismissButtonAriaLabel="Close"
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setValidationError]);

  const blockDeclineButton = useMemo(() => {
    if (reason.trim().length === 0) {
      return true;
    }
    return false;
  }, [reason]);

  return (
    <div className="decline-hours-approval-popup feedback-popup">
      <div className="icon">
        <IconWarning />
      </div>
      <div className="feedback">
        <h1>{translate("MANAGERHOURS.DECLINE.Title")}</h1>
        <div className="popup-text">{translate("MANAGERHOURS.DECLINE.Message")}</div>
        <div className="message">
          <TextField
            onChange={(ev, input) => {
              setReason(input || "");
            }}
            label={translate("MANAGERHOURS.DECLINE.Reason")}
            placeholder="Ex: Lorem Ipsum"
            multiline
            rows={3}
            required
            disabled={spinner}
          />
        </div>
        {validationError ? errorMessageBar() : null}
      </div>
      <div className="action-btns">
        <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} isDisabled={spinner} />
        <PrimaryButton
          isDisabled={spinner || blockDeclineButton}
          additionalClass="warning"
          text={translate("ACTIONS.Decline")}
          onClick={() => {
            onClickDeclineButton();
          }}
        >
          {" "}
          {spinner ? <Spinner size={SpinnerSize.small} /> : null}
        </PrimaryButton>
      </div>
    </div>
  );
}
