import "./InfoBlock.css";
import React from "react";

interface IInfoBlockProps {
  label?: string;
  text: string;
}

export function InfoBlock(props: IInfoBlockProps) {
  return (
    <div className="info-block">
      <div className="label">{props.label}</div>
      <div className="text">{props.text}</div>
    </div>
  );
}
