import "./MobileNav.css";

import { ContextualMenu, DefaultButton, IContextualMenuProps, IconButton } from "@fluentui/react";

import { ReactComponent as IconMobileNav } from "../../assets/mobile-menu-icon.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { MobileNavPanel } from "./MobileNavPanel";
import { N4UPermissions } from "../../infrastructure/identity/Permissions";
import { NavLink } from "react-router-dom";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { usePermission } from "../../infrastructure/identity/hooks/usePermissions";
import { useShowMobileMenuPanel } from "../../infrastructure/ui/UIServices";

export interface IMobileNavButton {
  icon: React.ReactNode;
  to: string;
  name: string;
}

export interface IMobileNavProps {
  buttons: IMobileNavButton[];
  btnActiveText: string;
}

const menuBtnProps: IContextualMenuProps = {
  items: [
    {
      key: "manager",
      text: translate("MENU.Manager"),
      href: "/manager",
    },
    {
      key: "employee",
      text:  translate("MENU.Employee"),
      href: "/me/",
    },
  ],
};

export function MobileNav(props: IMobileNavProps) {
  const isManager = usePermission(N4UPermissions.MANAGER);
  const isApprover = usePermission(N4UPermissions.APPROVER);

  const { mobileNavState, setSMobileNavState } = useShowMobileMenuPanel();

  return (
    <>
      <div className="mobile-nav-bar">
        <NavLink to={"/"}>
          <Logo />
        </NavLink>
        <div className="menu-icon">
          <IconButton onRenderIcon={() => <IconMobileNav />} onClick={() => setSMobileNavState(true)} />
        </div>
        {isManager || isApprover ? (
          <div className="module-btn">
            <DefaultButton
              className="primary-btn"
              text={props.btnActiveText}
              primary
              aria-roledescription="split button"
              menuProps={menuBtnProps}
              menuAs={getModuleMenu}
            />
          </div>
        ) : null}
      </div>
      {mobileNavState ? <MobileNavPanel buttons={props.buttons} /> : null}
    </>
  );
}

function getModuleMenu(props: IContextualMenuProps) {
  return <ContextualMenu {...props} />;
}
