import { useContext } from "react";
import { NavigationSvcContext } from "../NavigationServices";

export function usePageTitle() {
    const context = useContext(NavigationSvcContext);
    if(!context){
        throw new Error("usePageTitle must be used within NavigationServices");
    }

    return context.setPageTitle;
}
