import "./CreateAllocationPanel.css";
import {
  BaseButton,
  Button,
  ComboBox,
  getInitials,
  IComboBoxOption,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { useCloseOverlaySpinner, useClosePanel, useOverlaySpinner, usePopup, useShowSpinner } from "../../../../infrastructure/ui/UIServices";
import { AllocationsService, CreateAllocationRequestDTO } from "../../../../services/AllocationsService";
import { useCallback, useEffect, useState } from "react";
import { User, UsersService } from "../../../../services/UsersService";
import { CAConfirmationPopup } from "../popups/CAConfirmationPopup";
import { CAAlertPopup } from "../popups/CAAlertPopup";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { FeatureFlags } from "../../../../infrastructure/features/Flags";
import { useActiveFeatures } from "../../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

const serviceAllocation = new AllocationsService();
const serviceUser = new UsersService();

interface IProps {
  equipmentId: number;
  onChange: () => void;
}

function mapEmployeesToIDropdownOptions(users: User[]) {
  var optionsMapped: IComboBoxOption[] = users.map((r) => {
    var opMapped: IComboBoxOption = {
      text: r.displayName,
      key: r.userPrincipalName,
      data: r.url,
    };
    return opMapped;
  });
  return optionsMapped;
}

export function CreateAllocationPanel(props: IProps) {
  const openPopup = usePopup();
  const closePanel = useClosePanel();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const { showSpinner, setShowSpinner } = useShowSpinner();

  const [users, setUsers] = useState<IComboBoxOption[]>([]);
  const [employeeSelected, setEmployeeSelected] = useState<string>("");
  const [isLoadingUsersList, setIsLoadingUsersList] = useState<boolean>(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    serviceUser
      .getAll()
      .then((res) => {
        setIsLoadingUsersList(false);
        setUsers(mapEmployeesToIDropdownOptions(res));
      })
      .catch((error) => {
        openPopup(
          <FeedbackPopup type="error">
            <p>{error}</p>
          </FeedbackPopup>
        );
      });
  }, []);

  useEffect(() => {
    if (employeeSelected) {
      setIsSaveButtonDisabled(false);
    }
  }, [employeeSelected]);

  const peopleSelected = useCallback(
    (persona: IComboBoxOption) => {
      setEmployeeSelected(persona.key as string);
      return persona;
    },
    [setEmployeeSelected]
  );

  const onClickSave = useCallback(() => {
    if (props.equipmentId && employeeSelected) {
      openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
      serviceAllocation
        .createAllocation(employeeSelected, props.equipmentId)
        .then((_) => {
          closeOverlaySpinner();
          props.onChange();
          closePanel();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("ALLOCATIONS.AllocationCreatedWithSuccess")}</p>
            </FeedbackPopup>
          );
        })
        .catch((error) => {
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
  }, [props.equipmentId, employeeSelected, openOverlaySpinner, closeOverlaySpinner, props.onChange, closePanel, openPopup]);

  return (
    <div className="create-allocation-content">
      <CardHeader headerTitle={translate("ALLOCATIONS.CreateAllocation")} icon={true} setDisplayState={(state: boolean) => closePanel()}></CardHeader>
      {isLoadingUsersList ? (
        <Spinner size={SpinnerSize.large} label={translate("LOADING.PleaseWait")} />
      ) : (
        <form action="">
          <ComboBox
            label={translate("ALLOCATIONS.COMMON.AllocateTo")}
            allowFreeform={true}
            autoComplete={"on"}
            options={users}
            placeholder={users.length > 0 ? translate("ALLOCATIONS.SelectOneEmployee") : ""}
            onItemClick={(ev, item, index) => {
              if (item) peopleSelected(item);
            }}
            onChange={(event, value) => {
              if (value) peopleSelected(value);
            }}
            onRenderOption={(item) => {
              if (item) {
                return (
                  <>
                    <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                  </>
                );
              }
              return null;
            }}
            required={true}
          />

          <TextField placeholder={translate("ALLOCATIONS.COMMON.Approved")} label={translate("COMMON.Status")} disabled={true} />
          <div className="action-btns">
            <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePanel()} />
            <PrimaryButton text={translate("ACTIONS.Create")} onClick={onClickSave} isDisabled={isSaveButtonDisabled}></PrimaryButton>
          </div>
        </form>
      )}
    </div>
  );
}
