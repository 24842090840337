import "./Header.css";

import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { IContextualMenuProps, getInitials, initializeIcons } from "@fluentui/react";
import { InternationalizationService, translate } from "../../infrastructure/i18n/InternationalizationService";
import { UserProfile, UsersService } from "../../services/UsersService";
import { useCallback, useEffect, useRef, useState } from "react";

import { ReactComponent as AccountArrow } from "../../assets/account-arrow.svg";
import { Breadcrumb } from "@fluentui/react/lib/Breadcrumb";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { ReactComponent as IconNavLogout } from "../../assets/logout-icon.svg";
import { ReactComponent as IconNavEN } from "../../assets/en-icon.svg";
import { ReactComponent as IconNavPT } from "../../assets/pt-icon.svg";
import { N4UPermissions } from "../../infrastructure/identity/Permissions";
import { Persona } from "@fluentui/react/lib/Persona";
import { useBreadcrumb } from "../../infrastructure/navigation/hooks/useBreadcrumb";
import { useHeaderInfo } from "../../infrastructure/navigation/hooks/useHeaderInfo";
import { useIdentityUserDisplayName } from "../../infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useNavigate } from "react-router-dom";
import { usePermission } from "../../infrastructure/identity/hooks/usePermissions";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

interface IHeaderProps {
  btnActiveText: string;
}

var userService = new UsersService();

export function Header(props: IHeaderProps) {
  const isManager = usePermission(N4UPermissions.MANAGER);
  const isApprover = usePermission(N4UPermissions.APPROVER);

  const { userDisplayName } = useIdentityUserDisplayName();
  const { pageInfo } = useHeaderInfo();
  const { breadcrumb } = useBreadcrumb();
  const windowResize = useWindowResize();

  const navigate = useNavigate();

  initializeIcons();

  const [userProfile, setUserProfile] = useState<UserProfile>();

  useEffect(() => {
    userService.getProfile("").then((res) => {
      setUserProfile(res);
    });
  }, []);

  const menuManagerBtnProps: IContextualMenuProps = {
    items: [
      {
        key: "manager",
        text: translate("AREA.Manager"),
        href: "/manager/",
      },
      {
        key: "employee",
        text: translate("AREA.Employee"),
        href: "/me/",
      },
    ],
  };

  const menuBtnProps: IContextualMenuProps = {
    items: [
      {
        key: "employee",
        text: translate("AREA.Employee"),
        href: "/me/",
      },
    ],
  };

  const linkRef = useRef(null);
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const onShowContextualMenu = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(!showContextualMenu);
    },
    [showContextualMenu, setShowContextualMenu]
  );
  const onHideContextualMenu = useCallback(() => setShowContextualMenu(false), []);

  const menuItems: IContextualMenuItem[] = [
    {
      key: "name",
      text: userDisplayName || "loading ...",
      onRender: () => (
        <div className="contextual-menu">
          <div className="contextual-menu-name">{userProfile?.displayName}</div>
          <div className="contextual-menu-mail">{userProfile?.email}</div>
        </div>
      ),
    },
    {
      key: "divider_1",
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: "pt",
      text: "PT (Experimental)",
      iconProps: {},
      onRenderIcon: () => {
        return <IconNavPT />;
      },
      onClick: () => {
        InternationalizationService.setLocalePT();
      },
    },
    {
      key: "en",
      text: "EN",
      iconProps: {},
      onRenderIcon: () => {
        return <IconNavEN />;
      },
      onClick: () => {
        InternationalizationService.setLocaleEN();
      },
    },
    {
      key: "divider_2",
      itemType: ContextualMenuItemType.Divider,
    },
    {
      key: "logout",
      text: "Logout",
      iconProps: {},
      onRenderIcon: () => {
        return <IconNavLogout />;
      },
      onClick: () => {
        navigate("/identity/logout");
      },
    },
  ];

  return (
    <div className="header">
      <div className="header-info">
        <h1>{pageInfo?.title} </h1>
        {breadcrumb && breadcrumb.length > 1 ? <Breadcrumb items={breadcrumb} maxDisplayedItems={5} overflowAriaLabel="More links" /> : null}
      </div>
      {windowResize > 768 ? (
        <div className="header-account">
          <div className="account">
            {isManager || isApprover ? (
              <DefaultButton
                className="primary-btn"
                text={props.btnActiveText}
                primary
                aria-roledescription="split button"
                menuProps={isManager || isApprover ? menuManagerBtnProps : menuBtnProps}
                data-test={"header-btn"}
                menuAs={getModuleMenu}
              />
            ) : null}

            <div className="account-menu">
              <a ref={linkRef} onClick={onShowContextualMenu} href="#">
                <Persona imageUrl={userProfile?.url} imageInitials={getInitials(userProfile?.displayName, false, true)} imageAlt="Account Avatar" className="avatar" />

                <AccountArrow />
              </a>
              <ContextualMenu items={menuItems} hidden={!showContextualMenu} target={linkRef} onItemClick={onHideContextualMenu} onDismiss={onHideContextualMenu} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function getModuleMenu(props: IContextualMenuProps) {
  return <ContextualMenu {...props} />;
}
