import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HoursService } from './services/HoursService';
import React from "react";
import ReactDOM from "react-dom";
import { SPAApp } from "./infrastructure/SPAApp";
import { Startup } from "./infrastructure/Startup";
import dayjs from 'dayjs';
import { initializeIcons } from '@fluentui/react';
import isToday from 'dayjs/plugin/isToday';
import reportWebVitals from "./reportWebVitals";

initializeIcons();


var advancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(advancedFormat);
dayjs.extend(isToday);

declare global {
  interface Window  {
    terminal: (command: string) => Promise<void>;
  }
}


window.terminal = (command) => {

  switch(command){
    case "cache reset hours":
      let svc = new HoursService();
      return svc.resetCache().then(a => console.log("[TERMINAL] Success")).catch(err => console.log("[TERMINAL] Error: ", err));

  }
  return Promise.resolve();
}

//TODO FIX TEST FRAMEWORK
Startup.Run(() => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <SPAApp>
            <App />
        </SPAApp>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
