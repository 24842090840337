import React from "react";
import { useNavigate } from "react-router-dom";
import { TertiaryButton } from "../../../components/buttons/TertiaryButton";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import "./ErrorPage.css";

export function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="wrapper">
        <div className="error-text">
          <div className="error">404</div>
          <div className="info">
            <div className="title">
              Ooops!
              <br />
              {translate("ERRORPAGES.NOTFOUND.Title")}
            </div>
            <div className="text"> {translate("ERRORPAGES.NOTFOUND.Message")}</div>
          </div>
        </div>
        <TertiaryButton additionalClass="error-page-btn" text={translate("ACTIONS.Back")} onClick={() => navigate("/")} />
      </div>
    </div>
  );
}
