import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';

export function useIdentityIsProcessing() {

    const {inProgress} = useMsal();


    return {isProcessing: inProgress == InteractionStatus.Startup || inProgress == InteractionStatus.HandleRedirect};
}
