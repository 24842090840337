import "./IconBtn.css";
import { IconButton, IIconProps } from "@fluentui/react";

interface IIconBtnProps {
  children?: React.ReactNode;
  additionalClass?: string;
  icon?: IIconProps;
  onClick?: () => void;
  isDisabled?: boolean;
}

export function IconBtn(props: IIconBtnProps) {
  return (
    <IconButton
      className={"icon-btn " + (props.additionalClass ? props.additionalClass : "")}
      iconProps={props.icon}
      onClick={() => props.onClick?.()}
      disabled={props.isDisabled}
    >
      {props.children}
    </IconButton>
  );
}
