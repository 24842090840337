import "./NavIcon.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { useShowMobileMenuPanel } from "../../infrastructure/ui/UIServices";

interface INavIconProps {
  icon: React.ReactNode;
  to: string;
  name: string;
  newTab? : boolean;
}

export function NavIcon(props: INavIconProps) {
  const { setSMobileNavState } = useShowMobileMenuPanel();

  return (
    <>
      <li>
        <NavLink
          to={props.to}
          target= {props.newTab ? "_blank" : undefined}
          rel={props.newTab ? "noopener noreferrer" : undefined}
          className={({ isActive }) =>
            isActive ? "nav-icon selected" : "nav-icon"
          }
          onClick={() => setSMobileNavState(false)}
        >
          <div className="menu-icon-wrapper">
            {props.icon}
            {props.name}
          </div>
        </NavLink>
      </li>
    </>
  );
}
