import { FeatureSvcContext, IFeature } from "../FeatureServices";
import { useContext, useMemo } from "react";

import { useNavigate } from 'react-router-dom';

export function evaluateRequestedFeatures(requestedFeatures: string[], activeFeatures: IFeature[]) {
  for (const requestedFeature of requestedFeatures) {
    if (activeFeatures.filter((f) => f.id == requestedFeature).length < 1) {
      return false;
    }
  }
  return true;
}

export function useActiveFeatures(requestedFeatures: string[]) {
  const context = useContext(FeatureSvcContext);
  if (!context) {
    throw new Error("useActiveFeatures must be used within FeatureServices");
  }

  const isActive = useMemo(() => {
    return evaluateRequestedFeatures(requestedFeatures, context.features);
  }, [context.features, requestedFeatures]);

  return isActive;
}



export function useEnforceActiveFeatures(requestedFeatures: string[]) {

  const context = useContext(FeatureSvcContext);


  if (!context) {
    throw new Error("useActiveFeatures must be used within FeatureServices");
  }

  const navigate = useNavigate();

  const isActive = useMemo(() => {
    return evaluateRequestedFeatures(requestedFeatures, context.features);
  }, [context.features, requestedFeatures]);

  if (!isActive) {
    navigate("/error");
  }

}

export function useFeatures() {
  const context = useContext(FeatureSvcContext);
  if (!context) {
    throw new Error("useActiveFeatures must be used within FeatureServices");
  }

  return { features: context.features };
}
