import React from "react";
import { useNavigate } from "react-router-dom";
import { TertiaryButton } from "../../../components/buttons/TertiaryButton";
import { ReactComponent as IconWarning } from "../../../assets/warning-icon.svg";
import "./UnavailableService.css";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

interface IUnavailableServiceProps {
  errorText?: string;
}

export function UnavailableService(props: IUnavailableServiceProps) {
  const navigate = useNavigate();

  return (
    <div className="unavailable-service">
      <div className="wrapper">
        <div className="error-text">
          <div className="error">
            <IconWarning />
          </div>
          <div className="info">
            <div className="title">
              Ooops!
              <br />
              {translate("ERRORPAGES.Unavailable")}
            </div>
            <div className="text">{props.errorText}</div>
          </div>
        </div>
        <TertiaryButton additionalClass="error-page-btn" text={translate("ACTIONS.Back")} onClick={() => navigate("/")} />
      </div>
    </div>
  );
}
