import "./CardLayout.css";
import React, { useMemo } from "react";

interface ICardLayoutProps {
  children: React.ReactNode;
  classNames?: string;
}

export function CardLayout(props: ICardLayoutProps) {
  const css = useMemo(() => {
    let cssNames = "card-background";
    if (props.classNames) {
      cssNames = cssNames + " " + props.classNames;
    }
    return cssNames;
  }, [props.classNames]);

  return <div className={css}>{props.children}</div>;
}
