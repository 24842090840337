import "./NumberPicker.css";

import { useCallback, useEffect, useState } from "react";

import { TextField } from "@fluentui/react";

interface INumberPickerProps {
  value: string;
  isDisabled?: boolean;
  labelText?: string;
  setValue: (value: string) => void;
  isRequired: boolean;
  minValue: number;
  maxValue: number;
}

export function NumberPicker(props: INumberPickerProps) {

  const [numberValue, setNumberValue] = useState<string>(props.value);

  useEffect(() => {
    setNumberValue(props.value);
  }, [props.value]);

  const incrementValue = useCallback(
    (str: string) => {
      let incrementResult = parseInt(str) + 1;
      if (incrementResult > props.maxValue) {
        incrementResult = props.maxValue;
        setNumberValue(incrementResult.toString());
        props.setValue("");
      } else {
        setNumberValue(incrementResult.toString());
        props.setValue(incrementResult.toString());
      }
    },
    [props]
  );

  const decrementValue = useCallback(
    (str: string) => {
      let decrementResult = parseInt(str) - 1;
      if (decrementResult < props.minValue) {
        decrementResult = props.minValue;
        setNumberValue(decrementResult.toString());
        props.setValue("");
      } else {
        setNumberValue(decrementResult.toString());
        props.setValue(decrementResult.toString());
      }
    },
    [props]
  );

  return (
    <div className="number-input">
      <div
        className={"decrement" + (props.isDisabled ? " disabled" : "") + (numberValue === props.minValue.toString() ? " limit" : "")}
        onClick={() => decrementValue(numberValue)}
      >
        -
      </div>
      <TextField className={props.isDisabled ? "disabled" : ""} label={props.labelText} value={numberValue} required={props.isRequired} type="number" disabled={props.isDisabled} />
      <div
        className={"increment" + (props.isDisabled ? " disabled" : "") + (numberValue === props.maxValue.toString() ? " limit" : "")}
        onClick={() => incrementValue(numberValue)}
      >
        +
      </div>
    </div>
  );
}
