import React, { createContext, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { useCallback } from "react";

type BreadcrumbRoute = { path: string; name: string };

interface BreadcrumItem {
  name?: string;
  path?: string;
}

interface PageState {
  title: string;
}

type RouteData = { [id: string]: string };

export interface NavigationSvcsContextState {
  breadcrumb: BreadcrumItem[];
  pageState?: PageState;
  routeData: RouteData;
  routes: BreadcrumbRoute[];
  setRoutes: React.Dispatch<React.SetStateAction<BreadcrumbRoute[]>>;
  setPageTitle: (title: string) => void;
  addToRouteData: (...data: [string, string][]) => void;
  cleanRouteData: () => void;
}

export const NavigationSvcContext =
  createContext<NavigationSvcsContextState | null>(null);

interface INavigationSvcsContextProps {
  children: React.ReactNode;
}

export function NavigationServices({ children }: INavigationSvcsContextProps) {
  const [routeData, setRouteData] = useState<RouteData>({});
  const [pageState, setPageState] = useState<PageState>();
  const [routes, setRoutes] = useState<BreadcrumbRoute[]>([]);

  const location = useLocation();

  const breadcrumb = useMemo(() => {
    let res = routes
      .map((r) => matchPath({ ...r, end: false }, location.pathname))
      .filter((r) => !!r)
      .map(
        (r): BreadcrumItem => ({
          path: r?.pathname,
          name: (r?.pattern as never)["name"],
        })
      );
    return res;
  }, [location, routes]);

  const setPageTitle = useCallback((title: string) => {
    setPageState({ ...pageState, title: title })
  },[setPageState, pageState]);

  const addToRouteData = useCallback( (...data: [string, string][]) => {
      let clone = { ...routeData };
      data.forEach(pair => {
        clone[pair[0]] = pair[1];
      });

      setRouteData(clone);


  },[routeData, setRouteData]);

  const cleanRouteData = useCallback(() => {
    setRouteData({});
  }, [setRouteData]);

  const value = {
    breadcrumb,
    routeData,
    pageState,
    routes,
    setRoutes,
    setPageTitle: setPageTitle,
    addToRouteData: addToRouteData,
    cleanRouteData: cleanRouteData,
  };

  return (
    <NavigationSvcContext.Provider value={value}>
      {children}
    </NavigationSvcContext.Provider>
  );
}
