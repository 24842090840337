export enum ErrorTypeEnum {
  ERROR = 1,
  WARNING = 2,
  OVERRIDE = 3, 
  ISPUBLIC = 4
}

export interface DomainExceptionResponse {
  Response: string;
  AlertType: number;
}

