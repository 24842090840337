import React, { useEffect, useRef, useState } from "react";
import { IconButton, IIconProps } from "@fluentui/react";
import "./ImageCarousel.css";

const arrowIcon: IIconProps = { iconName: "SkypeCircleArrow" };

export type ImageType = { id: number; url: string };

interface IImageCarousel {
  photosLength: number;
  images: ImageType[];
}

export function ImageCarousel(props: IImageCarousel) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState<ImageType>();
  const carouselItemsRef = useRef<HTMLDivElement[] | null[]>([]);

  useEffect(() => {
    if (props.images && props.images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(
        0,
        props.images.length
      );

      setSelectedImageIndex(0);
      setSelectedImage(props.images[0]);
    }
  }, [props.images]);

  const handleSelectedImageChange = (newIdx: number) => {
    if (props.images && props.images.length > 0) {
      setSelectedImage(props.images[newIdx]);
      setSelectedImageIndex(newIdx);
      if (carouselItemsRef?.current[newIdx]) {
        carouselItemsRef?.current[newIdx]?.scrollIntoView({
          behavior: "smooth", // 'auto' or 'smooth'
          block: "center", // 'start', 'center', 'end' or 'nearest'
          inline: "center", // 'start', 'center', 'end' or 'nearest'
        });
      }
    }
  };

  const handleRightClick = () => {
    if (props.images && props.images.length > 0) {
      let newIdx = selectedImageIndex + 1;
      if (newIdx >= props.images.length) {
        newIdx = 0;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  const handleLeftClick = () => {
    if (props.images && props.images.length > 0) {
      let newIdx = selectedImageIndex - 1;
      if (newIdx < 0) {
        newIdx = props.images.length - 1;
      }
      handleSelectedImageChange(newIdx);
    }
  };

  return (
    <div className="carousel-container">
      <div
        className="selected-image"
        style={{ backgroundImage: `url(${selectedImage?.url})` }}
      />
      <div className="carousel">
        <div className="carousel__images">
          {props.images &&
            props.images.map((image, idx) => (
              <div
                onClick={() => handleSelectedImageChange(idx)}
                style={{ backgroundImage: `url(${image.url})` }}
                key={image.id}
                className={`carousel__image ${
                  selectedImageIndex === idx && "carousel__image-selected"
                }`}
                ref={(el) => (carouselItemsRef.current[idx] = el)}
              />
            ))}
        </div>
        {props.photosLength > 1 ? (
          <>
            <IconButton
              className="carousel__button carousel__button-left"
              iconProps={arrowIcon}
              onClick={handleLeftClick}
            />
            <IconButton
              className="carousel__button carousel__button-right"
              iconProps={arrowIcon}
              onClick={handleRightClick}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
