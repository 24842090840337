import "./CalendarDayInfoPanel.css";

import { DailyRecordDTO, ITimeRecordResponseDTO } from "../../services/HoursService";
import { DetailsList, DetailsListLayoutMode, IColumn, IIconProps, Persona, PersonaSize, SelectionMode } from "@fluentui/react";
import { useCallback, useMemo } from "react";
import { useClosePanel, usePopup } from "../../infrastructure/ui/UIServices";

import { CardHeader } from "../cards/CardHeader";
import { ConfirmHoursApprovalPopup } from "../../areas/people-management/hours/popup/ConfirmHoursApprovalPopup";
import { DeclineHoursPopup } from "../../areas/people-management/hours/popup/DeclineHoursPopup";
import { DeleteHoursPopup } from "../../areas/people-management/hours/popup/DeleteHoursPopup";
import { ISelectedDay } from "../../areas/people-management/hours/management-calendar/ManagementCalendar";
import { InfoBlock } from "../cards/InfoBlock";
import { PrimaryButton } from "../buttons/PrimaryButton";

import dayjs from "dayjs";
import "dayjs/locale/pt";

import { InternationalizationService, translate } from '../../infrastructure/i18n/InternationalizationService';

const approveIcon: IIconProps = { iconName: "Accept" };
const declineIcon: IIconProps = { iconName: "Cancel" };
const deleteIcon: IIconProps = { iconName: "Delete" };

interface ICalendarDayInfoPanelProps {
  dailyRecord: DailyRecordDTO;
  day: Date;
  isActionBtns?: boolean;
  opCompleted?: () => void;
  isManagerCell?: boolean;
  holidayName?: string;
}

interface IActivityNotes {
  projectName: string;
  activityName: string;
  notes?: string;
}

export function CalendarDayInfoPanel(props: ICalendarDayInfoPanelProps) {
  const closePanel = useClosePanel();
  const openPopup = usePopup();

  let dateAsDayJs = dayjs(props.day).locale(InternationalizationService.getLocale()).format("dddd, D MMMM");

  const historyStringArray = useMemo(() => props.dailyRecord?.history?.split("#"), [props.dailyRecord?.history]);

  const getSelectedDay = useMemo(() => {
    var selectedDay: ISelectedDay[] = [{ dailyRecordId: props.dailyRecord.dailyRecordId, day: props.day }];
    return selectedDay;
  }, [props]);

  const mapTimeRecordsToActivityNotes = useMemo(() => {
    if (props.dailyRecord) {
      return props.dailyRecord.timeRecord
        .map(
          (tr): IActivityNotes => ({
            activityName: tr.activityName,
            projectName: tr.projectName,
            notes: tr.notes,
          })
        )
        .filter((recordHasNotes) => recordHasNotes.notes);
    } else return [] as IActivityNotes[];
  }, [props.dailyRecord]);

  const columns: IColumn[] = [
    {
      key: "column1",
      name: `${translate("MENU.Projects")}/${translate("ACTIVITIES.Activity")}`,
      fieldName: "name",
      minWidth: 130,
      maxWidth: 250,
      isRowHeader: true,
      isResizable: true,
      onRender: (item) => (
        <div className="project-activity truncate">
          <div className="truncate">{item.projectName}</div>
          <div className="activity truncate">{item.activityName}</div>
        </div>
      ),
      data: "string",
    },
    {
      key: "column2",
      name: translate("HOURS.Hrs"),
      fieldName: "activityHours",
      minWidth: 50,
      maxWidth: 50,
      isRowHeader: true,
      isResizable: true,
      data: "string",
    },
  ];

  return (
    <div className="calendar-day-info">
      <CardHeader
        headerTitle={dateAsDayJs}
        children={props.holidayName ? <div className={"subtitle holiday"}>{props.holidayName}</div> : null}
        createdDate={props.dailyRecord.createdDate ? <div className={"subtitle created"}>{"Created on " + props.dailyRecord.createdDate}</div> : null}
        icon={true}
        setDisplayState={() => closePanel()}
      ></CardHeader>
      <div className="calendar-day-info-content">
        <div className="working-time">
          <h2>{translate("HOURS.WorkingTime")}</h2>
          <div className="blocks">
            {props.dailyRecord?.morningStart && props.dailyRecord?.morningEnd ? (
              <InfoBlock label={translate("HOURS.MorningTime")} text={props.dailyRecord?.morningStart + " - " + props.dailyRecord.morningEnd}></InfoBlock>
            ) : null}
            {props.dailyRecord?.afternoonStart && props.dailyRecord?.afternoonEnd ? (
              <InfoBlock label={translate("HOURS.AfternoonTime")} text={props.dailyRecord?.afternoonStart + " - " + props.dailyRecord.afternoonEnd}></InfoBlock>
            ) : null}
          </div>
        </div>
        <div className="project-hours">
          <h2>{translate("HOURS.PRojectHours")}</h2>
          <DetailsList
            className="projects-list"
            items={props.dailyRecord?.timeRecord!}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </div>
        {mapTimeRecordsToActivityNotes.length === 0 ? null : (
          <div className="notes">
            <h2>{translate("HOURS.Notes")}</h2>
            {mapTimeRecordsToActivityNotes.map((act) => (
              <div className="note-info">
                <div className="project">
                  {act?.projectName}
                  <span className="activity">{act?.activityName}</span>
                </div>
                <div className="note-text">{act?.notes}</div>
              </div>
            ))}
          </div>
        )}
        {props.dailyRecord?.approvedBy ? (
          <div className="approved-by">
            <h2>{translate("COMMON.ApprovedBy")}</h2>
            <Persona
              imageUrl={props.dailyRecord?.approvedBy?.url}
              size={PersonaSize.size40}
              text={props.dailyRecord?.approvedBy?.displayName}
              secondaryText={props.dailyRecord?.approvedDate}
            />
          </div>
        ) : null}

        {props.dailyRecord?.history ? (
          <div className="history">
            <h2>{translate("COMMON.History")}</h2>
            {historyStringArray?.map((str, idx) => (
              <p key={idx}>{str}</p>
            ))}
          </div>
        ) : null}
      </div>
      {props.dailyRecord?.status === "OK" && props.isManagerCell ? (
        <div className="action-btns">
          <PrimaryButton
            text={translate("ACTIONS.Decline")}
            icon={declineIcon}
            onClick={() => openPopup(<DeclineHoursPopup selectedDays={getSelectedDay} declineOpCompleted={() => props.opCompleted?.()} />)}
          />
        </div>
      ) : null}
      {props.dailyRecord?.status === "WAIT" && !props.isManagerCell ? (
        <div className="action-btns">
          <PrimaryButton
            text={translate("ACTIONS.Remove")}
            icon={deleteIcon}
            onClick={() => openPopup(<DeleteHoursPopup dailyRecordId={props.dailyRecord.dailyRecordId} deleteOpCompleted={() => props.opCompleted?.()} />)}
          />
        </div>
      ) : null}
      {props.isActionBtns && props.dailyRecord?.status !== "OK" ? (
        <div className="action-btns">
          <PrimaryButton
            text={translate("ACTIONS.Decline")}
            icon={declineIcon}
            onClick={() => openPopup(<DeclineHoursPopup selectedDays={getSelectedDay} declineOpCompleted={() => props.opCompleted?.()} />)}
            isDisabled={props.dailyRecord?.status === "NOT_OK" ? true : false}
          />
          <PrimaryButton
            additionalClass={"btn-margin-left"}
            text={translate("ACTIONS.Approve")}
            icon={approveIcon}
            onClick={() => openPopup(<ConfirmHoursApprovalPopup selectedDays={getSelectedDay} confirmation={() => props.opCompleted?.()} />)}
          />
        </div>
      ) : null}
    </div>
  );
}
