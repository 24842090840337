import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect } from "react";

export function ExternalLinkPage() {

  const [searchParams, setSearchParams] = useSearchParams();


  const navigate = useNavigate();

  useEffect(()=> {
    console.log(searchParams);
    let url =   searchParams.get("url")
    if(url){
      window.open(url, "_self", 'noopener')
    }
    else {
      // navigate("/error");
    }

  }, [])


  return null;
}
