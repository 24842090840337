import dayjs from "dayjs";
import { useMemo } from "react";

export interface IDailyRecordCloneData {
  dailyRecordId?: number;
  dailyRecordStatus?: string;
  isSelected?: boolean;
}

interface IMiniCellProps {
  day: Date;
  dailyRecordId?: number;
  dailyRecordStatus?: string;
  isOutsideCurrentMonth: boolean;
  onClick: (day: Date, idx: number) => void;
  isSelected?: boolean;
  idx: number;
  dayToCloneFrom: Date;
  isWeekend: boolean;
  isHoliday: boolean;
}

export function MiniCell(props: IMiniCellProps) {
  const todayDate = dayjs(new Date()).format("YYYYMMDD");
  const day = dayjs(props.day).format("YYYYMMDD");
  const dayToCloneFrom = dayjs(props.dayToCloneFrom).format("YYYYMMDD");

  const cellStatus = useMemo(() => {
    switch (props.dailyRecordStatus) {
      case "WAIT":
        return " pending";
      case "NOT_OK":
        return " declined";
      case "OK":
        return " approved";
      default:
        return "";
    }
  }, [props.dailyRecordStatus]);

  return (
    <div
      className={
        "item" +
        (cellStatus === " approved" ? " disable" : "") +
        (dayToCloneFrom === day ? " clone-day-disable" : "") +
        (day === todayDate ? " border" : "") +
        (!props.isOutsideCurrentMonth ? cellStatus : "") +
        (props.isOutsideCurrentMonth ? " notCurrent" : "") +
        (props.isWeekend ? " weekend" : "") +
        (props.isHoliday ? " holiday" : "") +
        (props.isSelected && !props.isOutsideCurrentMonth ? " selected" : "") +
        (props.isSelected && !props.isOutsideCurrentMonth && cellStatus !== "" ? " selected-not-empty" : "")
      }
      onClick={dayToCloneFrom === day ? () => {} : () => props.onClick(props.day, props.idx)}
    >
      <div className="item-info">
        <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
      </div>
    </div>
  );
}
