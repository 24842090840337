import {
  AuthenticationResult,
  EndSessionRequest,
  EventType,
  IPublicClientApplication,
  InteractionStatus,
  NavigationClient,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import { IdentityClaims, IdentityHelper } from "./Identity.Helper";

import { ConfigurationProvider } from "../configuration/ConfigurationProvider";
import { IdentityNavigationClient } from "./Identity.NavigationClient";
import { IdentityProvider } from "./IdentityProvider";
import { UrlUtility } from "../../utils/UrlUtility";

export class IdentityService {
  static redirectUri() {
    return ConfigurationProvider.getConfiguration().App.BaseUrl;
  }

  static Global: IdentityService;
  protected static navigationClient: IdentityNavigationClient;
  protected static isAuthenticated: boolean = false;
  protected static inProgress: InteractionStatus;

  static initInstance(msalInstance: PublicClientApplication) {
    if (!msalInstance) {
      throw new Error("Invalid MSAL Client");
    }

    IdentityService.Global = new IdentityService(msalInstance);

    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
      if (event.error) {
        console.warn("MSAL Error", event);
      }
    });
  }

  static userIsAuthenticated(): boolean {
    return IdentityService.isAuthenticated;
  }

  static setAuthenticated(isAuthenticated: boolean) {
    IdentityService.isAuthenticated = isAuthenticated;
    if (!isAuthenticated) {
      IdentityService.Global.saveClaims(null);
    }
  }

  static setInProgress(progress: InteractionStatus) {
    IdentityService.inProgress = progress;
  }

  static getInProgress() {
    return IdentityService.inProgress;
  }

  static setNavigationClient(client: IdentityNavigationClient) {
    IdentityService.navigationClient = client;
  }

  /** -------------------------------------------------------------
   *
   *  Identity Service With MSAL
   *
   *  ------------------------------------------------------------- */

  msalInstance: IPublicClientApplication;

  constructor(instance: IPublicClientApplication) {
    this.msalInstance = instance;
  }

  async login(url: string) {
    console.log("------------------ CALL TO LOGIN ---------------------");
    await this.msalInstance.handleRedirectPromise();

    let request: RedirectRequest = {
      ...IdentityProvider.getBaseLoginRequest(),
      redirectStartPage: UrlUtility.join(ConfigurationProvider.getConfiguration().App.BaseUrl, url),
      redirectUri: IdentityService.redirectUri(),
    };
    return this.msalInstance.loginRedirect(request);
  }

  async logout() {
    console.log("------------------ CALL TO LOGOUT ---------------------");
    const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = this.msalInstance.getAllAccounts();

    let request: EndSessionRequest = {
      postLogoutRedirectUri: IdentityService.redirectUri(),
      account: activeAccount || accounts[0],
      logoutHint: activeAccount?.name || (accounts[0] && accounts[0].name),
    };

    return this.msalInstance.logoutRedirect(request);

    // const requestIdToken = {
    //     ...IdentityProvider.getBaseLoginRequest(),
    //     scopes: ["openid"],
    //     account: activeAccount || accounts[0],
    //     forceRefresh: true,
    // };

    //    return this.msalInstance
    //    .acquireTokenSilent(requestIdToken)
    //    .then(async idAuthResult => {
    //         this.saveClaims(null);
    //         request.idTokenHint: idAuthResult.idToken;

    //         await this.msalInstance.handleRedirectPromise();
    //         return this.msalInstance.logoutRedirect(request);
    //     }).catch(err => {
    //         this.saveClaims(null);
    //         console.log(" :::: ", err)
    //         alert("problem" + err);
    //         IdentityService.navigationClient.navigateSPA("/");
    //     });
  }

  async loginBackToUrl(url: string) {
    console.log("------------------ CALL TO LOGIN ---------------------");

    await this.msalInstance.handleRedirectPromise();

    let request: RedirectRequest = {
      ...IdentityProvider.getBaseLoginRequest(),
      redirectUri: IdentityService.redirectUri(),
      redirectStartPage: url,
    };
    return this.msalInstance.loginRedirect(request);
  }

  async getAuthenticationResult(): Promise<AuthenticationResult | void | null> {
    const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = this.msalInstance.getAllAccounts();

    if (IdentityService.inProgress !== InteractionStatus.None) {
      //Testing
      await this.msalInstance.handleRedirectPromise();
    }

    const request = {
      ...IdentityProvider.getBaseLoginRequest(),
      account: activeAccount || accounts[0],
    };

    const redirectRequest: RedirectRequest = {
      ...IdentityProvider.getBaseLoginRequest(),
      redirectStartPage: window.location.href,
      account: activeAccount || accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return this.msalInstance
      .acquireTokenSilent(request)
      .then((authResult) => {
        console.log("Silent Sucess");
        this.saveClaims(authResult.idTokenClaims as IdentityClaims);
        return authResult;
      })
      .catch(async (e) => {
        console.log("AcquireTokenSilent Error ::", e);

        if (e.name === "consent_required" || e.name === "interaction_required") {
          //IdentityService.navigationClient.navigateSPA("/login");

          return null;
          // return this.msalInstance
          //     .acquireTokenRedirect(redirectRequest)
          //     .catch((e) => {
          //         console.log("AcquireTokenSilent + Redirect Error ::", e);
          //         window.history.pushState({}, '',"/sessionexpired");
          //         return null;
          //     });;
        }

        IdentityService.navigationClient.navigateSPA("/identity/sessionexpired");

        return null;
      });
  }

  //Hearbeat purposes
  tryAquireAccessToken(): Promise<string | null> {
    const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = this.msalInstance.getAllAccounts();

    if (IdentityService.inProgress !== InteractionStatus.None) {
      return this.msalInstance
        .handleRedirectPromise()
        .then((authResult) => {
          if (authResult && (authResult as AuthenticationResult)) {
            this.saveClaims(authResult.idTokenClaims as IdentityClaims);
            return authResult.accessToken;
          }
          return null;
        })
        .catch((e) => {
          return null;
        });
    }

    const request = {
      ...IdentityProvider.getBaseLoginRequest(),
      account: activeAccount || accounts[0],
    };

    // const redirectRequest: RedirectRequest = {
    //   ...IdentityProvider.getBaseLoginRequest(),
    //   account: activeAccount || accounts[0]
    // }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    return this.msalInstance
      .acquireTokenSilent(request)
      .then((authResult) => {
        this.saveClaims(authResult.idTokenClaims as IdentityClaims);
        if (authResult && (authResult as AuthenticationResult)) {
          return authResult.accessToken;
        }
        return null;
      })
      .catch((e) => {
        return null;
      });
  }

  getAccessToken(): Promise<string | void> {
    return this.getAuthenticationResult().then((result) => {
      if (result && (result as AuthenticationResult)) {
        return result.accessToken;
      }
    });
  }

  static storageKey = "N4U_STORAGE_IDENTITY_CLAIMS";

  saveClaims(claims: IdentityClaims | null) {
    if (claims) {
      window.localStorage.setItem(IdentityService.storageKey, JSON.stringify(claims));
    } else {
      window.localStorage.removeItem(IdentityService.storageKey);
    }
  }

  getClaims(): IdentityClaims {
    let claims = window.localStorage.getItem(IdentityService.storageKey);
    return claims && JSON.parse(claims);
  }
}
