import "./AddProjectPopup.css";

import { Activity, ProjectsService } from "../../../../services/ProjectsService";
import {
  DetailsList,
  DetailsListLayoutMode,
  FontIcon,
  IColumn,
  IIconProps,
  IconButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  SpinnerSize,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useWindowResize } from "../../../../infrastructure/ui/UIServices";

import { ActivityCreateRequestDTO } from "../../../../models/api/projects/ActivityCreateRequestDTO";
import { ActivityDetail } from "../../../../models/projects/ActivityDetail";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { ProjectCreateRequestDTO } from "../../../../models/api/projects/ProjectCreateRequestDTO";
import { ProjectDetails } from "../../../../models/projects/ProjectDetails";
import { SecondaryButton } from "../../../../components/buttons/SecondaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

const addIcon: IIconProps = { iconName: "Add" };
const deleteIcon: IIconProps = { iconName: "Delete" };

interface IAddProjectProps {
  onAddProjectCompleted?: () => void;
}

const getErrorMessage = (value: string): string => {
  var trimmed = value.trim();
  return trimmed.length === 0 ? translate("COMMON.RequiredField") : "";
};

var projectsService = new ProjectsService();

export function AddProjectPopup(props: IAddProjectProps) {
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const windowResize = useWindowResize();

  const [code, setCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isProjectPublic, setIsProjectPublic] = useState<boolean>(false);
  const [client, setClient] = useState<string>("");
  const [activityCode, setActivityCode] = useState<string>("");
  const [activityName, setActivityName] = useState<string>("");
  const [isActivityPublic, setIsActivityPublic] = useState<boolean>(false);
  const [activities, setActivities] = useState<ActivityDetail[]>([]);

  const [isAddActivityDisabled, setIsAddActivityDisabled] = useState<boolean>(true);

  const [submitButtonSpinner, setSubmitButtonSpinner] = useState<boolean>(false);
  const [isAddProjectDisabled, setIsAddProjectDisabled] = useState<boolean>(true);
  const [showPrimaryMessageBar, setShowMessageBar] = useState<boolean>(false);
  const [errorMessage, setMessageError] = useState<string>("");

  useEffect(() => {
    if (code.trim() && name.trim()) setIsAddProjectDisabled(false);
    else setIsAddProjectDisabled(true);
    if (activityCode.trim() && activityName.trim()) {
      setIsAddActivityDisabled(false);
    } else {
      setIsAddActivityDisabled(true);
    }
  }, [code, name, activityCode, activityName]);

  const onClickAddNewProject = useCallback(() => {
    setShowMessageBar(false);
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);

    //Improve: ProjectDetails. No service, mapear para ProjectCreateRequestDTO
    var newProject: ProjectDetails = {
      activities: activities,
      client: client,
      code: code,
      name: name,
      id: 0,
      isDisabled: false,
      isPublic: isProjectPublic ? isProjectPublic : false,
    };

    projectsService
      .createProject(newProject)
      .then((_) => {
        closeOverlaySpinner();
        setIsAddProjectDisabled(false);
        props.onAddProjectCompleted?.();
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("PROJECTS.AddProjectSuccessfully")}</p>
          </FeedbackPopup>
        );
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          if (error.response?.data) {
            setShowMessageBar(true);
            closeOverlaySpinner();
            setMessageError(error.response.data);
          }
        } else {
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="error">
              <p>{error.response?.data}</p>
            </FeedbackPopup>
          );
        }
      });
  }, [name, code, client, activities, openPopup, isProjectPublic, closeOverlaySpinner, openOverlaySpinner, props]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        data-test="repeated-code"
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setShowMessageBar(false);
        }}
        dismissButtonAriaLabel="Close"
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setShowMessageBar]);

  const onClickAddActivity = useCallback(() => {
    var activitiesCopy = [...activities];
    var newActivity: ActivityDetail = {
      projectId: "",
      name: activityName,
      isPublic: isActivityPublic,
      activityId: activityCode,
      id: 0,
      isDisabled: false,
    };

    activitiesCopy.push(newActivity);
    setActivities(activitiesCopy);
    setActivityCode("");
    setActivityName("");
  }, [isActivityPublic, activityCode, activityName, setActivities, activities]);

  const onClickRemoveActivity = useCallback(
    (item: ActivityDetail) => {
      const index = activities.findIndex((act) => act === item);
      var activitiesCopy = [...activities];
      if (index > -1) {
        activitiesCopy.splice(index, 1);
        setActivities(activitiesCopy);
      }
    },
    [activities, setActivities]
  );

  const columns: IColumn[] = useMemo(() => {
    if (windowResize > 768) {
      return [
        {
          key: "column1",
          name: translate("COMMON.Code"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "activityId",
          minWidth: 160,
          maxWidth: 200,
          data: "string",
          onRender: (item) => <div className="truncate">{item.activityId}</div>,
        },
        {
          key: "column2",
          name: translate("COMMON.Name"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "activityName",
          minWidth: 200,
          data: "string",
          onRender: (item) => <div className="truncate">{item.name}</div>,
        },
        {
          key: "column3",
          name: "Vis.",
          fieldName: "isPublic",
          minWidth: 40,
          maxWidth: 40,
          isRowHeader: true,
          isResizable: true,
          isIconOnly: false,
          isCollapsible: true,
          data: "string",
          onRender: (item) => {
            return <FontIcon aria-label={item.isPublic ? "Public" : "Private"} iconName={item.isPublic ? "" : "LockSolid"} />;
          },

        },
        {
          key: "column4",
          name: "Remove",
          fieldName: "remove",
          minWidth: 32,
          maxWidth: 32,
          isRowHeader: true,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: isAddActivityDisabled,
          onRender: (item) => <IconButton onClick={() => onClickRemoveActivity(item)} iconProps={deleteIcon} />,
        },
      ];
    } else {
      return [
        {
          key: "column1",
          name: translate("COMMON.Code"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "activityId",
          minWidth: 140,
          data: "string",
          onRender: (item) => (
            <div className="activity-info truncate">
              <div className="activity-name">{item.name}</div>
              <div className="activity-code">{item.activityId}</div>
            </div>
          ),
        },
        {
          key: "column2",
          name: "Remove",
          fieldName: "remove",
          minWidth: 32,
          maxWidth: 32,
          isRowHeader: true,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: isAddActivityDisabled,
          onRender: (item) => <IconButton onClick={() => onClickRemoveActivity(item)} iconProps={deleteIcon} />,
        },
      ];
    }
  }, [onClickRemoveActivity, isAddActivityDisabled, windowResize, translate]);

  return (
    <div className="add-project-popup">
      <CardHeader headerTitle={translate("PROJECTS.AddProject")} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <div className="add-project">
          <Toggle
            label={translate("COMMON.Visibility")}
            onText={translate("COMMON.Public")}
            offText={translate("COMMON.Private")}
            onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
              setIsProjectPublic(checked as boolean);
            }}
          />
          <TextField
            onChange={(e, input) => {
              var newString = input?.trim();
              setCode(newString || "");
            }}
            label={translate("COMMON.Code")}
            placeholder="Ex: ENX"
            required
            onGetErrorMessage={getErrorMessage}
            validateOnLoad={false}
          />
          <TextField
            onChange={(e, input) => {
              var newString = input?.trim();
              setName(newString || "");
            }}
            label={translate("COMMON.Name")}
            placeholder="Ex: Euronext"
            required
            onGetErrorMessage={getErrorMessage}
            validateOnLoad={false}
          />
          <TextField
            onChange={(e, input) => {
              var newString = input?.trim();
              setClient(newString || "");
            }}
            label={translate("COMMON.Client")}
            placeholder="Ex: Euronext"
            validateOnLoad={false}
          />
        </div>

        <div className="add-activity">
          <h2>{translate("ACTIVITIES.AddActivity")}</h2>
          <div className="add-activity-inputs">
            <Toggle
              data-test={"turn-activity-public"}
              label={translate("COMMON.Visibility")}
              onText={translate("COMMON.Public")}
              offText={translate("COMMON.Private")}
              onChange={(ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                setIsActivityPublic(checked as boolean);
              }}
            />
            <TextField
              onChange={(e) => {
                setActivityCode(e.currentTarget.value);
              }}
              label={translate("COMMON.Code")}
              placeholder="Ex: DEV"
              value={activityCode}
              validateOnLoad={false}
            />
            <TextField
              onChange={(e) => {
                setActivityName(e.currentTarget.value);
              }}
              label={translate("COMMON.Name")}
              placeholder="Ex: Development"
              value={activityName}
              validateOnLoad={false}
            />
            <SecondaryButton dataTest={"Add-act-btn"} isDisabled={isAddActivityDisabled} onClick={onClickAddActivity} text={translate("ACTIVITIES.AddActivity")} icon={addIcon} />
          </div>
          <DetailsList
            className="project-hours-list"
            items={activities}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
          />
          {activities.length === 0 ? <div className="empty">{translate("ACTIVITIES.EMPTYLIST.Message")}</div> : null}
        </div>
      </div>
      {showPrimaryMessageBar ? errorMessageBar() : null}
      <div className="action-btns">
        <TertiaryButton isDisabled={submitButtonSpinner} text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
        <PrimaryButton
          dataTest="add-prj-btn"
          text={translate("ACTIONS.Add")}
          isDisabled={isAddProjectDisabled}
          onClick={() => {
            onClickAddNewProject();
          }}
        >
          {" "}
          {submitButtonSpinner ? <Spinner size={SpinnerSize.xSmall} /> : null}
        </PrimaryButton>
      </div>
    </div>
  );
}
