import { useContext, useMemo } from "react";

import { IdentitySvcContext } from '../IdentityContext';
import { N4UPermissions } from "../Permissions";

export function useSetPermissions() {

    const context = useContext(IdentitySvcContext);
    if (!context) {
        throw new Error("usePermissions must be used within IdentityContextProvider");
    }
    return context.setPermissions;
}



export function usePermission(permission:  N4UPermissions) {
    const context = useContext(IdentitySvcContext);
    if (!context) {
        throw new Error("usePermissions must be used within IdentityContextProvider");
    }
    const isAllowed = useMemo(() => {
        return (context.permissions.filter(a => a.id === ("" + permission)).length > 0)
    }, [context.permissions, permission]);

    return isAllowed;
}
