import React from "react";
import { Overlay } from "../overlay/Overlay";
import { Popup } from "./Popup";

interface IPopupManagementProps {
  children: React.ReactNode;
}

export function PopupManagement(props: IPopupManagementProps) {
  return (
    <Overlay>
      <div className="popup-center">
        <Popup>{props.children}</Popup>
      </div>
    </Overlay>
  );
}
