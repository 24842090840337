import "./PersonalInfo.css";

import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner, SpinnerSize, getInitials } from "@fluentui/react";
import { UserProfile, UsersService } from "../../../services/UsersService";
import { useShowSpinner, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { CardHeader } from "../../../components/cards/CardHeader";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { InfoBlock } from "../../../components/cards/InfoBlock";
import { translate } from '../../../infrastructure/i18n/InternationalizationService';
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";

var userService = new UsersService();

const imageOnErrorHandler = () => {
  //Could use a method of this kind to choose what to do if the photo is empty
  //Replacing for some default or not.
};

interface Props {
  email?: string | null;
}

export function PersonalInfo(props: Props) {
  const { showSpinner, setShowSpinner } = useShowSpinner();
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const isPageActive = useActiveFeatures([FeatureFlags.EMPLOYEE_PROFILE]);
  const windowResize = useWindowResize();
  const navigate = useNavigate();
  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    setShowSpinner(true);
    userService.getProfile(props.email || "").then((res) => {
      setUserProfile(res);
      setShowSpinner(false);
    });
  }, []);

  const addressTreatment = useMemo(() => {
    if (userProfile?.district?.length !== 0 || userProfile?.postalCode?.length !== 0) {
      var res = ", " + userProfile?.district?.toString() + " " + userProfile?.postalCode?.toString();
      return res;
    }
    if (!userProfile.district && !userProfile.postalCode && !userProfile.city && !userProfile.streetAddress) {
      return "-";
    }
    return "";
  }, [userProfile, setUserProfile]);

  return (
    <CardLayout classNames="personal-info">
      <CardHeader headerTitle={translate("PROFILE.PersonalInfo")} setDisplayState={() => {}} />
      {showSpinner ? (
        <Spinner size={SpinnerSize.large} label={translate("LOADING.LoadingDataPleaseWait")} />
      ) : (
        <div className="wrapper">
          <div className="primary-info">
            <Persona
              size={windowResize > 425 ? PersonaSize.size120 : PersonaSize.size72}
              imageAlt="Account Avatar"
              className="avatar"
              imageUrl={userProfile?.url}
              imageInitials={getInitials(userProfile?.displayName, false, true)}
              onError={() => imageOnErrorHandler()}
            />
            <div>
              <h1>{userProfile?.displayName}</h1>
              <div className="role">{userProfile?.jobTitle ? userProfile?.jobTitle : null}</div>
            </div>
          </div>
          <div className="secondary-info">
            <div>
              <InfoBlock label={translate("PROFILE.Email")} text={userProfile?.email!} />
              <InfoBlock label={translate("PROFILE.Phone")} text={userProfile?.mobilePhone ? userProfile.mobilePhone : "-"} />
            </div>
            <div>
              <InfoBlock label={translate("PROFILE.Address")} text={`${userProfile?.streetAddress} ${userProfile?.city}${addressTreatment}`} />
              <InfoBlock label={translate("PROFILE.Nationality")} text={userProfile?.country ? userProfile?.country : "-"} />
            </div>
          </div>
        </div>
      )}
    </CardLayout>
  );
}
