import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./EditEquipmentPanel.css";
import {
  BaseButton,
  Button,
  DatePicker,
  defaultDatePickerStrings,
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  IIconProps,
  Label,
  MessageBar,
  MessageBarType,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { ReactComponent as IconUpload } from "../../../../assets/upload-icon.svg";
import { useCloseOverlaySpinner, useClosePanel, useClosePopup, useOverlaySpinner, usePopup, useShowSpinner } from "../../../../infrastructure/ui/UIServices";
import { IInfoPanelPhoto } from "./InfoPanel";
import dayjs from "dayjs";
import { DeleteImagePopup } from "../popups/DeleteImagePopup";
import { ConfirmationPopup } from "../popups/ConfirmationPopup";
import {
  EquipmentsService,
  IEquipmentType,
  GetSingleEquipmentInfoResponseDTO,
  UpdateEquipmentInputsRequestDTO,
  IEquipmentStatus,
} from "../../../../services/EquipmentsService";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";

import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

const uploadIcon: IIconProps = { iconName: "Upload" };
const deleteIcon: IIconProps = { iconName: "Delete" };

const equipmentsService = new EquipmentsService();
interface EditEquipmentProps {
  equipmentId: number;
  onActionCompleted: () => void;
}

function mapEquipmentTypeToIDropdownOptions(equipmentsTypeDB: IEquipmentType[]) {
  var optionsMapped = equipmentsTypeDB.map((obj) => {
    var opMapped: IDropdownOption = {
      key: obj.id,
      text: obj.description,
    };
    return opMapped;
  });
  return optionsMapped;
}

function mapEquipmentStatusToIDropdownOptions(equipmentsStatusDB: IEquipmentStatus[]) {
  var optionsMapped = equipmentsStatusDB
    .filter((obj) => obj.code !== "DEACTIVATED")
    .map((obj) => {
      var opMapped: IDropdownOption = {
        key: obj.id,
        text: obj.description,
      };
      return opMapped;
    });
  return optionsMapped;
}

//To show message bellow any input if empty.
const getErrorMessage = (value: string): string => {
  return value.length == 0 ? translate("COMMON.RequiredField") : "";
};

export function EditEquipmentPanel(props: EditEquipmentProps) {
  const closePanel = useClosePanel();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const { showSpinner, setShowSpinner } = useShowSpinner();
  const [spinner, setSpinner] = useState<boolean>(false);
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [brand, setBrand] = useState<string>();
  const [model, setModel] = useState<string>();
  const [reference, setReference] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [serialNumber, setSerialNumber] = useState<string>();
  const [equipmentTypeId, setEquipmentTypeId] = useState<number>();
  const [equipmentStatusId, setEquipmentStatusId] = useState<number>();
  const [purchaseDate, setPurchaseDate] = useState<Date | undefined>();
  const [equipmentPhotos, setEquipmentPhotos] = useState<IInfoPanelPhoto[]>([]);
  const [mappedEquipmentsType, setMappedEquipmentsType] = useState<IDropdownOption[]>([]);
  const [mappedEquipmentStatus, setMappedEquipmentsStatus] = useState<IDropdownOption[]>([]);

  /*   const [disableSave, setDisableSave] = useState<boolean>(false); */
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState<boolean>(true);

  //Responses from backend.
  const [error, setError] = useState<boolean>(true);
  const [messageError, setMessageError] = useState<string>("");
  const [shimmeredDetailsList, setShimmeredDetailsList] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    if (props.equipmentId) {
      Promise.all([equipmentsService.getAllEquipmentTypes(), equipmentsService.getEquipmentByID(props.equipmentId), equipmentsService.getAllEquipmentStatus()])
        .then((res) => {
          setMappedEquipmentsType(mapEquipmentTypeToIDropdownOptions(res[0]));
          setMappedEquipmentsStatus(mapEquipmentStatusToIDropdownOptions(res[2]));
          mapEquipmentInfoDTOToEachState(res[1]);
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(true);
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    } else {
      setShowSpinner(false);
      openPopup(
        <FeedbackPopup type="error">
          <p>{translate("EQUIPMENTS.ERRORS.InvalidId")}: ({props.equipmentId}).</p>
        </FeedbackPopup>
      );
    }
  }, []);

  useEffect(() => {
    if (reference && description && brand && serialNumber && equipmentTypeId !== undefined) setIsEditButtonDisabled(false);
    else setIsEditButtonDisabled(true);
  }, [reference, description, brand, serialNumber, equipmentTypeId, purchaseDate]);

  const mapEquipmentInfoDTOToEachState = useCallback(
    (originalData: GetSingleEquipmentInfoResponseDTO) => {
      if (!originalData) {
        return;
      }
      setBrand(originalData.brand);
      setDescription(originalData.description);
      setEquipmentTypeId(originalData.type.id);
      setEquipmentStatusId(originalData.status.id);
      setModel(originalData.model || "");
      setReference(originalData.reference);
      setSerialNumber(originalData.serialNumber);
      setEquipmentPhotos(originalData.photos);

      if (originalData.purchaseDate) {
        try {
          var newDate: Date = new Date(originalData.purchaseDate);
          setPurchaseDate(newDate);
        } catch {
          // todo !!!!!!!!!!!
        }
      }
    },
    [setBrand, setDescription, setEquipmentTypeId, setEquipmentStatusId, setModel, setReference, setSerialNumber, setEquipmentPhotos, setPurchaseDate, openPopup]
  );

  const onChangeEquipmentType = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setEquipmentTypeId(item.key as number);
    },
    [setEquipmentTypeId]
  );

  const onChangeEquipmentStatus = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setEquipmentStatusId(item.key as number);
    },
    [setEquipmentStatusId]
  );

  //Save photos, calling the service to store the images in the SP attachmentss
  const onChangePhotos = useCallback(
    (event: any) => {
      setShimmeredDetailsList(true);
      if (!props.equipmentId) {
        return;
      }

      var files: File[] = event.target.files;
      var filesCopy: File[] = [];

      // Check if on the original images array, already contains the new images coming from the event
      for (var i = 0; i < files.length; i++) {
        var selectedPhoto = equipmentPhotos.find((p) => p.name === files[i].name);
        if (!selectedPhoto) {
          filesCopy.push(files[i]);
        }
      }

      if (filesCopy.length > 0) {
        equipmentsService
          .addEquipmentPhoto(props.equipmentId, filesCopy)
          .then((_) => {
            equipmentsService
              .getEquipmentPhotosById(props.equipmentId)
              .then((res) => {
                setEquipmentPhotos(res);
                setShimmeredDetailsList(false);
              })
              .catch((_) => {
                setError(false);
                setShimmeredDetailsList(false);
              });
          })
          .catch((_) => {
            setShimmeredDetailsList(false);
            setError(false);
          });
      }
    },
    [props.equipmentId, setShimmeredDetailsList, equipmentPhotos, setEquipmentPhotos, setShimmeredDetailsList, setError]
  );

  const deletePhoto = useCallback(
    (item: IInfoPanelPhoto) => {
      closePopup();
      setShimmeredDetailsList(true);

      if (props.equipmentId) {
        equipmentsService
          .deleteEquipmentPhoto(props.equipmentId, item.name)
          .then((_) => {
            equipmentsService
              .getEquipmentPhotosById(props.equipmentId)
              .then((res) => {
                setEquipmentPhotos(res);
                setShimmeredDetailsList(false);
              })
              .catch((error) => {
                setError(false);
                setShimmeredDetailsList(false);
                openPopup(
                  <FeedbackPopup type="error">
                    <p>{error}</p>
                  </FeedbackPopup>
                );
              });
          })
          .catch((error) => {
            setShimmeredDetailsList(false);
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
            setError(false);
          });
      } else {
        setShimmeredDetailsList(false);
        openPopup(
          <FeedbackPopup type="error">
            <p>{translate("EQUIPMENTS.ERRORS.InvalidId")}: ({props.equipmentId}).</p>
          </FeedbackPopup>
        );
      }
    },
    [closePopup, openPopup, setShimmeredDetailsList, setError, setEquipmentPhotos, props.equipmentId]
  );

  //Message bar for errors or success operations.
  const closeMessageBar = useCallback(
    (event: React.MouseEvent<HTMLElement | BaseButton | Button, MouseEvent> | undefined) => {
      setError(true);
    },
    [setError]
  );

  //Validation before call the services to edit info.
  const onClickSave = useCallback(() => {
    //Get ride of TimeZone: without this, if you select day 15, the request goes with day: 14 23h:00.
    //Better to find a proper solution
    var offsetDate: Date | undefined;
    if (purchaseDate) {
      offsetDate = new Date(purchaseDate.getTime() - purchaseDate.getTimezoneOffset() * 60000);
    }
    var requestDTO: UpdateEquipmentInputsRequestDTO = {
      reference: reference,
      brand: brand,
      description: description,
      equipmentTypeId: equipmentTypeId,
      model: model,
      purchaseDate: offsetDate ? offsetDate.toISOString() : undefined,
      serialNumber: serialNumber,
      equipmentStatusId: equipmentStatusId,
    };

    if (props.equipmentId && props.equipmentId > 0) {
      openOverlaySpinner(<Spinner size={SpinnerSize.large} />);

      equipmentsService
        .updateEquipmentInputs(props.equipmentId, requestDTO)
        .then((_) => {
          closeOverlaySpinner();
          closePanel();
          props.onActionCompleted();
          setIsEditButtonDisabled(false);
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("EQUIPMENTS.EDITPANEL.EditWithSuccess")}</p>
            </FeedbackPopup>
          );
        })
        .catch((error) => {
          closeOverlaySpinner();
          closePanel();
          openPopup(<FeedbackPopup type="error">{error}</FeedbackPopup>);
        });
    }
    setMessageError(translate("EQUIPMENTS.ERRORS.InvalidId"));
  }, [
    brand,
    setError,
    openPopup,
    reference,
    closePanel,
    setSpinner,
    description,
    serialNumber,
    purchaseDate,
    setShowSpinner,
    openOverlaySpinner,
    setMessageError,
    equipmentTypeId,
    equipmentStatusId,
    props.equipmentId,
    closeOverlaySpinner,
    props.onActionCompleted,
    setIsEditButtonDisabled,
  ]);

  //Details list for equipment's images
  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "column1",
        name: "Image",
        isRowHeader: true,
        isResizable: true,
        fieldName: "image",
        minWidth: 40,
        maxWidth: 40,
        data: "string",
        onRender: (item) => {
          let imgWrapper = {
            backgroundImage: "url(" + item.url + ")",
          };
          return <div className="img-wrapper" style={imgWrapper}></div>;
        },
      },
      {
        key: "column2",
        name: "Name",
        isRowHeader: true,
        isResizable: true,
        fieldName: "name",
        minWidth: 100,
        maxWidth: 250,
        data: "string",
        onRender: (item) => <span> {item.name} </span>,
      },
      {
        key: "column3",
        name: "Remove",
        fieldName: "remove",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        onRender: (item) => <IconButton iconProps={deleteIcon} onClick={() => openPopup(<DeleteImagePopup onClicked={() => deletePhoto(item)} />)} />,
      },
    ],
    [deletePhoto, openPopup, equipmentPhotos]
  );

  return (
    <div className="edit-equipment-content">
      <CardHeader headerTitle={translate("EQUIPMENTS.EDITPANEL.Title")} icon={true} setDisplayState={(state: boolean) => closePanel()}></CardHeader>
      <div className="side-panel-form">
        {showSpinner ? (
          <Spinner size={SpinnerSize.large} label={translate("LOADING.PleaseWait")} />
        ) : (
          <>
            <Dropdown
              placeholder="Select Type"
              label={translate("COMMON.Type")}
              selectedKey={equipmentTypeId}
              onChanged={onChangeEquipmentType}
              options={mappedEquipmentsType || []}
              required={true}
            />
            <TextField
              label={translate("EQUIPMENTS.COMMON.Ref")}
              placeholder="Ex: LP01"
              value={reference}
              onChange={(ev, response) => {
                setReference(response || "");
              }}
              required
              onGetErrorMessage={getErrorMessage}
              validateOnLoad={false}
            />
            <TextField
              label={translate("EQUIPMENTS.COMMON.SerialNumber")}
              placeholder="Ex: Mc732xvdsPro"
              value={serialNumber}
              onChange={(ev, response) => {
                setSerialNumber(response || "");
              }}
              required
              onGetErrorMessage={getErrorMessage}
              validateOnLoad={false}
            />
            <TextField
              label={translate("EQUIPMENTS.COMMON.Brand")}
              placeholder="Ex: Apple"
              value={brand}
              onChange={(ev, response) => {
                setBrand(response || "");
              }}
              required
              onGetErrorMessage={getErrorMessage}
              validateOnLoad={false}
            />
            <TextField
              label={translate("EQUIPMENTS.COMMON.Model")}
              placeholder="Ex: Macbook Pro"
              value={model}
              onChange={(ev, response) => {
                setModel(response || "");
              }}
            />
            <Dropdown
              placeholder="Select Status"
              label={translate("EQUIPMENTS.COMMON.Status")}
              selectedKey={equipmentStatusId}
              onChanged={onChangeEquipmentStatus}
              options={mappedEquipmentStatus || []}
              required={true}
            />
            <TextField
              label={translate("EQUIPMENTS.COMMON.Description")}
              placeholder="Ex: Lorem Ipsum"
              multiline
              rows={3}
              value={description}
              onChange={(ev, response) => {
                setDescription(response || "");
              }}
              required
              onGetErrorMessage={getErrorMessage}
              validateOnLoad={false}
            />
            <DatePicker
              className="datepicker"
              maxDate={new Date()}
              minDate={new Date(2019, 0, 1)}
              placeholder="Select Date"
              label={translate("EQUIPMENTS.COMMON.Date")}
              onSelectDate={setPurchaseDate as (date: Date | null | undefined) => void}
              strings={defaultDatePickerStrings}
              formatDate={(date) => dayjs(date).format("DD-MM-YYYY")}
              value={purchaseDate}
              defaultValue={0}
            />
            <Label>{translate("EQUIPMENTS.COMMON.Images")}</Label>
            <div className="upload-btn-wrapper">
              <input type="file" name="file-1[]" id="file-1" className="inputfile" accept="image/png, image/jpeg" onChange={onChangePhotos} multiple={true} />
              <Label className="upload-btn-label" htmlFor="file-1">
                <IconUpload />
                <span>{translate("EQUIPMENTS.COMMON.UploadImages")}</span>
              </Label>
            </div>

            {equipmentPhotos.length < 1 ? (
              <div className="photos-list-empty">{translate("EQUIPMENTS.COMMON.NoImagesUploaded")}</div>
            ) : (
              <ShimmeredDetailsList
                className="photos-list"
                items={equipmentPhotos}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
                enableShimmer={shimmeredDetailsList}
              />
            )}
            {!error ? (
              <MessageBar
                role="alert"
                messageBarType={MessageBarType.error}
                onDismiss={(event) => {
                  closeMessageBar(event);
                }}
                dismissButtonAriaLabel="Close">
                <b>{messageError}</b>
              </MessageBar>
            ) : null}
            <div className="action-btns">
              <PrimaryButton text={translate("ACTIONS.Save")} onClick={onClickSave} isDisabled={isEditButtonDisabled}>
                {spinner ? <Spinner size={SpinnerSize.small} /> : null}
              </PrimaryButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
