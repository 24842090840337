import "./EquipmentListPage.css";

import {
  DetailsListLayoutMode,
  DetailsRow,
  FontIcon,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  IIconProps,
  IOverflowSetItemProps,
  IconButton,
  Link,
  OverflowSet,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  TooltipDelay,
  TooltipHost,
  getInitials,
} from "@fluentui/react";
import {
  EquipmentsFiltersDTO,
  EquipmentsService,
  GetEquipmentsAndMostRecentAllocationResponseDTO,
  UpdateEquipmentInputsRequestDTO,
} from "../../../services/EquipmentsService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePanel, usePopup } from "../../../infrastructure/ui/UIServices";

import { AddEquipmentPanel } from "./panels/AddEquipmentPanel";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { EditEquipmentPanel } from "./panels/EditEquipmentPanel";
import { EmptyResult } from "../../../components/images/EmptyResult";
import { EquipmentsFiltersPanel } from "./panels/EquipmentsFiltersPanel";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { ReactComponent as IconKeyboard } from "../../../assets/keyboard-icon.svg";
import { ReactComponent as IconLaptop } from "../../../assets/laptop-icon.svg";
import { ReactComponent as IconMouse } from "../../../assets/mouse-icon.svg";
import { ReactComponent as IconOtherEquipments } from "../../../assets/other-devices-icon.svg";
import { ReactComponent as IconScreen } from "../../../assets/screen-icon.svg";
import { InfoPanel } from "./panels/InfoPanel";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import React from "react";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import dayjs from "dayjs";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { useUpdatePageData } from "../../../infrastructure/navigation/hooks/useUpdatePageData";

//Icons
const filtersIcon: IIconProps = { iconName: "Filter" };
const addIcon: IIconProps = { iconName: "Add" };
const iconProps = { iconName: "Search" };

//Mapper equipment type to a icon
function equipmentTypeIconMapper(item: GetEquipmentsAndMostRecentAllocationResponseDTO) {
  switch (item.type.code) {
    case "PC":
      return <IconLaptop />;
    case "SCREEN":
      return <IconScreen />;
    case "MOUSE":
      return <IconMouse />;
    case "KEYBOARD":
      return <IconKeyboard />;
    case "OTHERS":
      return <IconOtherEquipments />;
  }
}

function columnKeyToColumnName(key: string) {
  switch (key) {
    case "column1":
      return "type";
    case "column2":
      return "reference";
    case "column3":
      return "brand";
    case "column4":
      return "model";
    case "column5":
      return "allocatedTo";
    case "column6":
      return "status";
  }
}

//On render overflow menu items
const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <Link role="menuitem" onClick={item.onClick}>
      {item.name}
    </Link>
  );
};

//On render overflow menu
const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return <IconButton role="menuitem" title="More options" menuIconProps={{ iconName: "More" }} menuProps={{ items: overflowItems! }} />;
};

const equipmentsService = new EquipmentsService();

interface IOrderInfo {
  columnName: string;
  direction: "ASC" | "DESC";
}

export function EquipmentListPage() {
  /*   const addToRouteData = useUpdatePageData();  */
  const openPanel = usePanel();
  const openPopup = usePopup();
  const navigate = useNavigate();

  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_EQUIPMENTS_LIST]);
  if (!isPageActive) {
    navigate("/error");
  }

  const closePopup = useClosePopup();
  const updatePageTitle = usePageTitle();

  const [timer, setTimer] = useState<any>();

  const [searchFor, setSearchFor] = useState<string>("");
  const [filtersCounter, setFiltersCounter] = useState<number>(0);
  const [employeeFilter, setEmployeeFilter] = useState<string>("");
  const [statusIdFilter, setStatusIdFilter] = useState<number[]>([]);
  const [needsRepairFilter, setNeedsRepairFilter] = useState<number>();
  const [inRepairFilter, setInRepairFilter] = useState<number>();
  const [availableMonitorsFilter, setAvailableMonitorsFilter] = useState<boolean>(false);
  const [availableComputersFilter, setAvailableComputersFilter] = useState<boolean>(false);

  const [shimmeredDetailsList, setShimmeredDetailsList] = useState(true);
  const [equipmentTypesFilter, setEquipmentTypesFilter] = useState<number[]>([]);
  const [requestFilters, setRequestFilters] = useState<EquipmentsFiltersDTO>({});
  const [equipmentStatusesFilter, setEquipmentStatusesFilter] = useState<number[]>([]);
  const [equipmentDeactivatedStatusId, setEquipmentDeactivatedStatusId] = useState<number>();
  const [orderInfo, setOrderInfo] = useState<IOrderInfo | null>({ columnName: "date", direction: "DESC" });
  const [equipmentList, setEquipmentList] = useState<GetEquipmentsAndMostRecentAllocationResponseDTO[]>([]);

  useEffect(() => {
    updatePageTitle(translate("MENU.Equipments"));
    getEquipmentsList();
    equipmentsService
      .getAllEquipmentStatus()
      .then((res) => {
        var deactivatedStatus = res.find((status) => {
          return status.code === "DEACTIVATED";
        });
        setEquipmentDeactivatedStatusId(deactivatedStatus?.id);
      })
      .catch((error) => {
        openPopup(
          <FeedbackPopup type="error">
            <p>{error}</p>
          </FeedbackPopup>
        );
      });
  }, []);

  useEffect(() => {
    getEquipmentsList();
  }, [setRequestFilters, requestFilters]);

  const getEquipmentsList = useCallback(() => {
    setShimmeredDetailsList(true);
    if (requestFilters.orderBy) {
      var columnName = columnKeyToColumnName(requestFilters.orderBy);
      requestFilters.orderBy = columnName;
    }
    equipmentsService
      .getEquipmentsList(requestFilters)
      .then((res) => {
        setEquipmentList(res);
        setShimmeredDetailsList(false);
      })
      .catch((error) => {
        setShimmeredDetailsList(false);
        openPopup(
          <FeedbackPopup type="error">
            <p>{error}</p>
          </FeedbackPopup>
        );
      });
  }, [setShimmeredDetailsList, requestFilters, setRequestFilters, setEquipmentList]);

  const filtersByFilterPanel = useCallback(
    (request: EquipmentsFiltersDTO) => {
      setNeedsRepairFilter(request.needsRepairId);
      setInRepairFilter(request.inRepairId);
      setEmployeeFilter(request.allocatedTo || "");
      setStatusIdFilter(request.allocationStatusesId || []);
      setEquipmentTypesFilter(request.equipmentTypesId || []);
      setEquipmentStatusesFilter(request.equipmentStatusesId || []);
      setAvailableComputersFilter(request.availableComputers || false);
      setAvailableMonitorsFilter(request.availableMonitors || false);

      setRequestFilters(request);

      let counter = 0;
      if (request.allocatedTo) counter++;
      if (request.allocationStatusesId) counter += request.allocationStatusesId.length;
      if (request.equipmentTypesId) counter += request.equipmentTypesId.length;
      if (request.equipmentStatusesId) counter += request.equipmentStatusesId.length;
      if (request.availableComputers) counter++;
      if (request.availableMonitors) counter++;

      setFiltersCounter(counter);
    },
    [
      setStatusIdFilter,
      statusIdFilter,
      setEmployeeFilter,
      employeeFilter,
      setEquipmentStatusesFilter,
      equipmentStatusesFilter,
      setEquipmentTypesFilter,
      equipmentTypesFilter,
      setFiltersCounter,
      filtersCounter,
      setRequestFilters,
      requestFilters,
      searchFor,
      setSearchFor,
      orderInfo,
      setOrderInfo,
      inRepairFilter,
      setInRepairFilter,
      needsRepairFilter,
      setNeedsRepairFilter,
      availableComputersFilter,
      setAvailableComputersFilter,
      setAvailableMonitorsFilter,
      availableMonitorsFilter,
    ]
  );

  useEffect(() => {
    setShimmeredDetailsList(true);
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {
        var requestCopy = { ...requestFilters };
        requestCopy.searchFor = searchFor;
        setRequestFilters(requestCopy);
      }
    }, 700);
    setTimer(responseTimeOut);
  }, [searchFor]);

  const handleColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
      let newOrderInfo: IOrderInfo | null = {
        columnName: column.key,
        direction: "DESC",
      };

      if (orderInfo && orderInfo.columnName === column.key) {
        if (orderInfo.direction === "DESC") {
          newOrderInfo.direction = "ASC";
        } else {
          newOrderInfo = null;
        }
      }

      setOrderInfo(newOrderInfo);
      var requestCopy = { ...requestFilters };
      requestCopy.orderDirection = newOrderInfo?.direction;
      requestCopy.orderBy = newOrderInfo?.columnName;
      setRequestFilters(requestCopy);
    },
    [orderInfo, setOrderInfo, setRequestFilters, requestFilters]
  );

  const onClickDeactivateEquipment = useCallback(
    (equipmentId: number) => {
      if (equipmentDeactivatedStatusId) {
        var requestDTO: UpdateEquipmentInputsRequestDTO = {};
        requestDTO.equipmentStatusId = equipmentDeactivatedStatusId;

        equipmentsService
          .updateEquipmentInputs(equipmentId, requestDTO)
          .then((_) => {
            getEquipmentsList();
            openPopup(
              <FeedbackPopup type="success">
                <p>{translate("EQUIPMENTS.DeactivatedWithSucess")}</p>
              </FeedbackPopup>
            );
          })
          .catch((error) => {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          });
      }
    },
    [openPopup, getEquipmentsList, equipmentDeactivatedStatusId]
  );

  var columns: IColumn[] = useMemo(
    () => [
      {
        key: "column1",
        name: translate("COMMON.Type"),
        fieldName: "type",
        minWidth: 40,
        maxWidth: 40,
        isRowHeader: true,
        isCollapsible: true,
        isSorted: orderInfo?.columnName === "column1",
        isSortedDescending: orderInfo?.columnName === "column1" && orderInfo?.direction === "DESC",
        onColumnClick: handleColumnClick,
        onRender: (item) => {
          if (item.reference)
            return (
              <div className={item.status.code === "DEACTIVATED" ? "device-icon-disabled" : "device-icon"}>{item.type.code ? equipmentTypeIconMapper(item) : ""}</div>
            );
        },
        data: "string",
      },
      {
        key: "column2",
        name: translate("EQUIPMENTS.COMMON.Ref"),
        fieldName: "reference",
        minWidth: 140,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        isSorted: orderInfo?.columnName === "column2",
        isSortedDescending: orderInfo?.columnName === "column2" && orderInfo?.direction === "DESC",
        data: "string",
        onColumnClick: handleColumnClick,
        onRender: (item) => {
          if (item.reference) return <div className={"truncate" + (item.status.code === "DEACTIVATED" ? " disabled" : "")}>{item.reference ? item.reference : ""}</div>;
        },
      },
      {
        key: "column3",
        name: translate("EQUIPMENTS.COMMON.Brand"),
        fieldName: "brand",
        minWidth: 90,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        isSorted: orderInfo?.columnName === "column3",
        isSortedDescending: orderInfo?.columnName === "column3" && orderInfo?.direction === "DESC",
        data: "string",
        onColumnClick: handleColumnClick,
        onRender: (item) => {
          if (item.status.code) return <div className={"truncate" + (item.status.code === "DEACTIVATED" ? " disabled" : "")}>{item.brand ? item.brand : ""}</div>;
        },
      },
      {
        key: "column4",
        name: translate("EQUIPMENTS.COMMON.Model"),
        fieldName: "model",
        minWidth: 140,
        maxWidth: 300,
        isResizable: true,
        isRowHeader: true,
        isCollapsible: true,
        isSorted: orderInfo?.columnName === "column4",
        isSortedDescending: orderInfo?.columnName === "column4" && orderInfo?.direction === "DESC",
        data: "string",
        onColumnClick: handleColumnClick,
        onRender: (item) => {
          if (item.reference) return <div className={"truncate" + (item.status.code === "DEACTIVATED" ? " disabled" : "")}>{item.model ? item.model : ""}</div>;
        },
      },
      {
        key: "column5",
        name: translate("COMMON.AllocatedTo"),
        fieldName: "allocatedTo",
        minWidth: 170,
        maxWidth: 320,
        isResizable: true,
        isRowHeader: true,
        isCollapsible: true,
        isSorted: orderInfo?.columnName === "column5",
        isSortedDescending: orderInfo?.columnName === "column5" && orderInfo?.direction === "DESC",
        onColumnClick: handleColumnClick,
        onRender: (item) => (
          <div className="truncate">
            <div className="allocation-employee">
              {item.employee.displayName ? (
                <div className={"truncate" + (item.status.code === "DEACTIVATED" ? " disabled" : "")}>
                  <Persona
                    text={item.employee.displayName}
                    imageInitials={getInitials(item.employee.displayName, false, true)}
                    imageUrl={item.employee.url}
                    size={PersonaSize.size32}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ),
        data: "string",
      },
      {
        key: "column6",
        name: translate("COMMON.Date"),
        fieldName: "date",
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        isCollapsible: true,
        data: "datetime",
        onColumnClick: handleColumnClick,
        onRender: (item) => {
          if (item.date) return <div className={"truncate" + (item.status.code === "DEACTIVATED" ? " disabled" : "")}>{dayjs(item.date).format("DD/MM/YYYY")}</div>;
        },
      },
      {
        key: "column7",
        name: translate("COMMON.Status"),
        fieldName: "status",
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
        isRowHeader: true,
        isCollapsible: true,
        isSorted: orderInfo?.columnName === "column7",
        isSortedDescending: orderInfo?.columnName === "column7" && orderInfo?.direction === "DESC",
        onColumnClick: handleColumnClick,
        onRender: (item) => {
          if (item.state)
            return (
              <div className={item.status.code === "DEACTIVATED" ? `device-state-disabled ${item.state.toLowerCase()}` : `device-state ${item.state.toLowerCase()}`}>
                {item.state}
              </div>
            );
        },
        data: "string",
      },
      {
        key: "column8",
        name: "",
        fieldName: "status",
        minWidth: 10,
        maxWidth: 20,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        onRender: (item) => {
          if (item.status.code === "OK") {
            return (
              <>
                <TooltipHost content={translate("EQUIPMENTS.EquipmentStatus") + item.status.description.toLowerCase()} delay={TooltipDelay.medium}>
                  <FontIcon iconName="RedEye" />
                </TooltipHost>
              </>
            );
          } else if (item.status.code === "IN_REPAIR") {
            return (
              <>
                <TooltipHost content={translate("EQUIPMENTS.EquipmentStatus") + item.status.description.toLowerCase()} delay={TooltipDelay.medium}>
                  <FontIcon iconName="Repair" />
                </TooltipHost>
              </>
            );
          } else if (item.status.code === "NEEDS_REPAIR") {
            return (
              <>
                <TooltipHost content={translate("EQUIPMENTS.EquipmentStatus") + item.status.description.toLowerCase()} delay={TooltipDelay.medium}>
                  <FontIcon iconName="Warning" />
                </TooltipHost>
              </>
            );
          } else if (item.status.code === "DEACTIVATED") {
            return (
              <>
                <TooltipHost content={translate("EQUIPMENTS.EquipmentStatus") + item.status.description.toLowerCase()} delay={TooltipDelay.medium}>
                  <FontIcon iconName="Hide" /*Hide*/ className="disabled" />
                </TooltipHost>
              </>
            );
          }
        },
        data: "string",
      },
      {
        className: "overflow-menu",
        key: "column9",
        name: "Overflow Menu",
        fieldName: "overflow",
        minWidth: 25,
        maxWidth: 30,
        isIconOnly: true,
        isCollapsible: false,
        onRender: (item) => (
          <OverflowSet
            aria-label="Basic Menu Example"
            role="menubar"
            items={[]}
            overflowItems={[
              {
                key: "item1",
                name: translate("ACTIONS.Info"),
                onClick: () =>
                  openPanel(
                    <InfoPanel
                      type={item.type}
                      brand={item.brand}
                      model={item.model}
                      photos={item.photos}
                      status={item.status}
                      date={item.purchaseDate}
                      reference={item.reference}
                      description={item.description}
                      serialNumber={item.serialNumber}
                    />
                  ),
              },
              {
                key: "item2",
                name: translate("AREATITLES.Allocations"),
                onClick: () => navigate(item.equipmentId + "/allocations"),
              },
              {
                key: "item3",
                name: translate("ACTIONS.Edit"),
                disabled: item.status.code === "DEACTIVATED" ? true : false,
                onClick: () => openPanel(<EditEquipmentPanel equipmentId={item.equipmentId} onActionCompleted={getEquipmentsList} />),
              },
              {
                key: "item4",
                name: translate("ACTIONS.Deactivate"),
                disabled: item.status.code === "DEACTIVATED" ? true : false,
                onClick: () => {
                  openPopup(
                    <FeedbackPopup
                      type="warning"
                      title={translate("EQUIPMENTS.WARNINGS.ThisEquipmentWillBeDeactivated")}
                      primaryActionLabel={translate("ACTIONS.Deactivate")}
                      primaryActionFunc={() => {
                        onClickDeactivateEquipment(item.equipmentId);
                      }}
                    >
                      <p>{translate("COMMON.PermanentAction")}</p>
                    </FeedbackPopup>
                  );
                },
              },
            ]}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />
        ),
        data: "string",
      },
    ],
    [
      onRenderOverflowButton,
      onClickDeactivateEquipment,
      equipmentTypeIconMapper,
      onRenderItem,
      getEquipmentsList,
      navigate,
      openPanel,
      equipmentList,
      orderInfo,
      handleColumnClick,
    ]
  );

  const onRenderColumnListRow = useCallback(
    (props: any) => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      return <DetailsRow {...props} styles={customStyles} onClick={() => navigate(props.item.equipmentId + "/allocations")} />;
    },
    [navigate]
  );

  return (
    <>
      <CardLayout classNames="list-container">
        <div className="command-bar">
          <div className="filters">
            <SecondaryButton
              additionalClass={"btn-margin-right remove-text"}
              text={translate("EQUIPMENTS.ClickToFilterEquipments")}
              icon={filtersIcon}
              onClick={() =>
                openPanel(
                  <EquipmentsFiltersPanel
                    filters={(response) => {
                      filtersByFilterPanel(response);
                    }}
                    searchFor={searchFor}
                    inRepairFilter={inRepairFilter}
                    needsRepairFilter={needsRepairFilter}
                    statusIdFilter={statusIdFilter}
                    employeeFilter={employeeFilter}
                    equipmentTypes={equipmentTypesFilter}
                    equipmentStatuses={equipmentStatusesFilter}
                    availableComputersFilter={availableComputersFilter}
                    availableMonitorsFilter={availableMonitorsFilter}
                  />
                )
              }
            >
              {filtersCounter === 0 ? null : <div className="filters-counter">{filtersCounter}</div>}
            </SecondaryButton>
            <TextField
              iconProps={iconProps}
              className="no-margin hide-textField"
              onChange={(ev, newValue) => {
                setSearchFor(newValue || "");
              }}
              placeholder={translate("EQUIPMENTS.PlaceholderSearchFor")}
            />
          </div>
          <div className="action-btns">
            <PrimaryButton
              additionalClass={"margin-left remove-text"}
              text={translate("EQUIPMENTS.AddEquipment")}
              icon={addIcon}
              onClick={() => openPanel(<AddEquipmentPanel onCompletedActionPanel={getEquipmentsList} />)}
            />
          </div>
        </div>

        {equipmentList?.length === 0 && !shimmeredDetailsList ? (
          <EmptyResult />
        ) : (
          <ShimmeredDetailsList
            className="equipment-list"
            items={equipmentList || []}
            onShouldVirtualize={(_) => false}
            columns={columns}
            setKey="none"
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            enableShimmer={shimmeredDetailsList}
            onRenderRow={onRenderColumnListRow}
          />
        )}
      </CardLayout>
    </>
  );
}






/*
function orderBy(direction: string, key: string) { */
/* return function (a: any, b: any) {
    // se são iguais
    if (a[key] === b[key]) {
      return 0;
    }
    // nulos ordenados no fim
    else if (a[key] === null) {
      return 1;
    } else if (b[key] === null) {
      return -1;
    } else if (direction === "ASC") {
      return a[key] < b[key] ? 1 : -1;
    } else {
      return a[key] < b[key] ? -1 : 1;
    }
  }; */
/* } */

/* function orderByUserNameProperty(direction: string, key: string) { */
/* return function (a?: any, b?: any) {
    // Se o objecto é nulo.
    if (a[key] === null) {
      return 1;
    } else if (b[key] === null) {
      return -1;
    }
    // se são iguais
    else if (a[key].displayName === b[key]?.displayName) {
      return 0;
    } else if (direction === "ASC") {
      return a[key]?.displayName < b[key]?.displayName ? 1 : -1;
    } else {
      return a[key]?.displayName < b[key]?.displayName ? -1 : 1;
    }
  }; */
/* } */
/*
const handleColumnClick2 = useCallback(
  (ev: React.MouseEvent<HTMLElement>, column: IColumn) => { */
/* let newOrderInfo: IOrderInfo | null = {
      columnName: column.key,
      direction: "DESC",
    };

    if (orderInfo && orderInfo.columnName === column.key) {
      //DESC, ASC, NADA
      if (orderInfo.direction === "DESC") {
        newOrderInfo.direction = "ASC";
      } else {
        newOrderInfo = null;
      }
    }
    setOrderInfo(newOrderInfo);

    if (orderInfo?.columnName === "column5") {
      const newItems = equipmentList.slice(0).sort(orderByUserNameProperty(orderInfo.direction, column.fieldName!));
      setEquipmentList(newItems);
    }

    if (
      orderInfo?.columnName === "column2" ||
      orderInfo?.columnName === "column3" ||
      orderInfo?.columnName === "column4" ||
      orderInfo?.columnName === "column6" ||
      orderInfo?.columnName === "column7"
    ) {
      const newItems = equipmentList.slice(0).sort(orderBy(orderInfo.direction, column.fieldName!));
      setEquipmentList(newItems);
    } */
/*   },
  [
     orderInfo, setOrderInfo
  ]
);
 */
