export const FeatureFlags = {
  EMPLOYEE_HOURS: "Employee.Hours",
  EMPLOYEE_EQUIPMENT: "Employee.Equipments",
  EMPLOYEE_PROFILE: "Employee.Profile",
  EMPLOYEE_CV: "Employee.CV",
  EMPLOYEE_DOCUMENTS: "Employee.Documents",
  MANAGER_HOURS: "Manager.Hours",
  MANAGER_EQUIPMENTS: "Manager.Equipment",
  MANAGER_EMPLOYEE_PROFILE: "Manager.Employee.Profile",
  MANAGER_EMPLOYEE_CV: "Manager.Employee.CV",
  MANAGER_EMPLOYEE_LIST: "Manager.Employee.List",
  MANAGER_PROJECTS_LIST: "Manager.Projects.List",
  MANAGER_PROJECT_INFO: "Manager.Project.Info",
  MANAGER_EQUIPMENTS_LIST: "Manager.Equipments.List",
  MANAGER_ALLOCATION_EQUIPMENT: "Manager.Allocations.Equipment",
  MANAGER_APPROVALGROUPS_LIST: "Manager.ApprovalGroups.List",
};
