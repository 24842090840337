import "./AddGroupPopup.css";

import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IIconProps,
  IconButton,
  SelectionMode,
  TextField,
  IComboBoxOption,
  ComboBox,
  Persona,
  getInitials,
  PersonaSize,
  Spinner,
  SpinnerSize,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";

import "dayjs/locale/pt";
import { useNavigate } from "react-router-dom";
import { Employee } from "../../../../models/common/Employee";
import { IconBtn } from "../../../../components/buttons/IconBtn";
import { UsersService } from "../../../../services/UsersService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { ApprovalGroupsService } from "../../../../services/ApprovalGroupsService";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useWindowResize } from "../../../../infrastructure/ui/UIServices";

const addIcon: IIconProps = { iconName: "Add" };
const deleteIcon: IIconProps = { iconName: "Delete" };

var usersService = new UsersService();
var approvalGroupsService = new ApprovalGroupsService();

export interface AddGroupProps {
  onAddGroupCompleted: () => void;
}

export function AddGroupPopup(props: AddGroupProps) {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const closePopup = useClosePopup();
  const windowResize = useWindowResize();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [initialLoadSpinner, setInitialLoadSpinner] = useState<boolean>(true);

  const [groupCode, setGroupCode] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");

  const [selectedMember, setSelectedMember] = useState<IComboBoxOption>();
  const [selectedApprover, setSelectedApprover] = useState<IComboBoxOption>();

  const [selectedGroupMembers, setSelectedGroupMembers] = useState<IComboBoxOption[]>([]);
  const [selectedGroupApprovers, setSelectedGroupApprovers] = useState<IComboBoxOption[]>([]);

  const [originalListOfEmployees, setOriginalListOfEmployees] = useState<IComboBoxOption[]>([]);
  const [filteredEmployeesList, setFilteredEmployeesList] = useState<IComboBoxOption[]>([]);

  const [isAddGroupDisabled, setIsAddGroupDisabled] = useState<boolean>(true);

  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [messageBarErrorContent, setMessageBarErrorContent] = useState<string>("");

  const mapUsersListToIComboBoxOption = useCallback(
    (users: Employee[]) => {
      var employeesList: IComboBoxOption[] = users.map((r) => {
        var employee: IComboBoxOption = {
          text: r.displayName,
          key: r.userPrincipalName,
          data: r.url,
        };
        return employee;
      });
      setOriginalListOfEmployees(employeesList);
      setFilteredEmployeesList(employeesList);
    },
    [setOriginalListOfEmployees, setFilteredEmployeesList]
  );

  useEffect(() => {
    usersService
      .getAll()
      .then((res) => {
        mapUsersListToIComboBoxOption(res);
        setInitialLoadSpinner(false);
      })
      .catch(() => {
        setInitialLoadSpinner(false);
        navigate("/error");
      });
  }, []);

  useEffect(() => {
    const results = originalListOfEmployees.filter(
      (employee) => !selectedGroupApprovers.some((approver) => employee.key === approver.key) && !selectedGroupMembers.some((member) => employee.key === member.key)
    );
    setFilteredEmployeesList(results);
  }, [selectedGroupApprovers, selectedGroupMembers]);

  useEffect(() => {
    if (groupCode && groupName && selectedGroupApprovers.length > 0 && selectedGroupMembers.length > 0) {
      setIsAddGroupDisabled(false);
    } else {
      setIsAddGroupDisabled(true);
    }
  }, [groupCode, groupName, selectedGroupApprovers, selectedGroupMembers]);

  const onClickRemoveApprover = useCallback(
    (item: IComboBoxOption) => {
      const index = selectedGroupApprovers.findIndex((approver) => approver.key === item.key);
      if (index > -1) {
        var approversCopy = [...selectedGroupApprovers];
        approversCopy.splice(index, 1);
        setSelectedGroupApprovers(approversCopy);
      }
    },
    [selectedGroupApprovers, setSelectedGroupApprovers]
  );

  const onClickRemoveMember = useCallback(
    (item: IComboBoxOption) => {
      const index = selectedGroupMembers.findIndex((member) => member.key === item.key);

      if (index > -1) {
        var membersCopy = [...selectedGroupMembers];
        membersCopy.splice(index, 1);
        setSelectedGroupMembers(membersCopy);
      }
    },
    [selectedGroupMembers, setSelectedGroupMembers]
  );

  const onClickAddApprover = useCallback((): void => {
    if (selectedApprover) {
      setSelectedGroupApprovers([selectedApprover, ...selectedGroupApprovers]);
    }
    setSelectedMember(undefined);
    setSelectedApprover(undefined);
  }, [setSelectedApprover, setSelectedMember, selectedApprover, setSelectedGroupApprovers, selectedGroupApprovers]);

  const onClickAddMember = useCallback((): void => {
    if (selectedMember) {
      setSelectedGroupMembers([selectedMember, ...selectedGroupMembers]);
    }

    setSelectedMember(undefined);
    setSelectedApprover(undefined);
  }, [setSelectedMember, setSelectedApprover, selectedMember, setSelectedGroupMembers, selectedGroupMembers]);

  const approverColumns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: "Approver name",
        fieldName: "approver",
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        minWidth: 50,
        onRender: (item) => (
          <div className="truncate">
            <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
          </div>
        ),
        data: "string",
      },
      {
        key: "column2",
        name: "Remove",
        fieldName: "remove",
        minWidth: 30,
        isRowHeader: true,
        isIconOnly: true,
        isCollapsible: false,
        data: "string",
        onRender: (item) => <IconButton onClick={() => onClickRemoveApprover(item)} iconProps={deleteIcon} />,
      },
    ];
  }, [onClickRemoveApprover]);

  const memberColumns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: "Member name",
        fieldName: "member",
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        minWidth: 50,
        onRender: (item) => (
          <div className="truncate">
            <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
          </div>
        ),
        data: "string",
      },
      {
        key: "column2",
        name: "Remove",
        fieldName: "remove",
        minWidth: 30,
        isRowHeader: true,
        isIconOnly: true,
        isCollapsible: false,
        data: "string",
        onRender: (item) => <IconButton onClick={() => onClickRemoveMember(item)} iconProps={deleteIcon} />,
      },
    ];
  }, [onClickRemoveMember]);

  const onClickAddGroup = useCallback(() => {
    setShowValidationError(false);
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);

    var approverEmails = selectedGroupApprovers.map((i) => i.key.toString());
    var membersEmails = selectedGroupMembers.map((i) => i.key.toString());

    approvalGroupsService
      .createApprovalGroup(groupCode, groupName, approverEmails, membersEmails)
      .then((_) => {
        closeOverlaySpinner();
        openPopup(
          <FeedbackPopup type="success">
            <p>{"You have created a new Approval Group!"}</p>
          </FeedbackPopup>
        );
        props.onAddGroupCompleted();
      })
      .catch((error) => {
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(error.response?.data);
          if (parsedResponse.AlertType === ErrorTypeEnum.WARNING) {
            setShowValidationError(true);
            setMessageBarErrorContent(parsedResponse.Response);
          }
        } catch {
          if (error.code === "ERR_BAD_REQUEST") {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.response.data}</p>
              </FeedbackPopup>
            );
          } else {
            navigate("/error");
          }
        }
        closeOverlaySpinner();
        setIsAddGroupDisabled(false);
      });
  }, [
    closeOverlaySpinner,
    setIsAddGroupDisabled,
    setShowValidationError,
    setMessageBarErrorContent,
    navigate,
    openOverlaySpinner,
    setShowValidationError,
    selectedGroupApprovers,
    selectedGroupMembers,
    groupCode,
    groupName,
  ]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.severeWarning}
        onDismiss={() => {
          setShowValidationError(false);
        }}
        dismissButtonAriaLabel={translate("ACTIONS.Close")}
      >
        <>{messageBarErrorContent}</>
      </MessageBar>
    );
  }, [messageBarErrorContent, setShowValidationError, showValidationError]);

  return (
    <div className="popup-content add-group-popup">
      <CardHeader headerTitle={"Add Group"} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <div className="group-details">
          <div className="group-details-inputs">
            <TextField
              className="textfield code"
              onChange={(ev, input) => {
                setGroupCode(input || "");
              }}
              label={windowResize < 768 ? "" : "Code"}
              placeholder={"Ex: G01"}
              value={groupCode}
              required={true}
            />
            <TextField
              className="textfield"
              onChange={(ev, input) => {
                setGroupName(input || "");
              }}
              label={windowResize < 768 ? "" : "Name"}
              placeholder={"Ex: Group 01 - ECS "}
              value={groupName}
              required={true}
            />
          </div>
          {showValidationError ? errorMessageBar() : null}
        </div>
        <div className="group-personas">
          <div className="group-personas-approver">
            <h2>{"Add Approvers"}</h2>
            <div className="group-personas-inputs">
              <ComboBox
                className="select-persona"
                placeholder={initialLoadSpinner ? "Loading, please wait ..." : "Select employee"}
                allowFreeform={true}
                autoComplete={"on"}
                options={filteredEmployeesList}
                selectedKey={selectedApprover ? selectedApprover.key : null}
                onChange={(event, item) => {
                  if (item) setSelectedApprover(item);
                }}
                onRenderOption={(item) => {
                  if (item) {
                    return (
                      <>
                        <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                      </>
                    );
                  }
                  return null;
                }}
              />
              <IconBtn
                additionalClass="add-btn"
                onClick={() => {
                  onClickAddApprover();
                }}
                icon={addIcon}
              ></IconBtn>
            </div>
            {selectedGroupApprovers.length < 1 ? (
              <div className="selected-personas-empty">{"No approvers selected yet!"} </div>
            ) : (
              <DetailsList
                className="approvers-list"
                items={selectedGroupApprovers}
                columns={approverColumns}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
              />
            )}
          </div>
          <div className="group-personas-member">
            <h2>{"Add Members"}</h2>
            <div className="group-personas-inputs">
              <ComboBox
                className="select-persona"
                placeholder={initialLoadSpinner ? "Loading, please wait ..." : "Select employee"}
                allowFreeform={true}
                autoComplete={"on"}
                options={filteredEmployeesList}
                selectedKey={selectedMember ? selectedMember.key : null}
                onChange={(event, item) => {
                  if (item) setSelectedMember(item);
                }}
                onRenderOption={(item) => {
                  if (item) {
                    return (
                      <>
                        <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                      </>
                    );
                  }
                  return null;
                }}
                required={true}
              />

              <IconBtn
                additionalClass="add-btn"
                onClick={() => {
                  onClickAddMember();
                }}
                icon={addIcon}
              ></IconBtn>
            </div>
            {selectedGroupMembers.length < 1 ? (
              <div className="selected-personas-empty">{"No members selected yet!"} </div>
            ) : (
              <DetailsList
                className="members-list"
                items={selectedGroupMembers}
                columns={memberColumns}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
              />
            )}
          </div>
        </div>
      </div>

      <div className="action-btns">
        <TertiaryButton text={"Cancel"} onClick={() => closePopup()} />
        <PrimaryButton isDisabled={isAddGroupDisabled} onClick={() => onClickAddGroup()} text={"Add Group"}></PrimaryButton>
      </div>
    </div>
  );
}
