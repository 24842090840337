import "./MobileNavPanel.css";

import { IIconProps, IconButton, Persona, getInitials } from "@fluentui/react";
import { UserProfile, UsersService } from "../../services/UsersService";
import { evaluateRequestedFeatures, useFeatures } from "../../infrastructure/features/hooks/useFeatures";
import { useEffect, useState } from "react";

import { ReactComponent as IconNavLogout } from "../../assets/logout-icon.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { NavIcon } from "./NavIcon";
import { NavLink } from "react-router-dom";
import { useShowMobileMenuPanel } from "../../infrastructure/ui/UIServices";

const closeIcon: IIconProps = { iconName: "ChromeClose" };

export interface IMobileNavPanelButton {
  icon: React.ReactNode;
  to: string;
  name: string;
  features?: string[];
}

export interface IMobileNavPanelProps {
  buttons: IMobileNavPanelButton[];
}

var userService = new UsersService();

export function MobileNavPanel(props: IMobileNavPanelProps) {
  const { setSMobileNavState } = useShowMobileMenuPanel();
  const { features } = useFeatures();

  const [userProfile, setUserProfile] = useState<UserProfile>();

  useEffect(() => {
    userService.getProfile("").then((res) => {
      setUserProfile(res);
    });
  }, []);

  return (
    <div className="mobile-nav-panel">
      <div className="panel-header">
        <NavLink to={"/"}>
          <Logo />
        </NavLink>
        <IconButton className="close-icon" iconProps={closeIcon} onClick={() => setSMobileNavState(false)} />
      </div>
      <div className="mobile-nav-content">
        <ul>
          {props.buttons
            .filter((m) => (m.features ? evaluateRequestedFeatures(m.features, features) : true))
            .map((m) => (
              <NavIcon key={m.name} icon={m.icon} to={m.to} name={m.name}></NavIcon>
            ))}
        </ul>
        <div className="account">
          <div className="account-info">
            <Persona imageUrl={userProfile?.url} imageInitials={getInitials(userProfile?.displayName, false, true)} imageAlt="Account Avatar" className="avatar" />
            <div className="account-data">
              <div className="name">{userProfile?.displayName}</div>
              <div className="mail">{userProfile?.email}</div>
            </div>
          </div>
          <ul>
            <NavIcon icon={<IconNavLogout />} to={"/identity/logout"} name={"Logout"}></NavIcon>
          </ul>
        </div>
      </div>
    </div>
  );
}
