import "./AddActivityPopup.css";

import { MessageBar, Spinner, SpinnerSize, TextField, Toggle } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup } from "../../../../infrastructure/ui/UIServices";

import { ActivityDetail } from "../../../../models/projects/ActivityDetail";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { ProjectsService } from "../../../../services/ProjectsService";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

interface IProps {
  projectId: string;
  onAddActivityCompleted: () => void;
}

const getErrorMessage = (value: string): string => {
  var trimmed = value.trim();
  return trimmed.length == 0 ? translate("COMMON.RequiredField") : "";
};

var projectsService = new ProjectsService();

export function AddActivityPopup(props: IProps) {
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [submitButtonSpinner, setSubmitButtonSpinner] = useState<boolean>(false);
  const [isAddActivityDisabled, setIsAddActivityDisabled] = useState<boolean>(true);
  const [showMessageBar, setShowMessageBar] = useState<boolean>(false);
  const [errorMessage, setMessageError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const onClickAddActivity = useCallback(() => {
    setIsAddActivityDisabled(true);
    setShowMessageBar(false);

    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
    var newActivity: ActivityDetail = {
      activityId: code,
      name: name,
      id: 0,
      isDisabled: false,
      projectId: "",
      isPublic: isPublic,
    };

    projectsService
      .createActivity(props.projectId, newActivity)
      .then((_) => {
        closeOverlaySpinner();
        props.onAddActivityCompleted?.();
        setIsAddActivityDisabled(false);
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("ACTIVITIES.ADD.SuccessMessage")}</p>
          </FeedbackPopup>
        );
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          if (error.response?.data) {
            setShowMessageBar(true);
          }
          setMessageError(error.response?.data);
          closeOverlaySpinner();
        } else {
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="error">
              <p>{error.response?.data}</p>
            </FeedbackPopup>
          );
        }
      });
  }, [code, name, isPublic, closeOverlaySpinner, setMessageError, setShowMessageBar, openPopup, setIsAddActivityDisabled, props]);

  useEffect(() => {
    if (code.trim() && name.trim()) setIsAddActivityDisabled(false);
    else setIsAddActivityDisabled(true);
  }, [name, code]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        truncated={true}
        dismissButtonAriaLabel="Close"
        onDismiss={() => {
          setShowMessageBar(false);
        }}
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setShowMessageBar, setIsAddActivityDisabled]);

  return (
    <div className="add-activity-popup">
      <CardHeader headerTitle={translate("ACTIVITIES.AddActivity")} icon={true} setDisplayState={(state: boolean) => closePopup()} />
      <div className="content">
        <form>
          <Toggle
            label={translate("COMMON.Visibility")}
            onText={translate("COMMON.Public")}
            offText={translate("COMMON.Private")}
            onChange={(ev, checked) => setIsPublic(checked || false)}
          />
          <TextField
            onChange={(e) => {
              setCode(e.currentTarget.value);
            }}
            label={translate("COMMON.Code")}
            placeholder="Ex: DEV"
            required
            onGetErrorMessage={getErrorMessage}
            validateOnLoad={false}
          />
          <TextField
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            label={translate("COMMON.Name")}
            placeholder="Ex: Development"
            required
            onGetErrorMessage={getErrorMessage}
            validateOnLoad={false}
          />
        </form>
        {showMessageBar ? errorMessageBar() : null}
        <div className="action-btns">
          <TertiaryButton isDisabled={submitButtonSpinner} text={translate("ACTIONS.Cancel")}onClick={() => closePopup()} />
          <PrimaryButton
            text={translate("ACTIONS.Add")}
            isDisabled={isAddActivityDisabled}
            onClick={() => {
              onClickAddActivity();
            }}
          >
            {" "}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
