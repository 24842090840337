import "./SessionExpiredPage.css";

import { ReactComponent as IconSessionExpired } from "../../../assets/session-expired-icon.svg";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { TertiaryButton } from "../../../components/buttons/TertiaryButton";
import { useNavigate } from "react-router-dom";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

export function SessionExpiredPage() {
  const navigate = useNavigate();

  return (
    <div className="session-expired-page">
      <div className="wrapper">
        <div className="error-text">
          <div className="error">
            <IconSessionExpired />
          </div>
          <div className="info">
            <div className="title">{translate("ERRORPAGES.EXPIRED.Title")}</div>
            <div className="text">{translate("ERRORPAGES.EXPIRED.Message")}</div>
          </div>
        </div>
        <div className="action-btns">
          <TertiaryButton additionalClass="session-expired-page-tertiary-btn" text={translate("ACTIONS.Back")} onClick={() => navigate("/identity/logout")} />
          <SecondaryButton additionalClass="session-expired-page-secondary-btn  btn-margin-left" text="Login" onClick={() => navigate("/identity/login")} />
        </div>
      </div>
    </div>
  );
}
