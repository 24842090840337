import { useContext } from "react";
import { NavigationSvcContext } from "../NavigationServices";

export function useHeaderInfo() {
    const context = useContext(NavigationSvcContext);
    if(!context){
        throw new Error("useHeaderInfo must be used within NavigationServices");
    }

   return {pageInfo: context.pageState}
}
