import { Overlay } from "../overlay/Overlay";
import "./SidePanel.css";

interface ISidePaneltProps {
  children: React.ReactNode;
}

export function SidePanel(props: ISidePaneltProps) {
  return (
    <Overlay>
      <div className="side-panel">{props.children}</div>
    </Overlay>
  );
}
