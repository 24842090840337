import "./AllocateHoursPopup.css";

import { Activity, Project, ProjectsService } from "../../../../services/ProjectsService";
import { CreateDailyRecordRequestDTO, HoursService, ITimeRecord } from "../../../../services/HoursService";
import {
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  FontIcon,
  IColumn,
  IDropdownOption,
  IIconProps,
  IconButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  SpinnerSize,
  TextField,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TimeSpanHelper, TimeSpanHelperObj } from "../../../../helpers/TimeSpanHelper";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useShowSpinner, useWindowResize } from "../../../../infrastructure/ui/UIServices";

import { CardHeader } from "../../../../components/cards/CardHeader";
import { DomainExceptionResponse } from "../../../../models/errors/DomainExceptions";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { NumberPicker } from "../../../../components/inputs/NumberPicker";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../components/buttons/SecondaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { TimePicker } from "../../../../components/inputs/TimePicker";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import { InternationalizationService, translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { useNavigate } from "react-router-dom";

const addIcon: IIconProps = { iconName: "Add" };
const deleteIcon: IIconProps = { iconName: "Delete" };

var hoursService = new HoursService();
var projectsService = new ProjectsService();

const minValue = 1;
const maxValue = 8;

//Mapper projects[] to IDropDownOptions
function mapProjectToIDropdownOptions(projects: Project[]) {
  var optionsMapped = projects.map((obj) => {
    var opMapped: IDropdownOption = {
      key: obj.projectId,
      text: obj.name,
    };
    return opMapped;
  });
  return optionsMapped;
}

//Mapper activity[] to IDropDownOptions
function mapActivityToIDropdownOptions(acitivities: Activity[]) {
  var optionsMapped = acitivities.map((obj) => {
    var opMapped: IDropdownOption = {
      key: obj.activityId,
      text: obj.name,
    };
    return opMapped;
  });
  return optionsMapped;
}

interface IAllocateHoursProps {
  currentDay: Date;
  onAllocateNewDailyRecordCompleted?: () => void;
  holidayName?: string;
}

interface TimeRecordDTO {
  projectId: number | string;
  projectName: string;
  activityId: number | string;
  activityName: string;
  hours: string;
  notes?: string;
}

////THIS COMPONENT NEEDS A REFACTOR BADLY
export function AllocateHoursPopup(props: IAllocateHoursProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const navigate = useNavigate();
  const { showSpinner, setShowSpinner } = useShowSpinner();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const windowResize = useWindowResize();

  /* -------------------- Daily records -------------------- */
  const [initialMorningTime, setInitialMorningTime] = useState<string>("09:00"); //Default working time, to change later with several options.
  const [finalMorningTime, setFinalMorningTime] = useState<string>("13:00");
  const [initialAfternoonTime, setInitialAfternoonTime] = useState<string>("14:00");
  const [finalAfternoonTime, setFinalAfternoonTime] = useState<string>("18:00");
  /* -------------------- TimeRecords -------------------- */
  const [project, setProject] = useState<IDropdownOption>();
  const [mappedProjects, setMappedProjects] = useState<IDropdownOption[]>([]);
  const [activity, setActivity] = useState<IDropdownOption>(); //Same here.
  const [mappedActivities, setMappedActivities] = useState<IDropdownOption[]>([]);
  const [hours, setHours] = useState<string>(minValue.toString());
  const [activityNotes, setActivityNotes] = useState<string>("");
  const [timeRecords, setTimeRecords] = useState<TimeRecordDTO[]>([]);
  const [errorMessage, setMessageError] = useState<string>("");

  /* -------------------- Booleans -------------------- */
  const [projectNotSelected, setProjectNotSelected] = useState<boolean>(true);
  const [activityNotSelected, setActivityNotSelected] = useState<boolean>(true);
  const [disableAddTimeRecordButton, setDisableAddTimeRecordButton] = useState<boolean>(true);
  const [saveButton, setSaveButtonIsDisabled] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<boolean>(false);

  /* -------------------- TimeSpan -------------------- */
  const [workingTimespan, setWorkingTimespan] = useState<TimeSpanHelperObj>();
  const [totalProjectHours, setTotalProjectHours] = useState<number>(0);

  // Header popup, extended date. //dddd, Do MMMM
  const selectedDateAsDayJs = useMemo(
    () => (windowResize > 768 ? dayjs(props.currentDay).locale(InternationalizationService.getLocale()).format("dddd, D MMMM") 
    : dayjs(props.currentDay).locale(InternationalizationService.getLocale()).format("ddd, D MMM")),
    [props.currentDay, windowResize]
  );

  useEffect(() => {
    projectsService
      .getMyProjects()
      .then((res) => {
        var projectsMapped = mapProjectToIDropdownOptions(res);
        setMappedProjects(projectsMapped);
      })
      .catch((errorResponse) => {
        openPopup(
          <FeedbackPopup type="error">
            <p>{errorResponse}</p>
          </FeedbackPopup>
        );
      });
  }, []);

  useEffect(() => {
    var isMorningValid: boolean;
    var isAfternoonValid: boolean;

    initialMorningTime && finalMorningTime ? (isMorningValid = true) : (isMorningValid = false);
    initialAfternoonTime && finalAfternoonTime ? (isAfternoonValid = true) : (isAfternoonValid = false);

    setWorkingTimespan(TimeSpanHelper.getTimeSpan(initialMorningTime, finalMorningTime, initialAfternoonTime, finalAfternoonTime, isMorningValid, isAfternoonValid));
  }, [initialMorningTime, finalMorningTime, initialAfternoonTime, finalAfternoonTime]);

  // THIS USEEFFECT NEEDS A REFACTOR
  //BAD CODING
  useEffect(() => {
    if (timeRecords.length > 0 && initialMorningTime && finalMorningTime) {
      setSaveButtonIsDisabled(false);
    }
    if (timeRecords.length > 0 && initialAfternoonTime && finalAfternoonTime) {
      setSaveButtonIsDisabled(false);
    }
    if (!initialAfternoonTime && !initialMorningTime && !finalAfternoonTime && !finalMorningTime) {
      setSaveButtonIsDisabled(true);
    }
    if ((initialAfternoonTime && !finalAfternoonTime) || (!initialAfternoonTime && finalAfternoonTime)) {
      setSaveButtonIsDisabled(true);
    }
    if ((initialMorningTime && !finalMorningTime) || (!initialMorningTime && finalMorningTime)) {
      setSaveButtonIsDisabled(true);
    }
  }, [initialAfternoonTime, initialMorningTime, finalAfternoonTime, finalMorningTime, timeRecords]);



  useEffect(() => {
    var totalActivitiesHours: number = 0;

    if (timeRecords && workingTimespan) {
      timeRecords.map((timeRecord) => {
        return (totalActivitiesHours += Number(timeRecord.hours));
      });

      var remainingHours = Math.floor(workingTimespan?.totalHours - totalActivitiesHours);

      if (workingTimespan.totalHours < 0 || remainingHours < 0) {
        setHours("0");
      } else {
        setHours(remainingHours.toString());
      }

      if (activity?.key && remainingHours > 0) {
        setActivityNotSelected(false);
        setDisableAddTimeRecordButton(false);
      } else {
        setActivityNotSelected(true);
        setDisableAddTimeRecordButton(true);
      }
    }
  }, [timeRecords, workingTimespan, /* project, */ activity]);

  // SET project on the DropDownOptions
  const onChangeProject = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setProject(item);
      projectsService
        .getMyActivities(item.key)
        .then((response) => {
          var activitiesMapped = mapActivityToIDropdownOptions(response);
          setMappedActivities(activitiesMapped);
          setProjectNotSelected(false);
        })
        .catch((errorResponse) => {
          openPopup(
            <FeedbackPopup type="error">
              <p>{errorResponse}</p>
            </FeedbackPopup>
          );
        });
    },
    [setProject, setMappedActivities, setProjectNotSelected]
  );

  const onChangeActivity = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setActivity(item);
      setActivityNotSelected(false);
      setDisableAddTimeRecordButton(false);
    },
    [setActivity, setActivityNotSelected, setDisableAddTimeRecordButton]
  );

  const convertProjectIdToProjectName = useCallback(
    (item: IDropdownOption) => {
      var res = mappedProjects.find((p) => p.key === item.key);
      return res?.text;
    },
    [mappedProjects]
  );

  const convertActivityToActivityName = useCallback(
    (item: IDropdownOption) => {
      var res = mappedActivities.find((p) => p.key === item.key);
      return res?.text;
    },
    [mappedActivities]
  );

  const onClickAddTimeRecord = useCallback(() => {
    var timeRecordsCopy = [...timeRecords];
    var addTimeRecord: TimeRecordDTO = {
      projectId: project?.key || 0,
      activityId: activity?.key || 0,
      hours: hours || "",
      notes: activityNotes.trim(),
      activityName: convertActivityToActivityName(activity!) || "",
      projectName: convertProjectIdToProjectName(project!) || "",
    };
    timeRecordsCopy.push(addTimeRecord);
    setTimeRecords(timeRecordsCopy);
    setActivity({ key: "", text: "" });
    setActivityNotes("");
    setActivityNotSelected(true); //not needed: test before remove
    setTotalProjectHours(totalProjectHours + Number(hours));
  }, [timeRecords, setTimeRecords, project, activity, setHours, hours, activityNotes, setActivityNotes]);

  const onClickRemoveTimeRecord = useCallback(
    (item: TimeRecordDTO) => {
      const index = timeRecords.findIndex((proj) => proj === item);
      const project = timeRecords.find((proj) => proj === item);
      var arrayCopy = [...timeRecords];
      if (index > -1) {
        arrayCopy.splice(index, 1);
        setTimeRecords(arrayCopy);
        setTotalProjectHours(totalProjectHours - Number(project?.hours));
      }
      if (arrayCopy.length === 0) {
        setSaveButtonIsDisabled(true);
      }
    },
    [timeRecords, setTimeRecords, setSaveButtonIsDisabled]
  );

  const columns: IColumn[] = useMemo(() => {
    if (windowResize > 768) {
      return [
        {
          key: "column1",
          name: translate("HOURS.Hrs"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "hours",
          minWidth: 40,
          maxWidth: 40,
          data: "string",
          onRender: (item) => <div className="truncate">{item.hours}</div>,
        },
        {
          key: "column2",
          name: translate("MENU.Projects"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "projectName",
          minWidth: 100,
          maxWidth: 250,
          data: "string",
          onRender: (item) => <div className="truncate">{item.projectName}</div>,
        },
        {
          key: "column3",
          name: translate("ACTIVITIES.Activity"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "activityName",
          minWidth: 100,
          data: "string",
          onRender: (item) => <div className="truncate">{item.activityName}</div>,
        },
        {
          key: "column4",
          name: translate("HOURS.Notes"),
          fieldName: "notes",
          minWidth: 20,
          maxWidth: 20,
          isRowHeader: false,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: disableAddTimeRecordButton,
          onRender: (item) =>
            item.notes ? (
              <TooltipHost content={item.notes} delay={TooltipDelay.medium}>
                <FontIcon aria-label="Note" iconName="QuickNote" className="notes-icon" />
              </TooltipHost>
            ) : null,
        },
        {
          key: "column5",
          name: translate("ACTIONS.Remove"),
          fieldName: "remove",
          minWidth: 32,
          maxWidth: 32,
          isRowHeader: true,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: disableAddTimeRecordButton,
          onRender: (item) => <IconButton onClick={() => onClickRemoveTimeRecord(item)} iconProps={deleteIcon} />,
        },
      ];
    } else {
      return [
        {
          key: "column1",
          name: translate("HOURS.Hrs"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "hours",
          minWidth: 40,
          maxWidth: 40,
          data: "string",
          onRender: (item) => <div className="truncate">{item.hours}</div>,
        },
        {
          key: "column2",
          name: translate("MENU.Projects"),
          isRowHeader: true,
          isResizable: true,
          isCollapsible: false,
          fieldName: "projectName",
          minWidth: 100,
          data: "string",
          onRender: (item) => (
            <div className="project-info truncate">
              <div className="project truncate">{item.projectName}</div>
              <div className="activity truncate">{item.activityName}</div>
            </div>
          ),
        },
        {
          key: "column3",
          name: translate("ACTIONS.Remove"),
          fieldName: "remove",
          minWidth: 32,
          maxWidth: 32,
          isRowHeader: true,
          isIconOnly: true,
          isCollapsible: false,
          data: "string",
          disabled: disableAddTimeRecordButton,
          onRender: (item) => <IconButton onClick={() => onClickRemoveTimeRecord(item)} iconProps={deleteIcon} />,
        },
      ];
    }
  }, [onClickRemoveTimeRecord, convertActivityToActivityName, convertProjectIdToProjectName, windowResize]);

  //Mapper timeRecordDTO to ITimeRecord (component -> service)
  const mapTimeRecordDTOToTimeRecord = useCallback(
    (timeRecords: TimeRecordDTO[]) => {
      var timeRecordsMapped = timeRecords.map((tr) => {
        var replaceSpecialCharacters = tr.hours.replace(",", ".");
        var recordMapped: ITimeRecord = {
          activityId: tr.activityId,
          projectId: tr.projectId,
          hours: replaceSpecialCharacters,
          notes: tr.notes,
        };
        return recordMapped;
      });
      return timeRecordsMapped;
    },
    [timeRecords]
  );

  const onClickAddDailyRecord = useCallback(() => {
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);
    setSaveButtonIsDisabled(true);
    setDisableAddTimeRecordButton(true);
    var currentDayToString = dayjs(props.currentDay).format("YYYY/MM/DD");
    var dailyRecord: CreateDailyRecordRequestDTO = {
      day: currentDayToString,
      initialMorningTime: initialMorningTime,
      finalMorningTime: finalMorningTime,
      initialAfternoonTime: initialAfternoonTime,
      finalAfternoonTime: finalAfternoonTime,
      timeRecords: mapTimeRecordDTOToTimeRecord(timeRecords),
    };

    hoursService
      .createDailyRecord(dailyRecord)
      .then(() => {
        closeOverlaySpinner();
        setSaveButtonIsDisabled(false);
        setDisableAddTimeRecordButton(false);
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("HOURS.YouHaveCreatedADailyRecord")}</p>
          </FeedbackPopup>
        );
        props.onAllocateNewDailyRecordCompleted?.();
      })
      .catch((error) => {
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(error.response?.data);
          openPopup(
            <FeedbackPopup type="error">
              <p>{parsedResponse.Response}</p>
            </FeedbackPopup>
          );
          closeOverlaySpinner();
          setSaveButtonIsDisabled(false);
          setDisableAddTimeRecordButton(false);
        } catch {
          if (error.code === "ERR_BAD_REQUEST") {
            if (error.response.data) {
              setValidationError(true);
              closeOverlaySpinner();
              setMessageError(error.response.data);
              setSaveButtonIsDisabled(false);
            } else {
              navigate("/error");
            }
          } else {
            closeOverlaySpinner();
            setSaveButtonIsDisabled(false);
            setDisableAddTimeRecordButton(false);
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.response?.data}</p>
              </FeedbackPopup>
            );
          }
        }
      });
  }, [initialMorningTime, props.onAllocateNewDailyRecordCompleted, finalMorningTime, initialAfternoonTime, finalAfternoonTime, timeRecords, openPopup]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setValidationError(false);
          setSaveButtonIsDisabled(false);
        }}
        dismissButtonAriaLabel={translate("ACTIONS.Close")}
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setSaveButtonIsDisabled, setValidationError]);

  return (
    <div className="popup-content allocate-hours-popup">
      <CardHeader
        headerTitle={translate("HOURS.AllocateTime")}
        children={
          <div className={"subtitle" + (props.holidayName ? " holiday" : "")}>
            {selectedDateAsDayJs}
            {props.holidayName ? " - " + props.holidayName : null}
          </div>
        }
        icon={true}
        setDisplayState={(state: boolean) => closePopup()}
      />
      <div className="content">
        <div className="working-time">
          <div className="working-time-inputs">
            <TimePicker
              labelText={translate("HOURS.MorningTime")}
              func={(value) => {
                setInitialMorningTime(value);
              }}
              defaultHour="09"
              defaultMinutes="00"
            />
            <TimePicker
              func={(value) => {
                setFinalMorningTime(value);
              }}
              defaultHour="13"
              defaultMinutes="00"
            />
            <TimePicker
              labelText={translate("HOURS.AfternoonTime")}
              func={(value) => {
                setInitialAfternoonTime(value);
              }}
              defaultHour="14"
              defaultMinutes="00"
            />
            <TimePicker
              func={(value) => {
                setFinalAfternoonTime(value);
              }}
              defaultHour="18"
              defaultMinutes="00"
            />
          </div>
          {validationError ? errorMessageBar() : null}
        </div>
        <div className="project-hours">
          <h2>{translate("HOURS.ProjectHours")}</h2>
          <div className="project-inputs">
            <Dropdown
              className="truncate"
              calloutProps={{ calloutWidth: undefined, calloutMinWidth: 155, calloutMaxWidth: 300 }}
              selectedKey={project ? project.key : undefined}
              placeholder={translate("HOURS.ACTIONS.SelectProject")}
              label={translate("MENU.Projects")}
              options={mappedProjects || []}
              required={true}
              onChanged={onChangeProject}
            />
            <Dropdown
              className={projectNotSelected ? "project-disabled" : ""}
              calloutProps={{ calloutWidth: undefined, calloutMinWidth: 155, calloutMaxWidth: 300 }}
              selectedKey={activity ? activity.key : undefined}
              placeholder={translate("HOURS.ACTIONS.SelectActivity")}
              label={translate("ACTIVITIES.Activity")}
              options={mappedActivities || []}
              required={true}
              onChanged={onChangeActivity}
              disabled={projectNotSelected}
            />
            <NumberPicker
              value={hours}
              isDisabled={activityNotSelected}
              labelText={translate("HOURS.Hours")}
              setValue={(value) => setHours(value)}
              isRequired={true}
              minValue={minValue}
              maxValue={maxValue}
            />
            <TextField
              className="notes"
              onChange={(ev, input) => {
                setActivityNotes(input || "");
              }}
              label={windowResize < 768 ? translate("HOURS.Notes") : ""}
              placeholder={windowResize < 768 ? "Ex: Lorem Ipsum" : translate("HOURS.Notes")}
              multiline
              value={activityNotes}
              rows={2}
            />
            <SecondaryButton isDisabled={disableAddTimeRecordButton} onClick={onClickAddTimeRecord} text={translate("HOURS.ACTIONS.AddHours")} icon={addIcon} />
          </div>
          {timeRecords.length > 0 ? (
            <DetailsList
              className="project-hours-list"
              items={timeRecords}
              columns={columns}
              selectionMode={SelectionMode.none}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
            />
          ) : null}
        </div>
      </div>

      <div className="action-btns">
        <div className="total-hours">
          <div className="working-hours">{translate("HOURS.TotalWorkingHours", "" + workingTimespan?.phrase)}</div>
          {totalProjectHours > 0 ? <div className="project-time">{translate("HOURS.TotalProjectHours", "" + totalProjectHours)}</div> : null}
        </div>
        <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePopup()} />
        <PrimaryButton isDisabled={saveButton} onClick={() => onClickAddDailyRecord()} text={translate("ACTIONS.Allocate")}>
          {" "}
          {showSpinner ? <Spinner size={SpinnerSize.small} /> : null}
        </PrimaryButton>
      </div>
    </div>
  );
}
