export class UrlUtility {

    static join(...urls: string[]): string{

        return urls
            .map(u => u.charAt(u.length-1) === '/' ? u.slice(0, -1) : u)
            .map(u => u.charAt(0) === '/' ? u.slice(1) : u)
            .join("/");
    }

    static stripUndefinedProps(obj: any){
        let clone = {...obj};
        Object.keys(clone).forEach(key => {
            if (clone[key] === undefined || clone[key] === null) {
             delete clone[key];
            }
        });
        return clone;
    }
}




