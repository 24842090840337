import "./FeedbackPopup.css";

import { Spinner, SpinnerSize } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as IconError } from "../../../assets/error-icon.svg";
import { ReactComponent as IconSuccess } from "../../../assets/success-icon.svg";
import { ReactComponent as IconWarning } from "../../../assets/warning-icon.svg";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import { TertiaryButton } from "../../buttons/TertiaryButton";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useClosePopup } from "../../../infrastructure/ui/UIServices";

interface IFeedbackPopupProps {
  title?: string;
  type: string;
  children: React.ReactNode;
  primaryActionLabel?: string;
  primaryActionFunc?: () => void;
  cancelActionFunc?: () => void;
  isStopSpinner?: boolean;
}

export function FeedbackPopup(props: IFeedbackPopupProps) {
  const closePopup = useClosePopup();

  const [isSpinner, setIsSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (props.isStopSpinner) {
      setIsSpinner(props.isStopSpinner);
    }
  }, [props.isStopSpinner]);

  const feedbackIcon = useMemo(() => {
    switch (props.type) {
      case "success":
        return <IconSuccess />;
      case "error":
        return <IconError />;
      case "warning":
        return <IconWarning />;
    }
  }, [props.type]);

  const feedbackTitle = useMemo(() => {
    if (props.title) {
      return () => props.title;
    }
    switch (props.type) {
      case "success":
        return () => translate("ACTIONS.Success");
      case "error":
        return () => translate("ACTIONS.Error");
      case "warning":
        return () =>translate("ACTIONS.AreYouSure");
    }
  }, [props.type, props.title]);

  const getActionBtns = useCallback(() => {
    switch (props.type) {
      case "success":
        return <PrimaryButton additionalClass="success" text={translate("ACTIONS.Done")} onClick={() => {
          props.primaryActionFunc?.();
          closePopup()
        }} />;
      case "error":
        return <PrimaryButton additionalClass="error" text={translate("ACTIONS.TryAgain")} onClick={() => closePopup()} />;
      case "warning":
        return (
          <>
            <TertiaryButton
              text={translate("ACTIONS.Cancel")}
              onClick={() => {
                props.cancelActionFunc?.();
                setIsSpinner(true);
                closePopup();
              }}
              isDisabled={isSpinner}
            />
            <PrimaryButton
              isDisabled={isSpinner}
              additionalClass="warning"
              text={props.primaryActionLabel ? props.primaryActionLabel : translate("ACTIONS.Continue")}
              onClick={() => {
                props.primaryActionFunc?.();
                setIsSpinner(true);
              }}
            >
              {isSpinner ? <Spinner size={SpinnerSize.small} /> : null}
            </PrimaryButton>
          </>
        );
    }
  }, [props.type, props.primaryActionFunc, props.primaryActionLabel, isSpinner, setIsSpinner, closePopup]);

  return (
    <div className="feedback-popup">
      <div className="icon">{feedbackIcon}</div>
      <div className="feedback">
        <h1>{props.title ? props.title : feedbackTitle?.()}</h1>
        <div className="popup-text">{props.children}</div>
      </div>
      <div className="action-btns">{getActionBtns()}</div>
    </div>
  );
}
