import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
  AREA: {
    Manager: "Gestão",
    Employee: "Colaborador",

    SHORT: {
      Manager: "G",
      Employee: "C",
    },
  },

  MENU: {
    Profile: "Perfil",
    Hours: "Horas",
    Employees: "Funcionários",
    Projects: "Projetos",
    Equipments: "Equipamentos",
    Documents: "Documentos",
    Sharepoint: "Sharepoint",
  },

  PROFILE: {
    MyProfile: "Meu Perfil",
    PersonalInfo: "Informação Pessoal",
    Phone: "Telemóvel",
    Address: "Morada",
    Nationality: "Nacionalidade",
    Email: "Email",
  },

  LOADING: {
    LoadingDataPleaseWait: "A carregar informação, por favor aguarde ...",
    PleaseWait: "Por favor, aguarde ...",
  },

  CALENDAR: {
    Weekdays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    Months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
  },

  ACTIONS: {
    Allocate: "Alocar",
    Delete: "Delete",
    Edit: "Editar",
    Deactivate: "Desativar",
    Save: "Guardar",
    Ok: "OK",
    Confirm: "Confirmar",
    SeeDetails: "Ver Detalhes",
    Clone: "Copiar",
    Remove: "Remover",
    Cancel: "Cancelar",
    Override: "Substituir",
    TryAgain: "Tente novamente",
    Done: "Concluido",
    Continue: "Continuar",
    AreYouSure: "Tem a certeza?",
    Error: "Erro!",
    Success: "Successo!",
    Approve: "Aprovar",
    Decline: "Rejeitar",
    Close: "Fechar",
    Create: "Criar",
    Change: "Alterar",
    Add: "Adicionar",
    View: "Ver",
    Info: "Detalhes",
    Back: "Voltar",
    ChangeVisibility: "Alterar visibilidade?",
  },

  EMPTYRESULT: {
    Description: "Não foram encontrados resultados ...",
  },

  PROJECTS: {
    AddProject: "Adicionar projeto",
    AddProjectSuccessfully: "Projeto adicionado com sucesso.",

    EDIT: {
      VisibilityChangedWithSuccess: "Visibilidade do projeto alterada.",
      EditedWithSuccess: "Projeto editado com sucesso.",
      Title: "Editar info do projecto",
      AreYouSureYouWantToChangeVisibility: "Tem a certeza que pretende aStatuslterar a visibilidade do projeto?",
    },

    DISABLE: {
      DisableProject: "Desativar projeto",
      ProjectDisabled: "Projeto desativado.",
      AreYouSureYouWantDisable: "Tem a certeza que pretende desativar o projeto?",
    },
    ERRORS: {
      LoadingProject: "Error ao tentar carregar os detalhes do projecto devido ao ID do projeto ser inválido",
      InvalidProjectId: "ID do projeto inválido",
    },

    COMMANDBAR: {
      PHSearchProject: "Procurar projecto",
      RefreshCache: "Atualizar cache",
    },
  },

  ACTIVITIES: {
    Activity: "Atividade",
    AddActivity: "Adicionar atividade",
    ProjectActivitiesName: "Atividades do projeto",
    ActivityDisabled: "Atividade desativada",
    DisableActivity: "Desativar atividade",

    EMPTYLIST: {
      Message: "Este projeto não tem atividades! Tente adicionar uma!",
      Title: "Sem atividades para já",
    },

    ADD: {
      SuccessMessage: "Atividade inserida com sucesso.",
    },

    EDIT: {
      AreYouSureYouWantToChangeVisibility: "Tem a certeza que pretende alterar a visibilidade da atividade?",
      SuccessMessage: "Atividade editada com sucesso.",
      Title: "Editar detalhes da atividade",
      VisibilityChangedWithSuccess: "Visibilidade da atividade alterada.",
    },

    DISABLE: {
      DisableWithSuccess: "Atividade desativada com sucesso.",
      AreYouSureYouWantDisable: "Tem a certeza que pretende desativar a atividade?",
    },

    ERRORS: {
      ActivityIdNotValid: "ID da atividade inválido.",
      ActivityIdNotValidLongMessage: "Erro ao carregar os detalhes da atividade devido ao seu ID inválido",
    },
  },

  MANAGERHOURS: {
    SelectAll: "Selecionar tudo",
    DeselectAll: "Desselecionar tudo",
    TotalHoursView: "Visão total de horas",

    APPROVE: {
      Title: "Aprovar horas?",
      Message: "Esta ação irá aprovar as horas dos dias selecionados.",
      SuccessResponse: "Horas aprovadas com sucesso.",
    },

    DECLINE: {
      Title: "Rejeitar horas?",
      Message: "Esta ação irá rejeitar as horas dos dias selecionados.",
      Reason: "Razão",
      SuccessResponse: "Horas rejeitadas com sucesso.",
    },

    EXPORT: {
      Export: "Exportar",
      CurrentMonthAllEmployees: "Mês atual - Todos funcionários",
      EmployeeCurrentMonth: "Mês atual",
      EmployeeCurrentMonthPdf: "Mês atual PDF",
      EmployeeCurrentMonthTimesheet: "Mês atual - folha de horas PDF",
    },
  },

  ASSOCIATIONS: {
    SuccessfullAssociation: "Associação de funcionário com sucesso.",
    EmptyListTitle: "Sem associações por enquanto",
    EmptyAssociationsProjectListMessage: "Este projeto não tem funcionários associados.",
    EmptyAssociationsActivitiesListMessage: "Esta atividade não tem funcionários associados.",
    AddEmployee: "Associar funcionário",
    GrantedBy: "Concedido por",

    REMOVE: {
      Message: "Tem a certeza que pretende remover esta associação?",
      Title: "Remover associação?",
      SuccessfullResponse: "Associação removida com sucesso.",
    },

    WARNINGS: {
      ThisProjectIsPublic: "Este projeto é público!",
      ThisActivityIsPublic: "Esta atividade é pública!",
    },
    ERRORS: {
      UserNotSelected: "Nenhum funcionário selecionado.",
    },
  },

  ALLOCATIONS: {
    CreateAllocation: "Criar alocação",
    SelectOneEmployee: "Selecione um funcionário",
    AllocationCreatedWithSuccess: "Alocação criada com sucesso.",
    AllocationChangedTo: "Estado da alocação alterado para",
    AllocationHistoryTitle: "Histórico da alocação",

    COMMON: {
      AllocatedBy: "Alocado por ",
      AllocateTo: "Alocar para",
      Approved: "Aprovado",
    },

    WARNINGS: {
      AreYouSureToChangeAllocationStatus: "Tem a certeza que pretende alterar o estado da alocação?",
      WantToChangeAllocationStatus: "Quer alterar o estado da alocação?",
    },
  },

  MYEQUIPMENTS: {
    NoEquipmentsAllocated: "Não há registo de equipamentos alocados para si.",
  },

  EMPLOYEES: {
    Photo: "Foto",
    Position: "Cargo",
    Contact: "Contacto",
  },

  ERRORPAGES: {
    Unavailable: "Aconteceu algo de errado.",

    NOTFOUND: {
      Title: "Página não encontrada",
      Message: "Parece que não conseguimos encontrar a página que procura.",
    },
    EXPIRED: {
      Title: "Sessão expirada",
      Message: "A sua sessão expirou devido a inatividade.",
    },
  },

  COMMON: {
    PlaceholderSearchEmployee: "Procure um funcionário",
    RequiredField: "Campo obrigatório",
    History: "Histórico",
    ApprovedBy: "Aprovado por",
    PermanentAction: "Esta ação torna-se permanente.",
    OtherOptions: "Outras opções",
    Private: "Privado",
    Public: "Público",
    Visibility: "Visibilidade",
    Employee: "Funcionário",
    Employees: "Funcionários",
    Name: "Nome",
    AllocatedTo: "Alocado para",
    Date: "Data",
    Status: "Estado",
    Type: "Tipo",
    Code: "Código",
    Client: "Cliente",
    SelectEmployee: "Selecione um funcionário",

  },

  HOURS: {
    ClickToAllocateTime: "Clica para imputar horas",
    EditTimeAllocation: "Editar Imputação de Horas",
    CloneFromX: "Copiar de {0}",
    SelectTheTargetDaysFromTheCalendar: "Escolha os dias alvo a partir do calendário.",
    YouHaveClonedADailyRecord: "Cópia terminada com sucesso.",
    YouHaveCreatedADailyRecord: "Imputação criada com sucesso.",
    YouHaveEditedADailyRecord: "Imputação editada com sucesso.",
    YouHaveDeletedADailyRecord: "Imputação removida com sucesso.",
    AllocateTime: "Imputar Horas",
    MorningTime: "Turno Manhã",
    AfternoonTime: "Turno Tarde",
    WorkingTime: "Horário de trabalho",
    ProjectHours: "Projetos trabalhados",
    Hours: "Horas",
    Hrs: "Hrs",
    TotalWorkingHours: "Total de horas trabalhadas: {0}",
    TotalProjectHours: "Total de horas imputadas: {0}h",
    Notes: "Notas",

    ACTIONS: {
      Allocate: "Imputar",
      AddHours: "Adicionar",
      SelectActivity: "Selecione Atividade",
      SelectProject: "Selecione Projeto",
      DeleteTimeRecord: "Remover imputação?",
    },

    ERRORS: {
      TargetDaysAlreadyContainsInformation: "Os dias selecionados já possuem imputações!",
      XAlreadyContainsInformation: "{0}, já possui(em) imputação(ções).",
      DoYouWantToOverride: "Pretende substituir?",
    },

    WARNINGS: {
      YouAreAboutToPermanentlyRemoveATimeRecord: "Esta ação irá permanentemente remover esta imputação.",
    },
  },

  AREATITLES: {
    Allocations: "Alocações",
    MyEquipments: "Meus equipamentos",
    ActivityInfo: "Detalhes da atividade",
    ProjectInfo: "Detalhes do projeto",
  },

  EQUIPMENTS: {
    AddEquipment: "Novo equipamento",
    ClickToFilterEquipments: "Filtrar",
    PlaceholderSearchFor: "marca, modelo, nº série, ...",
    EquipmentStatus: "Estado do equipamento ",
    DeactivatedWithSucess: "Equipamento desativado com sucesso.",
    NoPhotos: "Sem fotos disponíveis",

    FILTERS: {
      MainTitle: "Filtros",
      TemplatesTitle: "Pesquisa rápida",
      TemplateInRepair: "Em reparação",
      TemplateNeedsRepair: "Necessita reparação",
      TemplateAvailablePC: "PC's livres",
      TemplateMonitor: "Monitores livres",
      AllocateTo: "Alocado a",
      PhAllocateTo: "Selecione um funcionário",
      EquipmentType: "Tipo de equipamento",
      PhEquipmentType: "Selecione um ou mais tipos",
      AllocationStatus: "Estados de alocações",
      PhAllocationStatus: "Selecione um ou mais estados de alocações",
      EquipmentStatus: "Estados de equipamentos",
      PhEquipmentStatus: "Selecione um ou mais tipos",
      ClickApplyFilters: "Aplicar filtros",
    },

    COMMON: {
      Ref: "Referência",
      Brand: "Marca",
      Model: "Modelo",
      Description: "Descrição",
      SerialNumber: "N.º de série",
      Images: "Imagens",
      NoImagesUploaded: "Sem imagens carregadas",
      UploadImages: "Carregar imagens",
    },

    EDITPANEL: {
      Title: "Editar equipamento",
      EditWithSuccess: "Equipamento editado com sucesso!",
    },

    ADDEQUIPPANEL: {
      PhDate: "Selecione uma data",
      PhType: "Selecione um tipo de equipamento",
    },

    WARNINGS: {
      ThisEquipmentWillBeDeactivated: "Este equipamento será desativado!",
      YouAreAboutToDeleteImage: "Tem a certeza que quer apagar esta imagem?",
    },

    ERRORS: {
      InvalidId: "A problem happend due to invalid equipment ID.",
    },
  },
};
