import { ActivitiesGetResponseDTO } from "../models/api/projects/ActivitiesGetResponseDTO";
import { ActivityCreateRequestDTO } from "../models/api/projects/ActivityCreateRequestDTO";
import { ActivityDetail } from "../models/projects/ActivityDetail";
import { ActivityGetResponseDTO } from "../models/api/project-associations/ActivityGetResponseDTO";
import { ActivityUpdateVisibilityRequestDTO } from "../models/api/projects/ActivityUpdateVisibilityRequestDTO";
import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../infrastructure/http/HttpClient";
import { ProjectCreateRequestDTO } from "../models/api/projects/ProjectCreateRequestDTO";
import { ProjectDetails } from "../models/projects/ProjectDetails";
import { ProjectGetDetailsResponseDTO } from "../models/api/projects/ProjectGetDetailsReponseDTO";
import { ProjectGetResponseDTO } from "../models/api/projects/ProjectGetResponseDTO";
import { ProjectUpdateVisibilityRequestDTO } from "../models/api/projects/ProjectUpdateVisibilityRequestDTO";
import { ProjectEditNameRequestDTO } from "../models/api/projects/ProjectEditRequestDTO";
import { ActivityEditRequestDTO } from "../models/api/projects/ActivitiesEditRequestDTO";
import { ProjectPathDTO } from "../models/api/projects/ProjectPathDTO";
import { PatchDTO } from "../models/api/projects/PatchDTO";
import { PatchOperationDTO } from "../models/api/projects/PatchOperationDTO";
import { openStdin } from "process";
import { ActivityPathDTO } from "../models/api/projects/ActivityPathDTO";

export interface Project {
  id: number;
  projectId: string;
  client: string;
  name: string;
  isDisable: boolean;
  activityCount: number;
  isPublic: boolean;
}

export interface Activity {
  id: number;
  projectId: string;
  activityId: string;
  name: string;
  isDisable: boolean;
  isPublic: boolean;
}

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class ProjectsService {
  getProjects(): Promise<Project[]> {
    return HttpClient.sessionRequest<ProjectGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects`),
    })
      .then((response) => {
        return response.data.projects.map(
          (p): Project => ({
            id: p.id,
            name: p.name,
            client: p.client,
            projectId: p.code,
            isDisable: p.isDisabled,
            activityCount: p.activityCount,
            isPublic: p.isPublic,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getMyProjects(): Promise<Project[]> {
    return HttpClient.sessionRequest<ProjectGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Me/Projects`),
    })
      .then((response) => {
        return response.data.projects.map(
          (p): Project => ({
            id: p.id,
            name: p.name,
            client: p.client,
            projectId: p.code,
            isDisable: p.isDisabled,
            activityCount: p.activityCount,
            isPublic: p.isPublic,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createProject(request: ProjectDetails): Promise<void> {
    var mappedProject: ProjectCreateRequestDTO = {
      client: request.client,
      code: request.code,
      name: request.name,
      isPublic: request.isPublic,
      activities: request.activities.map(
        (act): ActivityCreateRequestDTO => ({
          name: act.name,
          code: act.activityId,
          isPublic: act.isPublic,
        })
      ),
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Projects`),
      data: mappedProject,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  deleteProject(id: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/Projects/${id}`),
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  deleteActivity(id: string, actId: string): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/Projects/${id}/Activities/${actId}`),
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createActivity(projectId: string, activity: ActivityDetail): Promise<void> {
    var newActivity: ActivityCreateRequestDTO = {
      code: activity.activityId,
      name: activity.name,
      isPublic: activity.isPublic,
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/Projects/${projectId}/Activities`),
      data: newActivity,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  getProjectById(projectId: any): Promise<ProjectDetails> {
    return HttpClient.sessionRequest<ProjectGetDetailsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects/${projectId}`),
    })
      .then((response) => {
        var mapped: ProjectDetails = {
          id: response.data.project.id,
          code: response.data.project.code,
          isDisabled: response.data.project.isDisabled,
          name: response.data.project.name,
          client: response.data.project.client,
          isPublic: response.data.project.isPublic,
          activities: response.data.activities.map(
            (act): ActivityDetail => ({
              id: act.id,
              isPublic: act.isPublic,
              name: act.name,
              projectId: act.projectId,
              isDisabled: act.isDisabled,
              activityId: act.code,
            })
          ),
        };
        return mapped;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getActivities(id: number | string): Promise<Activity[]> {
    return HttpClient.sessionRequest<ActivitiesGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects/${id}/Activities`),
    })
      .then((response) => {
        return response.data.activities.map(
          (a): Activity => ({
            activityId: a.code,
            id: a.id,
            name: a.name,
            projectId: a.projectId,
            isDisable: a.isDisabled,
            isPublic: a.isPublic,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getMyActivities(id: number | string): Promise<Activity[]> {
    return HttpClient.sessionRequest<ActivitiesGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Me/Projects/${id}/Activities`),
    })
      .then((response) => {
        return response.data.activities.map(
          (a): Activity => ({
            activityId: a.code,
            id: a.id,
            name: a.name,
            projectId: a.projectId,
            isDisable: a.isDisabled,
            isPublic: a.isPublic,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getActivityByProjectIdAndActivityId(id: string, actId: string): Promise<Activity> {
    return HttpClient.sessionRequest<ActivityGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects/${id}/Activities/${actId}`),
    })
      .then((response) => {
        var act: Activity = {
          activityId: response.data.code,
          id: response.data.id,
          name: response.data.name,
          projectId: response.data.projectId,
          isDisable: response.data.isDisabled,
          isPublic: response.data.isPublic,
        };
        return act;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getActivityById(actId: string): Promise<Activity> {
    return HttpClient.sessionRequest<ActivityGetResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/Projects/Activities/${actId}`), //change to projects/{code}/activities/{actCode}
    })
      .then((response) => {
        var act: Activity = {
          activityId: response.data.code,
          id: response.data.id,
          name: response.data.name,
          projectId: response.data.projectId,
          isDisable: response.data.isDisabled,
          isPublic: response.data.isPublic,
        };
        return act;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateProjectName(id: number, projName: string, clientName: string) {
    var request: ProjectEditNameRequestDTO = {
      name: projName,
      client: clientName,
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Projects/${id}/UpdateName`),
      data: request,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  updateActivityName(projCode: string, actCode: string, activityName: string) {
    var request: ActivityEditRequestDTO = {
      name: activityName,
    };
    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Projects/${projCode}/Activities/${actCode}/UpdateName`),
      data: request,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  updateProjectVisibility(projCode: string, projVisibility: boolean): Promise<void> {
    var request: ProjectUpdateVisibilityRequestDTO = {
      isPublic: projVisibility,
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Projects/${projCode}/UpdateVisibility`),
      data: request,
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateActivityVisibility(projCode: string, actCode: string, actVisibility: boolean): Promise<void> {
    var request: ActivityUpdateVisibilityRequestDTO = {
      isPublic: actVisibility,
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Projects/${projCode}/Activities/${actCode}/UpdateVisibility`),
      data: request,
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateProjectPatch(projCode: string, projectInfo: ProjectPathDTO): Promise<void> {
    var patchOperations: PatchOperationDTO[] = [];

    if (projectInfo.projectName !== undefined) patchOperations.push({ op: "edit", field: "projectName", value: projectInfo.projectName });

    if (projectInfo.clientName !== undefined) patchOperations.push({ op: "edit", field: "clientName", value: projectInfo.clientName });

    if (projectInfo.isPublic !== undefined) patchOperations.push({ op: "edit", field: "isPublic", value: projectInfo.isPublic });

    var allOps: PatchDTO = { ops: patchOperations };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Projects/${projCode}`),
      data: allOps,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  updateActivityPatch(projCode: string, actCode: string, activityInfo: ActivityPathDTO): Promise<void> {
    var patchOperations: PatchOperationDTO[] = [];

    if (activityInfo.activityName !== undefined) patchOperations.push({ op: "edit", field: "activityName", value: activityInfo.activityName });

    if (activityInfo.isPublic !== undefined) patchOperations.push({ op: "edit", field: "isPublic", value: activityInfo.isPublic });

    var allOps: PatchDTO = { ops: patchOperations };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/Projects/${projCode}/Activities/${actCode}`),
      data: allOps,
    })
      .then((_) => {})
      .catch((error) => {
        throw error.response?.data;
      });
  }
}
