import "./../../App.css";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { ActivityInfoPage } from "./projects/ActivityInfoPage";
import { AllocationsPage } from "./allocations/AllocationsPage";
import { EmployeeInfoPage } from "../employees/employee-info/EmployeeInfoPage";
import { EmployeeListPage } from "./employee-list/EmployeeListPage";
import { EquipmentListPage } from "./equipment-list/EquipmentListPage";
import { FeatureFlags } from "../../infrastructure/features/Flags";
import { Header } from "../../components/header/Header";
import { ReactComponent as IconNavEmployees } from "../../assets/employee-list-icon.svg";
import { ReactComponent as IconNavEquipments } from "../../assets/equipment-icon.svg";
import { ReactComponent as IconNavHours } from "../../assets/hours-icon.svg";
import { ReactComponent as IconNavProjects } from "../../assets/projects-list-icon.svg";
import { ManagementHoursPage } from "./hours/ManagementHoursPage";
import { ManagementProjectsPage } from "./projects/ManagementProjectsPage";
import { MobileNav } from "../../components/navbar/MobileNav";
import { ModuleDefinition } from "../../infrastructure/modules/ModuleDefinition";
import { ModuleHelper } from "../../infrastructure/modules/ModuleHelper";
import { N4UPermissions } from "../../infrastructure/identity/Permissions";
import { NavBar } from "../../components/navbar/NavBar";
import { ProjectInfoPage } from "./projects/ProjectInfoPage";
import React from "react";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { usePermission } from "../../infrastructure/identity/hooks/usePermissions";
import { useRegisterBreadcrumbRoutes } from "../../infrastructure/navigation/hooks/useBreadcrumb";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

const moduleDefinition: ModuleDefinition = {
  basePath: "/embeded/manager",
  routes: [
    {
      name: () => "",
      pathPattern: "/",
      menuIcon: <IconNavEmployees />,
      element: <Navigate to="employees" />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
    },
    {
      name: () => translate("MENU.Employees"),
      pathPattern: "/employees",
      menuIcon: <IconNavEmployees />,
      element: <EmployeeListPage />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
    },
    {
      name: () =>"##EMPLOYEE##",
      pathPattern: "/employees/:id",
      menuIcon: null,
      element: <EmployeeInfoPage />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST, FeatureFlags.MANAGER_EMPLOYEE_CV],
    },
    {
      name:() => translate("MENU.Equipments"),
      pathPattern: "/equipments",
      menuIcon: <IconNavEquipments />,
      element: <EquipmentListPage />,
      features: [FeatureFlags.MANAGER_HOURS],
    },
    {
      name: () => "##DEVICE##",
      pathPattern: "/equipments/:id/allocations",
      menuIcon: null,
      element: <AllocationsPage />,
      features: [FeatureFlags.MANAGER_ALLOCATION_EQUIPMENT],
    },
    {
      name: () => translate("MENU.Hours"),
      pathPattern: "/hours",
      menuIcon: <IconNavHours />,
      element: <ManagementHoursPage />,
      features: [FeatureFlags.MANAGER_HOURS],
    },
    {
      name: () => translate("MENU.Projects"),
      pathPattern: "/projects",
      menuIcon: <IconNavProjects />,
      element: <ManagementProjectsPage />,
      features: [FeatureFlags.MANAGER_PROJECTS_LIST],
    },
    {
      name: () => "##PROJECT##",
      pathPattern: "/projects/:id",
      menuIcon: null,
      element: <ProjectInfoPage />,
      features: [FeatureFlags.MANAGER_PROJECTS_LIST, FeatureFlags.MANAGER_PROJECT_INFO],
    },
    {
      name:() => "##ACTIVITY##",
      pathPattern: "/projects/:id/activities/:actId",
      menuIcon: null,
      element: <ActivityInfoPage />,
      features: [FeatureFlags.MANAGER_PROJECTS_LIST, FeatureFlags.MANAGER_PROJECT_INFO],
    },
  ],
};

const menus = () => [
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 1),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 3),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 5),
  ModuleHelper.generateFromModuleRoute(moduleDefinition, 6),
];

export function EmbededPeopleManagementModule() {
  const isAllowed = usePermission(N4UPermissions.MANAGER);

  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();
  const windowResize = useWindowResize();
  const navigate = useNavigate();

  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  if (!isAllowed) {
    navigate("/error"); //TODO FIX THIS ACCESS DENIED
  }

  return (
    <>


          <div className="embeded content">
            <Routes>
              {moduleDefinition.routes.map((r) => (
                <Route key={r.pathPattern} path={r.pathPattern} element={r.element} />
              ))}
            </Routes>
          </div>

    </>
  );
}
