import "./ManagementPermissionsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useOverlaySpinner, usePanel, usePopup, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import {
  CommandBar,
  DetailsListLayoutMode,
  DetailsRow,
  FocusZone,
  FontIcon,
  getInitials,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  IDetailsRowStyles,
  IOverflowSetItemProps,
  Link,
  List,
  OverflowSet,
  Persona,
  PersonaSize,
  SearchBox,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
} from "@fluentui/react";
import { AddPermission } from "./popups/AddPermission";
import { SubordinatesListPanel } from "./SubordinatesListPanel";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { FeatureFlags } from "../../../infrastructure/features/Flags";

const imageOnErrorHandler = () => {};

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <Link role="menuitem" onClick={item.onClick}>
      {item.name}
    </Link>
  );
};

//On render overflow menu
const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return <IconButton role="menuitem" title={translate("COMMON.OtherOptions")} menuIconProps={{ iconName: "More" }} menuProps={{ items: overflowItems! }} />;
};

const user_permissions = [
  {
    displayName: "Ricardo José Mateus Costa",
    photoUrl: "https://localhost:7077/api/v1/Users/AllanD@M365x83199427.OnMicrosoft.com/Photo",
    email: "ricardo@teste.demo",
    position: "CEO",
    grantedBy: "Manuel Campos",
    grantedDate: "15/08/2022",
    access: ["Projects", "Hours"],
    isLocked: false,
  },
  {
    displayName: "Álvaro Manuel Pinheiro Ferreira",
    photoUrl: "https://localhost:7077/api/v1/Users/AlexW@M365x83199427.OnMicrosoft.com/Photo",
    email: "alvaro@teste.demo",
    position: "CTO",
    grantedBy: "Manuel Campos",
    grantedDate: "15/06/2022",
    access: ["ALL"],
    isLocked: true,
  },
  {
    displayName: "Manuel Aristóteles Campos",
    photoUrl: "https://localhost:7077/api/v1/Users/IsaiahL@M365x83199427.OnMicrosoft.com/Photo",
    email: "manuel@teste.demo",
    position: "RH",
    grantedBy: "",
    grantedDate: "",
    access: ["Hours"],
    isLocked: false,
  },
  {
    displayName: "Mário José da Cunha Santos",
    photoUrl: "",
    email: "mario@teste.demo",
    position: "Product Manager",
    grantedBy: "Álvaro Ferreira",
    grantedDate: "01/02/2023",
    access: ["Hours", "Equipments"],
    isLocked: false,
  },
];

export function ManagementPermissionsPage() {
  const navigate = useNavigate();
  const isPageActive = useActiveFeatures([FeatureFlags.EMPLOYEE_DOCUMENTS]);
  if (!isPageActive) {
    navigate("/error");
  }

  const windowResize = useWindowResize();
  const updatePageTitle = usePageTitle();
  const openPopup = usePopup();
  const openPanel = usePanel();

  useEffect(() => {
    updatePageTitle("Permissions");
  }, []);

  const columns: IColumn[] = [
    {
      key: "column1",
      name: "Employee",
      fieldName: "displayName",
      minWidth: 220,
      maxWidth: 400,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: false,
      onRender: (item) => (
        <div className="employee-info-container">
          <Persona
            className="employee-photo"
            imageUrl={item.photoUrl}
            size={PersonaSize.size40}
            imageInitials={getInitials(item.displayName, false, true)}
            onError={() => imageOnErrorHandler()}
          />
          <div className="employee-info truncate" key={item.email}>
            <div className="name truncate">{item.displayName}</div>
            <div className="mail truncate">{item.email}</div>
          </div>
        </div>
      ),
      data: "string",
    },
    {
      key: "column2",
      name: "Position",
      fieldName: "jobTitle",
      minWidth: 130,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      onRender: (item) => {
        if (item.position) return <div className="truncate">{item.position}</div>;
        return <>{"-"}</>;
      },
    },
    {
      key: "column3",
      name: "Granted by",
      fieldName: "grantedBy",
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: false,

      onRender: (item) => {
        if (item.grantedBy)
          return (
            <div className="employee-info truncate">
              <div className="name truncate">{item.grantedBy}</div>
              <div className="mail truncate">{item.grantedDate}</div>
            </div>
          );
        return <>{"-"}</>;
      },
      data: "string",
    },
    {
      key: "column4",
      name: "Access",
      fieldName: "access",
      minWidth: 280,
      maxWidth: 280,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: false,
      onRender: (item) => {
        if (item.access) return <>{item.access.map((a: string) => a + "; ")}</>;
        return <>{"-"}</>;
      },

      data: "string",
    },
    {
      className: "overflow-menu",
      key: "column8",
      name: "Overflow Menu",
      fieldName: "overflow",
      minWidth: 25,
      maxWidth: 25,
      isIconOnly: true,
      isCollapsible: false,
      onRender: (item) => (
        <OverflowSet
          aria-label="Basic Menu Example"
          role="menubar"
          items={[]}
          overflowItems={[
            {
              key: "item1",
              name: "See subordinates",
              onClick: () => openPanel(<SubordinatesListPanel position={item.position} displayName={item.displayName} />),
            },
            {
              key: "item2",
              name: "Remove management permissions",
              disabled: item.isLocked ? true : false,
              onClick: () => {},
            },
          ]}
          onRenderOverflowButton={onRenderOverflowButton}
          onRenderItem={onRenderItem}
        />
      ),
      data: "string",
    },
  ];

  const _renderRow = useCallback((props: any) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.item.isLocked) {
        customStyles.root = { backgroundColor: "#FDFFE8" };
      }
    }
    return <DetailsRow {...props} styles={customStyles} />;
  }, []);

  const _items: ICommandBarItemProps[] = [
    {
      className: "add-permission",
      key: "addPermission",
      text: windowResize > 768 ? "Add permission" : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () => openPopup(<AddPermission />),
    },
  ];

  return (
    <>
      <CardLayout classNames="permissions-container">
        <div className="commandbar">
          <SearchBox placeholder="Search" underlined={true} />
          <div className="action-btns">
            <CommandBar items={_items} ariaLabel="Action buttons" />
          </div>
        </div>
        <ShimmeredDetailsList
          className="employees-permissions-list"
          items={user_permissions || []}
          columns={columns}
          onShouldVirtualize={(_) => false}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onRenderRow={_renderRow}
        />
      </CardLayout>
    </>
  );
}
