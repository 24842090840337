import { optionProperties } from "@fluentui/react";
import { createContext, useCallback, useEffect, useState } from "react";
import { ConfigurationProvider } from "../configuration/ConfigurationProvider";

export interface IFeature {
  id: string;
}

export interface FeatureSvcsContextState {
  features: IFeature[];
}

export const FeatureSvcContext = createContext<FeatureSvcsContextState | null>(null);

interface IFeatureSvcsContextProps {
  children: React.ReactNode;
}

export function FeatureServices({ children }: IFeatureSvcsContextProps) {
  const [features, setFeatures] = useState<IFeature[]>([]);

  useEffect(() => {
    const activeFeatures = ConfigurationProvider.getConfiguration().ActiveFeatures.map(
      (str): IFeature => ({
        id: str,
      })
    );
    setFeatures(activeFeatures);
  }, []);

  const value = {
    features,
  };

  return <FeatureSvcContext.Provider value={value}>{children}</FeatureSvcContext.Provider>;
}
