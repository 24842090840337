import {
  CheckboxVisibility,
  ComboBox,
  DetailsListLayoutMode,
  IColumn,
  IComboBoxOption,
  IIconProps,
  IObjectWithKey,
  IconButton,
  Persona,
  PersonaSize,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  getInitials,
} from "@fluentui/react";
import { User, UsersService } from "../../../../services/UsersService";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CardLayout } from "../../../../components/layouts/card-layout/CardLayout";
import { useNavigate } from "react-router-dom";
import { useWindowResize } from "../../../../infrastructure/ui/UIServices";
import { InternationalizationProvider } from "../../../../infrastructure/i18n/InternationalizationProvider";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

const chevronLeftIcon: IIconProps = { iconName: "ChevronLeft" };
const chevronRightIcon: IIconProps = { iconName: "ChevronRight" };

var usersService = new UsersService();

const imageOnErrorHandler = (name: string) => {
  //Could use a method of this kind to choose what to do if the photo is empty
  //Replacing for some default or not.
  // console.log(`#EmployeeSelection: not possible to load %s photo's.`, name);
};

interface IEmployeeSelectionProps {
  onEmployeeChanged: (selectedEmployee: IComboBoxOption) => void;
  month: number;
  year: number;
  onNextMonthClicked: () => void;
  onPrevMonthClicked: () => void;
  setHeight: string;
}

export function EmployeeSelection(props: IEmployeeSelectionProps) {
 
  const navigate = useNavigate();
  const windowResize = useWindowResize();
  const ref = useRef<HTMLInputElement>(null);
  
 //const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const months = InternationalizationProvider.getCalendarMonths();

  const [selectedEmployee, setSelectedEmployee] = useState<IComboBoxOption>();
  const [employeesSpinner, setEmployeesSpinner] = useState<boolean>(true);
  const [employees, setEmployees] = useState<IComboBoxOption[]>([]);
  const [employeePartialName, setEmployeePartialName] = useState<string>();
  const [timer, setTimer] = useState<any>();

  useEffect(() => {
    if (ref.current) {
      ref.current.style.maxHeight = props.setHeight + "px";
    }
  }, [props.setHeight]);

  const selectionModel = useMemo(
    () =>
      new Selection({
        selectionMode: SelectionMode.single,
        getKey: (item: any) => "" + item.key,
        items: employees as IObjectWithKey[],
        onSelectionChanged: () => {
          if (selectionModel.getSelection() && selectionModel.getSelection()[0]) {
            setSelectedEmployee(selectionModel.getSelection()[0] as IComboBoxOption);
            selectionModel.toggleAllSelected();
          }
        },
      }),
    [employees]
  );

  const onInitEmployeeSelection = useCallback(
    (res: User[]) => {
      if (res) {
        var employeesList: IComboBoxOption[] = res.map((r) => {
          var employee: IComboBoxOption = {
            text: r.displayName,
            key: r.userPrincipalName,
            data: r.url,
          };
          return employee;
        });
        if (employeesList && employeesList.length) {
          selectionModel.setIndexSelected(0, true, false);
        }
      }
    },
    [selectionModel]
  );

  const mapUsersListToIComboBoxOption = useCallback(
    (users: User[]) => {
      var employeesList: IComboBoxOption[] = users.map((r) => {
        var employee: IComboBoxOption = {
          text: r.displayName,
          key: r.userPrincipalName,
          data: r.url,
        };
        return employee;
      });
      setEmployees(employeesList);
      setEmployeesSpinner(false);
    },
    [setEmployees, setEmployeesSpinner]
  );

  const handleSelectEmployee = useCallback(
    (item?: IComboBoxOption) => {
      if (item) {
        const idx = selectionModel.getItems().findIndex((i) => i.key == item.key);
        selectionModel.setIndexSelected(idx, true, false);
      }
    },
    [selectionModel]
  );

  useEffect(() => {
    setEmployeesSpinner(true);
    usersService
      .getByApproverType()
      .then((res) => {
        mapUsersListToIComboBoxOption(res);
        setEmployeesSpinner(false);
        onInitEmployeeSelection(res);
      })
      .catch(() => {
        setEmployeesSpinner(false);
        navigate("/error");
      });
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      props.onEmployeeChanged(selectedEmployee);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    setEmployeesSpinner(true);
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {
        usersService
          .searchUsersByName(employeePartialName || "")
          .then((res) => {
            mapUsersListToIComboBoxOption(res);
            setEmployeesSpinner(false);
          })
          .catch(() => {
            navigate("error");
            setEmployeesSpinner(false);
          });
      }
    }, 700);
    setTimer(responseTimeOut);
  }, [employeePartialName, setEmployeePartialName, setEmployeesSpinner]);

  const columns: IColumn[] = [
    {
      key: "column1",
      name: "Employee",
      fieldName: "employee",
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      onRender: (item) => (
        <Persona
          text={item.text}
          imageInitials={getInitials(item.text, false, true)}
          imageUrl={item.data}
          onError={() => imageOnErrorHandler(item.text)}
          size={PersonaSize.size24}
        />
      ),
      data: "string",
    },
  ];

  return (
    <div ref={ref} className="calendar-employee-list">
      <CardLayout classNames="calendar-employee-list">
        <div className="list-header">
          <h1>
            {windowResize < 375 ? months[props.month].slice(0, 3) : months[props.month]} {props.year}
          </h1>
          <div className="month-btns">
            <IconButton className="previous" iconProps={chevronLeftIcon} onClick={() => props.onPrevMonthClicked()} />
            <IconButton className="next" iconProps={chevronRightIcon} onClick={() => props.onNextMonthClicked()} />
          </div>
        </div>
        {windowResize > 1200 ? (
          <>
            <div className="search-box-container">
              <div className="search-box">
                <TextField
                  onChange={(ev, input) => {
                    setEmployeePartialName(input || "");
                  }}
                  placeholder={translate("COMMON.PlaceholderSearchEmployee")}
                  value={employeePartialName}
                />
              </div>
            </div>
            <ShimmeredDetailsList
              enableShimmer={employeesSpinner}
              onShouldVirtualize={(_) => false}
              className="employee-list content-scroll"
              items={employees}
              columns={columns}
              getKey={(x) => x && x.key}
              selection={selectionModel}
              onRenderRow={(props, defaultRender) => {
                return <div className={props?.item.key === selectedEmployee?.key ? "selected" : undefined}>{defaultRender && defaultRender(props)}</div>;
              }}
              layoutMode={DetailsListLayoutMode.justified}
              checkboxVisibility={CheckboxVisibility.hidden}
              isHeaderVisible={false}
            />
          </>
        ) : (
          <ComboBox
            label={translate("COMMON.Employee") +"s"}
            allowFreeform={true}
            autoComplete={"on"}
            options={employees}
            selectedKey={selectedEmployee?.key}
            onItemClick={(ev, item, index) => {
              handleSelectEmployee(item);
            }}
            onChange={(event, item) => {
              if (item) handleSelectEmployee(item);
            }}
            onRenderOption={(item) => {
              if (item) {
                return (
                  <>
                    <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                    {/* TODO: approved days
                                 <div className="approved-days">{item.data}</div> */}
                  </>
                );
              }
              return null;
            }}
            required={true}
          />
        )}
      </CardLayout>
    </div>
  );
}
