import "./ManagementProjectsPage.css";

import {
  CommandBar,
  DetailsListLayoutMode,
  DetailsRow,
  Facepile,
  FontIcon,
  IColumn,
  ICommandBarItemProps,
  IDetailsRowStyles,
  IFacepilePersona,
  IIconProps,
  IOverflowSetItemProps,
  IconButton,
  Link,
  OverflowButtonType,
  OverflowSet,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  TooltipDelay,
  TooltipHost,
  getInitials,
} from "@fluentui/react";
import { Project, ProjectsService } from "../../../services/ProjectsService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useOverlaySpinner, usePopup, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { AddProjectPopup } from "./popups/AddProjectPopup";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { EditProjectInfoPopup } from "./EditProjectInfoPopup";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import avatar from "../../../assets/avatar.png";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";

const disableIcon: IIconProps = { iconName: "RedEye" };
const disabledIcon: IIconProps = { iconName: "Hide" };
const add: IIconProps = { iconName: "Add" };

var projectsService = new ProjectsService();

const imageOnErrorHandler = () => {
  //Could use a method of this kind to choose what to do if the photo is empty. Replacing for some default or not.
};

// interface IOrderInfo {
//   columnName: string;
//   orderBy?: string;
//   direction: "ASC" | "DESC";
// }

function removeNullValues(data: Project[]) {
  return data.filter((obj) => {
    return obj.name !== null; //fix this method
  });
}

//On render overflow menu items
const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <Link role="menuitem" onClick={item.onClick}>
      {item.name}
    </Link>
  );
};

//On render overflow menu
const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return <IconButton role="menuitem" title={translate("COMMON.OtherOptions")} menuIconProps={{ iconName: "More" }} menuProps={{ items: overflowItems! }} />;
};

export function ManagementProjectsPage() {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_EMPLOYEE_LIST]);
  const windowResize = useWindowResize();
  const updatePageTitle = usePageTitle();
  const [projectsSpinner, setProjectsSpinner] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isStopSpinner, setIsStopSpinner] = useState<boolean>(false);

  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    updatePageTitle(translate("MENU.Projects"));
    setProjectsSpinner(true);
    getProjects();
  }, []);

  const getProjects = useCallback(() => {
    projectsService
      .getProjects()
      .then((res) => {
        if (res !== undefined) {
          var filteredProjects = removeNullValues(res);
          setProjects(filteredProjects);
        }
        setProjectsSpinner(false);
      })
      .catch(() => {
        setProjectsSpinner(false);
        navigate("/error");
      });
  }, [setProjects, setProjectsSpinner, navigate]);

  const disableProject = useCallback(
    (id: number) => {
      projectsService
        .deleteProject(id)
        .then(() => {
          setIsStopSpinner(true);
          getProjects();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("PROJECTS.DISABLE.ProjectDisabled")}</p>
            </FeedbackPopup>
          );
        })
        .catch((error) => {
          if (error !== undefined) {
            setIsStopSpinner(true);
            openPopup(
              <FeedbackPopup type="error">
                <p>{error}</p>
              </FeedbackPopup>
            );
          }
        });
    },
    [setIsStopSpinner, openPopup, getProjects]
  );

  const _items: ICommandBarItemProps[] = [
    {
      className: "refresh-cache",
      key: "refreshCache",
      text: windowResize > 768 ? translate("PROJECTS.COMMANDBAR.RefreshCache") : "",
      iconProps: windowResize > 768 ? { iconName: "Refresh" } : { iconName: "Refresh" },
      onClick: () => {
        window.terminal("cache reset hours").then((_) => window.location.reload());
      },
    },
    {
      className: "add-project",
      key: "addProject",
      role: "add-prj-commandBar",
      data: "mydata-test",
      text: windowResize > 768 ? translate("PROJECTS.AddProject") : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () =>
        openPopup(
          <AddProjectPopup
            onAddProjectCompleted={() => {
              getProjects();
              setProjectsSpinner(true);
            }}
          />
        ),
    },
  ];

  // const overflowButtonProps = {
  //   ariaLabel: "More employees",
  //   onClick: (ev: React.MouseEvent<HTMLButtonElement>) => alert("overflow icon clicked"),
  // };

  // const employees: IFacepilePersona[] = [
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  //   {
  //     imageInitials: getInitials("John Doe", false, true),
  //     imageUrl: avatar,
  //   },
  // ];

  // const addButtonProps = (projectId: string) => ({
  //   ariaLabel: "Add a new employee",
  //   onClick: (ev: React.MouseEvent<HTMLButtonElement>) => {
  //     ev.stopPropagation();
  //     openPopup(<AddEmployeePopup projectId={projectId} />);
  //   },
  // });

  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: translate("COMMON.Code"),
        fieldName: "id",
        minWidth: 200,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        onRender: (item) => {
          if (item.projectId) return <div className={"truncate" + (item.isDisable ? " disable" : "")}>{item.projectId}</div>;
          return <>{"-"}</>;
        },
        data: "string",
      },
      {
        key: "column2",
        name: translate("COMMON.Name"),
        fieldName: "projectName",
        minWidth: 130,
        maxWidth: 300,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        onRender: (item) => <div className={"truncate" + (item.isDisable ? " disable" : "")}>{item.name}</div>,
        data: "string",
      },
      {
        key: "column3",
        name: translate("COMMON.Client"),
        fieldName: "client",
        minWidth: 200,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        onRender: (item) => {
          if (item.name) return <div className={"truncate" + (item.isDisable ? " disable" : "")}>{item.client ? item.client : "-"}</div>;
          return <>{"-"}</>;
        },
        data: "string",
      },
      {
        key: "column4",
        name: "N.º Act.",
        fieldName: "activityCount",
        minWidth: 80,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        onRender: (item) => {
          if (item.name) return <div className={"truncate" + (item.isDisable ? " disable" : "")}>{item.activityCount ? item.activityCount : "-"}</div>;
          return <>{"-"}</>;
        },
        data: "string",
      },
      {
        key: "column5",
        name: "Vis.",
        fieldName: "isPublic",
        minWidth: 40,
        maxWidth: 40,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        onRender: (item) => {
          return <FontIcon className={item.isDisable ? "disable" : ""} aria-label={item.isPublic ? "Public" : "Private"} iconName={item.isPublic ? "" : "LockSolid"} />;
        },
        data: "string",
      },
      {
        key: "column6",
        name: translate("COMMON.Status"),
        fieldName: "projectStatus",
        minWidth: 40,
        maxWidth: 40,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        onRender: (item) => (
          <>
            <TooltipHost
              content={item.isDisable ? translate("PROJECTS.DISABLE.ProjectDisabled") : translate("PROJECTS.DISABLE.DisableProject")}
              delay={TooltipDelay.medium}
            >
              <IconButton
                role={"disable-prj-"+ item.projectId}
                iconProps={!item.isDisable ? disableIcon : disabledIcon}
                disabled={item.isDisable ? true : false}
                title={translate("PROJECTS.DISABLE.DisableProject")}
                ariaLabel="Disable Project"
                onClick={(e) => {
                  e.stopPropagation();
                  openPopup(
                    <FeedbackPopup
                      type="warning"
                      title={translate("PROJECTS.DISABLE.DisableProject") + "?"}
                      primaryActionLabel={translate("ACTIONS.Deactivate")}
                      primaryActionFunc={() => {
                        disableProject(item.id);
                      }}
                    >
                      <p>{translate("PROJECTS.DISABLE.AreYouSureYouWantDisable")}</p>
                    </FeedbackPopup>
                  );
                }}
              />
            </TooltipHost>
          </>
        ),
        data: "string",
      },
      {
        className: "overflow-menu",
        key: "column8",
        name: "Overflow Menu",
        fieldName: "overflow",
        minWidth: 30,
        maxWidth: 30,
        isIconOnly: true,
        isCollapsible: false,
        onRender: (item) => (
          <OverflowSet
            aria-label="Basic Menu Example"
            role="menubar"
            items={[]}
            overflowItems={[
              {
                key: "item1",
                name: translate("ACTIONS.Edit"),
                disabled: item.isDisable ? true : false,
                onClick: () =>
                  openPopup(
                    item.id ? (
                      <EditProjectInfoPopup
                        projectId={item?.id}
                        onOperationCompleted={() => {
                          getProjects();
                        }}
                      />
                    ) : (
                      <FeedbackPopup type="error" title={translate("PROJECTS.ERRORS.InvalidProjectId")} primaryActionLabel={translate("ACTIONS.Close")}>
                        <p>
                          {translate("PROJECTS.ERRORS.LoadingProject")} ({item?.id}).
                        </p>
                      </FeedbackPopup>
                    )
                  ),
              },
              {
                key: "item2",
                name: translate("ACTIONS.View"),
                onClick: () => navigate(item?.id + ""),
              },
            ]}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />
        ),
        data: "string",
      },
    ];
  }, [disableProject, openPopup, getProjects]);

  const _renderRow = useCallback(
    (props: any) => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if (props.itemIndex % 2 === 0) {
          customStyles.root = { backgroundColor: "#fafafa" };
        }
      }
      return <DetailsRow {...props} styles={customStyles} onClick={() => navigate("" + props.item.id)} />;
    },
    [navigate]
  );

  return (
    <CardLayout classNames="projects-list-card">
      {!projectsSpinner && projects.length === 0 ? (
        <div className="empty">
          <div className="empty-title">No projects yet</div>
          <p>The project list is empty. Try add one!</p>
          <SecondaryButton
            dataTest="add-prj-empty-list"
            text="Add Project"
            icon={add}
            onClick={() =>
              openPopup(
                <AddProjectPopup
                  onAddProjectCompleted={() => {
                    getProjects();
                    setProjectsSpinner(true);
                  }}
                />
              )
            }
          />
        </div>
      ) : (
        <>
          <div className="commandbar">
            <TextField
              disabled={true}
              className="search-input no-margin"
              /*  onChange={(ev, input) => {
              setMyState(input);
              }}
            value={myState} */
              placeholder={translate("PROJECTS.COMMANDBAR.PHSearchProject")}
            />
            <div className="action-btns">
              <CommandBar items={_items} ariaLabel="Action buttons" />
            </div>
          </div>
          <ShimmeredDetailsList
            data-test={"projects-list"}
            enableShimmer={projectsSpinner}
            className="projects-list"
            items={projects}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            onShouldVirtualize={(_) => false}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={_renderRow}
          />
        </>
      )}
    </CardLayout>
  );
}

/*   const handleColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
      let newOrderInfo: IOrderInfo | null = {
        columnName: column.key,
        orderBy: column.fieldName!,
        direction: "ASC",
      };
      if (orderInfo && orderInfo.columnName === column.key) {
        if (orderInfo.direction === "ASC") {
          newOrderInfo.direction = "DESC";
        } else newOrderInfo.direction = "ASC";
      }
      setOrderInfo(newOrderInfo);
    },
    [orderInfo, setOrderInfo]
  ); */
