import axios, { AxiosError, AxiosResponse } from "axios";

function handleResponse(response: AxiosResponse<any, any>) {
  return response;
}

function handleError(error: any) {
  console.warn("Interceptor", error);
  const err = error as AxiosError;

  console.log(error);
  if (axios.isCancel(error)) {
    console.warn("Previous request cancelled by the user.");
  }
  if (err.config.url?.includes("Calendar/Holidays")) {
    throw error;
  }
  if (error.code === "ERR_NETWORK") {
    window.location.href = "/NetworkError";
    return;
  }
  if (error.code === "ERR_BAD_RESPONSE") {
    window.location.href = "/error";
    return;
  }
  throw error;
}

export function AddAxiosInterceptors() {
  axios.interceptors.response.use(handleResponse, handleError);
}
