import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../infrastructure/http/HttpClient";
import { AddApprovalGroupMemberRequestDTO } from "../models/api/approval-groups/AddApprovalGroupMemberRequestDTO";
import { ApprovalGroupsResponseDTO } from "../models/api/approval-groups/ApprovalGroupsResponseDTO";
import { CreateApprovalGroupRequestDTO } from "../models/api/approval-groups/CreateApprovalGroupRequestDTO";
import { GetApprovalGroupResponseDTO } from "../models/api/approval-groups/GetApprovalGroupResponseDTO";
import { UpdateApprovalGroupRequestDTO } from "../models/api/approval-groups/UpdateApprovalGroupRequestDTO";
import { ApprovalGroup } from "../models/approval-groups/ApprovalGroup";
import { ApprovalGroupInfo } from "../models/approval-groups/ApprovalGroupInfo";
import { ApprovalGroupMember } from "../models/approval-groups/ApprovalGroupMember";

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class ApprovalGroupsService {
  getAllApprovalGroups(): Promise<ApprovalGroup[]> {
    return HttpClient.sessionRequest<ApprovalGroupsResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/ApprovalGroups`),
    })
      .then((response) => {
        return response.data.groups.map(
          (p): ApprovalGroup => ({
            id: p.id,
            code: p.code,
            name: p.name,
            totalMembers: p.totalMembers,
            totalApprovers: p.totalApprovers,
          })
        );
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  createApprovalGroup(code: string, name: string, approversEmails: string[], membersEmails: string[]): Promise<void> {
    let request: CreateApprovalGroupRequestDTO = {
      code: code,
      name: name,
      approversEmails: approversEmails,
      membersEmails: membersEmails,
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/ApprovalGroups`),
      data: request,
    })
      .then((_) => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  getApprovalGroupInfo(approvalGroupCode: string): Promise<ApprovalGroupInfo> {
    return HttpClient.sessionRequest<GetApprovalGroupResponseDTO>({
      method: "GET",
      url: Route(`/api/v1/ApprovalGroups/${approvalGroupCode}`),
    })
      .then((r) => {
        var mapped: ApprovalGroupInfo = {
          id: r.data.id,
          groupCode: r.data.code,
          groupName: r.data.name,
          approversEmployees: r.data.approvers.map(
            (item): ApprovalGroupMember => ({
              groupMemberId: item.groupMemberId,
              employee: {
                url: item.employee.photoURL,
                displayName: item.employee.displayName,
                userPrincipalName: item.employee.principalName,
              },
            })
          ),
          membersEmployees: r.data.members.map(
            (item): ApprovalGroupMember => ({
              groupMemberId: item.groupMemberId,
              employee: {
                url: item.employee.photoURL,
                displayName: item.employee.displayName,
                userPrincipalName: item.employee.principalName,
              },
            })
          ),
        };
        return mapped;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateGroupDetails(groupId: number, groupName: string) {
    var request: UpdateApprovalGroupRequestDTO = {
      approvalGroupId: groupId,
      name: groupName,
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/v1/ApprovalGroups/ApprovalGroup`),
      data: request,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  addGroupMember(email: string, groupCode: string, isApprover: boolean): Promise<void> {
    let request: AddApprovalGroupMemberRequestDTO = {
      approvalGroupCode: groupCode,
      memberEmail: email,
      isApprover: isApprover,
    };

    return HttpClient.sessionRequest({
      method: "POST",
      url: Route(`/api/v1/ApprovalGroups/ApprovalGroupMember`),
      data: request,
    })
      .then((_) => {
        return;
      })
      .catch((error) => {
        throw error;
      });
  }

  removeGroupMember(groupMemberId: number, code: string): Promise<void> {
    let queryParams = {
      memberId: groupMemberId,
      approvalGroupCode: code,
    };
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/ApprovalGroups/ApprovalGroupMember`),
      params: queryParams,
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  removeApprovalGroup(approvalGroupId: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/v1/ApprovalGroups/${approvalGroupId}`),
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }
}
