import React, { useCallback, useState } from "react";
import "./ConfirmHoursApprovalPopup.css";
import { useClosePanel, usePopup } from "../../../../infrastructure/ui/UIServices";
import { ApproveDailyRecordsRequestDTO, HoursService } from "../../../../services/HoursService";
import dayjs from "dayjs";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { DomainExceptionResponse, ErrorTypeEnum } from "../../../../models/errors/DomainExceptions";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

interface IConfirmHoursApprovalPopupProps {
  confirmation: () => void;
  selectedDays?: { dailyRecordId: number; day: Date }[];
}

var hoursService = new HoursService();
export function ConfirmHoursApprovalPopup(props: IConfirmHoursApprovalPopupProps) {
  const openPopup = usePopup();
  const closePanel = useClosePanel();

  const [spinner, setSpinner] = useState<boolean>(false);
  const [isStopSpinner, setIsStopSpinner] = useState<boolean>(false);

  const onClickApproveButton = useCallback(() => {
    setSpinner(true);
    var getRecordsIdsSelected = props.selectedDays?.map((obj) => {
      var op = obj.dailyRecordId;
      return op;
    });
    var request: ApproveDailyRecordsRequestDTO = {
      dailyRecordsIds: getRecordsIdsSelected || [],
    };
    hoursService
      .approveDailyRecords(request)
      .then(() => {
        props.confirmation();
        setIsStopSpinner(true);
        openPopup(
          <FeedbackPopup type="success">
            <p>{translate("MANAGERHOURS.APPROVE.SuccessResponse")}</p>
          </FeedbackPopup>
        );
        closePanel();
      })
      .catch((err) => {
        try {
          var parsedResponse: DomainExceptionResponse = JSON.parse(err);
          if (parsedResponse.AlertType === ErrorTypeEnum.ERROR) {
            openPopup(
              <FeedbackPopup type="error">
                <p>{parsedResponse.Response}</p>
              </FeedbackPopup>
            );
          }
        } catch {
          setIsStopSpinner(true);
          openPopup(
            <FeedbackPopup type="error">
              <p>{err}</p>
            </FeedbackPopup>
          );
        }
      });
  }, [props.selectedDays, props.confirmation, spinner, openPopup]);

  return (
    <div className="confirm-hours-approval-popup">
      <FeedbackPopup
        type="warning"
        title={translate("MANAGERHOURS.APPROVE.Title")}
        primaryActionLabel={translate("ACTIONS.Approve")}
        primaryActionFunc={() => {
          onClickApproveButton();
        }}
        isStopSpinner={isStopSpinner}
      >
        <p>{translate("MANAGERHOURS.APPROVE.Message")}</p>
      </FeedbackPopup>
    </div>
  );
}
