import "./../../App.css";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { ActivityInfoPage } from "./projects/ActivityInfoPage";
import { AllocationsPage } from "./allocations/AllocationsPage";
import { EmployeeInfoPage } from "../employees/employee-info/EmployeeInfoPage";
import { EmployeeListPage } from "./employee-list/EmployeeListPage";
import { EquipmentListPage } from "./equipment-list/EquipmentListPage";
import { FeatureFlags } from "../../infrastructure/features/Flags";
import { Header } from "../../components/header/Header";
import { ReactComponent as IconNavEmployees } from "../../assets/employee-list-icon.svg";
import { ReactComponent as IconNavGroups } from "../../assets/groups-approval-icon.svg";
import { ReactComponent as IconNavEquipments } from "../../assets/equipment-icon.svg";
import { ReactComponent as IconNavHours } from "../../assets/hours-icon.svg";
import { ReactComponent as IconNavProjects } from "../../assets/projects-list-icon.svg";
import { ReactComponent as IconNavWorkingplaces } from "../../assets/working-place.svg";
import { ReactComponent as IconPermissions } from "../../assets/permissions-menu-icon.svg";

import { ManagementProjectsPage } from "./projects/ManagementProjectsPage";
import { MobileNav } from "../../components/navbar/MobileNav";
import { ModuleDefinition } from "../../infrastructure/modules/ModuleDefinition";
import { ModuleHelper } from "../../infrastructure/modules/ModuleHelper";
import { N4UPermissions } from "../../infrastructure/identity/Permissions";
import { NavBar } from "../../components/navbar/NavBar";
import { ProjectInfoPage } from "./projects/ProjectInfoPage";
import React from "react";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { usePermission } from "../../infrastructure/identity/hooks/usePermissions";
import { useRegisterBreadcrumbRoutes } from "../../infrastructure/navigation/hooks/useBreadcrumb";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

import { ManagementHoursPage } from "./hours/ManagementHoursPage";
import { ManagementPermissionsPage } from "./permissions/ManagementPermissionsPage";
import { ManagementGroupsPage } from "./approval-groups/ManagementGroupsPage";
import { ApprovalGroupInfoPage } from "./approval-groups/ApprovalGroupInfoPage";

const moduleDefinition: ModuleDefinition = {
  basePath: "/manager",
  routes: [
    {
      name: () => translate("MENU.Employees"),
      pathPattern: "/employees",
      approverHasAccess: false,
      menuIcon: <IconNavEmployees />,
      element: <EmployeeListPage />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
    },
    {
      name: () => "##EMPLOYEE##",
      pathPattern: "/employees/:id",
      menuIcon: null,
      approverHasAccess: false,
      element: <EmployeeInfoPage />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST, FeatureFlags.MANAGER_EMPLOYEE_CV],
    },
    {
      name: () => translate("MENU.Equipments"),
      pathPattern: "/equipments",
      approverHasAccess: false,
      menuIcon: <IconNavEquipments />,
      element: <EquipmentListPage />,
      features: [FeatureFlags.MANAGER_HOURS],
    },
    {
      name: () => "##DEVICE##",
      pathPattern: "/equipments/:id/allocations",
      menuIcon: null,
      approverHasAccess: false,
      element: <AllocationsPage />,
      features: [FeatureFlags.MANAGER_ALLOCATION_EQUIPMENT],
    },
    {
      name: () => translate("MENU.Hours"),
      pathPattern: "/hours",
      menuIcon: <IconNavHours />,
      approverHasAccess: true,
      element: <ManagementHoursPage />,
      features: [FeatureFlags.MANAGER_HOURS],
    },
    {
      name: () => translate("MENU.Projects"),
      pathPattern: "/projects",
      menuIcon: <IconNavProjects />,
      approverHasAccess: false,
      element: <ManagementProjectsPage />,
      features: [FeatureFlags.MANAGER_PROJECTS_LIST],
    },
    {
      name: () => "##PROJECT##",
      pathPattern: "/projects/:id",
      menuIcon: null,
      approverHasAccess: false,
      element: <ProjectInfoPage />,
      features: [FeatureFlags.MANAGER_PROJECTS_LIST, FeatureFlags.MANAGER_PROJECT_INFO],
    },
    {
      name: () => "##ACTIVITY##",
      pathPattern: "/projects/:id/activities/:actId",
      menuIcon: null,
      approverHasAccess: false,
      element: <ActivityInfoPage />,
      features: [FeatureFlags.MANAGER_PROJECTS_LIST, FeatureFlags.MANAGER_PROJECT_INFO],
    },
    {
      name: () => "Groups",
      pathPattern: "/groups",
      menuIcon: <IconNavGroups />,
      approverHasAccess: false,
      element: <ManagementGroupsPage />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
    },
    {
      name: () => "##GROUPS##",
      pathPattern: "/groups/:id",
      menuIcon: null,
      approverHasAccess: false,
      element: <ApprovalGroupInfoPage />,
      features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
    },
    {
      name: () => "Permissions",
      pathPattern: "/permissions",
      menuIcon: <IconPermissions />,
      approverHasAccess: false,
      element: <ManagementPermissionsPage />,
      features: [FeatureFlags.EMPLOYEE_DOCUMENTS],
    },
  ],
};

export function PeopleManagementModule() {
  const navigate = useNavigate();

  const isManager = usePermission(N4UPermissions.MANAGER);
  const isApprover = usePermission(N4UPermissions.APPROVER);

  if (!isManager && !isApprover) {
    navigate("/error"); //TODO FIX THIS ACCESS DENIED
  }

  const windowResize = useWindowResize();
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

  const menu = () => {
    if (isManager) {
      moduleDefinition.routes.push({
        name: () => "",
        pathPattern: "/",
        menuIcon: null,
        approverHasAccess: false,
        element: <Navigate to="employees" />,
        features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
      });

      return [
        ModuleHelper.generateFromModuleRoute(moduleDefinition, 0),
        ModuleHelper.generateFromModuleRoute(moduleDefinition, 2),
        ModuleHelper.generateFromModuleRoute(moduleDefinition, 4),
        ModuleHelper.generateFromModuleRoute(moduleDefinition, 5),
        ModuleHelper.generateFromModuleRoute(moduleDefinition, 8),
        ModuleHelper.generateFromModuleRoute(moduleDefinition, moduleDefinition.routes.length - 1),
      ];
    }

    if (isApprover && !isManager) {
      moduleDefinition.routes.push({
        name: () => "",
        pathPattern: "/",
        menuIcon: null,
        approverHasAccess: true,
        element: <Navigate to="hours" />,
        features: [FeatureFlags.MANAGER_EMPLOYEE_LIST],
      });

      return [ModuleHelper.generateFromModuleRoute(moduleDefinition, 4)];
    }
  };
                    
  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  return (                                       
    <>
      <div className="App">
        {windowResize > 768 ? <NavBar buttons={menu() || []} /> : null}

        <div className="content-wrapper">
          <Header btnActiveText={translate("AREA.Manager")} />
          <div className="content">
            <Routes>
              {isManager
                ? moduleDefinition.routes.map((r) => <Route key={r.pathPattern} path={r.pathPattern} element={r.element} />)
                : moduleDefinition.routes.filter((r) => r.approverHasAccess).map((r) => <Route key={r.pathPattern} path={r.pathPattern} element={r.element} />)}
            </Routes>
          </div>
        </div>
      </div>
      {windowResize <= 768 ? <MobileNav buttons={menu() || []} btnActiveText={translate("AREA.SHORT.Manager")} /> : null}
    </>
  );
}
