import "./TertiaryButton.css";
import { DefaultButton, IIconProps } from "@fluentui/react";

interface ITertiaryButtonProps {
  children?: React.ReactNode;
  additionalClass?: string;
  text: string;
  icon?: IIconProps;
  onClick?: () => void;
  isDisabled?: boolean;
}

export function TertiaryButton(props: ITertiaryButtonProps) {
  return (
    <DefaultButton
      className={"tertiary-btn " + (props.additionalClass ? props.additionalClass : "")}
      text={props.text}
      iconProps={props.icon}
      onClick={() => props.onClick?.()}
      disabled={props.isDisabled}
    >
      {props.children}
    </DefaultButton>
  );
}
