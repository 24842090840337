import { Navigate, Route, Routes } from "react-router-dom";

import { IdentityAction } from "./infrastructure/identity/components/IdentityAction";
import { SessionExpiredPage } from "./areas/common/session-expired/SessionExpiredPage";

export function AppIdentityRoutingModule(){

    return (
        <Routes>
           	<Route path="/login" element={<IdentityAction exec={(svc) => svc.loginBackToUrl("me")} />}/>
            <Route path="/logout" element={ <IdentityAction exec={(svc) => svc.logout()} />}/>
            <Route path="/sessionexpired" element={<SessionExpiredPage/>}/>

            <Route path="*" element={<Navigate to="/notfound"/>}/>
        </Routes>
    )
}
