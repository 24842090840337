import { MsalProvider, useMsal } from "@azure/msal-react";
import React, { ReactNode, useEffect } from "react";

import { IdentityNavigationClient } from "../Identity.NavigationClient";
import { IdentityProvider } from "../IdentityProvider";
import { IdentityService } from "../IdentityService";
import { IdentityServices } from "../IdentityContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface IIdentityAppProps {
  children: ReactNode;
}

export function IdentityApp(props: IIdentityAppProps) {
  const navigate = useNavigate();

  useEffect(() => {
    const navigationClient = new IdentityNavigationClient(navigate);
    IdentityService.setNavigationClient(navigationClient);
    IdentityProvider.getMsalClient().setNavigationClient(navigationClient);
  }, [navigate]);

  return (
    <ClientSideNavigation>
      <MsalProvider instance={IdentityProvider.getMsalClient()}>
        <IdentityServices>
          <IdentityProgressInfo>{props.children}</IdentityProgressInfo>
        </IdentityServices>
      </MsalProvider>
    </ClientSideNavigation>
  );
}

export function IdentityProgressInfo(props: IIdentityAppProps) {
  const { inProgress } = useMsal();

  useEffect(() => {
    console.log("::: PROGRESS CHANGE", inProgress);
    IdentityService.setInProgress(inProgress);
  }, [inProgress]);

  return <>{props.children}</>;
}

function ClientSideNavigation(props: IIdentityAppProps) {
  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return <>{props.children}</>;
}
