import { ConfigurationProvider } from "../infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../infrastructure/http/HttpClient";

export interface User {
  displayName: string;
  userPrincipalName: string;
  url: string;
}

export interface UserProfile {
  mobilePhone: string;
  email: string;
  displayName: string;
  jobTitle: string;
  postalCode: string;
  district: string;
  streetAddress: string;
  city: string;
  country: string;
  url: string;
}

export interface UserHeader {
  displayName: string;
  email: string;
  url: string;
}

const Route = (path: string) => {
  return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class UsersService {
  getAll(): Promise<User[]> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Users/AllUsers`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }



  getByApproverType(): Promise<User[]> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Users/UsersByApprover`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getProfile(userPrincipalName: string): Promise<UserProfile> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Profile?userPrincipalName=${userPrincipalName}`),
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  searchUsersByName(principalNameToSearch: string): Promise<User[]> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Users/search?byName=${principalNameToSearch}`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getAllUsersProfile(): Promise<UserProfile[]> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Profile/GetAll`),
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }

  getSortedEmployeesList(searchByName?: string, orderBy?: string, direction?: string): Promise<UserProfile[]> {
    let queryString = {
      searchByName: searchByName,
      orderBy: orderBy,
      direction: direction,
    };
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/v1/Profile/filters`),
      params: queryString,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error.response?.data;
      });
  }
}
