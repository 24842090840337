import "./SubordinatesListPanel.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Checkbox, ComboBox, DetailsList, DetailsListLayoutMode, getInitials, IColumn, IComboBoxOption, List, Persona, PersonaSize, Stack } from "@fluentui/react";
import { CardHeader } from "../../../components/cards/CardHeader";

import { useClosePanel } from "../../../infrastructure/ui/UIServices";
import { User, UsersService } from "../../../services/UsersService";

const stackTokens = { childrenGap: 10 };

export interface SubordinatesListProps {
  displayName: string;
  position?: string;
}

var usersService = new UsersService();

export function SubordinatesListPanel(props: SubordinatesListProps) {
  const closePanel = useClosePanel();

  const navigate = useNavigate();
  const [employees, setEmployees] = useState<User[]>([]);

  useEffect(() => {
    usersService
      .getAll()
      .then((res) => {
        setEmployees(res.slice(0, 10));
      })
      .catch(() => {
        navigate("/error");
      });
  }, []);

  const _columns: IColumn[] = [
    {
      key: "column1",
      name: "Employee",
      fieldName: "displayName",
      minWidth: 10,
      maxWidth: 160,
      isRowHeader: true,
      isResizable: true,
      onRender: (item) => <div className="name truncate">{item.displayName}</div>,
      data: "string",
    },
    {
      key: "column2",
      name: "Position",
      fieldName: "userPrincipalName",
      minWidth: 85,
      maxWidth: 85,
      isRowHeader: true,
      isResizable: true,
      onRender: (item) => <div className="name truncate">{item.userPrincipalName}</div>,
      data: "string",
    },
  ];

  const onRenderCell = (item?: User): JSX.Element => {
    if (item) {
      return (
        <Persona
          className="employee-photo"
          imageUrl={item.url}
          size={PersonaSize.size32}
          imageInitials={getInitials(item.displayName, false, true)}
          text={item.displayName}
        />
      );
    } else return <></>;
  };

  return (
    <div className="subordinates-employees-info">
      <CardHeader headerTitle={"Subordinates"} children={props.displayName + " - " + props.position} icon={true} setDisplayState={() => closePanel()}></CardHeader>
      <div className="subordinates-employees-info-content">
        <List items={employees} onRenderCell={onRenderCell} />
      </div>
    </div>
  );
}
