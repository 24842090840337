import "./ManagementGroupsPage.css";

import {
  CommandBar,
  DetailsListLayoutMode,
  DetailsRow,
  Facepile,
  IColumn,
  ICommandBarItemProps,
  IDetailsRowStyles,
  IFacepilePersona,
  IIconProps,
  IOverflowSetItemProps,
  IconButton,
  Link,
  OverflowSet,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  getInitials,
} from "@fluentui/react";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useOverlaySpinner, usePopup, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { SecondaryButton } from "../../../components/buttons/SecondaryButton";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { ApprovalGroup } from "../../../models/approval-groups/ApprovalGroup";
import { ApprovalGroupsService } from "../../../services/ApprovalGroupsService";
import { EmployeeDTO } from "../../../models/api/equipments/EmployeeDTO";
import { AddGroupPopup } from "./popup/AddGroupPopup";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { ApprovalGroupInfoPage } from "./ApprovalGroupInfoPage";
import { EditApprovalGroupInfoPopup } from "./popup/EditApprovalGroupInfoPopup";
import { isTemplateExpression } from "typescript";

const add: IIconProps = { iconName: "Add" };

var approvalService = new ApprovalGroupsService();

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return (
    <Link role="menuitem" onClick={item.onClick}>
      {item.name}
    </Link>
  );
};

const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element => {
  return <IconButton role="menuitem" title={"Outras opções"} menuIconProps={{ iconName: "More" }} menuProps={{ items: overflowItems! }} />;
};

export function ManagementGroupsPage() {
  const navigate = useNavigate();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_APPROVALGROUPS_LIST]);
  const windowResize = useWindowResize();
  const updatePageTitle = usePageTitle();
  const [groupsSpinner, setGroupsSpinner] = useState<boolean>(true);
  const [groups, setGroups] = useState<ApprovalGroup[]>([]);
  const [isStopSpinner, setIsStopSpinner] = useState<boolean>(false);

  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    updatePageTitle("Time Approval Groups");
    getApprovalGroups();
  }, []);

  const getApprovalGroups = useCallback(() => {
    setGroupsSpinner(true);
    approvalService
      .getAllApprovalGroups()
      .then((res) => {
        setGroups(res);
        setGroupsSpinner(false);
      })
      .catch((err) => {
        setGroupsSpinner(false);
        openPopup(
          <FeedbackPopup type="error">
            <p>{err}</p>
          </FeedbackPopup>
        );
      });
  }, [setGroups, setGroupsSpinner, openPopup, navigate]);

  const onClickRemoveApprovalGroup = useCallback(
    (approvalGroupId: number) => {
      approvalService
        .removeApprovalGroup(approvalGroupId)
        .then(() => {
          openPopup(
            <FeedbackPopup type="success">
              <p>{"Approval Group removed with success."}</p>
            </FeedbackPopup>
          );
          getApprovalGroups();
          setGroupsSpinner(true);
        })
        .catch((error) => {
          if (error.response.data) {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.response.data}</p>
              </FeedbackPopup>
            );
          } else {
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.code} </p>
                <p>{error.message}</p>
              </FeedbackPopup>
            );
          }
        });
    },
    [setIsStopSpinner, openPopup, getApprovalGroups]
  );

  const _items: ICommandBarItemProps[] = [
    {
      className: "add-group",
      key: "addGroup",
      text: windowResize > 768 ? "Add group" : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () =>
        openPopup(
          <AddGroupPopup
            onAddGroupCompleted={() => {
              getApprovalGroups();
            }}
          />
        ),
    },
  ];

  const columns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: translate("COMMON.Code"),
        fieldName: "code",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column2",
        name: translate("COMMON.Name"),
        fieldName: "name",
        minWidth: 350,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        data: "string",
      },
      {
        key: "column3",
        name: "Approvers",
        fieldName: "totalApprovers",
        minWidth: 250,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        onRender: (item) => {
          return <>{item.totalApprovers}</>;
        },
        data: "string",
      },
      {
        key: "column4",
        name: "Members",
        fieldName: "totalMembers",
        minWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isCollapsible: true,
        onRender: (item) => {
          return <>{item.totalMembers}</>;
        },
        data: "string",
      },
      {
        className: "overflow-menu",
        key: "column5",
        name: "Overflow Menu",
        fieldName: "overflow",
        minWidth: 30,
        maxWidth: 30,
        isIconOnly: true,
        isCollapsible: false,
        onRender: (item) => (
          <OverflowSet
            aria-label="Basic Menu Example"
            role="menubar"
            items={[]}
            overflowItems={[
              {
                key: "item1",
                name: "View",

                onClick: () => (item?.id ? navigate(item?.id + "") : null),
              },
              {
                key: "item2",
                name: "Edit",
                onClick: () =>
                  openPopup(
                    item.code ? (
                      <EditApprovalGroupInfoPopup
                        groupId={item.id}
                        code={item.code}
                        name={item.name}
                        onOperationCompleted={() => {
                          getApprovalGroups();
                        }}
                      />
                    ) : (
                      <FeedbackPopup type="error" title={"Invalid Approval Group Code"} primaryActionLabel={"Close"}>
                        <p>
                          {"Not able to get the Approval Group Code"} ({item?.code}).
                        </p>
                      </FeedbackPopup>
                    )
                  ),
              },
              {
                key: "item3",
                name: "Remove",
                onClick: () =>
                  openPopup(
                    item.code ? (
                      <FeedbackPopup
                        type="warning"
                        title={"Remove approval group? "}
                        primaryActionLabel={"Remove"}
                        primaryActionFunc={() => {
                          onClickRemoveApprovalGroup(item.id);
                        }}
                      >
                        <p>{"This action will permanently remove this approval group and his associated members."}</p>
                      </FeedbackPopup>
                    ) : (
                      <FeedbackPopup type="error" title={"Invalid Approval Group Code"} primaryActionLabel={"Close"}>
                        <p>
                          {"Not able to get the Approval Group Code"} ({item?.code}).
                        </p>
                      </FeedbackPopup>
                    )
                  ),
              },
            ]}
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
          />
        ),
        data: "string",
      },
    ];
  }, [getApprovalGroups, openPopup]);

  const _renderRow = useCallback(
    (props: any) => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if (props.itemIndex % 2 === 0) {
          customStyles.root = { backgroundColor: "#fafafa" };
        }
      }
      return <DetailsRow {...props} styles={customStyles} onClick={() => navigate("" + props.item.id)} />;
    },
    [navigate]
  );

  return (
    <CardLayout classNames="groups-list-card">
      {!groupsSpinner && groups.length === 0 ? (
        <div className="empty">
          <div className="empty-title">No approval groups yet</div>
          <p>The approval groups list is empty. Try add one!</p>
          <SecondaryButton
            text="Add Group"
            icon={add}
            onClick={() =>
              openPopup(
                <AddGroupPopup
                  onAddGroupCompleted={() => {
                    getApprovalGroups();
                  }}
                />
              )
            }
          />
        </div>
      ) : (
        <>
          <div className="commandbar">
            <TextField disabled={true} className="search-input no-margin" placeholder={"Search name, approver"} />
            <div className="action-btns">
              <CommandBar items={_items} ariaLabel="Action buttons" />
            </div>
          </div>
          <ShimmeredDetailsList
            enableShimmer={groupsSpinner}
            className="groups-list"
            items={groups || []}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            onShouldVirtualize={(_) => false}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onRenderRow={_renderRow}
          />
        </>
      )}
    </CardLayout>
  );
}
