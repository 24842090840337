import { useContext } from "react";
import { NavigationSvcContext } from "../NavigationServices";

export function useUpdatePageData() {
    const context = useContext(NavigationSvcContext);
    if(!context){
        throw new Error("useUpdatePageData must be used within NavigationServices");
    }

   return context.addToRouteData;
}
