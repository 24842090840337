import "./InfoPanel.css";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { InfoBlock } from "../../../../components/cards/InfoBlock";
import { useClosePanel } from "../../../../infrastructure/ui/UIServices";
import { ImageCarousel } from "../../../../components/carousel/ImageCarousel";
import dayjs from "dayjs";
import { IEquipmentStatus, IEquipmentType } from "../../../../services/EquipmentsService";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

export interface IInfoPanelPhoto {
  name: string;
  url: string;
}

interface IInfoPanelProps {
  serialNumber: string;
  reference: string;
  brand: string;
  model: string;
  description: string;
  photos: IInfoPanelPhoto[];
  type: IEquipmentType;
  date: string;
  status: IEquipmentStatus;
}

export function InfoPanel(props: IInfoPanelProps) {
  const closePanel = useClosePanel();

  return (
    <div className="equipment-info">
      <CardHeader headerTitle={translate("ACTIONS.Info")} icon={true} setDisplayState={(state: boolean) => closePanel()}></CardHeader>
      {!props.photos.length ? (
        <div className="no-photos">{translate("EQUIPMENTS.NoPhotos")}</div>
      ) : (
        <ImageCarousel photosLength={props.photos.length} images={props.photos.map((p, idx) => ({ id: idx + 1, url: p.url }))}></ImageCarousel>
      )}
      <InfoBlock label={translate("EQUIPMENTS.COMMON.Ref")} text={props.reference}></InfoBlock>
      <InfoBlock label={translate("EQUIPMENTS.COMMON.Brand")} text={props.brand}></InfoBlock>
      {props.model ? <InfoBlock label={translate("EQUIPMENTS.COMMON.Model")} text={props.model}></InfoBlock> : null}
      <InfoBlock label={translate("EQUIPMENTS.COMMON.Description")} text={props.description}></InfoBlock>
      <InfoBlock label={translate("EQUIPMENTS.COMMON.SerialNumber")} text={props.serialNumber}></InfoBlock>
      <InfoBlock label={translate("COMMON.Type")} text={props.type.description}></InfoBlock>
      {props.date ? <InfoBlock label={translate("COMMON.Date")} text={dayjs(props.date).format("DD/MM/YYYY")}></InfoBlock> : null}
      {props.status ? <InfoBlock label={translate("COMMON.Status")} text={props.status.description}></InfoBlock> : null}
    </div>
  );
}
