import { FeatureServices } from "./features/FeatureServices";
import { IdentityApp } from "./identity/components/IdentityApp";
import { NavigationServices } from "./navigation/NavigationServices";
import { UISContextProvider } from "./ui/UIServices";

interface ISPAAppProps {
  children: React.ReactNode;
}

export function SPAApp(props: ISPAAppProps) {
  return (
    <IdentityApp>
      <FeatureServices>
        <NavigationServices>
          <UISContextProvider>
              {props.children}
          </UISContextProvider>
        </NavigationServices>
      </FeatureServices>
    </IdentityApp>

  );
}
