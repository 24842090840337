import { Calendar, ICalendarActions, ICalendarCell, ICalendarFrameCell } from "../../../../components/calendar/Calendar";
import { CommandBar, IComboBoxOption, ICommandBarItemProps, Persona, PersonaSize } from "@fluentui/react";
import { DailyRecordDTO, HoursService, IDailyRecordResponseDTO } from "../../../../services/HoursService";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePopup, useShowSpinner, useWindowResize } from "../../../../infrastructure/ui/UIServices";

import { CardLayout } from "../../../../components/layouts/card-layout/CardLayout";
import { ConfirmHoursApprovalPopup } from "../popup/ConfirmHoursApprovalPopup";
import { DeclineHoursPopup } from "../popup/DeclineHoursPopup";
import { EmptyCell } from "../../../../components/calendar/cells/EmptyCell";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { ReactComponent as IconDeselect } from "../../../../assets/deselect-icon.svg";
import { ReactComponent as IconSelect } from "../../../../assets/select-icon.svg";
import { ReactComponent as IconTotal } from "../../../../assets/total-icon.svg";
import { ManagerCell } from "../../../../components/calendar/cells/ManagerCell";
import { TimeReportService } from "../../../../services/TimeReportsService";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";
import { usePermission } from "../../../../infrastructure/identity/hooks/usePermissions";
import { N4UPermissions } from "../../../../infrastructure/identity/Permissions";

var hoursService = new HoursService();
var timeReportService = new TimeReportService();

const EmployeeCalendarCellFactory = (
  isTotalHoursView: boolean,
  cellData: ICalendarFrameCell<DailyRecordDTO>,
  idx: number,
  selectedDays: ISelectedDay[],
  setSelectedDays: (value: ISelectedDay[]) => void,
  reRenderByCompletedOperations: () => void
) => {
  if (cellData.isOutsideActiveMonth || !cellData.data) {
    return (
      <React.Fragment key={idx}>
        <EmptyCell
          key={idx}
          day={cellData.day}
          isOutsideActiveMonth={cellData.isOutsideActiveMonth}
          isWeekend={cellData.isWeekend}
          isHoliday={cellData.isHoliday}
          holidayName={cellData.holidayName}
        ></EmptyCell>
      </React.Fragment>
    );
  }
  if (cellData) {
    return (
      <React.Fragment key={idx}>
        <ManagerCell
          key={idx}
          isOutsideCurrentMonth={cellData.isOutsideActiveMonth}
          dailyRecord={cellData.data}
          day={cellData.day}
          isWeekend={cellData.isWeekend}
          isHoliday={cellData.isHoliday}
          holidayName={cellData.holidayName}
          onClick={(id, day) => {
            let clone = [...selectedDays];
            let selectedDay = clone.find((info) => info.dailyRecordId === id);
            if (selectedDay) {
              clone = clone.filter((info) => info.dailyRecordId !== id);
            } else {
              clone.push({ dailyRecordId: id, day: day });
            }
            setSelectedDays(clone);
          }}
          isSelected={!!selectedDays.find((info) => info.dailyRecordId === cellData.data?.dailyRecordId)}
          isTotalHoursView={isTotalHoursView}
          opCompleted={() => reRenderByCompletedOperations()}
        />
      </React.Fragment>
    );
  }
};

export interface ISelectedDay {
  dailyRecordId: number;
  day: Date;
}

interface IManagementCalendarProps {
  employee: IComboBoxOption;
  onMonthChanged: (month: number, year: number) => void;
  mRef?: React.Ref<ICalendarActions>;
  getHeight: (clientHeight: number) => void;
}

function removeNullRecords(dailyRecords: IDailyRecordResponseDTO[]) {
  return dailyRecords.filter((obj) => {
    return obj.day !== null;
  });
}

export function ManagementCalendar(props: IManagementCalendarProps) {
  const { showSpinner, setShowSpinner } = useShowSpinner();
  const openPopup = usePopup();
  const windowResize = useWindowResize();
  const ref = useRef(null);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDays, setSelectedDays] = useState<ISelectedDay[]>([]);
  const [dailyRecords, setDailyRecords] = useState<ICalendarCell<DailyRecordDTO>[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(true);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>();
  const [isTotalHoursView, setIsTotalHoursView] = useState<boolean>(false);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState<string>("");

  const isManager = usePermission(N4UPermissions.MANAGER);
  const isApprover = usePermission(N4UPermissions.APPROVER);

  useEffect(() => {
    setSelectedDays([]);
    setSelectAll(true);
  }, [props.employee]);

  useEffect(() => {
    if (ref.current) {
      const { clientHeight } = ref?.current;
      props.getHeight(clientHeight);
    }
  }, [windowResize]);

  useEffect(() => {
    setSelectedEmployeeName(props.employee.text);
    if (dailyRecords) {
      if (dailyRecords.find((dr) => dr.data?.dailyRecordId && dr.data.status !== "OK" && dr.data.status !== "NOT_OK")) return setIsSelectAll(true);
      else return setIsSelectAll(false);
    }
  }, [dailyRecords]);

  const selectAllCells = useCallback(
    (selectedStatus: boolean) => {
      let clone: {
        dailyRecordId: number;
        day: Date;
      }[] = [];
      if (selectedStatus) {
        dailyRecords.map((dr) => {
          if (dr.data?.dailyRecordId) {
            if (dr.data.status !== "OK" && dr.data.status !== "NOT_OK") {
              clone.push({
                dailyRecordId: dr.data?.dailyRecordId,
                day: dr.day,
              });
            }
          }
        });
        setSelectedDays(clone);
        setSelectAll(false);
      } else {
        setSelectedDays(clone);
        setSelectAll(true);
      }
    },
    [dailyRecords]
  );

  useEffect(() => {
    if (props.employee) {
      setShowSpinner(true);
      hoursService
        .getMonthlyRecordsByManager(props.employee.key as string, currentMonth.getFullYear(), currentMonth.getMonth() + 1)
        .then((res) => {
          if (res !== undefined) {
            var filteredRecords = removeNullRecords(res);
            setDailyRecords(
              filteredRecords.map((dto) => ({
                day: new Date(dto.day),
                data: dto.data,
              }))
            );
            setShowSpinner(false);
          }
        })
        .catch((err) => {
          if (err !== undefined) {
            setShowSpinner(false);
            openPopup(
              <FeedbackPopup type="error">
                <p>{err}</p>
              </FeedbackPopup>
            );
          }
        });
    }
  }, [props.employee, currentMonth, reRender, openPopup]);

  const handleMonthChanged = useCallback(
    (month: number, year: number) => {
      setCurrentMonth(new Date(year, month, 4));
      props.onMonthChanged(month, year);
    },
    [setCurrentMonth]
  );

  const _items: ICommandBarItemProps[] = [
    {
      key: "export",
      text: translate("MANAGERHOURS.EXPORT.Export"),
      arilaLabel: "Export",
      iconProps: { iconName: "DownloadDocument" },
      disabled: isApprover && !isManager,
      subMenuProps: {
        items: [
          {
            key: "export-all-excel",
            text: translate("MANAGERHOURS.EXPORT.CurrentMonthAllEmployees"),
            iconProps: { iconName: "ExcelDocument", styles: { root: { color: "#217346" } } },
            onClick: () => {
              timeReportService.exportAllEmployeesExcelUrl(currentMonth.getFullYear(), currentMonth.getMonth() + 1);
            },
          },
          {
            key: "export-all-excel",
            text: "" + props.employee.text + " - " + translate("MANAGERHOURS.EXPORT.EmployeeCurrentMonth"),
            iconProps: { iconName: "ExcelDocument", styles: { root: { color: "#217346" } } },
            onClick: () => {
              timeReportService.exportAllExcelUrl(props.employee.key as string, currentMonth.getFullYear(), currentMonth.getMonth() + 1);
            },
          },
          {
            key: "export-all-pdf",
            text: "" + props.employee.text + " - " + translate("MANAGERHOURS.EXPORT.EmployeeCurrentMonthPdf"),
            iconProps: { iconName: "PDF", styles: { root: { color: "#d13438" } } },
            onClick: () => {
              timeReportService.exportAllPDF(props.employee.key as string, currentMonth.getFullYear(), currentMonth.getMonth() + 1);
            },
          },
          {
            key: "export-time-sheet-pdf",
            text: "" + props.employee.text + " - " + translate("MANAGERHOURS.EXPORT.EmployeeCurrentMonthTimesheet"),
            iconProps: { iconName: "PDF", styles: { root: { color: "#d13438" } } },
            onClick: () => {
              timeReportService.exportTimeSheetPDF(props.employee.key as string, currentMonth.getFullYear(), currentMonth.getMonth() + 1);
            },
          },
        ],
      },
    },
    {
      key: "select",
      text: selectAll ? translate("MANAGERHOURS.SelectAll") : translate("MANAGERHOURS.DeselectAll"),
      // This needs an ariaLabel since it's icon-only
      ariaLabel: selectAll ? "Select All" : "Deselect All",
      iconOnly: true,
      onRenderIcon: () => {
        if (selectAll) return <IconSelect onClick={() => selectAllCells(true)} />;
        else return <IconDeselect onClick={() => selectAllCells(false)} />;
      },
      disabled: isSelectAll ? false : true,
    },
    {
      key: "total",
      text: translate("MANAGERHOURS.TotalHoursView"),
      // This needs an ariaLabel since it's icon-only
      ariaLabel: "Total Hours View",
      iconOnly: true,
      onRenderIcon: () => {
        return <IconTotal />;
      },
      onClick: () => setIsTotalHoursView(!isTotalHoursView),
      disabled: dailyRecords.length > 0 ? false : true,
    },
    {
      className: "approve",
      key: "approve",
      text: translate("ACTIONS.Approve"),
      iconProps: { iconName: "Accept" },
      onClick: () => openPopup(<ConfirmHoursApprovalPopup selectedDays={selectedDays} confirmation={() => reRenderByCompletedOperations()} />),
      disabled: selectedDays.length === 0 ? true : false,
    },
    {
      className: "decline",
      key: "decline",
      text: translate("ACTIONS.Decline"),
      iconProps: { iconName: "Cancel" },
      onClick: () => openPopup(<DeclineHoursPopup selectedDays={selectedDays} declineOpCompleted={reRenderByCompletedOperations} />),
      disabled: selectedDays.length > 0 ? false : true,
    },
  ];

  const reRenderByCompletedOperations = useCallback(() => {
    setReRender(!reRender);
    setSelectedDays([]);
    setSelectAll(true);
  }, [reRender]);

  return (
    <div ref={ref} className="calendar-card">
      <CardLayout>
        <div className="calendar-commandbar">
          <div className="employee">{props.employee.key ? <Persona imageUrl={props.employee.data} size={PersonaSize.size40} text={props.employee.text} /> : null}</div>
          {windowResize > 768 ? (
            <div className="action-btns">
              <CommandBar items={_items} ariaLabel="Action buttons" />
            </div>
          ) : null}
        </div>
        <Calendar
          cellDataSource={dailyRecords}
          showSpinner={showSpinner}
          showHeader={false}
          onCurrentMonthChanged={handleMonthChanged}
          renderCell={(cellData, idx) => EmployeeCalendarCellFactory(isTotalHoursView, cellData, idx, selectedDays, setSelectedDays, reRenderByCompletedOperations)}
          mRef={props.mRef}
        />
      </CardLayout>
    </div>
  );
}
