import "./App.css";

import { EmbededEmployeeModule, ROUTES as EmbededEmployeeRoutes } from "./areas/employees/EmbededEmployeeModule";
import { EmployeeModule, ROUTES as EmployeeModuleRoutes } from "./areas/employees/EmployeeModule";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRenderOverlaySpinner, useRenderPanel, useRenderPopup } from "./infrastructure/ui/UIServices";

import { AppIdentityRoutingModule } from "./App.Identity.RoutingModule";
import { EmbededPeopleManagementModule } from "./areas/people-management/EmbededPeopleManagementModule";
import { ErrorPage } from "./areas/common/error/ErrorPage";
import { ExternalLinkPage } from "./areas/common/external-link/ExternalLinkPage";
import { Overlay } from "./components/layouts/overlay/Overlay";
import { PeopleManagementModule } from "./areas/people-management/PeopleManagementModule";
import { PopupManagement } from "./components/layouts/popup/PopupManagement";
import React from "react";
import { RequireAuth } from "./infrastructure/identity/components/RequireAuth";
import { SessionExpiredPage } from "./areas/common/session-expired/SessionExpiredPage";
import { SidePanelManagement } from "./areas/people-management/equipment-list/panels/SidePanelManagement";
import { SplashScreen } from "./areas/common/splash-screen/SplashScreen";
import { UnavailableService } from "./areas/common/error/UnavailableService";
import { useIdentityIsProcessing } from "./infrastructure/identity/hooks/useIdentityIsProcessing";

function App() {
  const { showPanel, panelContent } = useRenderPanel();
  const { showPopup, popupContent } = useRenderPopup();
  const { showOverlaySpinner, overlaySpinnerContent } = useRenderOverlaySpinner();

  const { isProcessing } = useIdentityIsProcessing();

  if (isProcessing) return <SplashScreen />;

  return (
    <>
      <Routes>
        <Route
          path={`${EmployeeModuleRoutes.ROOT}/*`}
          element={
            <RequireAuth>
              <EmployeeModule />
            </RequireAuth>
          }
        />
        <Route
          path={`${EmbededEmployeeRoutes.ROOT}/*`}
          element={
            <RequireAuth>
              <EmbededEmployeeModule />
            </RequireAuth>
          }
        />

        <Route path={`identity/*`} element={<AppIdentityRoutingModule />} />
        <Route
          path="/manager/*"
          element={
            <RequireAuth>
              <PeopleManagementModule />
            </RequireAuth>
          }
        />

        <Route
          path="/embeded/manager/*"
          element={
            <RequireAuth>
              <EmbededPeopleManagementModule />
            </RequireAuth>
          }
        />

        <Route path="/error" element={<ErrorPage />} />
        <Route path="/redirect/*" element={<ExternalLinkPage />} />
        <Route path="/" element={<Navigate to="/me/" replace />} />

        <Route path="/networkerror" element={<UnavailableService />} />
        <Route path="/sessionexpired" element={<SessionExpiredPage />} />
        <Route path="/splash" element={<SplashScreen />} />
        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
      {showPanel ? <SidePanelManagement>{panelContent}</SidePanelManagement> : null}
      {showPopup ? <PopupManagement>{popupContent}</PopupManagement> : null}
      {showOverlaySpinner ? <Overlay>{overlaySpinnerContent}</Overlay> : null}
    </>
  );
}

export default App;
