import "./Calendar.css";

import { IIconProps, IconButton, Spinner, SpinnerSize, elementContains } from "@fluentui/react";
import React, { useImperativeHandle } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHolidays, useWindowResize } from "../../infrastructure/ui/UIServices";

import { InternationalizationProvider } from "../../infrastructure/i18n/InternationalizationProvider";
import dayjs from "dayjs";
import { translate } from "../../infrastructure/i18n/InternationalizationService";

export interface ICalendarCell<TCellData> {
  data?: TCellData;
  day: Date;
}

export interface ICalendarFrameCell<TCellData> {
  data?: TCellData;
  day: Date;
  isOutsideActiveMonth: boolean;
  isWeekend: boolean;
  isHoliday: boolean;
  holidayName?: string;
}

interface ICalendarProps<TCellData> {
  cellDataSource?: ICalendarCell<TCellData>[];
  renderCell: (cellData: ICalendarFrameCell<TCellData>, idx: number) => React.ReactNode;
  showSpinner?: boolean;
  showHeader?: boolean;
  currentMonth?: number;
  currentYear?: number;
  onCurrentMonthChanged?: (newMonth: number, newYear: number) => void;
  isMini?: boolean;
  mRef?: React.Ref<ICalendarActions>;
}

export interface ICalendarActions {
  prevMonth: () => void;
  nextMonth: () => void;
}

export function Calendar<TCellData>(props: ICalendarProps<TCellData>) {
  const windowResize = useWindowResize();

  const holidays = useHolidays();

  const chevronLeftIcon: IIconProps = { iconName: "ChevronLeft" };
  const chevronRightIcon: IIconProps = { iconName: "ChevronRight" };

  const weekdays = InternationalizationProvider.getCalendarWeekDays();

  const months = InternationalizationProvider.getCalendarMonths();

  const currentLocale = InternationalizationProvider.getCurrentLocale();

  const [currentDay, setCurrentDay] = useState(new Date());
  const [currentFrame, setCurrentFrame] = useState<ICalendarFrameCell<TCellData>[]>([]);

  //If comes by props or not.
  const currentMonth = useMemo(() => {
    if (props.currentMonth && props.currentMonth >= 0 && props.currentMonth < 12) {
      return props.currentMonth;
    }
    return currentDay.getMonth();
  }, [currentDay, props.currentMonth]);

  const currentYear = useMemo(() => {
    if (props.currentYear) {
      return props.currentYear;
    }
    return currentDay.getFullYear();
  }, [currentDay, props.currentYear]);

  const currentMonthLabel = useMemo(() => months[currentMonth], [currentMonth]);

  useEffect(() => {
    // começa ao domingo
    // dias antes desde domingo até dia 1 (se for domingo precisamos de uma fila interia).
    // todos os dias do mês.
    // dias apos fim do mes até sábado.

    let firstDayOfTheMonth = new Date(currentYear, currentMonth, 1);
    let weekdayOfTheFirstDayOfTheMonth = firstDayOfTheMonth.getDay();

    let datePointer = firstDayOfTheMonth;

    if (weekdayOfTheFirstDayOfTheMonth === 0) {
      //If it is sunday we need to go back an entire week/entire row.
      datePointer.setDate(datePointer.getDate() - 7);
    } else {
      //Otherwise go back just the needed days.
      datePointer.setDate(datePointer.getDate() - weekdayOfTheFirstDayOfTheMonth);
    }

    let frame: ICalendarFrameCell<TCellData>[] = [];

    for (let day = 0; day < 42; day++) {
      let datePointerDJS = dayjs(datePointer);

      let holiday = holidays.find((holiday) => datePointerDJS.isSame(dayjs(holiday.date), "day"));

      let holidayName: string | undefined;

      if (holiday && currentLocale === "en-EN") {
        holidayName = holiday.name;
      } else if (holiday) {
        holidayName = holiday.localName;
      }

      frame.push({
        day: new Date(datePointer),
        isOutsideActiveMonth: datePointer.getMonth() !== currentMonth,
        isWeekend: datePointer.getDay() === 0 || datePointer.getDay() === 6,
        data: props.cellDataSource?.find((item) => datePointerDJS.isSame(dayjs(item.day), "day"))?.data,
        isHoliday: holiday ? true : false,
        holidayName: holiday && holidayName,
      });
      datePointer.setDate(datePointer.getDate() + 1);
    }
    setCurrentFrame(frame);
  }, [currentYear, currentMonth, props.cellDataSource, holidays]);

  const getPreviousMonth = useCallback(() => {
    let date = new Date(currentYear, currentMonth, 4);
    let newDate = dayjs(date).subtract(1, "month");
    setCurrentDay(newDate.toDate());
    if (props.onCurrentMonthChanged) {
      props.onCurrentMonthChanged(newDate.get("month"), newDate.get("year"));
    }
  }, [setCurrentDay, currentYear, currentMonth, props.onCurrentMonthChanged]);

  const getNextMonth = useCallback(() => {
    let date = new Date(currentYear, currentMonth, 4);
    let newDate = dayjs(date).add(1, "month");
    setCurrentDay(newDate.toDate());
    if (props.onCurrentMonthChanged) {
      props.onCurrentMonthChanged(newDate.get("month"), newDate.get("year"));
    }
  }, [setCurrentDay, currentYear, currentMonth]);

  useImperativeHandle(
    props.mRef,
    () => ({
      prevMonth: getPreviousMonth,
      nextMonth: getNextMonth,
    }),
    [getPreviousMonth, getNextMonth]
  );

  return (
    <div className={"calendar" + (props.isMini || windowResize <= 768 ? "-mini" : "")}>
      {props.showHeader ? (
        <div className="calendar-header">
          {props.isMini || windowResize <= 768 ? (
            <div className="month">
              {currentMonthLabel} {currentYear}
            </div>
          ) : (
            <h1>
              {currentMonthLabel} {currentYear}
            </h1>
          )}
          <div className="month-btns">
            <IconButton className="previous" iconProps={chevronLeftIcon} onClick={getPreviousMonth} />
            <IconButton className="next" iconProps={chevronRightIcon} onClick={getNextMonth} />
          </div>
        </div>
      ) : null}
      <div className="calendar-container">
        {weekdays.map((day, idx) => (
          <div key={idx} className="day-of-week">
            {day}
          </div>
        ))}
        {props.showSpinner ? (
          <div className="calendar-loading">
            {" "}
            <Spinner size={SpinnerSize.large} label={translate("LOADING.PleaseWait")} />
          </div>
        ) : (
          currentFrame.map((cell, idx) => <React.Fragment key={idx}>{props.renderCell(cell, idx)}</React.Fragment>)
        )}
      </div>
    </div>
  );
}
