import "./EditActivityInfoPopup.css";

import { ActivityItem, MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useClosePopup, useOverlaySpinner, usePopup, useShowSpinner, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { ActivityPathDTO } from "../../../models/api/projects/ActivityPathDTO";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { ProjectsService } from "../../../services/ProjectsService";
import { TertiaryButton } from "../../../components/buttons/TertiaryButton";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { CardHeader } from "../../../components/cards/CardHeader";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

var projectsService = new ProjectsService();

export interface EditActivityInfoProps {
  activityId: number;
  onOperationCompleted: () => void;
}

export function EditActivityInfoPopup(props: EditActivityInfoProps) {
  const navigate = useNavigate();

  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [activityName, setActivityName] = useState<string>("");
  const [activityCode, setActivityCode] = useState<string>("");
  const [errorMessage, setMessageError] = useState<string>("");
  const [projectCode, setProjectCode] = useState<string>("");

  const [showPrimaryMessageBar, setShowMessageBar] = useState<boolean>(false);
  const [isEditActivityDisabled, setIsEditActivityDisabled] = useState<boolean>(true);
  const [activityDetailsSpinner, setActivityDetailsSpinner] = useState<boolean>(true);

  useEffect(() => {
    if (activityName) setIsEditActivityDisabled(false);
    else setIsEditActivityDisabled(true);
  }, [activityName]);

  useEffect(() => {
    if (props.activityId) {
      projectsService
        .getActivityById("" + props.activityId)
        .then((res) => {
          setProjectCode(res.projectId);
          setActivityCode(res.activityId);
          setActivityName(res.name);
          setActivityDetailsSpinner(false);
        })
        .catch((error) => {
          setActivityDetailsSpinner(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{error}</p>
            </FeedbackPopup>
          );
        });
    }
  }, []);

  const onClickEditActivityInfo = useCallback(() => {
    if (projectCode && activityCode && activityName) {
      openOverlaySpinner(<Spinner size={SpinnerSize.large} />);

      var request: ActivityPathDTO = {
        activityName: activityName,
      };

      projectsService
        .updateActivityPatch(projectCode, activityCode, request)
        .then((_) => {
          props.onOperationCompleted();
          closeOverlaySpinner();
          openPopup(
            <FeedbackPopup type="success">
              <p>{translate("ACTIVITIES.EDIT.SuccessMessage")}</p>
            </FeedbackPopup>
          );
        })
        .catch((error) => {
          if (error.code === "ERR_BAD_REQUEST") {
            if (error.response?.data) {
              setShowMessageBar(true);
              closeOverlaySpinner();
              setMessageError(error.response.data);
            }
          } else {
            closeOverlaySpinner();
            openPopup(
              <FeedbackPopup type="error">
                <p>{error.response?.data}</p>
              </FeedbackPopup>
            );
          }
        });
    }
  }, [projectCode, activityCode, activityName, setShowMessageBar, setMessageError, openOverlaySpinner, openPopup, closeOverlaySpinner, props.onOperationCompleted]);

  const errorMessageBar = useCallback(() => {
    return (
      <MessageBar
        className="messageBar"
        role="alert"
        messageBarType={MessageBarType.error}
        onDismiss={() => {
          setShowMessageBar(false);
        }}
        dismissButtonAriaLabel="Close"
      >
        <>{errorMessage}</>
      </MessageBar>
    );
  }, [errorMessage, setShowMessageBar]);

  return (
    <div className="edit-activity-popup">
      <div className="activity">
        {activityDetailsSpinner ? (
          <Spinner size={SpinnerSize.large} label={translate("LOADING.LoadingDataPleaseWait")} />
        ) : (
          <div>
            <CardHeader headerTitle={translate("ACTIVITIES.EDIT.Title")} icon={true} setDisplayState={(state: boolean) => closePopup()} />
            <div className="activity-info">
              <TextField disabled={true} label={translate("COMMON.Code")} defaultValue={activityCode ? activityCode : "-"} />
              <TextField
                onChange={(e) => {
                  setActivityName(e.currentTarget.value);
                }}
                label={translate("COMMON.Name")}
                placeholder="Ex: Development"
                value={activityName}
                required={true}
              />
            </div>
            {showPrimaryMessageBar ? errorMessageBar() : null}
            <div className="act-btns">
              <TertiaryButton text={translate("ACTIONS.Cancel")}onClick={closePopup} />
              <PrimaryButton text={translate("ACTIONS.Edit")} isDisabled={isEditActivityDisabled} onClick={onClickEditActivityInfo}></PrimaryButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
