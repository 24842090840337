import "./EmployeeHoursPage.css";

import { Calendar, ICalendarCell, ICalendarFrameCell } from "../../../components/calendar/Calendar";
import { DailyRecordDTO, HoursService, IDailyRecordResponseDTO } from "../../../services/HoursService";
import React, { useCallback, useEffect, useState } from "react";
import { usePopup, useShowSpinner } from "../../../infrastructure/ui/UIServices";

import { AllocateHourCell } from "../../../components/calendar/cells/AllocateHourCell";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { DateTimeHelper } from "../../../helpers/DateTimeHelper";
import { EmployeeCell } from "../../../components/calendar/cells/EmployeeCell";
import { EmptyCell } from "../../../components/calendar/cells/EmptyCell";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";

const hoursService = new HoursService();

const EmployeeCalendarCellFactory = (cellData: ICalendarFrameCell<DailyRecordDTO>, idx: number, onCompletedOperations: () => void) => {
  if (cellData.isOutsideActiveMonth) {
    return (
      <EmptyCell
        key={idx}
        day={cellData.day}
        isOutsideActiveMonth={cellData.isOutsideActiveMonth}
        isWeekend={cellData.isWeekend}
        isHoliday={cellData.isHoliday}
        holidayName={cellData.holidayName}
      ></EmptyCell>
    );
  }

  if (!cellData.data) {
    return (
      <AllocateHourCell
        reRender={onCompletedOperations}
        key={idx}
        day={cellData.day}
        isWeekend={cellData.isWeekend}
        isHoliday={cellData.isHoliday}
        holidayName={cellData.holidayName}
      />
    );
  }
  if (cellData) {
    return (
      <EmployeeCell
        reRender={onCompletedOperations}
        key={idx}
        isOutsideCurrentMonth={cellData.isOutsideActiveMonth}
        dailyRecord={cellData.data}
        day={cellData.day}
        isWeekend={cellData.isWeekend}
        isHoliday={cellData.isHoliday}
        holidayName={cellData.holidayName}
      />
    );
  }
};

function removeNullRecords(dailyRecords: IDailyRecordResponseDTO[]) {
  return dailyRecords.filter((obj) => {
    return obj.day !== null;
  });
}

export function EmployeeHoursPage() {
  const updatePageTitle = usePageTitle();
  const openPopup = usePopup();

  const { showSpinner, setShowSpinner } = useShowSpinner();
  const [dailyRecords, setDailyRecords] = useState<ICalendarCell<DailyRecordDTO>[]>([]);
  const [currentMonth, setCurrentMonth] = useState(DateTimeHelper.getCurrentMonth());
  const [currentYear, setCurrentYear] = useState(DateTimeHelper.getCurrentYear());
  const [reRender, setReRender] = useState<boolean>(false);

  useEffect(() => {
    updatePageTitle(translate("MENU.Hours"));
  }, []);

  const onCompletedOperations = useCallback(() => {
    setReRender(!reRender);
  }, [reRender, setReRender]);

  useEffect(() => {
    setShowSpinner(true);

    hoursService
      .getMonthlyRecords(currentYear, currentMonth + 1)
      .then((res) => {
        if (res !== undefined) {
          var filteredRecords = removeNullRecords(res);
          setDailyRecords(
            filteredRecords.map((dto) => ({
              day: new Date(dto.day),
              data: dto.data,
            }))
          );
          setShowSpinner(false);
        }
      })
      .catch((err) => {
        if (err !== undefined) {
          setShowSpinner(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{err}</p>
            </FeedbackPopup>
          );
        }
      });
  }, [currentMonth, currentYear, reRender, openPopup]);

  const handleMonthChanged = useCallback(
    (month: number, year: number) => {
      setCurrentMonth(month);
      setCurrentYear(year);
    },
    [setCurrentMonth, setCurrentYear]
  );

  return (
    <CardLayout classNames="calendar-card">
      <Calendar
        cellDataSource={dailyRecords}
        showSpinner={showSpinner}
        showHeader={true}
        onCurrentMonthChanged={handleMonthChanged}
        renderCell={(cellData, idx) => EmployeeCalendarCellFactory(cellData, idx, onCompletedOperations)}
      />
    </CardLayout>
  );
}