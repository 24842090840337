// import React, {createContext, useEffect, useState} from 'react';

// export interface UIContextState{
//   isLoading: boolean;
//   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
//   viewPortWidth: number;
//   viewPortHeight: number;
//   showPopup: boolean;
//   setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
//   popupContent: React.ReactNode | undefined | null;
//   setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode | undefined | null>>;

//   showFSL: boolean;
//   setShowFSL: React.Dispatch<React.SetStateAction<boolean>>;
//   FSLContent: React.ReactNode | undefined | null;
//   setFSLContent: React.Dispatch<React.SetStateAction<React.ReactNode | undefined | null>>;
// }

// export const UIContext = createContext<UIContextState|null>(null);

// interface IUIServicesProps{
//   children: React.ReactNode;
// }

// export function UIServices({children}: IUIServicesProps) {
//     const [isLoading, setIsLoading] = useState(false);

//     const [width, setWidth] = useState(window.innerWidth);
//     const [height, setHeight] = useState(window.innerHeight);

//     const [showPopup, setShowPopup] = useState(false);
//     const [popupContent, setPopupContent] = useState<React.ReactNode | undefined | null>(null);

//     const [showFSL, setShowFSL] = useState(false)
//     const [FSLContent, setFSLContent] = useState<React.ReactNode | undefined | null>(null);

//     useEffect(() => {
//       const handleWindowResize = () => {
//         setWidth(window.innerWidth);
//         setHeight(window.innerHeight);
//       }
//       window.addEventListener("resize", handleWindowResize);
//       return () => window.removeEventListener("resize", handleWindowResize);
//     }, []);

//     const value = {isLoading, setIsLoading, viewPortWidth: width, viewPortHeight:height, showPopup, setShowPopup, popupContent, setPopupContent,
//       showFSL, setShowFSL, FSLContent, setFSLContent};

//     return (
//       <UIContext.Provider value={value}> {children} </UIContext.Provider>
//     )
// }

import { InternationalizationProvider, LanguageLocale } from '../i18n/InternationalizationProvider';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { ConfigurationProvider } from "../configuration/ConfigurationProvider";
import { HolidayDTO } from '../../services/CalendarService';
import { HttpClient } from "../http/HttpClient";
import { UrlUtility } from "../../utils/UrlUtility";

export interface UISContextState {
  showPanel: boolean;
  setShowPanel: React.Dispatch<React.SetStateAction<boolean>>;
  panelContent: React.ReactNode;
  setPanelContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;

  showSpinner: boolean;
  setShowSpinner: (showSpinner: boolean) => void;

  viewPortWidth: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  viewPortHeight: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;

  mobileNavState: boolean;
  setSMobileNavState: React.Dispatch<React.SetStateAction<boolean>>;

  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  popupContent: React.ReactNode;
  setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;

  showOverlaySpinner: boolean;
  setShowOverlaySpinner: React.Dispatch<React.SetStateAction<boolean>>;
  overlaySpinnerContent: React.ReactNode;
  setOverlaySpinnerContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;

  holidays: HolidayDTO[];
  setHolidays: React.Dispatch<React.SetStateAction<HolidayDTO[]>>;

  // locale: LanguageLocale;
  // setLocale: React.Dispatch<React.SetStateAction<LanguageLocale>>;

}

export const UISContext = createContext<UISContextState | null>(null);

interface UISContextProps {
  children: React.ReactNode;
}

export function UISContextProvider({ children }: UISContextProps) {
  const [showPanel, setShowPanel] = useState(false);
  const [panelContent, setPanelContent] = useState<React.ReactNode>();

  const [showSpinner, setShowSpinner] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const [mobileNavState, setSMobileNavState] = useState(false);

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<React.ReactNode>();

  const [showOverlaySpinner, setShowOverlaySpinner] = useState(false);
  const [overlaySpinnerContent, setOverlaySpinnerContent] = useState<React.ReactNode>();


  // const [locale, setLocale] = useState<LanguageLocale>(InternationalizationProvider.getCurrentLocale());





  const [holidays, setHolidays] = useState<HolidayDTO[]>([]);

  useEffect(() => {
    HttpClient.request({
      url: `${UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "/api/v1/Calendar/Holidays")}`,
      method: "GET",
    })
      .then((res) =>setHolidays(res.data))
      .catch((_) => setHolidays([]));
  }, [])

  const value = {
    showPanel,
    setShowPanel,
    panelContent,
    setPanelContent,
    showSpinner,
    setShowSpinner,
    viewPortWidth: width,
    setWidth,
    viewPortHeight: height,
    setHeight,
    mobileNavState,
    setSMobileNavState,
    showPopup,
    setShowPopup,
    popupContent,
    setPopupContent,
    showOverlaySpinner,
    setShowOverlaySpinner,
    overlaySpinnerContent,
    setOverlaySpinnerContent,
    holidays,
    setHolidays,
    // locale,
    // setLocale
  };

  return <UISContext.Provider value={value}>{children}</UISContext.Provider>;
}


export function useRenderPanel() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showPanel: context.showPanel,
    panelContent: context.panelContent,
  };
}

export function usePanel() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return useMemo(
    () => (content: React.ReactNode) => {
      context.setShowPanel(true);
      context.setPanelContent(content);
    },
    [context.setShowPanel, context.setPanelContent]
  );
}

export function useClosePanel() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closePanel = useCallback(() => context.setShowPanel(false), [context.setShowPanel]);

  return closePanel;
}

export function useShowSpinner() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showSpinner: context.showSpinner,
    setShowSpinner: context.setShowSpinner,
  };
}

export function useWindowResize() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  useEffect(() => {
    const handleWindowResize = () => {
      context.setWidth(window.innerWidth);
      context.setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [context.setWidth, context.setHeight]);

  return context.viewPortWidth;
}

export function useShowMobileMenuPanel() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    mobileNavState: context.mobileNavState,
    setSMobileNavState: context.setSMobileNavState,
  };
}

export function useRenderPopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showPopup: context.showPopup,
    popupContent: context.popupContent,
  };
}

export function usePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return useMemo(
    () => (content: React.ReactNode) => {
      context.setShowPopup(true);
      context.setPopupContent(content);
    },
    [context.setShowPopup, context.setPopupContent]
  );
}

export function useClosePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closePopup = useCallback(() => context.setShowPopup(false), [context.setShowPopup]);

  return closePopup;
}

export function useRenderOverlaySpinner() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showOverlaySpinner: context.showOverlaySpinner,
    overlaySpinnerContent: context.overlaySpinnerContent,
  };
}

export function useOverlaySpinner() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return useMemo(
    () => (content: React.ReactNode) => {
      context.setShowOverlaySpinner(true);
      context.setOverlaySpinnerContent(content);
    },
    [context.setShowOverlaySpinner, context.setOverlaySpinnerContent]
  );
}

export function useCloseOverlaySpinner() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closeOverlaySpinner = useCallback(() => context.setShowOverlaySpinner(false), [context.setShowOverlaySpinner]);

  return closeOverlaySpinner;
}



export function useHolidays() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return context.holidays;
}
