import "./ManagementHoursPage.css";
import { ICalendarActions } from "../../../components/calendar/Calendar";
import { getMaxHeight, IComboBoxOption } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useWindowResize } from "../../../infrastructure/ui/UIServices";
import React from "react";
import { Spacer } from "../../../components/layouts/spacer/Spacer";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { EmployeeSelection } from "./employee-selection/EmployeeSelection";
import { ManagementCalendar } from "./management-calendar/ManagementCalendar";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { useNavigate } from "react-router-dom";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

export function ManagementHoursPage() {
  const updatePageTitle = usePageTitle();
  const windowResize = useWindowResize();

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState<IComboBoxOption>();
  const [divHeight, setDivHeight] = useState<string>("");
  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_HOURS]);
  const navigate = useNavigate();
  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    updatePageTitle(translate("MENU.Hours"));
  }, []);

  const calendarRef = React.createRef<ICalendarActions>();

  const handleMonthChanged = useCallback(
    (month: number, year: number) => {
      setCurrentMonth(new Date(year, month, 4));
    },
    [setCurrentMonth]
  );

  const handleSelectedEmployee = useCallback(
    (selectedEmployeeFromEmployeeSelection) => {
      if (selectedEmployeeFromEmployeeSelection) {
        setSelectedEmployee(selectedEmployeeFromEmployeeSelection);
      }
    },
    [setSelectedEmployee]
  );

  const getHeight = useCallback(
    (clientHeight) => {
      setDivHeight(clientHeight);
    },
    [setDivHeight]
  );

  return (
    <div className="manager-calendar">
      <EmployeeSelection
        onEmployeeChanged={handleSelectedEmployee}
        month={currentMonth.getMonth()}
        year={currentMonth.getFullYear()}
        onNextMonthClicked={() => calendarRef && calendarRef.current?.nextMonth()}
        onPrevMonthClicked={() => calendarRef && calendarRef.current?.prevMonth()}
        setHeight={divHeight}
      />
      <Spacer mode={windowResize > 1200 ? "horizontal" : "vertical"} />
      {selectedEmployee ? (
        <ManagementCalendar employee={selectedEmployee} onMonthChanged={handleMonthChanged} mRef={calendarRef} getHeight={(clientHeight) => getHeight(clientHeight)} />
      ) : null}
    </div>
  );
}
