import "./EmployeeListPage.css";

import {
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IIconProps,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
  getInitials,
} from "@fluentui/react";
import { UserProfile, UsersService } from "../../../services/UsersService";
import { useCallback, useEffect, useState } from "react";

import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { FeatureFlags } from "../../../infrastructure/features/Flags";
import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

var usersService = new UsersService();

const imageOnErrorHandler = () => {
  //Could use a method of this kind to choose what to do if the photo is empty. Replacing for some default or not.
};

interface IOrderInfo {
  columnName: string;
  orderBy?: string;
  direction: "ASC" | "DESC";
}

export function EmployeeListPage() {
  const navigate = useNavigate();
  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_EMPLOYEE_LIST]);
  if (!isPageActive) {
    navigate("/error");
  }
  const updatePageTitle = usePageTitle();

  const [employeePartialName, setEmployeePartialName] = useState<string>();
  const [employeesSpinner, setEmployeesSpinner] = useState<boolean>(true);
  const [orderInfo, setOrderInfo] = useState<IOrderInfo | null>({ columnName: "column2", orderBy: "displayName", direction: "ASC" });
  const [employees, setEmployees] = useState<UserProfile[]>([]);
  const [timer, setTimer] = useState<any>();

  useEffect(() => {
    updatePageTitle(translate("COMMON.Employee") +"s");
  }, []);

  useEffect(() => {
    usersService
      .getAllUsersProfile()
      .then((res) => {
        setEmployees(res);
        setEmployeesSpinner(false);
      })
      .catch(() => {
        navigate("/error");
      });
  }, []);

  const handleColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
      let newOrderInfo: IOrderInfo | null = {
        columnName: column.key,
        orderBy: column.fieldName!,
        direction: "ASC",
      };
      if (orderInfo && orderInfo.columnName === column.key) {
        if (orderInfo.direction === "ASC") {
          newOrderInfo.direction = "DESC";
        } else newOrderInfo.direction = "ASC";
      }
      setOrderInfo(newOrderInfo);
    },
    [orderInfo, setOrderInfo]
  );

  const columns: IColumn[] = [
    {
      key: "column1",
      name: translate("EMPLOYEES.Photo"),
      fieldName: "avatar",
      minWidth: 40,
      maxWidth: 40,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      onRender: (item) => (
        <Persona imageUrl={item.url} size={PersonaSize.size32} imageInitials={getInitials(item.displayName, false, true)} onError={() => imageOnErrorHandler()} />
      ),
      data: "string",
    },
    {
      key: "column2",
      name: translate("COMMON.Name"),
      fieldName: "displayName",
      minWidth: 200,
      maxWidth: 600,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isSorted: orderInfo?.columnName === "column2",
      isSortedDescending: orderInfo?.columnName === "column2" && orderInfo?.direction === "DESC",
      onColumnClick: handleColumnClick,
      isCollapsible: false,
      onRender: (item) => (
        <div className="employee-info truncate" key={item.email}>
          <div className="name truncate">{item.displayName}</div>
          <div className="mail truncate">{item.email}</div>
        </div>
      ),
      data: "string",
    },
    {
      key: "column3",
      name: translate("EMPLOYEES.Position"),
      fieldName: "jobTitle",
      minWidth: 170,
      maxWidth: 500,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      isSorted: orderInfo?.columnName === "column3",
      isSortedDescending: orderInfo?.columnName === "column3" && orderInfo?.direction === "DESC",
      onColumnClick: handleColumnClick,
      isCollapsible: true,
      data: "string",
      onRender: (item) => {
        if (item.jobTitle) return <div className="truncate">{item.jobTitle}</div>;
        return <>{"-"}</>;
      },
    },
    {
      key: "column4",
      name: translate("EMPLOYEES.Contact"),
      fieldName: "mobilePhone",
      minWidth: 140,
      maxWidth: 500,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      data: "string",
      onRender: (item) => {
        if (item.mobilePhone) return <div className="truncate">{item.mobilePhone}</div>;
        return <>{"-"}</>;
      },
    },
  ];

  useEffect(() => {
    setEmployeesSpinner(true);
    if (timer) {
      clearTimeout(timer);
    }
    var responseTimeOut = setTimeout(() => {
      if (timer) {
        usersService
          .getSortedEmployeesList(employeePartialName, orderInfo?.orderBy, orderInfo?.direction)
          .then((res) => {
            setEmployees(res);
            setEmployeesSpinner(false);
          })
          .catch(() => {
            setEmployeesSpinner(false);
            navigate("/error");
          });
      }
    }, 700);
    setTimer(responseTimeOut);
  }, [employeePartialName, setEmployeePartialName, setEmployees, setEmployeesSpinner]);

  useEffect(() => {
    usersService
      .getSortedEmployeesList(employeePartialName, orderInfo?.orderBy, orderInfo?.direction)
      .then((res) => {
        setEmployees(res);
        setEmployeesSpinner(false);
      })
      .catch(() => {
        setEmployeesSpinner(false);
        navigate("/error");
      });
  }, [orderInfo]);

  const _renderRow = useCallback(
    (props: any) => {
      return <DetailsRow {...props} onClick={() => navigate(props.item.displayName, { state: { email: props.item.email, displayName: props.item.displayName } })} />;
    },
    [navigate]
  );

  return (
    <>
      <CardLayout classNames="list-container">
        <div className="filters">
          <TextField
            className="search-input no-margin"
            onChange={(ev, input) => {
              setEmployeePartialName(input);
            }}
            value={employeePartialName}
            placeholder={translate("COMMON.PlaceholderSearchEmployee")}
          />
        </div>
        <ShimmeredDetailsList
          enableShimmer={employeesSpinner}
          className="employee-list"
          items={employees}
          onShouldVirtualize={(_) => false}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          onRenderRow={_renderRow}
        />
      </CardLayout>
    </>
  );
}
