import "./AllocateHourCell.css";
import { usePopup, useWindowResize } from "../../../infrastructure/ui/UIServices";
import { AllocateHoursPopup } from "../../../areas/employees/hours/popup/AllocateHoursPopup";
import { ReactComponent as IconAllocateTime } from "../../../assets/allocate-time-icon.svg";
import dayjs from "dayjs";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";

interface IAllocateCellProps {
  day: Date;
  reRender?: () => void;
  isWeekend: boolean;
  isHoliday: boolean;
  holidayName?: string;
}



export function AllocateHourCell(props: IAllocateCellProps) {
  const windowResize = useWindowResize();
  const openPopup = usePopup();

  const todayDate = dayjs(new Date()).format("YYYYMMDD");

  return (
    <>
      {windowResize > 768 ? (
        <div
          className={
            "item-allocate" +
            (dayjs(props.day).format("YYYYMMDD") === todayDate ? " border" : "") +
            (props.isWeekend ? " weekend" : "") +
            (props.isHoliday ? " holiday" : "")
          }
          onClick={() => {
            openPopup(
              <AllocateHoursPopup
                onAllocateNewDailyRecordCompleted={() => {
                  props.reRender?.();
                }}
                currentDay={props.day}
                holidayName={props.holidayName}
              />
            );
          }}
        >
          <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
          <div className="allocate-time-icon">
            <IconAllocateTime />
          </div>
          <div className="action-text">{translate("HOURS.ClickToAllocateTime")}</div>
          {windowResize >= 1024 ? <div className="holiday-label">{props.holidayName}</div> : null}
        </div>
      ) : (
        <div
          className={
            "item-allocate" +
            (dayjs(props.day).format("YYYYMMDD") === todayDate ? " border" : "") +
            (props.isWeekend ? " weekend" : "") +
            (props.isHoliday ? " holiday" : "")
          }
          onClick={() => {
            openPopup(
              <AllocateHoursPopup
                onAllocateNewDailyRecordCompleted={() => {
                  props.reRender?.();
                }}
                currentDay={props.day}
              />
            );
          }}
        >
          <div className="item-info">
            <div className={"day" + (props.isHoliday ? " holiday" : "")}>{props.day.getDate()}</div>
          </div>
        </div>
      )}
    </>
  );
}
