import React, { useCallback, useMemo } from "react";
import "./AddEquipmentPanel.css";
import { ReactComponent as IconUpload } from "../../../../assets/upload-icon.svg";
import {
  BaseButton,
  Button,
  DatePicker,
  defaultDatePickerStrings,
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  IColumn,
  IconButton,
  IDropdownOption,
  IIconProps,
  Label,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Spinner,
  SpinnerSize,
  TextField,
} from "@fluentui/react";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { useEffect, useState } from "react";
import { useClosePanel, useShowSpinner, usePopup, useOverlaySpinner, useCloseOverlaySpinner } from "../../../../infrastructure/ui/UIServices";
import dayjs from "dayjs";
import { EquipmentsService, IEquipmentType } from "../../../../services/EquipmentsService";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";

import { EquipmentsFieldsCreateDTO } from "../../../../models/api/equipments/EquipmentsFieldsCreateDTO";
import { FeedbackPopup } from "../../../../components/layouts/popup/FeedbackPopup";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";

const deleteIcon: IIconProps = { iconName: "Delete" };
var equipmentsService = new EquipmentsService();

export interface AddEquipmentPanelProps {
  onCompletedActionPanel: () => void;
}

//Mapper Equipment type to IDropDownOptions(combo)
function mapEquipmentTypeToIDropdownOptions(equipmentsTypeDB: IEquipmentType[]) {
  var optionsMapped = equipmentsTypeDB.map((obj) => {
    var opMapped: IDropdownOption = {
      key: obj.id,
      text: obj.description,
    };
    return opMapped;
  });
  return optionsMapped;
}

//To show message bellow any input if empty.
const getErrorMessage = (value: string): string => {
  return value.length == 0 ? translate("COMMON.RequiredField") : "";
};

export function AddEquipmentPanel(props: AddEquipmentPanelProps) {
  const closePanel = useClosePanel();
  const openPopup = usePopup();
  const { showSpinner, setShowSpinner } = useShowSpinner();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();

  const [brand, setBrand] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [equipmentImages, setEquipmentImages] = useState<File[]>([]);
  const [purchaseDate, setPurchaseDate] = useState<Date | undefined>();
  const [equipmentType, setEquipmentType] = useState<IDropdownOption>();
  const [mappedEquipmentsType, setMappedEquipmentsType] = useState<IDropdownOption[]>([]);

  const [maxDate, setMaxDate] = useState<Date>(new Date());

  const [error, setError] = useState<boolean>(true);
  const [equipmentTypesLoaded, setEquipmentTypesLoaded] = useState<boolean>(true);
  const [isAddEquipmentButtonDisabled, setIsAddEquipmentButtonDisabled] = useState<boolean>(true);

  const [errorMessageBar, setErrorMessageBar] = useState<string>("");
  const [errorMessageDropdown, setErrorMessageDropdown] = useState<string>("");
  const [errorInputs, setErrorInputs] = useState<boolean>(true); //Empty or input's errors when clicked Save.

  useEffect(() => {
    equipmentsService
      .getAllEquipmentTypes()
      .then((res) => {
        setMappedEquipmentsType(mapEquipmentTypeToIDropdownOptions(res));
        setEquipmentTypesLoaded(false);
      })
      .catch((error) => {
        openPopup(
          <FeedbackPopup type="error">
            <p>{error}</p>
          </FeedbackPopup>
        );
      });
  }, []);

  const onChangeEquipmentType = useCallback(
    (item: IDropdownOption, index?: number): void => {
      setEquipmentType(item);
    },
    [setEquipmentType]
  );

  useEffect(() => {
    if (reference && description && brand && serialNumber && equipmentType !== undefined) setIsAddEquipmentButtonDisabled(false);
    else setIsAddEquipmentButtonDisabled(true);
  }, [reference, description, brand, serialNumber, equipmentType, purchaseDate]);

  const deletePhoto = useCallback(
    (item: File) => {
      const index = equipmentImages.findIndex((obj) => obj.name === item.name);
      let arrayCopy = [...equipmentImages];
      if (!arrayCopy.splice(index, 1)) {
        setError(false);
      }
      setEquipmentImages(arrayCopy);
    },
    [equipmentImages, setEquipmentImages]
  );

  //Images details
  const columns: IColumn[] = useMemo(
    () => [
      {
        key: "column1",
        name: "Image",
        isRowHeader: true,
        isResizable: true,
        fieldName: "image",
        minWidth: 40,
        maxWidth: 40,
        data: "string",
        onRender: (item) => {
          let imgWrapper = {
            backgroundImage: "url(" + URL.createObjectURL(item) + ")",
          };
          return <div className="img-wrapper" style={imgWrapper}></div>;
        },
      },
      {
        key: "column2",
        name: "Name",
        isRowHeader: true,
        isResizable: true,
        fieldName: "name",
        minWidth: 100,
        maxWidth: 250,
        data: "string",
        onRender: (item) => <span> {item.name} </span>,
      },
      {
        key: "column3",
        name: "Remove",
        fieldName: "remove",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        onRender: (item) => <IconButton iconProps={deleteIcon} onClick={() => deletePhoto(item)} />,
      },
    ],
    [deletePhoto]
  );

  const onClickAddEquipment = useCallback(() => {
    openOverlaySpinner(<Spinner size={SpinnerSize.large} />);

    setErrorInputs(true);
    var newEquipment: EquipmentsFieldsCreateDTO = {
      reference: reference || "",
      typeID: Number(equipmentType?.key) || 0,
      description: description || "",
      brand: brand || "",
      model: model,
      purchaseDate: purchaseDate ? purchaseDate?.toISOString() : undefined,
      serialNumber: serialNumber || "",
    };

    equipmentsService
      .createNewEquipment(newEquipment, equipmentImages)
      .then((_) => {
        closeOverlaySpinner();
        props.onCompletedActionPanel();
        closePanel();
      })
      .catch((error) => {
        closeOverlaySpinner();
        setErrorMessageBar(error);
        setError(false);
      });
  }, [
    brand,
    reference,
    openPopup,
    closePanel,
    description,
    serialNumber,
    purchaseDate,
    equipmentType,
    setShowSpinner,
    setErrorInputs,
    equipmentImages,
    openOverlaySpinner,
    closeOverlaySpinner,
    props.onCompletedActionPanel,
  ]);

  //Save photos to the File[]
  const onChangePhotos = useCallback(
    (event: any) => {
      var files = event.target.files;
      var equipmentImagesCopy = [...equipmentImages];

      //For multiple image inputs.
      for (var i = 0; i < files.length; i++) {
        const index = equipmentImages.findIndex((obj) => obj.name === files[i].name);
        if (index === -1) {
          equipmentImagesCopy.push(files[i]);
        }
      }
      setEquipmentImages(equipmentImagesCopy);
    },
    [equipmentImages, setEquipmentImages]
  );

  const closeMessageBar = (event: React.MouseEvent<HTMLElement | BaseButton | Button, MouseEvent> | undefined) => {
    setError(true);
    setErrorInputs(true);
  };

  return (
    <div className="add-equipment-content">
      <CardHeader headerTitle={translate("EQUIPMENTS.AddEquipment")} icon={true} setDisplayState={(state: boolean) => closePanel()}></CardHeader>
      <div className="side-panel-form">
        <Dropdown
          placeholder={equipmentTypesLoaded ? translate("Loading.PleaseWait") : translate("EQUIPMENTS.ADDEQUIPPANEL.PhType")}
          label={translate("COMMON.Type")}
          selectedKey={equipmentType?.key}
          onChanged={onChangeEquipmentType}
          options={mappedEquipmentsType || []}
          required={true}
          errorMessage={errorMessageDropdown}
          disabled={equipmentTypesLoaded}
        />
        <TextField
          onChange={(ev, response) => {
            setReference(response || "");
          }}
          label={translate("EQUIPMENTS.COMMON.Ref")}
          placeholder="Ex: LP01"
          required
          onGetErrorMessage={getErrorMessage}
          validateOnLoad={false}
        />
        <TextField
          onChange={(ev, response) => {
            setSerialNumber(response || "");
          }}
          label={translate("EQUIPMENTS.COMMON.SerialNumber")}
          placeholder="Ex: 7CH203QM1"
          required
          onGetErrorMessage={getErrorMessage}
          /*errorMessage={serialNumber ? "erro" : "" } */
          validateOnLoad={false}
        />
        <TextField
          onChange={(ev, response) => {
            setBrand(response || "");
          }}
          label={translate("EQUIPMENTS.COMMON.Brand")}
          placeholder="Ex: Apple"
          required
          onGetErrorMessage={getErrorMessage}
          validateOnLoad={false}
        />
        <TextField
          onChange={(ev, response) => {
            setModel(response || "");
          }}
          label={translate("EQUIPMENTS.COMMON.Model")}
          placeholder="Ex: Macbook Pro"
        />
        <TextField
          label={translate("EQUIPMENTS.COMMON.Description")}
          placeholder="Ex: Lorem Ipsum"
          onChange={(ev, response) => {
            setDescription(response || "");
          }}
          multiline
          rows={3}
          required
          onGetErrorMessage={getErrorMessage}
          validateOnLoad={false}
        />
        <DatePicker
          className="datepicker"
          maxDate={maxDate}
          //minDate={minDate} is this needed?
          placeholder={translate("EQUIPMENTS.ADDEQUIPPANEL.PhDate")}
          label={translate("EQUIPMENTS.COMMON.Date")}
          onSelectDate={setPurchaseDate as (date: Date | null | undefined) => void}
          strings={defaultDatePickerStrings}
          formatDate={(date) => dayjs(date).format("DD-MM-YYYY")}
          value={purchaseDate}
        />
        <Label>{translate("EQUIPMENTS.COMMON.Images")}</Label>
        <div className="upload-btn-wrapper">
          <input type="file" name="file-1[]" id="file-1" className="inputfile" accept="image/png, image/jpeg" onChange={onChangePhotos} multiple />
          <Label className="upload-btn-label" htmlFor="file-1">
            <IconUpload />
            <span>{translate("EQUIPMENTS.COMMON.UploadImages")}</span>
          </Label>
        </div>
        {equipmentImages.length < 1 ? (
          <div className="photos-list-empty">{translate("EQUIPMENTS.COMMON.NoImagesUploaded")}</div>
        ) : (
          <DetailsList
            className="photos-list"
            items={equipmentImages}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={false}
          />
        )}
        {!error ? (
          <MessageBar
            role="alert"
            messageBarType={MessageBarType.error}
            onDismiss={(event) => {
              closeMessageBar(event);
            }}
            dismissButtonAriaLabel="Close"
          >
            <b>{errorMessageBar}</b>
          </MessageBar>
        ) : null}
        {!errorInputs ? (
          <MessageBar
            role="alert"
            messageBarType={MessageBarType.error}
            onDismiss={(event) => {
              closeMessageBar(event);
            }}
            dismissButtonAriaLabel="Close"
          >
            <b>Some fields are mandatory!</b>
          </MessageBar>
        ) : null}
      </div>

      <div className="action-btns">
        <TertiaryButton text={translate("ACTIONS.Cancel")} onClick={() => closePanel()} />
        <PrimaryButton text={translate("ACTIONS.Save")} onClick={onClickAddEquipment} isDisabled={isAddEquipmentButtonDisabled}></PrimaryButton>
      </div>
    </div>
  );
}
