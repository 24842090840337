import React from "react";
import { SidePanel } from "../../../../components/layouts/side-panel/SidePanel";

interface ISidePanelManagementProps {
  children: React.ReactNode;
}

export function SidePanelManagement(props: ISidePanelManagementProps) {
  return <SidePanel>{props.children}</SidePanel>;
}
