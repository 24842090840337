import {
  ComboBox,
  CommandBar,
  DefaultButton,
  getInitials,
  IComboBoxOption,
  ICommandBarItemProps,
  Label,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { SecondaryButton } from "../../../../components/buttons/SecondaryButton";
import { TertiaryButton } from "../../../../components/buttons/TertiaryButton";
import { CardHeader } from "../../../../components/cards/CardHeader";
import { useClosePanel } from "../../../../infrastructure/ui/UIServices";
import { AllocationsService } from "../../../../services/AllocationsService";
import { EquipmentsService, EquipmentsFiltersDTO } from "../../../../services/EquipmentsService";
import { UsersService } from "../../../../services/UsersService";
import { translate } from "../../../../infrastructure/i18n/InternationalizationService";
import "./EquipmentsFiltersPanel.css";

var equipmentsService = new EquipmentsService();
var allocationsService = new AllocationsService();
var usersService = new UsersService();

interface IProps {
  filters: (request: EquipmentsFiltersDTO) => void;
  equipmentTypes: number[];
  statusIdFilter: number[];
  equipmentStatuses: number[];
  employeeFilter: string;
  inRepairFilter?: number;
  needsRepairFilter?: number;
  availableComputersFilter?: boolean;
  availableMonitorsFilter?: boolean;
  searchFor?: string;
}

export function EquipmentsFiltersPanel(props: IProps) {
  //Close side panel
  const closePanel = useClosePanel();

  const [users, setUsers] = useState<IComboBoxOption[]>([]);
  const [allocationStatus, setAllocationStatus] = useState<IComboBoxOption[]>([]);
  const [equipmentTypes, setEquipmentTypes] = useState<IComboBoxOption[]>([]);
  const [equipmentStatuses, setEquipmentStatuses] = useState<IComboBoxOption[]>([]);
  const [employeeSelected, setEmployeeSelected] = useState<string>(props.employeeFilter);
  const [allocationStatusSelected, setAllocationsStatusSelected] = useState<number[]>(props.statusIdFilter);
  const [equipmentTypesSelected, setEquipmentTypesSelected] = useState<number[]>(props.equipmentTypes);
  const [equipmentStatusesSelected, setEquipmentStatusesSelected] = useState<number[]>(props.equipmentStatuses);
  const [searchFor, setSearchFor] = useState<string>(props.searchFor || "");

  const [isSpinnerEnabled, setIsSpinnerEnabled] = useState<boolean>(true);

  /* Quick templates states */
  const [equipmentStatusNeedsRepairID, setEquipmentStatusNeedsRepairID] = useState<number>();
  const [equipmentStatusInRepairID, setEquipmentStatusInRepairID] = useState<number>();
  const [inRepairSelected, setInRepairSelected] = useState<number | undefined>(props.inRepairFilter);
  const [needsRepairSelected, setNeedsRepairSelected] = useState<number | undefined>(props.needsRepairFilter);

  const [equipmentTypePcId, setEquipmentTypePcId] = useState<number>();
  const [equipmentTypeMonitorId, setEquipmentTypeMonitorId] = useState<number>();
  const [equipmentStatusActiveId, setEquipmentStatusActiveId] = useState<number>();
  const [availableAllocationStatusIDs, setAvailableAllocationStatusIDs] = useState<number[]>([]);

  useEffect(() => {
    Promise.all([usersService.getAll(), equipmentsService.getAllEquipmentTypes(), allocationsService.getAllocationsStatus(), equipmentsService.getAllEquipmentStatus()])
      .then((res) => {
        let typesList: IComboBoxOption[] = res[1].map((r) => {
          if (r.code === "PC") {
            setEquipmentTypePcId(r.id);
          }
          if (r.code === "SCREEN") {
            setEquipmentTypeMonitorId(r.id);
          }

          let type: IComboBoxOption = {
            key: r.id,
            text: r.description,
            data: r.code,
          };
          return type;
        });

        let usersList: IComboBoxOption[] = res[0].map((r) => {
          let user: IComboBoxOption = {
            text: r.displayName,
            key: r.userPrincipalName,
            data: r.url,
          };
          return user;
        });

        let statesList: IComboBoxOption[] = res[2].map((r) => {
          let state: IComboBoxOption = {
            key: r.id,
            text: r.name,
            data: r.code,
          };
          return state;
        });

        statesList.push({ key: -1, text: "No allocation", data: "EMPTY" });

        setAvailableAllocationStatusIDs(
          statesList
            .filter((ob) => ob.data === "CLOSED" || ob.data === "CANCELED" || ob.data === "EMPTY")
            .map((obj) => {
              return Number(obj.key);
            })
        );

        let statusesList: IComboBoxOption[] = res[3].map((r) => {
          if (r.code === "OK") {
            setEquipmentStatusActiveId(r.id);
          }
          if (r.code === "IN_REPAIR") {
            setEquipmentStatusInRepairID(r.id);
          }
          if (r.code === "NEEDS_REPAIR") {
            setEquipmentStatusNeedsRepairID(r.id);
          }
          let status: IComboBoxOption = {
            key: r.id,
            text: r.description,
            data: r.code,
          };
          return status;
        });

        setEquipmentTypes(typesList);
        setUsers(usersList);
        setAllocationStatus(statesList);
        setEquipmentStatuses(statusesList);
        setIsSpinnerEnabled(false);
      })
      .catch((error) => {
        setIsSpinnerEnabled(false);
      });
  }, []);

  const employeeSelection = useCallback(
    (item: IComboBoxOption) => {
      if (!item) return;
      setEmployeeSelected(item.key as string);
      return item;
    },
    [setEmployeeSelected]
  );

  const onChangeEquipmentTypes = useCallback(
    (input: any) => {
      const index = equipmentTypesSelected.findIndex((obj) => obj === input);
      var res = equipmentTypesSelected.includes(input);
      var arrayCopy = [...equipmentTypesSelected];
      if (!res) {
        arrayCopy.push(input);
      } else {
        arrayCopy.splice(index, 1);
      }
      setEquipmentTypesSelected(arrayCopy);
    },
    [equipmentTypesSelected, setEquipmentTypesSelected]
  );

  const onChangeAllocationStatus = useCallback(
    (input: any) => {
      const index = allocationStatusSelected.findIndex((obj) => obj === input);
      var res = allocationStatusSelected.includes(input);
      var arrayCopy = [...allocationStatusSelected];
      if (!res) {
        arrayCopy.push(input);
      } else {
        arrayCopy.splice(index, 1);
      }
      setAllocationsStatusSelected(arrayCopy);
    },
    [allocationStatusSelected, setAllocationsStatusSelected]
  );

  const handlerClickSave = useCallback(() => {
    var request: EquipmentsFiltersDTO = {
      searchFor: searchFor,
      equipmentTypesId: equipmentTypesSelected,
      allocatedTo: employeeSelected,
      allocationStatusesId: allocationStatusSelected,
      equipmentStatusesId: equipmentStatusesSelected,
      inRepairId: inRepairSelected,
      needsRepairId: needsRepairSelected,
    };
    props.filters(request);
    closePanel();
  }, [equipmentTypesSelected, employeeSelected, allocationStatusSelected, equipmentStatusesSelected, props.filters, closePanel]);

  const handlerClickReset = useCallback(() => {
    setEmployeeSelected("");
    setAllocationsStatusSelected([]);
    setEquipmentTypesSelected([]);
    setInRepairSelected(undefined);
    setNeedsRepairSelected(undefined);
    setEquipmentStatusesSelected([]);
  }, [setEmployeeSelected, setAllocationsStatusSelected, setEquipmentTypesSelected, setEquipmentStatusesSelected, setInRepairSelected, setNeedsRepairSelected]);

  const onChangeEquipmentStatuses = useCallback(
    (input: any) => {
      const index = equipmentStatusesSelected.findIndex((obj) => obj === input);
      var res = equipmentStatusesSelected.includes(input);
      var arrayCopy = [...equipmentStatusesSelected];

      if (!res) {
        arrayCopy.push(input);
      } else {
        arrayCopy.splice(index, 1);
      }
      setEquipmentStatusesSelected(arrayCopy);
    },
    [
      equipmentStatusesSelected,
      setEquipmentStatusesSelected,
      equipmentStatusInRepairID,
      equipmentStatusNeedsRepairID,
      setNeedsRepairSelected,
      needsRepairSelected,
      setInRepairSelected,
      inRepairSelected,
    ]
  );

  const onClickTemplatesEquipmentOperationalStatus = useCallback(
    (input: number) => {
      setEmployeeSelected("");
      setAllocationsStatusSelected([]);
      setEquipmentTypesSelected([]);
      setEquipmentStatusesSelected([Number(input)]);
    },
    [setEmployeeSelected, setAllocationsStatusSelected, setEquipmentStatusesSelected, setEquipmentTypesSelected, equipmentStatusNeedsRepairID, equipmentStatusInRepairID]
  );

  const onClickAvailables = useCallback(
    (input: string) => {
      setEmployeeSelected("");
      setInRepairSelected(undefined);
      setNeedsRepairSelected(undefined);
      setAllocationsStatusSelected(availableAllocationStatusIDs);
      setEquipmentStatusesSelected([Number(equipmentStatusActiveId)]);

      var equipmentTypesArray: number[] = [];

      if (input === "PC") {
        equipmentTypesArray.push(Number(equipmentTypePcId));
        setEquipmentTypesSelected(equipmentTypesArray);
      }
      if (input === "MONITOR") {
        equipmentTypesArray.push(Number(equipmentTypeMonitorId));
        setEquipmentTypesSelected(equipmentTypesArray);
      }
    },
    [
      setInRepairSelected,
      inRepairSelected,
      setNeedsRepairSelected,
      needsRepairSelected,
      allocationStatusSelected,
      setAllocationsStatusSelected,
      equipmentTypesSelected,
      setEquipmentTypesSelected,
      equipmentStatusesSelected,
      setEquipmentStatusesSelected,
      availableAllocationStatusIDs,
      equipmentTypePcId,
      equipmentTypeMonitorId,
      equipmentStatusActiveId,
      setEmployeeSelected,
      employeeSelected,
    ]
  );

  return (
    <div className="filters-panel">
      <CardHeader headerTitle={translate("EQUIPMENTS.FILTERS.MainTitle")} icon={true} setDisplayState={(state: boolean) => closePanel()} />
      <div className="side-panel-form">
        {isSpinnerEnabled ? (
          <Spinner size={SpinnerSize.large} label="Please, wait ..." />
        ) : (
          <>
            <div className="quick-templates">
              <Label>{translate("EQUIPMENTS.FILTERS.TemplatesTitle")}</Label>
              <div className="btns-container">
                <DefaultButton
                  className="btns"
                  onClick={() => {
                    if (equipmentStatusInRepairID) {
                      onClickTemplatesEquipmentOperationalStatus(equipmentStatusInRepairID);
                    }
                  }}
                  text={translate("EQUIPMENTS.FILTERS.TemplateInRepair")}
                />
                <DefaultButton
                  className="btns"
                  onClick={() => {
                    onClickTemplatesEquipmentOperationalStatus(Number(equipmentStatusNeedsRepairID));
                  }}
                  text={translate("EQUIPMENTS.FILTERS.TemplateNeedsRepair")}
                />
                <DefaultButton
                  className="btns"
                  text={translate("EQUIPMENTS.FILTERS.TemplateAvailablePC")}
                  onClick={() => {
                    onClickAvailables("PC");
                  }}
                />
                <DefaultButton
                  className="btns"
                  text={translate("EQUIPMENTS.FILTERS.TemplateMonitor")}
                  onClick={() => {
                    onClickAvailables("MONITOR");
                  }}
                />
              </div>
              <div className="line-separator" />
            </div>
            <div className="side-panel-form-scroll">
              <Label>{translate("EQUIPMENTS.FILTERS.AllocateTo")}</Label>
              <ComboBox
                allowFreeform={true}
                placeholder={translate("EQUIPMENTS.FILTERS.PhAllocateTo")}
                autoComplete={"on"}
                options={users}
                onItemClick={(ev, item, index) => {
                  if (item) employeeSelection(item);
                }}
                onChange={(event, value) => {
                  if (value) employeeSelection(value);
                }}
                onRenderOption={(item) => {
                  if (item) {
                    return (
                      <>
                        <Persona text={item.text} imageInitials={getInitials(item.text, false, true)} imageUrl={item.data} size={PersonaSize.size24} />
                      </>
                    );
                  }
                  return null;
                }}
                selectedKey={employeeSelected}
                required={true}
              />
              <ComboBox
                label={translate("EQUIPMENTS.FILTERS.EquipmentType")}
                placeholder={translate("EQUIPMENTS.FILTERS.PhEquipmentType")}
                options={equipmentTypes}
                multiSelect
                selectedKey={equipmentTypesSelected}
                onChange={(event, option) => {
                  onChangeEquipmentTypes(option?.key);
                }}
              />
              <ComboBox
                label={translate("EQUIPMENTS.FILTERS.AllocationStatus")}
                placeholder={translate("EQUIPMENTS.FILTERS.PhAllocationStatus")}
                multiSelect
                options={allocationStatus}
                onChange={(event, option) => {
                  onChangeAllocationStatus(option?.key);
                }}
                selectedKey={allocationStatusSelected}
              />
              <ComboBox
                label={translate("EQUIPMENTS.FILTERS.EquipmentStatus")}
                placeholder={translate("EQUIPMENTS.FILTERS.PhEquipmentStatus")}
                options={equipmentStatuses}
                multiSelect
                selectedKey={equipmentStatusesSelected}
                onChange={(event, option) => {
                  onChangeEquipmentStatuses(option?.key);
                }}
              />
            </div>
            <div className="btns">
              <TertiaryButton text="Reset" onClick={handlerClickReset} />
              <SecondaryButton text={translate("EQUIPMENTS.FILTERS.ClickApplyFilters")} onClick={handlerClickSave} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
