import "./ApprovalGroupInfoPage.css";

import {
  CommandBar,
  DetailsListLayoutMode,
  getInitials,
  IColumn,
  ICommandBarItemProps,
  IconButton,
  IIconProps,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useCloseOverlaySpinner, useOverlaySpinner, usePopup, useWindowResize } from "../../../infrastructure/ui/UIServices";

import { FeatureFlags } from "../../../infrastructure/features/Flags";

import { useActiveFeatures } from "../../../infrastructure/features/hooks/useFeatures";
import { useNavigate, useParams } from "react-router-dom";
import { usePageTitle } from "../../../infrastructure/navigation/hooks/usePageTitle";

import { ApprovalGroupsService } from "../../../services/ApprovalGroupsService";
import { useUpdatePageData } from "../../../infrastructure/navigation/hooks/useUpdatePageData";
import { CardLayout } from "../../../components/layouts/card-layout/CardLayout";
import { InfoBlock } from "../../../components/cards/InfoBlock";
import { Spacer } from "../../../components/layouts/spacer/Spacer";
import { ApprovalGroupInfo } from "../../../models/approval-groups/ApprovalGroupInfo";
import { FeedbackPopup } from "../../../components/layouts/popup/FeedbackPopup";
import { AddGroupMemberPopup } from "./popup/AddGroupMemberPopup";

const deleteIcon: IIconProps = { iconName: "Delete" };

var approvalGroupsService = new ApprovalGroupsService();

export function ApprovalGroupInfoPage() {
  const navigate = useNavigate();
  const addToRouteData = useUpdatePageData();
  const openPopup = usePopup();
  const openOverlaySpinner = useOverlaySpinner();
  const closeOverlaySpinner = useCloseOverlaySpinner();
  const isPageActive = useActiveFeatures([FeatureFlags.MANAGER_APPROVALGROUPS_LIST]);
  const windowResize = useWindowResize();
  const updatePageTitle = usePageTitle();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingGroupMemberLists, setIsLoadingGroupMemberLists] = useState<boolean>(true);

  const [groupDetails, setGroupDetails] = useState<ApprovalGroupInfo>();

  const { id: groupId } = useParams();

  if (!isPageActive) {
    navigate("/error");
  }

  useEffect(() => {
    updatePageTitle("Approval Group Info");
    addToRouteData(["##GROUPS##", "..."]);
    getApprovalGroupInfo();
  }, []);

  const getApprovalGroupInfo = useCallback(() => {
    setIsLoadingGroupMemberLists(true);
    if (groupId) {
      approvalGroupsService
        .getApprovalGroupInfo(groupId)
        .then((response) => {
          setGroupDetails(response);
          addToRouteData(["##GROUPS##", response.groupCode]);
          setIsLoading(false);
          setIsLoadingGroupMemberLists(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsLoadingGroupMemberLists(false);
          openPopup(
            <FeedbackPopup type="error">
              <p>{err}</p>
            </FeedbackPopup>
          );
        });
    }
  }, [openPopup, setIsLoading, setIsLoadingGroupMemberLists, setGroupDetails, addToRouteData, groupId]);

  const onClickRemoveGroupMember = useCallback(
    (groupMemberId: number) => {
      if (groupMemberId && groupDetails?.groupCode) {
        approvalGroupsService
          .removeGroupMember(groupMemberId, groupDetails.groupCode)
          .then(() => {
            openPopup(
              <FeedbackPopup type="success">
                <p>{"Removed with success!"}</p>
              </FeedbackPopup>
            );
            getApprovalGroupInfo();
          })
          .catch((error) => {
            if (error.response.data) {
              openPopup(
                <FeedbackPopup type="error">
                  <p>{error.response.data}</p>
                </FeedbackPopup>
              );
            } else {
              openPopup(
                <FeedbackPopup type="error">
                  <p>{error.code} </p>
                  <p>{error.message}</p>
                </FeedbackPopup>
              );
            }
          });
      } else
        openPopup(
          <FeedbackPopup type="error">
            <p>{"Failed to get the group member ID and group code."}</p>
          </FeedbackPopup>
        );
    },
    [openPopup, getApprovalGroupInfo]
  );

  const approverColumns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: "Name",
        fieldName: "approver",
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        minWidth: 50,
        onRender: (item) => (
          <div className="truncate">
            <Persona
              text={item.employee.displayName}
              imageInitials={getInitials(item.employee.displayName, false, true)}
              imageUrl={item.employee.url}
              size={PersonaSize.size24}
            />
          </div>
        ),
        data: "string",
      },
      {
        key: "column2",
        name: "Remove",
        fieldName: "remove",
        minWidth: 30,
        isRowHeader: true,
        isIconOnly: true,
        isCollapsible: false,
        data: "string",
        onRender: (item) => {
          return (
            <IconButton
              disabled={groupDetails?.approversEmployees.length === 1 ? true : false}
              onClick={(e) => {
                openPopup(
                  <FeedbackPopup
                    type="warning"
                    title={"Remove approver?"}
                    primaryActionLabel={"Remove"}
                    primaryActionFunc={() => {
                      onClickRemoveGroupMember(item.groupMemberId);
                    }}
                  >
                    <p>{"Are you sure you want to remove this approver?"}</p>
                  </FeedbackPopup>
                );
              }}
              iconProps={deleteIcon}
            />
          );
        },
      },
    ];
  }, [onClickRemoveGroupMember, openPopup]);

  const memberColumns: IColumn[] = useMemo(() => {
    return [
      {
        key: "column1",
        name: "Name",
        fieldName: "member",
        isRowHeader: true,
        isResizable: true,
        isCollapsible: false,
        minWidth: 50,
        onRender: (item) => (
          <div className="truncate">
            <Persona
              text={item.employee.displayName}
              imageInitials={getInitials(item.employee.displayName, false, true)}
              imageUrl={item.employee.url}
              size={PersonaSize.size24}
            />
          </div>
        ),
        data: "string",
      },
      {
        key: "column2",
        name: "Remove",
        fieldName: "remove",
        minWidth: 30,
        isRowHeader: true,
        isIconOnly: true,
        isCollapsible: false,
        data: "string",
        onRender: (item) => {
          return (
            <IconButton
              disabled={groupDetails?.membersEmployees.length === 1 ? true : false}
              onClick={(e) => {
                openPopup(
                  <FeedbackPopup
                    type="warning"
                    title={"Remove member?"}
                    primaryActionLabel={"Remove"}
                    primaryActionFunc={() => {
                      onClickRemoveGroupMember(item.groupMemberId);
                    }}
                  >
                    <p>{"Are you sure you want to remove this member?"}</p>
                  </FeedbackPopup>
                );
              }}
              iconProps={deleteIcon}
            />
          );
        },
      },
    ];
  }, [onClickRemoveGroupMember]);

  const _itemsApprovers: ICommandBarItemProps[] = [
    {
      className: "add-employee",
      key: "addEmployee",
      text: windowResize > 768 ? "Add" : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () => {
        if (groupDetails?.groupCode) {
          openPopup(
            <AddGroupMemberPopup
              typeOfEmployee="approver"
              alreadySelectedEmployees={[...groupDetails?.approversEmployees, ...groupDetails?.membersEmployees]}
              groupCode={groupDetails?.groupCode}
              isApprover={true}
              onCompletedOperations={() => {
                getApprovalGroupInfo();
              }}
            />
          );
        }
      },
    },
  ];

  const _itemsMembers: ICommandBarItemProps[] = [
    {
      className: "add-activity",
      key: "addMember",
      text: windowResize > 768 ? "Add" : "",
      iconProps: windowResize > 768 ? { iconName: "AddTo" } : { iconName: "Add" },
      onClick: () => {
        if (groupDetails?.groupCode) {
          openPopup(
            <AddGroupMemberPopup
              typeOfEmployee="member"
              alreadySelectedEmployees={[...groupDetails?.approversEmployees, ...groupDetails?.membersEmployees]}
              groupCode={groupDetails?.groupCode}
              isApprover={false}
              onCompletedOperations={() => {
                getApprovalGroupInfo();
              }}
            />
          );
        }
      },
    },
  ];

  return (
    <div className={"group-wrapper"}>
      <div className="group">
        <CardLayout classNames={"group-info-card"}>
          {isLoading ? (
            <Spinner size={SpinnerSize.large} label={"Loading, please wait ..."} />
          ) : (
            <>
              <div className="header">
                <h1 className="truncate">{groupDetails?.groupName ? groupDetails.groupName : "-"}</h1>
              </div>
              <div className="group-info">
                <InfoBlock label={"Code"} text={groupDetails?.groupCode ? groupDetails.groupCode : "-"} />
                <InfoBlock label={"Approvers"} text={groupDetails?.approversEmployees ? groupDetails.approversEmployees.length.toString() : "-"} />
                <InfoBlock label={"Members"} text={groupDetails?.membersEmployees ? groupDetails.membersEmployees.length.toString() : "-"} />
              </div>
            </>
          )}
        </CardLayout>
        <Spacer mode="vertical" />
        <CardLayout classNames="group-approvers-card">
          <div className="commandbar">
            <h1>{"Approvers"}</h1>
            <div className="action-btns">
              <CommandBar items={_itemsApprovers} ariaLabel="Employees action buttons" />
            </div>
          </div>

          <div className="group-approvers">
            <ShimmeredDetailsList
              className="group-approvers-list"
              enableShimmer={isLoadingGroupMemberLists}
              items={groupDetails?.approversEmployees || []}
              columns={approverColumns}
              onShouldVirtualize={(_) => false}
              selectionMode={SelectionMode.none}
              setKey="none"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          </div>
        </CardLayout>
      </div>
      <Spacer mode="horizontal" />
      <CardLayout classNames="group-members-card">
        <div className="commandbar">
          <h1>{"Members"}</h1>
          <div className="action-btns">
            <CommandBar items={_itemsMembers} ariaLabel="Action buttons" />
          </div>
        </div>
        <div className="wrapper">
          <ShimmeredDetailsList
            enableShimmer={isLoadingGroupMemberLists}
            className="members-list"
            items={groupDetails?.membersEmployees || []}
            columns={memberColumns}
            selectionMode={SelectionMode.none}
            onShouldVirtualize={(_) => false}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </div>
      </CardLayout>
    </div>
  );
}
